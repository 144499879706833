type AppConfig = {
  apiUrl: string;
  gaMeasurementId: string | null;
  sentryDsn: string | null;
  sentryEnvironment: string | null;
  launchDarklyKey: string;
  googleMapsKey: string;
  auth0Domain: string;
  auth0ClientId: string;
  auth0Audience: string;
};

declare global {
  interface Window {
    __config: AppConfig;
  }
}

export default window.__config || {
  apiUrl: "http://localhost:9000",
  gaMeasurementId: null,
  sentryDsn: null,
  sentryEnvironment: null,
  launchDarklyKey: "6303a50854e511124387c35c",
  googleMapsKey: "AIzaSyCTipuUf3-s4-Eqtw4XNJTd4tGk4RxHPm8",
  auth0Domain: "dev-heatu2omprdmi5bc.us.auth0.com",
  auth0ClientId: "SQf30JfPEUNnU3wXvl8dGQEKPfPjQDwR",
  auth0Audience: "backend.local",
};

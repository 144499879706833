import React, { useState } from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { useQueryClient } from "react-query";

import {
  PageHeader,
  PageHeaderBackLink,
  PageHeaderSubtitle,
  PageHeaderTitle,
} from "src/layout/PageHeader";
import { PageContent } from "src/layout/PageContent";
import { EditAccessProfileForm } from "src/routes/Property/AccessControl/Profiles/components/EditAccessProfileForm";
import { getEntryGroupQueryKey } from "src/routes/Property/queries";
import { handleHookFormHTTPError } from "src/common/form";

export const EditAccessProfile = () => {
  const { dealerOrganizationId, propertyOrganizationId, accessProfileId } =
    useParams();

  const toast = useToast();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <PageHeader>
        <PageHeaderBackLink>
          <Link
            as={RouterLink}
            to={`/dealer-organizations/${dealerOrganizationId}/properties/${propertyOrganizationId}/access-control?panelTab=profiles`}
          >
            &#8249; Back to list
          </Link>
        </PageHeaderBackLink>
        <PageHeaderTitle>Edit Profile</PageHeaderTitle>
        <PageHeaderSubtitle>Enter profile details below</PageHeaderSubtitle>
      </PageHeader>
      <PageContent>
        <EditAccessProfileForm
          accessProfileId={accessProfileId as string}
          propertyOrganizationId={propertyOrganizationId as string}
          mutationOptionsBuilder={({ setError, getValues }) => ({
            onSuccess: () => {
              toast({
                description: "Profile updated",
                status: "success",
              });
              navigate(
                `/dealer-organizations/${dealerOrganizationId}/properties/${propertyOrganizationId}/access-control?panelTab=profiles`
              );
              queryClient.invalidateQueries(
                getEntryGroupQueryKey(accessProfileId as string)
              );
            },
            onError: handleHookFormHTTPError(setError, getValues, toast),
          })}
          Footer={({ isSubmitting }) => (
            <ButtonGroup display="flex" justifyContent="flex-end">
              <Link
                as={RouterLink}
                to={`/dealer-organizations/${dealerOrganizationId}/properties/${propertyOrganizationId}/access-control?panelTab=profiles`}
              >
                <Button>Cancel</Button>
              </Link>
              <Button
                isLoading={isSubmitting}
                colorScheme={"brand.blue"}
                onClick={() => setIsModalOpen(true)}
              >
                Save
              </Button>
            </ButtonGroup>
          )}
        >
          {({ onSubmit }) => (
            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Edit Access Profile</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  Are you sure you want to edit this access profile?
                </ModalBody>
                <ModalFooter>
                  <Flex justifyContent={"flex-end"}>
                    <Button
                      colorScheme="brand.lightGray"
                      color="gray.700"
                      mr={3}
                      onClick={() => setIsModalOpen(false)}
                    >
                      Close
                    </Button>
                    <Button
                      colorScheme="brand.blue"
                      onClick={async () => {
                        setIsModalOpen(false);
                        await onSubmit();
                      }}
                      type={"submit"}
                      ml={4}
                    >
                      Save Changes
                    </Button>
                  </Flex>
                </ModalFooter>
              </ModalContent>
            </Modal>
          )}
        </EditAccessProfileForm>
      </PageContent>
    </>
  );
};

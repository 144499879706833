import React from "react";
import { createIcon } from "@chakra-ui/react";

export const CircleInfo = createIcon({
  displayName: "CircleInfo",
  viewBox: "0 0 14 14",
  path: (
    <path
      fill="currentColor"
      d="M6.33398 5.00065H7.66732V3.66732H6.33398M7.00065 12.334C4.06065 12.334 1.66732 9.94065 1.66732 7.00065C1.66732 4.06065 4.06065 1.66732 7.00065 1.66732C9.94065 1.66732 12.334 4.06065 12.334 7.00065C12.334 9.94065 9.94065 12.334 7.00065 12.334ZM7.00065 0.333984C6.12517 0.333984 5.25827 0.506423 4.44943 0.841454C3.64059 1.17649 2.90566 1.66755 2.28661 2.28661C1.03636 3.53685 0.333984 5.23254 0.333984 7.00065C0.333984 8.76876 1.03636 10.4645 2.28661 11.7147C2.90566 12.3338 3.64059 12.8248 4.44943 13.1598C5.25827 13.4949 6.12517 13.6673 7.00065 13.6673C8.76876 13.6673 10.4645 12.9649 11.7147 11.7147C12.9649 10.4645 13.6673 8.76876 13.6673 7.00065C13.6673 6.12517 13.4949 5.25827 13.1598 4.44943C12.8248 3.64059 12.3338 2.90566 11.7147 2.28661C11.0956 1.66755 10.3607 1.17649 9.55187 0.841454C8.74304 0.506423 7.87613 0.333984 7.00065 0.333984ZM6.33398 10.334H7.66732V6.33398H6.33398V10.334Z"
    />
  ),
});

import { pick } from "lodash";
import * as Yup from "yup";

import { YupPossiblePhoneNumber } from "src/common/phone-numbers";
import { omit } from "src/common/util";

export const IntercomActivationCreateSchema = {
  name: Yup.string().required().default("").label("Name"),
  serialNumber: Yup.string().required().default("").label("Serial Number"),
  enableResidentDirectory: Yup.boolean()
    .required()
    .default(true)
    .label("Enable Tenant Directory"),
  enableNumericCodes: Yup.boolean()
    .required()
    .default(true)
    .label("Enable Numeric Codes"),
  enableQrCodes: Yup.boolean()
    .required()
    .default(true)
    .label("Enable QR Codes"),
  enableCallManager: Yup.boolean()
    .required()
    .default(true)
    .label("Enable Call a Manager"),
  enableVideoCalls: Yup.boolean()
    .required()
    .default(true)
    .label("Enable Video Calls"),
  enableDMI: Yup.boolean()
    .required()
    .default(false)
    .label("Enable Virtual Intercom"),
  enableDMIUA: Yup.boolean()
    .required()
    .default(true)
    .label("Enable Mobile Device Check"),
  requireNfcTap: Yup.boolean()
    .required()
    .default(false)
    .label("Require NFC Tap"),
  lat: Yup.number()
    .min(-90)
    .max(90)
    .nullable()
    .default(null)
    .transform((value) => {
      return isNaN(value) ? null : value;
    })
    .label("Latitude"),
  long: Yup.number()
    .min(-180)
    .max(180)
    .nullable()
    .default(null)
    .transform((value) => {
      return isNaN(value) ? null : value;
    })
    .label("Longitude"),
  locationThresholdFeet: Yup.number()
    .required()
    .min(30)
    .max(1000)
    .default(30)
    .transform((value) => {
      return isNaN(value) ? 30 : value;
    })
    .label("Maximum Distance from Intercom"),
};

export const IntercomActivationUpdateSchema = omit(
  IntercomActivationCreateSchema,
  ["serialNumber"]
);

export const CellKitAssignmentCreateSchema = {
  serialNumber: Yup.string().required().default("").label("Serial Number"),
};

export const IntercomActivationUpdateDefaultMobileIntercomSchema = pick(
  IntercomActivationCreateSchema,
  ["enableDMI", "enableDMIUA", "lat", "long", "locationThresholdFeet"]
);

export const EditPropertyUserValidationSchema = Yup.object().shape({
  firstName: Yup.string().required().label("First Name"),
  lastName: Yup.string().required().label("Last Name"),
  phone: YupPossiblePhoneNumber({ required: true }),
  role: Yup.string().required().label("Role"),
  physicalAccessCredentials: Yup.array(
    Yup.object().shape({
      code: Yup.string()
        .length(5)
        .matches(/\d\d\d\d\d/, "${label} must be numeric")
        .test(
          "is-valid-code",
          "${label} must be between 00000 and 65535",
          (code) => Boolean(code && code >= "00000" && code <= "65535")
        )
        .required()
        .label("Access Code"),
    })
  ),
  accessProfileIds: Yup.array(Yup.string().required()).label("Access Profiles"),
});

export const AddAccessPanelValidationSchema = Yup.object().shape({
  panelSerialNumber: Yup.string().required().label("Panel Serial Number"),
  ipAddress: Yup.string().required().label("IP Address"),
  port: Yup.string().required().label("Port"),
  timezone: Yup.string().required().label("Timezone"),
  listenPort734n: Yup.number().required().label("Network Module Listen Port"),
  passphrase734n: Yup.string().required().label("Network Module Passphrase"),
  notes: Yup.string().label("Notes"),
});

export const EditAccessPanelValidationSchema = Yup.object().shape({
  ipAddress: Yup.string().required().label("IP Address"),
  port: Yup.string().required().label("Port"),
  timezone: Yup.string().required().label("Timezone"),
  listenPort734n: Yup.number().required().label("Network Module Listen Port"),
  passphrase734n: Yup.string().required().label("Network Module Passphrase"),
  notes: Yup.string().label("Notes"),
});

export const AddEntryGroupValidationSchema = Yup.object().shape({
  displayName: Yup.string().required().label("Display Name"),
  propertyOrganizationId: Yup.string()
    .required()
    .label("Property Organization"),
  entryPointIds: Yup.array().of(Yup.string().required()).label("Entry Points"),
});

export const EditEntryGroupValidationSchema = Yup.object().shape({
  displayName: Yup.string().required().label("Display Name"),
  entryPointIds: Yup.array().of(Yup.string().required()).label("Entry Points"),
});

export const AddEntryPointValidationSchema = Yup.object().shape({
  displayName: Yup.string().required().label("Display Name"),
  readerId: Yup.string().required().label("Reader ID"),
  propertyOrganizationId: Yup.string()
    .required()
    .label("Property Organization"),
  strikeTime: Yup.number().required().label("Strike Time").min(0).max(250),
  delayTime: Yup.number().required().label("Delay Time").min(0).max(9),
  dmpProperties: Yup.object().shape({
    deviceNumber: Yup.number().required().label("Device Number"),
  }),
  fireExitRelease: Yup.boolean().label("Fire Exit Release"),
  publicEntryPoint: Yup.boolean().label("Public Entry Point"),
  notes: Yup.string().label("Notes"),
});

export const EditEntryPointValidationSchema = Yup.object().shape({
  displayName: Yup.string().required().label("Display Name"),
  strikeTime: Yup.number().required().label("Strike Time").min(0).max(250),
  delayTime: Yup.number().required().label("Delay Time").min(0).max(9),
  entryGroupIds: Yup.array().of(Yup.string().required().label("Entry Groups")),
  dmpProperties: Yup.object().shape({
    deviceNumber: Yup.number().required().label("Device Number"),
  }),
  fireExitRelease: Yup.boolean().label("Fire Exit Release"),
  publicEntryPoint: Yup.boolean().label("Public Entry Point"),
  notes: Yup.string().label("Notes"),
  readerId: Yup.string().required().label("Reader ID"),
});
export const AddAccessProfileValidationSchema = Yup.object().shape({
  displayName: Yup.string().required().label("Display Name"),
  entryGroupIds: Yup.array().of(Yup.string().required().label("Entry Groups")),
  entryScheduleIds: Yup.array().of(
    Yup.string().required().label("Entry Schedules")
  ),
});

export const EditAccessProfileValidationSchema = Yup.object().shape({
  displayName: Yup.string().required().label("Display Name"),
  entryGroupIds: Yup.array().of(Yup.string().required().label("Entry Groups")),
  entryScheduleIds: Yup.array().of(
    Yup.string().required().label("Entry Schedules")
  ),
});

export const AddEntryScheduleValidationSchema = Yup.object().shape({
  displayName: Yup.string().required().label("Display Name"),
  propertyOrganizationId: Yup.string()
    .required()
    .label("Property Organization"),
  schedule: Yup.array()
    .of(
      Yup.object({
        dayOfWeek: Yup.number().min(0).max(6).required().label("Day of Week"),
        startTime: Yup.string().required(),
        endTime: Yup.string().required(),
      })
    )
    .label("Entry Schedule"),
});

export const EditEntryScheduleValidationSchema = Yup.object().shape({
  displayName: Yup.string().required().label("Display Name"),
  schedule: Yup.array()
    .of(
      Yup.object({
        dayOfWeek: Yup.number().min(0).max(6).required().label("Day of Week"),
        startTime: Yup.string().required(),
        endTime: Yup.string().required(),
      })
    )
    .label("Entry Schedule"),
});

export const EditLockUnlockScheduleValidationSchema = Yup.object().shape({
  schedule: Yup.array()
    .of(
      Yup.object({
        dayOfWeek: Yup.number().min(0).max(6).required().label("Day of Week"),
        startTime: Yup.string().required(),
        endTime: Yup.string().required(),
      })
    )
    .label("Entry Schedule"),
});

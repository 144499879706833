import React from "react";
import { Alert, AlertIcon, Stack } from "@chakra-ui/react";

import { Loading } from "src/common/Loading";
import { useIntercomHealthQuery } from "src/common/queries";
import { ComponentStatusList } from "src/routes/IntercomUnits/IntercomActivation/IntercomSystem/IntercomHealthStatus/ComponentStatusList";
import { IntercomStatusSummary } from "src/routes/IntercomUnits/IntercomActivation/IntercomSystem/IntercomHealthStatus/IntercomStatusSummary";

export const IntercomHealthStatus = () => {
  const healthQuery = useIntercomHealthQuery();

  if (healthQuery.isLoading) {
    return <Loading />;
  }

  if (healthQuery.isError) {
    return (
      <Alert status="error">
        <AlertIcon />
        Error loading intercom health. Please try again later.
      </Alert>
    );
  }

  if (!healthQuery.data || healthQuery.data?.intercomReportId === null) {
    return (
      <Alert status="warning">
        <AlertIcon />
        No intercom health data available yet. Please check again later.
      </Alert>
    );
  }

  return (
    <Stack gap={2}>
      <IntercomStatusSummary intercomHealth={healthQuery.data} />
      <ComponentStatusList components={healthQuery.data.componentReports} />
    </Stack>
  );
};

import React from "react";
import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  Link,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "react-query";

import {
  PageHeader,
  PageHeaderBackLink,
  PageHeaderSubtitle,
  PageHeaderTitle,
} from "src/layout/PageHeader";
import { PageContent } from "src/layout/PageContent";
import { HTTPError, useKy } from "src/common/ky";
import { states } from "src/common/us-states";
import { getPropertyOrganizationsQueryKey } from "src/common/queries";
import { noop } from "src/common/util";
import { handleHookFormHTTPError } from "src/common/form";
import { PhoneNumberInput } from "src/common/PhoneNumbers";
import { YupPossiblePhoneNumber } from "src/common/phone-numbers";

interface AddPropertyOrganizationValues {
  name: string;
  phone: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  email: string;
  ownerFirstName: string;
  ownerLastName: string;
  ownerAddress: string;
  ownerCity: string;
  ownerState: string;
  ownerZip: string;
  ownerPhone: string;
  ownerEmail: string;
  dealerOrganizationId: string;
  adminFirstName: string;
  adminLastName: string;
  adminPhone: string;
  adminEmail: string;
}

const AddPropertyOrganizationValidationSchema = Yup.object().shape({
  name: Yup.string().required().label("Property Name"),
  phone: YupPossiblePhoneNumber({
    label: "Property Phone Number",
    required: true,
  }),
  address: Yup.string().required().label("Property Address"),
  city: Yup.string().required().label("Property City"),
  state: Yup.string().required().label("Property State"),
  zip: Yup.string().min(5).required().label("Property Zip Code"),
  email: Yup.string().email().required().label("Property Email"),
  ownerFirstName: Yup.string().required().label("Property Owner First Name"),
  ownerLastName: Yup.string().required().label("Property Owner Last Name"),
  ownerAddress: Yup.string().required().label("Property Owner Address"),
  ownerCity: Yup.string().required().label("Property Owner City"),
  ownerState: Yup.string().required().label("Property Owner State"),
  ownerZip: Yup.string().min(5).required().label("Property Owner Zip Code"),
  ownerPhone: YupPossiblePhoneNumber({
    label: "Property Owner Phone",
    required: true,
  }),
  ownerEmail: Yup.string().email().required().label("Property Owner Email"),
  adminFirstName: Yup.string().required().label("Admin First Name"),
  adminLastName: Yup.string().required().label("Admin Last Name"),
  adminPhone: YupPossiblePhoneNumber({
    label: "Admin Phone Number",
    required: true,
  }),
  adminEmail: Yup.string().email().required().label("Admin Email"),
});

export const AddPropertyOrganization = () => {
  const { dealerOrganizationId } = useParams();
  const ky = useKy();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    setError,
    getValues,
  } = useForm<AddPropertyOrganizationValues>({
    defaultValues: {
      dealerOrganizationId,
      name: "",
      phone: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      email: "",
      ownerFirstName: "",
      ownerLastName: "",
      ownerAddress: "",
      ownerCity: "",
      ownerState: "",
      ownerZip: "",
      ownerPhone: "",
      ownerEmail: "",
      adminFirstName: "",
      adminLastName: "",
      adminPhone: "",
      adminEmail: "",
    },
    resolver: yupResolver(AddPropertyOrganizationValidationSchema),
  });

  const navigate = useNavigate();
  const toast = useToast();
  const queryClient = useQueryClient();
  const showSuccessToastAndNavigateToPropertiesList = async () => {
    toast({
      title: "Property created.",
      description: "The property organization was created successfully.",
      status: "success",
      isClosable: true,
    });

    await queryClient.invalidateQueries(
      getPropertyOrganizationsQueryKey(dealerOrganizationId)
    );
    navigate(`/dealer-organizations/${dealerOrganizationId}/properties`);
  };

  const addPropertyOrganization = useMutation<
    void,
    HTTPError,
    AddPropertyOrganizationValues
  >(async (values) => {
    await ky.post("property-organizations", { json: values });
  });

  return (
    <>
      <PageHeader>
        <PageHeaderBackLink>
          <Link
            as={RouterLink}
            to={`/dealer-organizations/${dealerOrganizationId}/properties`}
          >
            &#8249; Back to list
          </Link>
        </PageHeaderBackLink>
        <PageHeaderTitle>Add Property Organization</PageHeaderTitle>
        <PageHeaderSubtitle>
          Enter the new property details here
        </PageHeaderSubtitle>
      </PageHeader>
      <PageContent>
        <form
          onSubmit={handleSubmit((values) =>
            addPropertyOrganization
              .mutateAsync(values, {
                onSuccess: showSuccessToastAndNavigateToPropertiesList,
                onError: handleHookFormHTTPError(setError, getValues, toast),
              })
              .catch(noop)
          )}
        >
          <Grid gap={4}>
            <GridItem>
              <Heading as={"h3"} fontSize="24px" mt={4} mb={4}>
                Property Organization Details
              </Heading>
            </GridItem>

            <GridItem>
              <Divider />
            </GridItem>

            <GridItem>
              <Controller
                name="name"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl
                    isRequired
                    isInvalid={fieldState.invalid && fieldState.isTouched}
                  >
                    <FormLabel htmlFor="name">Property Name</FormLabel>
                    <Input
                      width={["100%", "100%", "50%", "50%"]}
                      {...field}
                      id="name"
                    />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>

            <GridItem>
              <Controller
                name="phone"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl
                    isRequired
                    isInvalid={fieldState.invalid && fieldState.isTouched}
                  >
                    <FormLabel htmlFor="phone">Property Phone</FormLabel>
                    <PhoneNumberInput
                      width={["100%", "100%", "50%", "50%"]}
                      {...field}
                      id="phone"
                    />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>

            <GridItem>
              <Controller
                name="address"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl
                    isRequired
                    isInvalid={fieldState.invalid && fieldState.isTouched}
                  >
                    <FormLabel htmlFor="address">Property Address</FormLabel>
                    <Input
                      width={["100%", "100%", "50%", "50%"]}
                      {...field}
                      id="address"
                    />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>

            <GridItem>
              <Controller
                name="city"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl
                    isRequired
                    isInvalid={fieldState.invalid && fieldState.isTouched}
                  >
                    <FormLabel htmlFor="city">Property City</FormLabel>
                    <Input
                      width={["100%", "100%", "50%", "50%"]}
                      {...field}
                      id="city"
                    />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>

            <GridItem>
              <Controller
                name="state"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl
                    isRequired
                    isInvalid={fieldState.invalid && fieldState.isTouched}
                  >
                    <FormLabel htmlFor="state">Property State</FormLabel>
                    <Select
                      width={["100%", "100%", "50%", "50%"]}
                      {...field}
                      id="state"
                    >
                      <option value="" />
                      {states.map((state) => (
                        <option
                          key={state.abbreviation}
                          value={state.abbreviation}
                        >
                          {state.name}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>

            <GridItem>
              <Controller
                name="zip"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl
                    isRequired
                    isInvalid={fieldState.invalid && fieldState.isTouched}
                  >
                    <FormLabel htmlFor="zip">Property ZIP Code</FormLabel>
                    <Input
                      width={["100%", "100%", "50%", "50%"]}
                      {...field}
                      id="zip"
                    />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>

            <GridItem>
              <Controller
                name="email"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl
                    isRequired
                    isInvalid={fieldState.invalid && fieldState.isTouched}
                  >
                    <FormLabel htmlFor="email">Property Email</FormLabel>
                    <Input
                      width={["100%", "100%", "50%", "50%"]}
                      {...field}
                      id="email"
                    />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>

            <GridItem mt={12}>
              <Heading as={"h3"} fontSize="24px" mb={4}>
                Property Owner Contact Information
              </Heading>
              <Text color="gray.500" fontWeight="medium" fontSize="md">
                {"Please enter the Property Owner's Contact information."}
              </Text>
            </GridItem>

            <GridItem>
              <Controller
                name="ownerFirstName"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl
                    isRequired
                    isInvalid={fieldState.invalid && fieldState.isTouched}
                  >
                    <FormLabel htmlFor="ownerFirstName">
                      Property Owner First Name
                    </FormLabel>
                    <Input
                      width={["100%", "100%", "50%", "50%"]}
                      {...field}
                      id="ownerFirstName"
                    />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>

            <GridItem>
              <Controller
                name="ownerLastName"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl
                    isRequired
                    isInvalid={fieldState.invalid && fieldState.isTouched}
                  >
                    <FormLabel htmlFor="ownerLastName">
                      Property Owner Last Name
                    </FormLabel>
                    <Input
                      width={["100%", "100%", "50%", "50%"]}
                      {...field}
                      id="ownerLastName"
                    />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>

            <GridItem>
              <Controller
                name="ownerAddress"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl
                    isRequired
                    isInvalid={fieldState.invalid && fieldState.isTouched}
                  >
                    <FormLabel htmlFor="ownerAddress">
                      Property Owner Billing Address
                    </FormLabel>
                    <Input
                      width={["100%", "100%", "50%", "50%"]}
                      {...field}
                      id="ownerAddress"
                    />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>

            <GridItem>
              <Controller
                name="ownerCity"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl
                    isRequired
                    isInvalid={fieldState.invalid && fieldState.isTouched}
                  >
                    <FormLabel htmlFor="ownerCity">
                      Property Owner City
                    </FormLabel>
                    <Input
                      width={["100%", "100%", "50%", "50%"]}
                      {...field}
                      id="ownerCity"
                    />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>

            <GridItem>
              <Controller
                name="ownerState"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl
                    isRequired
                    isInvalid={fieldState.invalid && fieldState.isTouched}
                  >
                    <FormLabel htmlFor="ownerState">
                      Property Owner State
                    </FormLabel>
                    <Select
                      width={["100%", "100%", "50%", "50%"]}
                      {...field}
                      id="ownerState"
                    >
                      <option value="" />
                      {states.map((state) => (
                        <option
                          key={state.abbreviation}
                          value={state.abbreviation}
                        >
                          {state.name}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>

            <GridItem>
              <Controller
                name="ownerZip"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl
                    isRequired
                    isInvalid={fieldState.invalid && fieldState.isTouched}
                  >
                    <FormLabel htmlFor="ownerZip">
                      Property Owner ZIP Code
                    </FormLabel>
                    <Input
                      width={["100%", "100%", "50%", "50%"]}
                      {...field}
                      id="ownerZip"
                    />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>

            <GridItem>
              <Controller
                name="ownerEmail"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl
                    isRequired
                    isInvalid={fieldState.invalid && fieldState.isTouched}
                  >
                    <FormLabel htmlFor="ownerEmail">
                      Property Owner Email
                    </FormLabel>
                    <Input
                      width={["100%", "100%", "50%", "50%"]}
                      {...field}
                      id="ownerEmail"
                    />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>

            <GridItem>
              <Controller
                name="ownerPhone"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl
                    isRequired
                    isInvalid={fieldState.invalid && fieldState.isTouched}
                  >
                    <FormLabel htmlFor="ownerPhone">
                      Property Owner Phone Number
                    </FormLabel>
                    <PhoneNumberInput
                      width={["100%", "100%", "50%", "50%"]}
                      {...field}
                      id="ownerPhone"
                    />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>

            <GridItem mt={12}>
              <Heading as={"h3"} fontSize="24px" mb={4}>
                Property Admin Contact Information
              </Heading>
              <Text color="gray.500" fontWeight="medium" fontSize="md">
                The Property Admin is the primary responsible party for the
                property, usually a property manager. This user will be able to
                change settings, add other users, and generally administer
                things from the property side.
              </Text>
            </GridItem>

            <GridItem>
              <Divider />
            </GridItem>

            <GridItem>
              <Controller
                name="adminFirstName"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl
                    isRequired
                    isInvalid={fieldState.invalid && fieldState.isTouched}
                  >
                    <FormLabel htmlFor="adminFirstName">
                      Admin First Name
                    </FormLabel>
                    <Input
                      width={["100%", "100%", "50%", "50%"]}
                      {...field}
                      id="adminFirstName"
                    />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>

            <GridItem>
              <Controller
                name="adminLastName"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl
                    isRequired
                    isInvalid={fieldState.invalid && fieldState.isTouched}
                  >
                    <FormLabel htmlFor="adminLastName">
                      Admin Last Name
                    </FormLabel>
                    <Input
                      width={["100%", "100%", "50%", "50%"]}
                      {...field}
                      id="adminLastName"
                    />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>

            <GridItem>
              <Controller
                name="adminPhone"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl
                    isRequired
                    isInvalid={fieldState.invalid && fieldState.isTouched}
                  >
                    <FormLabel htmlFor="adminPhone">Admin Phone</FormLabel>
                    <PhoneNumberInput
                      width={["100%", "100%", "50%", "50%"]}
                      {...field}
                      id="adminPhone"
                    />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>

            <GridItem>
              <Controller
                name="adminEmail"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl
                    isRequired
                    isInvalid={fieldState.invalid && fieldState.isTouched}
                  >
                    <FormLabel htmlFor="adminEmail">Admin Email</FormLabel>
                    <Input
                      width={["100%", "100%", "50%", "50%"]}
                      {...field}
                      id="adminEmail"
                    />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>

            {addPropertyOrganization.error && (
              <GridItem>
                <Alert status="error">
                  <AlertIcon />
                  {addPropertyOrganization.error.message}
                </Alert>
              </GridItem>
            )}

            <GridItem>
              <Divider />
            </GridItem>

            <GridItem>
              <ButtonGroup display="flex" justifyContent="flex-end">
                <Link
                  as={RouterLink}
                  to={`/dealer-organizations/${dealerOrganizationId}/properties`}
                >
                  <Button>Cancel</Button>
                </Link>
                <Button
                  type="submit"
                  colorScheme="brand.blue"
                  isLoading={isSubmitting}
                >
                  Save Property
                </Button>
              </ButtonGroup>
            </GridItem>
          </Grid>
        </form>
      </PageContent>
    </>
  );
};

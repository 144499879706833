import {
  CountryCode,
  ParseError,
  parsePhoneNumber,
  isPossiblePhoneNumber,
} from "libphonenumber-js";
import * as Yup from "yup";

export const FILLER_DEF = "-";
export const EMPTY_DEF = "";
export const COUNTRY_DEF: CountryCode = "US";
export const PLACEHOLDER_DEF = "(555) 555-5555";

interface FormatPhoneNumberOpts {
  filler?: string;
  empty?: string;
  onInvalid?: OnInvalidPhoneNumber;
  complain?: boolean;
  country?: CountryCode;
}

type OnInvalidPhoneNumber = (
  phoneNumber: string,
  options: FormatPhoneNumberOpts,
  error: ParseError | Error
) => string;

const passthruOnInvalidPhoneNumber: OnInvalidPhoneNumber = (
  phoneNumber: string
): string => {
  return phoneNumber;
};

const emptyOnInvalidPhoneNumber: OnInvalidPhoneNumber = (): string => {
  return "";
};

// formatting for input
export const formatPhoneNumberE164 = (
  value: string | undefined | null,
  options: FormatPhoneNumberOpts = {}
): string => {
  if (!value) return ""; // default empty replacement

  const onInvalid = options.onInvalid ?? emptyOnInvalidPhoneNumber;
  const country = options.country || COUNTRY_DEF;

  if (isPossiblePhoneNumber(value, country)) {
    try {
      const parsed = parsePhoneNumber(value, country);

      if (parsed) {
        const fixed = parsed.format("E.164");
        return fixed;
      }
    } catch (err) {
      if (!(err instanceof ParseError)) throw err; // rethrow unexpected
      if (options.complain) throw err; // rethrow if we set complain
      return onInvalid(value, options, err);
    }
  }

  const err = new Error("Phone number parsing error");
  if (options.complain) throw err;
  return onInvalid(value, options, err);
};

// formatting for display
export const formatPhoneNumberNational = (
  value: string | null | undefined,
  options: FormatPhoneNumberOpts = {}
): string => {
  if (!value || value === FILLER_DEF) {
    return options.filler ?? FILLER_DEF; // default filler replacement
  }

  const onInvalid = options.onInvalid ?? passthruOnInvalidPhoneNumber;
  const country = options.country || COUNTRY_DEF;

  try {
    const parsed = parsePhoneNumber(value, country);
    if (parsed) {
      const fixed = parsed.formatNational();
      return fixed;
    }
  } catch (err) {
    if (!(err instanceof ParseError)) throw err; // rethrown unknown
    if (options.complain) throw err; // rethrow if we set complain
    return onInvalid(value, options, err);
  }

  const err = new Error("Phone number parsing error");
  if (options.complain) throw err;
  return onInvalid(value, options, err);
};

export const formatPhoneNumber = formatPhoneNumberNational;

interface YupPhoneNumberOpts {
  label?: string;
  country?: CountryCode;
  required?: boolean;
}
export const YupPossiblePhoneNumber = (opts: YupPhoneNumberOpts = {}) => {
  const country = opts.country || COUNTRY_DEF;

  let yup = Yup.string();
  if (opts.required) yup = yup.required();

  yup = yup.test(
    "possible-phone-number",
    "Invalid phone number",
    // NOTE: isValidPhoneNumber will block test numbers like:
    //   (555) 123-1234 -- i.e. it's too strict
    // and also can become out of date (which it was at time of testing)
    (value) => isPossiblePhoneNumber(value || "", country)
  );

  const label = opts.label ?? "Phone Number";
  if (label) yup = yup.label(label); // set label to "" to skip setting a label
  return yup;
};

import React from "react";
import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Link,
  Radio,
  RadioGroup,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "react-query";
import { HTTPError } from "ky";

import {
  PageHeader,
  PageHeaderBackLink,
  PageHeaderSubtitle,
  PageHeaderTitle,
} from "src/layout/PageHeader";
import { PageContent } from "src/layout/PageContent";
import { noop } from "src/common/util";
import { useKy } from "src/common/ky";
import { handleHookFormHTTPError } from "src/common/form";
import { PhoneNumberInput } from "src/common/PhoneNumbers";
import { YupPossiblePhoneNumber } from "src/common/phone-numbers";

interface AddStaffMemberValues {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  dealerOrganizationId: string;
  role: string;
}

const AddStaffMemberValidationSchema = Yup.object().shape({
  firstName: Yup.string().required().label("First Name"),
  lastName: Yup.string().required().label("Last Name"),
  phone: YupPossiblePhoneNumber({ required: true }),
  email: Yup.string().email().required().label("Email"),
  role: Yup.string().required().label("Role"),
});

export const AddDealerStaffMember = () => {
  const { dealerOrganizationId } = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const ky = useKy();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    setError,
    getValues,
  } = useForm<AddStaffMemberValues>({
    defaultValues: {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      role: "staff",
      dealerOrganizationId,
    },
    resolver: yupResolver(AddStaffMemberValidationSchema),
  });

  const addStaffMember = useMutation<void, HTTPError, AddStaffMemberValues>(
    async (values: AddStaffMemberValues) => {
      await ky.post("dealer-users", {
        json: { ...values },
      });
    },
    {
      onSuccess: () => {
        toast({
          description: "Staff Member successfully added",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        navigate(`/dealer-organizations/${dealerOrganizationId}/staff`);
      },
      onError: handleHookFormHTTPError(setError, getValues, toast),
    }
  );

  return (
    <>
      <PageHeader>
        <PageHeaderBackLink>
          <Link
            as={RouterLink}
            to={`/dealer-organizations/${dealerOrganizationId}/staff`}
          >
            &#8249; Back to list
          </Link>
        </PageHeaderBackLink>
        <PageHeaderTitle>Add Staff Member</PageHeaderTitle>
        <PageHeaderSubtitle>
          Enter the new staff member details here
        </PageHeaderSubtitle>
      </PageHeader>
      <PageContent>
        <form
          onSubmit={handleSubmit((data) =>
            addStaffMember
              .mutateAsync({
                firstName: data.firstName,
                lastName: data.lastName,
                phone: data.phone,
                email: data.email,
                dealerOrganizationId: data.dealerOrganizationId,
                role: data.role,
              })
              .catch(noop)
          )}
        >
          <Grid gap={4}>
            <GridItem>
              <Controller
                name="firstName"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl
                    isRequired
                    isInvalid={fieldState.invalid && fieldState.isTouched}
                  >
                    <FormLabel htmlFor="firstName">First Name</FormLabel>
                    <Input {...field} id="firstName" />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>

            <GridItem>
              <Controller
                name="lastName"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl
                    isRequired
                    isInvalid={fieldState.invalid && fieldState.isTouched}
                  >
                    <FormLabel htmlFor="lastName">Last Name</FormLabel>
                    <Input {...field} id="lastName" />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>

            <GridItem>
              <Controller
                name="phone"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl
                    isRequired
                    isInvalid={fieldState.invalid && fieldState.isTouched}
                  >
                    <FormLabel htmlFor="phone">Phone</FormLabel>
                    <PhoneNumberInput {...field} id="phone" />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>

            <GridItem>
              <Controller
                name="email"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl
                    isRequired
                    isInvalid={fieldState.invalid && fieldState.isTouched}
                  >
                    <FormLabel htmlFor="dealerUserEmail">Email</FormLabel>
                    <Input {...field} id="dealerUserEmail" />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>

            <GridItem>
              <Controller
                name="role"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl
                    isRequired
                    isInvalid={fieldState.invalid && fieldState.isTouched}
                  >
                    <FormLabel htmlFor="dealerUserRole">Role</FormLabel>
                    <RadioGroup id="dealerUserRole" {...field}>
                      <Stack direction="column">
                        <Radio value="admin">Admin</Radio>
                        <Radio value="staff">Staff</Radio>
                      </Stack>
                    </RadioGroup>
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>

            {addStaffMember.error && (
              <GridItem>
                <Alert status="error">
                  <AlertIcon />
                  {addStaffMember.error.message}
                </Alert>
              </GridItem>
            )}

            <GridItem>
              <Divider />
            </GridItem>

            <GridItem>
              <ButtonGroup display="flex" justifyContent="flex-end">
                <Link
                  as={RouterLink}
                  to={`/dealer-organizations/${dealerOrganizationId}/staff`}
                >
                  <Button>Cancel</Button>
                </Link>
                <Button
                  type="submit"
                  colorScheme="brand.blue"
                  isLoading={isSubmitting}
                >
                  Save Staff Member
                </Button>
              </ButtonGroup>
            </GridItem>
          </Grid>
        </form>
      </PageContent>
    </>
  );
};

import { Box, Flex, HStack, Icon, Link, Text } from "@chakra-ui/react";
import * as React from "react";
import { Link as RouterLink, Outlet } from "react-router-dom";
import { HiExclamationCircle } from "react-icons/hi";

import { Logo } from "src/layout/Logo";
import { MobileHamburgerMenu } from "src/layout/MobileHamburgerMenu";
import { NavMenu } from "src/layout/NavMenu";
import { ProfileDropdown } from "src/layout/ProfileDropdown";
import { useMobileMenuState } from "src/layout/useMobileMenuState";
import { useCurrentDealerOrganization } from "src/routes/DealerOrganizationSelector";
import { AppErrorBoundary } from "src/common/ErrorBoundary/AppErrorBoundary";

type TopBarProps = {
  hideNavigation?: boolean;
};
export const TopBar = ({ hideNavigation }: TopBarProps) => {
  const { isMenuOpen, toggle } = useMobileMenuState();

  return (
    <Flex align="center" bg="brand.navy" color="white" px="6" minH="16">
      <Flex justify="space-between" align="center" w="full">
        {!hideNavigation && (
          <>
            <MobileHamburgerMenu onClick={toggle} isOpen={isMenuOpen} />
            <NavMenu.Mobile isOpen={isMenuOpen} closeMenu={toggle} />
          </>
        )}

        {/* Desktop Logo placement */}
        <Logo
          display={{ base: "none", lg: "block" }}
          flexShrink={0}
          h="5"
          marginEnd="10"
        />

        {!hideNavigation && <NavMenu.Desktop />}

        {/* Mobile Logo placement */}
        <Logo
          flex={{ base: "1", lg: "0" }}
          display={{ lg: "none" }}
          flexShrink={0}
          h="5"
        />

        <HStack spacing="3">
          <ProfileDropdown hideNavigation={hideNavigation} />
        </HStack>
      </Flex>
    </Flex>
  );
};

export const DealerOrganizationLayout = () => {
  const dealerOrganization = useCurrentDealerOrganization();
  const financialsIsSet = dealerOrganization
    ? dealerOrganization.dealerFinancialsIsSet
    : false;

  return (
    <Flex direction="column" bg={"gray.50"} height="100vh">
      <TopBar />
      <AppErrorBoundary>
        {dealerOrganization && !financialsIsSet && (
          <Link
            as={RouterLink}
            _hover={{ textDecoration: "none" }}
            to={`/dealer-organizations/${dealerOrganization.dealerOrganizationId}/profile/financials/edit`}
          >
            <Flex
              bg={"orange.500"}
              alignItems="center"
              p={2}
              userSelect={"none"}
              cursor="pointer"
            >
              <Icon as={HiExclamationCircle} color="white" />
              <Box marginLeft={2}>
                <Text color="white" fontWeight="700">
                  Your account is missing Financial Information
                </Text>
                <Text color="white">
                  Please go into your Dealer Profile and fill out the Financial
                  Information. You will not be able to add Property
                  Organizations until you do so.
                </Text>
              </Box>
            </Flex>
          </Link>
        )}
        <Outlet />
      </AppErrorBoundary>
    </Flex>
  );
};

import React, { useEffect } from "react";
import { Heading } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";

import { IntercomComponentList } from "src/routes/IntercomUnits/IntercomActivation/IntercomSystem/IntercomComponentList";
import { IntercomServiceLogs } from "src/routes/IntercomUnits/IntercomActivation/IntercomSystem/IntercomServiceLogs";
import { IntercomGeneration } from "src/common/types";
import { Card } from "src/layout/Card";
import { IntercomActivationLayout } from "src/routes/IntercomUnits/IntercomActivation/IntercomActivationLayout";
import { IntercomHealthStatus } from "src/routes/IntercomUnits/IntercomActivation/IntercomSystem/IntercomHealthStatus/IntercomHealthStatus";
import useIntercomDetails from "src/routes/IntercomUnits/IntercomActivation/useIntercomDetails";

const SectionHeading = ({ children }: { children: React.ReactNode }) => (
  <Heading as="h2" fontSize="20px" fontWeight="bold" mb={4} mt={6}>
    {children}
  </Heading>
);

const Section = ({
  children,
  title,
}: {
  children: React.ReactNode;
  title: string;
}) => (
  <>
    <SectionHeading>{title}</SectionHeading>
    <Card>{children}</Card>
  </>
);

export const IntercomSystem = () => {
  const { dealerOrganizationId, propertyOrganizationId, intercomActivationId } =
    useParams();
  const navigate = useNavigate();
  const { generation } = useIntercomDetails();

  useEffect(() => {
    if (generation === IntercomGeneration.GEN_3) {
      navigate(
        `/dealer-organizations/${dealerOrganizationId}/properties/${propertyOrganizationId}/intercoms/${intercomActivationId}/edit`
      );
    }
  }, [generation]);

  return (
    <IntercomActivationLayout
      title="Intercom System"
      subtitle="View intercom health, service log, and intercom components."
      includeCard={false}
    >
      <Section title="Intercom Health">
        <IntercomHealthStatus />
      </Section>

      <Section title="Service Log">
        <IntercomServiceLogs />
      </Section>

      <Section title="Components">
        <IntercomComponentList />
      </Section>
    </IntercomActivationLayout>
  );
};

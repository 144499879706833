export enum StaffUserRole {
  admin = "admin",
  staff = "staff",
}

export type StaffUser = {
  staffUserId: string;
  userId: string;
  user: User;
  createdAt: string;
  role: StaffUserRole;
};

export const DEALER_TYPES = ["Billable", "Internal"];
export const enum DealerType {
  Billable = "Billable",
  Internal = "Internal",
}

export type DealerOrganization = {
  dealerOrganizationId: string;
  createdAt: string;
  name: string;
  dba: string;
  displayName: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipCode: string;
  phone: string;
  website: string;
  pointOfContactId: string;
  pointOfContact: DealerUser;
  dealerUser: DealerUser;
  dealerFinancialsIsSet: boolean;
  dealerAgreementAccepted: string;
  accountId: string;
  dealerType: DealerType;
};

export type PaginatedResult<T> = {
  pageSize: number;
  pageNumber: number;
  total: number;
  result: T[];
};

export type User = {
  userId: string;
  idpUserId: string;
  createdAt: string;
  updatedAt: string | null;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
};

export type DealerUser = {
  dealerUserId: string;
  dealerOrganizationId: string;
  userId: string;
  user: User;
  createdAt: string;
  role: DealerUserRole;
  eulaAcceptedAt: string;
};

export type DealerFinancials = {
  dealerFinancialsId: string;
  dealerOrganizationId: string;
  createdAt: string;
  updatedAt: string;
  businessName: string;
  licenseNumber: string;
  isTaxExempt: boolean;
  taxIdNumber: string;
  billingContactName: string;
  billingContactPhone: string;
  billingContactEmail: string;
  billingContactAddress: string;
  billingContactCity: string;
  billingContactState: string;
  billingContactZipCode: string;
  invoicingMethod: string;
};

export enum DealerUserRole {
  admin = "admin",
  staff = "staff",
}

export const PROPERTY_TYPES = ["Billable", "Demo", "Internal"] as const;
export const enum PropertyType {
  Billable = "Billable",
  Demo = "Demo",
  Internal = "Internal",
}

export type PropertyOrganization = {
  propertyOrganizationId: string;
  dealerOrganizationId: string;
  createdAt: string;
  name: string;
  phone: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  email: string;
  ownerFirstName: string;
  ownerLastName: string;
  ownerAddress: string;
  ownerCity: string;
  ownerState: string;
  ownerZip: string;
  ownerPhone: string;
  ownerEmail: string;
  lat: number;
  lng: number;
  adminFirstName: string | null;
  adminLastName: string | null;
  adminPhone: string | null;
  adminEmail: string | null;
  contactId: string | null;
  accessControlOnboardedAt: string | null;
  panel?: Panel;
  propertyType: PropertyType;
};

export type PropertyUser = {
  propertyUserId: string;
  propertyOrganizationId: string;
  userId: string;
  user: User;
  createdAt: string;
  eulaAcceptedAt: Date | null;
  role: PropertyUserRole;
  physicalAccessCredentialIds: string[];
  physicalAccessCredentials: AccessCredential[];
  accessProfiles?: AccessProfile[];
  accessProfileIds: string[];
};

export type Intercom = {
  intercomId: string;
  intercomModelId: string;
  intercomModel: string;
  productSku: string;
  serialNumber: string;
  notes: string;
  createdAt: string;
  name: string;
};

export type CellKitAssignmentInfo = {
  cellKitId: string;
  serialNumber: string;
  simNumber: string;
  modemId: string;
  notes: string;
  carrier: string;
  createdAt: string;
  activatedAt: string | null;
};

export type DealerContactInfo = {
  name: string;
  address: string;
  phoneNumber: string;
  website: string;
};

export type IntercomActivation = {
  intercomActivationId: string;
  propertyOrganizationId: string;
  intercomId: string;
  intercomModel: string;
  serialNumber: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  enableResidentDirectory: boolean;
  enableNumericCodes: boolean;
  enableQrCodes: boolean;
  enableCallManager: boolean;
  enableVideoCalls: boolean;
  enableDMI: boolean;
  enableDMIUA: boolean;
  lat: number | null;
  long: number | null;
  locationThresholdFeet: number;
  requireNfcTap: boolean;
  assignedCellKit: string | null;
  cellKitAssignmentInfo?: CellKitAssignmentInfo;
  softwareVersion: string | null;
  dealerContactInfo?: DealerContactInfo;
};

export type FeatureFlags = {
  defaultMobileIntercom?: boolean;
  gen4DealerPortal?: boolean;
  accessControl?: boolean;
};

export type CellKitAssignment = {
  cellKitAssignmentId: string;
  intercomActivationId: string;
  cellKitId: string;
  assignedByDealerUserId: string;
  createdAt: string;
  updatedAt: string | null;
  deletedAt: string | null;
};

export type AccessCredential = {
  accessCredentialId: string;
  propertyOrganizationId: string;
  code: string;
  createdAt: string;
  type: string;
};

export interface ValidationError<T> {
  property: keyof T;
  messages: string[];
}

export enum PropertyUserRole {
  admin = "admin",
  staff = "staff",
}

export enum IntercomGeneration {
  GEN_3 = "Gen3",
  GEN_4 = "Gen4",
}

export type IntercomComponent = {
  componentId: string;
  intercomActivationId: string | undefined;
  componentType: IntercomComponentType;
  connectionStatus: string;
  serialNumber: string | null;
  activatedAt: string | null;
  disabled: boolean;
  metadata: Record<string, unknown>;
};

export enum IntercomComponentType {
  ACCELEROMETER = "ACCELEROMETER",
  CAMERA = "CAMERA",
  EEPROM = "EEPROM",
  ETHERNET = "ETHERNET",
  IO_BOARD = "IO_BOARD",
  KEYPAD = "KEYPAD",
  MAIN_BOARD = "MAIN_BOARD",
  MICROPHONE = "MICROPHONE",
  MODEM = "MODEM",
  READER = "READER",
  SCREEN = "SCREEN",
  SPEAKER = "SPEAKER",
  TEMP_SENSOR = "TEMP_SENSOR",
  TOUCH = "TOUCH",
}

export enum IntercomComponentStatus {
  ONLINE = "ONLINE",
  OFFLINE = "OFFLINE",
  UNKNOWN = "UNKNOWN",
}

export type ComponentReport = {
  componentType: IntercomComponentType;
  status: IntercomComponentStatus;
  details: string | null;
  errorCode: number | null;
};

export type IntercomHealth = {
  intercomReportId: string | null;
  intercomActivationId: string | null;
  createdAt: string | null;
  source: string | null;
  componentReports: ComponentReport[];
};

export type IntercomServiceLog = {
  intercomActivationId: string;
  serviceSessionId: string;
  technicianId: string;
  endedAt: string | null;
  intercomName: string;
  generatedReportLocation: string;
  startedAt: string;
  propertyStaffSummary: string;
  technicianName: string;
  technicianSummary: string;
};

export type PaginationOptions = {
  pageSize?: number;
  pageNumber?: number;
  sortBy?: string;
  sortDir?: "ASC" | "DESC";
};

export type Panel = {
  panelId: string;
  serialNumber: string;
  panelType: string;
  propertyOrganizationId: string;
  dmpProperties?: {
    integratorPort?: {
      ipAddress: string;
      port: number;
    };
    listenPort734n: number;
    passphrase734n: string;
  };
  syncStatus: SyncStatus | undefined;
  timezone: string;
  notes: string | undefined;
};

export type AccessProfileDMPProperties = {
  sensorReset: boolean;
};

export type AccessProfile = {
  accessProfileId: string;
  displayName: string;
  propertyOrganizationId: string;
  entryGroupIds: string[];
  entryScheduleIds: string[];
  dmpProperties: AccessProfileDMPProperties | undefined;
};

export type EntryGroup = {
  entryGroupId: string;
  propertyOrganizationId: string;
  displayName: string;
};

export enum DayOfWeek {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}

export type EntrySchedule = {
  entryScheduleId: string;
  propertyOrganizationId: string;
  displayName: string;
  schedule: EntryScheduleDay[];
};

export type EntryScheduleDay = {
  dayOfWeek: DayOfWeek;
  startTime: string;
  endTime: string;
};

export type LockUnlockSchedule = {
  entryScheduleId: string;
  entryPointId: string;
  propertyOrganizationId: string;
  displayName: string;
  schedule: EntryScheduleDay[];
};

export type LockUnlockScheduleDay = {
  dayOfWeek: DayOfWeek;
  startTime: string;
  endTime: string;
};

export type EntryPoint = {
  entryPointId: string;
  propertyOrganizationId: string;
  displayName: string;
  strikeTime: number;
  delayTime: number;
  dmpProperties?: EntryPointDMPProperties;
  entryGroupIds: string[];
  fireExitRelease: boolean;
  publicEntryPoint: boolean;
  notes?: string;
  moduleConnectionType: "direct" | "network";
  readerId: string;
};

export type EntryPointDMPProperties = {
  deviceNumber: number;
};

export type SyncStatus = {
  date: string;
  code: "Success" | "Error";
  message: string;
};

import React, { useState } from "react";
import {
  Box,
  Button,
  Center,
  Checkbox,
  Container,
  Divider,
  Heading,
  Icon,
  Link,
  Text,
} from "@chakra-ui/react";
import { HiExternalLink } from "react-icons/hi";
import { Outlet } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";

import { TopBar } from "src/layout/DealerOrganizationLayout";
import {
  MY_DEALER_ORGANIZATIONS_QUERY_KEY,
  useCurrentDealerUser,
} from "src/routes/DealerOrganizationSelector";
import { AppErrorBoundary } from "src/common/ErrorBoundary/AppErrorBoundary";
import { useKy } from "src/common/ky";

export const EulaAgreementWall = () => {
  const [hasAgreed, setAgreed] = useState(false);
  const currentDealerUser = useCurrentDealerUser();
  const queryClient = useQueryClient();
  const ky = useKy();

  const acceptEula = useMutation(
    () =>
      ky.post(`dealer-users/${currentDealerUser?.dealerUserId}/accept-eula`),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(MY_DEALER_ORGANIZATIONS_QUERY_KEY),
    }
  );

  if (!currentDealerUser || currentDealerUser.eulaAcceptedAt) {
    return <Outlet />;
  }

  return (
    <>
      <TopBar hideNavigation />
      <AppErrorBoundary>
        <Container maxW="7xl">
          <Center flexDirection="column" pt="134px">
            <Heading mb="16px">Welcome to GateHawk</Heading>
            <Text mb="32px" fontWeight={400} fontSize="16px">
              You must read GateHawk&apos;s End User License Agreement and
              Privacy Policy before you can use your account.
            </Text>
            <Box bg="white" p="6" rounded="lg" shadow="base">
              <Center flexDirection="column">
                <Link
                  fontSize="18px"
                  href="https://gatehawk.com/legal/end-user-license-agreement.html"
                  isExternal
                >
                  GateHawk&apos;s End User License Agreement{" "}
                  <Icon as={HiExternalLink} />
                </Link>
                <Link
                  fontSize="18px"
                  href="https://gatehawk.com/legal/privacy-policy.html"
                  isExternal
                >
                  GateHawk&apos;s Privacy Policy
                  <Icon as={HiExternalLink} />
                </Link>
                <Divider mt="34px" mb="40px" />
                <Checkbox
                  isDisabled={acceptEula.isLoading}
                  onChange={(e) => setAgreed(e.target.checked)}
                  mb="40px"
                  fontWeight={600}
                >
                  I have read and agree to GateHawk&apos;s End User License
                  Agreement and Privacy Policy
                </Checkbox>
                <Button
                  onClick={() => acceptEula.mutate()}
                  isDisabled={!hasAgreed || acceptEula.isLoading}
                  w="100%"
                  colorScheme="brand.blue"
                >
                  {acceptEula.isLoading ? "Submitting..." : "Submit"}
                </Button>
              </Center>
            </Box>
          </Center>
        </Container>
      </AppErrorBoundary>
    </>
  );
};

import { useMemo } from "react";

import { useDealerUserRole } from "src/routes/DealerOrganizationSelector";
import { useGateHawkUserRole } from "src/common/GateHawkUserLoader";
import { DealerUserRole, StaffUserRole } from "src/common/types";

export const useIsAdmin = () => {
  const dealerUserRole = useDealerUserRole();
  const gatehawkUserRole = useGateHawkUserRole();

  return useMemo(
    () =>
      dealerUserRole === DealerUserRole.admin ||
      gatehawkUserRole === StaffUserRole.admin,
    [dealerUserRole, gatehawkUserRole]
  );
};

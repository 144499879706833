import React from "react";
import { Button, ButtonGroup, Link, useToast } from "@chakra-ui/react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";

import {
  PageHeader,
  PageHeaderBackLink,
  PageHeaderSubtitle,
  PageHeaderTitle,
} from "src/layout/PageHeader";
import { PageContent } from "src/layout/PageContent";
import { usePropertyOrganizationQuery } from "src/routes/Property/queries";
import { Loading } from "src/common/Loading";
import { ActivateAccessPanelForm } from "src/routes/Property/AccessControl/Panels/components/ActivateAccessPanelForm";
import { handleHookFormHTTPError } from "src/common/form";

export const AddAccessPanel = () => {
  const { propertyOrganizationId, dealerOrganizationId } = useParams();
  const propertyOrganizationQuery = usePropertyOrganizationQuery({
    propertyOrganizationId: propertyOrganizationId as string,
    dealerOrganizationId: dealerOrganizationId as string,
  });
  const toast = useToast();
  const navigate = useNavigate();

  if (
    propertyOrganizationQuery.isLoading ||
    !propertyOrganizationQuery.isSuccess
  ) {
    return <Loading />;
  }
  return (
    <>
      <PageHeader>
        <PageHeaderBackLink>
          <Link
            as={RouterLink}
            to={`/dealer-organizations/${dealerOrganizationId}/properties/${propertyOrganizationId}/access-control`}
          >
            &#8249; Back to list
          </Link>
        </PageHeaderBackLink>
        <PageHeaderTitle>
          {propertyOrganizationQuery.data.name} - Add Access Control Panel
        </PageHeaderTitle>
        <PageHeaderSubtitle>Enter panel detail below</PageHeaderSubtitle>
      </PageHeader>
      <PageContent>
        <ActivateAccessPanelForm
          propertyOrganizationId={propertyOrganizationId as string}
          mutationOptionsBuilder={({ setError, getValues }) => ({
            onSuccess: () => {
              toast({
                description: "Access Panel added",
                status: "success",
              });
              navigate(
                `/dealer-organizations/${dealerOrganizationId}/properties/${propertyOrganizationId}/access-control`
              );
            },
            onError: handleHookFormHTTPError(setError, getValues, toast),
          })}
          Footer={({ isSubmitting }) => (
            <ButtonGroup display="flex" justifyContent="flex-end">
              <Link
                as={RouterLink}
                to={`/dealer-organizations/${dealerOrganizationId}/properties/${propertyOrganizationId}/access-control`}
              >
                <Button>Cancel</Button>
              </Link>
              <Button
                type="submit"
                colorScheme="brand.blue"
                isLoading={isSubmitting}
              >
                Save
              </Button>
            </ButtonGroup>
          )}
        />
      </PageContent>
    </>
  );
};

import { useOutletContext } from "react-router-dom";

import { IntercomActivation } from "src/common/types";
import { extractIntercomDetails } from "src/routes/IntercomUnits/IntercomActivation/util";

const useIntercomDetails = () => {
  const { currentActivation } =
    useOutletContext<{ currentActivation: IntercomActivation }>();

  const { generation, screenSize } = extractIntercomDetails(currentActivation);

  return { generation, screenSize, currentActivation };
};

export default useIntercomDetails;

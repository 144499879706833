import { Box, HStack } from "@chakra-ui/react";
import * as React from "react";
import {
  Link as RouterLink,
  useLocation,
  useResolvedPath,
} from "react-router-dom";

interface NavItemProps {
  to: string;
  label: string;
  end?: boolean;
  closeMenu?: () => void;
}

interface DesktopNavItemProps extends NavItemProps {
  icon?: React.ReactNode;
}

const DesktopNavItem = (props: DesktopNavItemProps) => {
  const { icon, label, to, end } = props;

  const location = useLocation();
  const path = useResolvedPath(to);

  const locationPathname = location.pathname.toLowerCase();
  const toPathname = path.pathname.toLowerCase();

  const isActive =
    locationPathname === toPathname ||
    (!end &&
      locationPathname.startsWith(toPathname) &&
      locationPathname.charAt(toPathname.length) === "/");

  return (
    <HStack
      as={RouterLink}
      to={to}
      aria-current={isActive ? "page" : undefined}
      spacing="2"
      px="3"
      py="2"
      rounded="md"
      transition="all 0.2s"
      color="white"
      _hover={{ bg: "blackAlpha.400" }}
      _activeLink={{ bg: "white", color: "blue.900" }}
    >
      {icon && (
        <Box aria-hidden fontSize="md">
          {icon}
        </Box>
      )}
      <Box fontWeight="semibold">{label}</Box>
    </HStack>
  );
};

const MobileNavItem = (props: NavItemProps) => {
  const { label, to, end, closeMenu } = props;

  const location = useLocation();
  const path = useResolvedPath(to);

  const locationPathname = location.pathname.toLowerCase();
  const toPathname = path.pathname.toLowerCase();

  const isActive =
    locationPathname === toPathname ||
    (!end &&
      locationPathname.startsWith(toPathname) &&
      locationPathname.charAt(toPathname.length) === "/");

  return (
    <Box
      as={RouterLink}
      display="block"
      to={to}
      px="3"
      py="3"
      rounded="md"
      fontWeight="semibold"
      aria-current={isActive ? "page" : undefined}
      _hover={{ bg: "whiteAlpha.200" }}
      _activeLink={{ bg: "blackAlpha.300", color: "white" }}
      onClick={closeMenu}
    >
      {label}
    </Box>
  );
};

export const NavItem = {
  Desktop: DesktopNavItem,
  Mobile: MobileNavItem,
};

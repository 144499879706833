import * as React from "react";
export const Building = () => (
  <svg
    width={48}
    height={48}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M36 30H32V34H36V30ZM36 22H32V26H36V22ZM40 38H24V34H28V30H24V26H28V22H24V18H40V38ZM20 14H16V10H20V14ZM20 22H16V18H20V22ZM20 30H16V26H20V30ZM20 38H16V34H20V38ZM12 14H8V10H12V14ZM12 22H8V18H12V22ZM12 30H8V26H12V30ZM12 38H8V34H12V38ZM24 14V6H4V42H44V14H24Z"
      fill="#A0AEC0"
    />
  </svg>
);

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

import config from "src/common/config";

export const usePageTracking = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (config.gaMeasurementId) {
      ReactGA.initialize(config.gaMeasurementId);
    }
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname + location.search,
      });
    }
  }, [initialized, location]);
};

export const sendEvent = (eventName: string) =>
  ReactGA.isInitialized && ReactGA.event(eventName);

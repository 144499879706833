import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Input,
  Select,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useMutation } from "react-query";
import { HiPencil } from "react-icons/hi";

import { useProductActivationComponentsQuery } from "src/common/queries";
import { IntercomComponent, IntercomComponentType } from "src/common/types";
import { useKy } from "src/common/ky";

export const IntercomComponentList = () => {
  const componentsQuery = useProductActivationComponentsQuery();
  const { intercomActivationId } = useParams();

  const [isEditMode, setIsEditMode] = useState(false);
  const [formData, setFormData] = useState<IntercomComponent[]>(
    componentsQuery.data || []
  );
  const toast = useToast();
  const ky = useKy();

  // Utility functions
  const isInValidComponent = (component: IntercomComponent): boolean => {
    return !/^[a-zA-Z0-9]{1,10}$/.test(component.serialNumber || "");
  };

  const handleInputChange = <K extends keyof IntercomComponent>(
    index: number,
    field: K,
    value: IntercomComponent[K]
  ) => {
    if (formData) {
      const newFormData = [...formData];
      newFormData[index] = {
        ...newFormData[index],
        [field]: value,
      };
      setFormData(newFormData);
    }
  };

  // Component methods
  const updateComponents = useMutation(
    (values: IntercomComponent[]) =>
      ky.put(
        `product-unit-activations/${intercomActivationId}/components/batch`,
        {
          json: values,
        }
      ),
    {
      onSuccess: () => {
        toast({
          description: "Intercom components successfully updated",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      },
    }
  );

  useEffect(() => {
    if (componentsQuery.data && componentsQuery.data.length > 0) {
      setFormData(componentsQuery.data);
    }
  }, [componentsQuery.data]);

  const defaultComponent: IntercomComponent = {
    componentId: "",
    intercomActivationId: intercomActivationId,
    componentType: IntercomComponentType.MAIN_BOARD,
    connectionStatus: "UNKNOWN",
    serialNumber: null,
    activatedAt: null,
    disabled: false,
    metadata: {},
  };

  const addNewComponent = () => {
    setFormData([...formData, defaultComponent]);
  };

  const handleSave = () => {
    if (formData.some(isInValidComponent)) {
      return toast({
        description:
          "Some components have invalid Serial Numbers. They must be alphanumeric and max length of 10.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
    updateComponents.mutate(formData);
    setIsEditMode(false);
  };

  const handleCancel = () => {
    if (componentsQuery.data && componentsQuery.data?.length > 0)
      setFormData(componentsQuery.data);
    setIsEditMode(false);
  };

  return (
    <Box as="main" py="4" flex="1">
      {/* <Container maxW="7xl"> */}
      <Box bg="white" p="0" rounded="lg" shadow="base">
        <Table>
          <Thead style={{ background: "#F7FAFC" }}>
            <Th>Name</Th>
            <Th>Serial Number</Th>
          </Thead>
          <Tbody>
            {formData?.map((component, index) => (
              <Tr height="20" key={component.componentId}>
                <Td>
                  {isEditMode ? (
                    <Select
                      placeholder={component.componentType}
                      defaultValue={component.componentType}
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          "componentType",
                          e.target.value as IntercomComponentType
                        )
                      }
                    >
                      {Object.values(IntercomComponentType).map((type) => (
                        <option key={type} value={type}>
                          {type}
                        </option>
                      ))}
                    </Select>
                  ) : (
                    component.componentType
                  )}
                </Td>
                <Td>
                  {isEditMode ? (
                    <Input
                      type="text"
                      isInvalid={isInValidComponent(component)}
                      isRequired={true}
                      placeholder="Enter serial number"
                      defaultValue={component.serialNumber || ""}
                      onChange={(e) =>
                        handleInputChange(index, "serialNumber", e.target.value)
                      }
                    />
                  ) : (
                    component.serialNumber
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
      {isEditMode ? (
        <Flex mt="4" justifyContent="space-between">
          <Button colorScheme="brand.blue" onClick={addNewComponent}>
            Add New Component
          </Button>
          <Flex>
            <Button mr="2" variant="outline" onClick={handleCancel}>
              Cancel
            </Button>
            <Button colorScheme="brand.blue" onClick={handleSave}>
              Save
            </Button>
          </Flex>
        </Flex>
      ) : (
        <Flex mt="4" justifyContent="flex-end">
          <HiPencil size="18" />
          <Button
            paddingLeft={2}
            variant="link"
            colorScheme="brand.blue"
            onClick={() => setIsEditMode(true)}
          >
            Edit Component
          </Button>
        </Flex>
      )}
      {/* </Container> */}
    </Box>
  );
};

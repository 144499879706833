import React from "react";
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { HTTPError } from "ky";

import { ErrorOutline } from "src/common/icons/ErrorOutline";
import { CopyableErrorResponse } from "src/common/ErrorBoundary/CopyableErrorResponse";

type Props = {
  error: Error | HTTPError;
};
export const SomethingWentWrong = ({ error }: Props) => {
  return (
    <Flex
      h="100%"
      w="50%"
      minW="700"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <ErrorOutline w={53} h={53} color="#FF595E" />
      <Box>
        <Text fontSize="30px" fontWeight={600}>
          Oh no!
        </Text>
      </Box>
      <Box>
        <Text fontSize="30px" fontWeight={600}>
          The action you requested couldn&apos;t be completed
        </Text>
      </Box>
      {error instanceof HTTPError && <CopyableErrorResponse error={error} />}
      <Box h={50} />
      <Button
        colorScheme="brand.blue"
        onClick={() => {
          location.reload();
        }}
      >
        Reload
      </Button>
    </Flex>
  );
};

import { Flex, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { format } from "date-fns";
import React from "react";
import { useOutletContext } from "react-router-dom";

import { IntercomActivation } from "src/common/types";
import { IntercomActivationLayout } from "src/routes/IntercomUnits/IntercomActivation/IntercomActivationLayout";

export const CellKit = () => {
  const { currentActivation } =
    useOutletContext<{ currentActivation: IntercomActivation }>();

  const hasCellKit = Boolean(currentActivation?.cellKitAssignmentInfo);

  const { cellKitAssignmentInfo } = currentActivation;
  return (
    <IntercomActivationLayout
      title="Cell Upgrade Kit"
      subtitle="View information associated to intercom's cell kit. "
    >
      {!hasCellKit ? (
        <Flex>There is no cell kit assigned to this intercom.</Flex>
      ) : (
        <Table variant="unstyled">
          <Thead backgroundColor={"gray.50"}>
            <Tr>
              <Th>Serial Number</Th>
              <Th>Carrier</Th>
              <Th>Status</Th>
              <Th>Date Activated</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>{cellKitAssignmentInfo?.serialNumber || "-"}</Td>
              <Td>{cellKitAssignmentInfo?.carrier || "-"}</Td>
              <Td>
                {cellKitAssignmentInfo?.activatedAt ? "ACTIVE" : "INACTIVE"}
              </Td>
              <Td>
                {cellKitAssignmentInfo?.activatedAt
                  ? format(new Date(cellKitAssignmentInfo.activatedAt), "P")
                  : "-"}
              </Td>
            </Tr>
          </Tbody>
        </Table>
      )}
    </IntercomActivationLayout>
  );
};

import React from "react";
import {
  Button,
  ButtonGroup,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  Link,
  Select,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery, useQueryClient } from "react-query";

import {
  PageHeader,
  PageHeaderBackLink,
  PageHeaderTabs,
  PageHeaderTitle,
} from "src/layout/PageHeader";
import { PageContent } from "src/layout/PageContent";
import { TabLink } from "src/layout/TabLink";
import { Loading } from "src/common/Loading";
import { HTTPError, useKy } from "src/common/ky";
import { DealerOrganization as DealerOrganizationType } from "src/common/types";
import { dealerOrganizationByIdQueryKey } from "src/routes/Profile";
import { handleHookFormHTTPError } from "src/common/form";
import { PhoneNumberInput } from "src/common/PhoneNumbers";
import { YupPossiblePhoneNumber } from "src/common/phone-numbers";
import { states } from "src/common/us-states";

interface UpdateDealerProfileValues {
  name: string;
  dba: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipCode: string;
  phone: string;
  website: string;
  pocFirstName: string;
  pocLastName: string;
  pocPhone: string;
  pocEmail: string;
}

const EditDealerProfileValidationSchema = Yup.object().shape({
  name: Yup.string().required().label("Company Name"),
  addressLine1: Yup.string().required().label("Address Line 1"),
  addressLine2: Yup.string().optional().default("").label("Address Line 2"),
  city: Yup.string().required().label("City"),
  state: Yup.string().required().label("State"),
  zipCode: Yup.string().required().label("Zip Code"),
  phone: YupPossiblePhoneNumber({ label: "Phone", required: true }),
  website: Yup.string().required().label("Website"),
  pocFirstName: Yup.string()
    .optional()
    .default("")
    .label("Point of Contact First Name"),
  pocLastName: Yup.string()
    .optional()
    .default("")
    .label("Point of Contact Last Name"),
  pocPhone: YupPossiblePhoneNumber({
    label: "Point of Contact Phone",
    required: false,
  }).default(""),
  pocEmail: Yup.string()
    .email()
    .optional()
    .default("")
    .label("Point of Contact Email"),
});

export const ProfileEdit = () => {
  const queryClient = useQueryClient();
  const { dealerOrganizationId } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const ky = useKy();

  if (!dealerOrganizationId) {
    return null;
  }

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty },
    reset,
    setError,
    getValues,
  } = useForm<UpdateDealerProfileValues>({
    defaultValues: {
      name: "",
      dba: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zipCode: "",
      phone: "",
      website: "",
      pocFirstName: "",
      pocLastName: "",
      pocPhone: "",
      pocEmail: "",
    },
    resolver: yupResolver(EditDealerProfileValidationSchema),
  });

  const updateDealerDetails = useMutation<
    void,
    HTTPError,
    UpdateDealerProfileValues
  >(
    async (values: UpdateDealerProfileValues) => {
      await ky.patch(`dealer-organizations/${dealerOrganizationId}`, {
        json: values,
      });
    },
    {
      onSuccess: () => {
        navigate(
          `/dealer-organizations/${dealerOrganizationId}/profile/details`
        );
        toast({
          description: "Dealer Profile Successfully Updated",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        queryClient.invalidateQueries(
          `DEALER_ORGANIZATION_BY_ID:${dealerOrganizationId}`
        );
      },
      onError: handleHookFormHTTPError(setError, getValues, toast),
    }
  );

  const query = useQuery<DealerOrganizationType, HTTPError>(
    dealerOrganizationByIdQueryKey(dealerOrganizationId),
    () =>
      ky
        .get(`dealer-organizations/${dealerOrganizationId}`)
        .json<DealerOrganizationType>(),
    {
      onSuccess: (data) => {
        if (!isDirty) {
          reset({
            name: data.name || "",
            dba: data.dba || "",
            addressLine1: data.addressLine1 || "",
            addressLine2: data.addressLine2 || "",
            city: data.city || "",
            state: data.state || "",
            zipCode: data.zipCode || "",
            phone: data.phone || "",
            website: data.website || "",
            pocFirstName: data.pointOfContact.user.firstName || "",
            pocLastName: data.pointOfContact.user.lastName || "",
            pocPhone: data.pointOfContact.user.phone || "",
            pocEmail: data.pointOfContact.user.email || "",
          });
        }
      },
    }
  );

  if (query.isLoading || !query.isSuccess) {
    return <Loading />;
  }

  return (
    <>
      <PageHeader>
        <PageHeaderBackLink>
          <Link
            as={RouterLink}
            to={`/dealer-organizations/${dealerOrganizationId}/profile/details`}
          >
            &#8249; Back to details
          </Link>
        </PageHeaderBackLink>
        <PageHeaderTitle>Dealer Profile</PageHeaderTitle>
        <PageHeaderTabs>
          <TabLink
            key="profile"
            to={`/dealer-organizations/${dealerOrganizationId}/profile/details`}
            label="Details"
          />
          <TabLink
            key="financials"
            to={`/dealer-organizations/${dealerOrganizationId}/profile/financials`}
            label="Financials"
          />
        </PageHeaderTabs>
      </PageHeader>
      <PageContent>
        <form
          onSubmit={handleSubmit(
            async (data) => updateDealerDetails.mutateAsync(data),
            (err) => {
              console.error("Error while validating form", err);
              toast({
                description:
                  "Unknown error while validating form. Please try again.",
                status: "error",
                duration: 9000,
                isClosable: true,
              });
            }
          )}
        >
          <Grid templateColumns={"repeat(4 1fr)"} gap={6}>
            <GridItem colSpan={4}>
              <Heading size="md">Company Information</Heading>
            </GridItem>
            <GridItem colSpan={4}>
              <Divider />
            </GridItem>

            <GridItem>
              <Stack gap={4}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl
                      isRequired
                      isInvalid={fieldState.invalid && fieldState.isTouched}
                    >
                      <FormLabel htmlFor="name">Company Name</FormLabel>
                      <Input {...field} id="name" />
                      <FormErrorMessage>
                        {fieldState.error?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />

                <Controller
                  name="dba"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl>
                      <FormLabel htmlFor="dba">
                        Company DBA (if applicable)
                      </FormLabel>
                      <Input {...field} id="dba" />
                      <FormErrorMessage>
                        {fieldState.error?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />

                <Controller
                  name="addressLine1"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl
                      isRequired
                      isInvalid={fieldState.invalid && fieldState.isTouched}
                    >
                      <FormLabel htmlFor="addressLine1">
                        Address Line 1
                      </FormLabel>
                      <Input {...field} id="addressLine1" />
                      <FormErrorMessage>
                        {fieldState.error?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />

                <Controller
                  name="addressLine2"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl
                      isInvalid={fieldState.invalid && fieldState.isTouched}
                    >
                      <FormLabel htmlFor="addressLine2">
                        Address Line 2
                      </FormLabel>
                      <Input {...field} id="addressLine2" />
                      <FormErrorMessage>
                        {fieldState.error?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />

                <Controller
                  name="city"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl
                      isRequired
                      isInvalid={fieldState.invalid && fieldState.isTouched}
                    >
                      <FormLabel htmlFor="city">City</FormLabel>
                      <Input {...field} id="city" />
                      <FormErrorMessage>
                        {fieldState.error?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />

                <Controller
                  name="state"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl
                      isRequired
                      isInvalid={fieldState.invalid && fieldState.isTouched}
                    >
                      <FormLabel htmlFor="state">State</FormLabel>
                      <Select {...field} id="state">
                        <option value="" />
                        {states.map((state) => (
                          <option
                            key={state.abbreviation}
                            value={state.abbreviation}
                          >
                            {state.name}
                          </option>
                        ))}
                      </Select>
                      <FormErrorMessage>
                        {fieldState.error?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />

                <Controller
                  name="zipCode"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl
                      isRequired
                      isInvalid={fieldState.invalid && fieldState.isTouched}
                    >
                      <FormLabel htmlFor="zipCode">Zip Code</FormLabel>
                      <Input {...field} id="zipCode" />
                      <FormErrorMessage>
                        {fieldState.error?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />

                <Controller
                  name="phone"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl
                      isRequired
                      isInvalid={fieldState.invalid && fieldState.isTouched}
                    >
                      <FormLabel htmlFor="phone">Company Phone</FormLabel>
                      <PhoneNumberInput {...field} id="phone" />
                      <FormErrorMessage>
                        {fieldState.error?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />

                <Controller
                  name="website"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl
                      isRequired
                      isInvalid={fieldState.invalid && fieldState.isTouched}
                    >
                      <FormLabel htmlFor="website">Company Website</FormLabel>
                      <Input {...field} id="website" />
                      <FormErrorMessage>
                        {fieldState.error?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
              </Stack>
            </GridItem>

            <GridItem colSpan={4}>
              <Divider />
            </GridItem>

            <GridItem colSpan={4}>
              <ButtonGroup display="flex" justifyContent="flex-end">
                <Link
                  as={RouterLink}
                  to={`/dealer-organizations/${dealerOrganizationId}/profile/financials`}
                >
                  <Button>Cancel</Button>
                </Link>
                <Button
                  type="submit"
                  colorScheme="brand.blue"
                  isLoading={isSubmitting}
                >
                  Save
                </Button>
              </ButtonGroup>
            </GridItem>
          </Grid>
        </form>
      </PageContent>
    </>
  );
};

import React, { useMemo, useState } from "react";
import {
  Button,
  Flex,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import {
  HiOutlineDotsVertical,
  HiOutlinePencil,
  HiOutlineSearch,
  HiOutlineTrash,
} from "react-icons/hi";
import { useNavigate, useParams } from "react-router-dom";
import { sortBy } from "lodash";
import { useQueryClient } from "react-query";

import {
  getAccessProfilesByPropertyQueryKey,
  useGetAccessProfilesByPropertyQuery,
} from "src/routes/Property/queries";
import { Loading } from "src/common/Loading";
import { noop } from "src/common/util";
import { useDeleteProfile } from "src/routes/Property/hooks";
import { HTTPError } from "src/common/ky";
import { handleHTTPError } from "src/common/form";

export const ProfileList = () => {
  const profilesQuery = useGetAccessProfilesByPropertyQuery();
  const toast = useToast();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { dealerOrganizationId, propertyOrganizationId } = useParams();
  const [search, setSearch] = useState("");

  const { isDeletingProfile, deleteProfileMutation, setDeletingProfileId } =
    useDeleteProfile({
      onSuccess: () => {
        queryClient.invalidateQueries(getAccessProfilesByPropertyQueryKey());
        setDeletingProfileId(undefined);
        toast({
          description: "Profile deleted",
          status: "success",
        });
      },
      onError: async (error: HTTPError) => {
        await handleHTTPError(toast)(error);
        setDeletingProfileId(undefined);
      },
    });

  const profiles = useMemo(
    () => sortBy(profilesQuery.data ?? [], (v) => v.displayName.toLowerCase()),
    [profilesQuery.data]
  );

  const filteredProfiles = useMemo(
    () =>
      profiles.filter((p) =>
        p.displayName.toLowerCase().includes(search.toLowerCase())
      ),
    [profiles, search]
  );

  if (profilesQuery.isLoading || !profilesQuery.isSuccess) {
    return <Loading />;
  }

  return (
    <>
      <Stack
        direction={{ base: "column", lg: "row" }}
        justifyContent="space-between"
        alignItems={{ base: "stretch", lg: "center" }}
      >
        <Text fontSize="xl" fontWeight={600}>
          Profiles
        </Text>
        <HStack flex={1} justifyContent="flex-end">
          <InputGroup width="100%" maxWidth={500}>
            <InputLeftElement pointerEvents="none">
              <HiOutlineSearch />
            </InputLeftElement>
            <Input
              placeholder="Search by Name"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </InputGroup>
          <Button
            flexShrink={0}
            margin={2}
            onClick={() => {
              navigate(
                `/dealer-organizations/${dealerOrganizationId}/properties/${propertyOrganizationId}/access-control/profiles/add`
              );
            }}
          >
            Add Profile
          </Button>
        </HStack>
      </Stack>
      {filteredProfiles.length ? (
        <Table>
          <Thead>
            <Tr>
              <Th width={"100%"}>Display Name</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredProfiles.map((profile) => (
              <Tr key={profile.accessProfileId}>
                <Td>{profile.displayName}</Td>
                <Td>
                  <Menu>
                    <MenuButton
                      as={IconButton}
                      aria-label="Actions"
                      icon={<HiOutlineDotsVertical />}
                      variant="clear"
                    >
                      Actions
                    </MenuButton>
                    <MenuList w="320px">
                      <MenuItem
                        justifyContent="space-between"
                        isDisabled={false}
                        onClick={() => {
                          navigate(
                            `/dealer-organizations/${dealerOrganizationId}/properties/${profile.propertyOrganizationId}/access-control/profiles/${profile.accessProfileId}/edit`
                          );
                        }}
                      >
                        Edit Profile
                        <HiOutlinePencil />
                      </MenuItem>
                      <MenuItem
                        opacity={1}
                        justifyContent="space-between"
                        onClick={() => {
                          setDeletingProfileId(profile.accessProfileId);
                        }}
                        isDisabled={false}
                      >
                        <Flex>
                          <Text>Remove Profile</Text>
                        </Flex>
                        <HiOutlineTrash />
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      ) : (
        <Text>No profiles found.</Text>
      )}
      <Modal
        isOpen={isDeletingProfile}
        onClose={() => {
          setDeletingProfileId(undefined);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Remove Profile</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure? You can&apos;t undo this action.</ModalBody>

          <ModalFooter>
            <Button
              colorScheme="brand.lightGray"
              color="gray.700"
              mr={3}
              onClick={() => {
                setDeletingProfileId(undefined);
              }}
            >
              Cancel
            </Button>
            <Button
              colorScheme="brand.red"
              onClick={() => {
                deleteProfileMutation.mutateAsync().catch(noop);
              }}
              isDisabled={deleteProfileMutation.isLoading}
            >
              Remove Profile
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

import React from "react";
import {
  Divider,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import {
  HiOutlineDotsVertical,
  HiOutlinePencil,
  HiOutlineTrash,
} from "react-icons/hi";

import { PageContent } from "src/layout/PageContent";
import {
  PageHeader,
  PageHeaderTabs,
  PageHeaderTitle,
} from "src/layout/PageHeader";
import { Loading } from "src/common/Loading";
import { formatPhoneNumber } from "src/common/phone-numbers";
import { PropertyOrganization, PropertyUser } from "src/common/types";
import { HTTPError, useKy } from "src/common/ky";
import PropertyHeaderTabs from "src/routes/Property/PropertyHeaderTabs";

export const PROPERTY_ORGANIZATION_QUERY_KEY = "PROPERTY_ORGANIZATION";

export const Property = () => {
  const navigate = useNavigate();
  const { dealerOrganizationId, propertyOrganizationId } = useParams();
  const ky = useKy();

  const propertyOrganizationQuery = useQuery<PropertyOrganization, HTTPError>(
    [
      PROPERTY_ORGANIZATION_QUERY_KEY,
      propertyOrganizationId,
      dealerOrganizationId,
    ],
    () =>
      ky
        .get(
          `property-organizations/${propertyOrganizationId}/dealer-organization/${dealerOrganizationId}`
        )
        .json<PropertyOrganization>()
  );

  const propertyAdminQuery = useQuery<PropertyUser, HTTPError>(
    [
      "PROPERTY_ORGANIZATION_ADMIN",
      propertyOrganizationId,
      dealerOrganizationId,
    ],
    () =>
      ky
        .get(
          `property-users/${propertyOrganizationId}/dealer-organization/${dealerOrganizationId}/admin`
        )
        .json<PropertyUser>()
  );

  if (
    (propertyOrganizationQuery.isLoading && propertyAdminQuery.isLoading) ||
    !propertyOrganizationQuery.isSuccess ||
    !propertyAdminQuery.isSuccess
  ) {
    return <Loading />;
  }

  return (
    <>
      <PageHeader
        buttons={
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Actions"
              icon={<HiOutlineDotsVertical />}
              variant="clear"
            >
              Actions
            </MenuButton>
            <MenuList>
              <MenuItem
                justifyContent="space-between"
                onClick={() => {
                  navigate(
                    `/dealer-organizations/${dealerOrganizationId}/properties/${propertyOrganizationId}/edit`
                  );
                }}
              >
                Edit Property Details
                <HiOutlinePencil />
              </MenuItem>
              <MenuItem isDisabled justifyContent="space-between">
                Remove Property
                <HiOutlineTrash />
              </MenuItem>
            </MenuList>
          </Menu>
        }
      >
        <Link
          as={RouterLink}
          to={`/dealer-organizations/${dealerOrganizationId}/properties`}
        >
          &#8249; Back to list
        </Link>
        <PageHeaderTitle>{propertyOrganizationQuery.data.name}</PageHeaderTitle>
        <PageHeaderTabs>
          <PropertyHeaderTabs />
        </PageHeaderTabs>
      </PageHeader>
      <PageContent>
        <Grid templateColumns={"repeat(2, 1fr)"} gap={4}>
          <GridItem colSpan={2}>
            <Heading size="md">Property Details</Heading>
          </GridItem>

          <GridItem colSpan={2}>
            <Divider />
          </GridItem>

          <GridItem>
            <Text>Property Name</Text>
          </GridItem>
          <GridItem>
            <Text>{propertyOrganizationQuery.data.name}</Text>
          </GridItem>
          <GridItem>
            <Text>Property Type</Text>
          </GridItem>
          <GridItem>
            <Text>{propertyOrganizationQuery.data.propertyType}</Text>
          </GridItem>

          <GridItem>
            <Text>Street Address</Text>
          </GridItem>
          <GridItem>
            <Text>{propertyOrganizationQuery.data.address}</Text>
          </GridItem>

          <GridItem>
            <Text>City</Text>
          </GridItem>
          <GridItem>
            <Text>{propertyOrganizationQuery.data.city}</Text>
          </GridItem>

          <GridItem>
            <Text>State</Text>
          </GridItem>
          <GridItem>
            <Text>{propertyOrganizationQuery.data.state}</Text>
          </GridItem>

          <GridItem>
            <Text>ZIP Code</Text>
          </GridItem>
          <GridItem>
            <Text>{propertyOrganizationQuery.data.zip}</Text>
          </GridItem>

          <GridItem>
            <Text>Phone Number</Text>
          </GridItem>
          <GridItem>
            <Text>
              {formatPhoneNumber(propertyOrganizationQuery.data.phone)}
            </Text>
          </GridItem>

          <GridItem>
            <Text>Email</Text>
          </GridItem>
          <GridItem>
            <Text>{propertyOrganizationQuery.data.email}</Text>
          </GridItem>
        </Grid>
      </PageContent>

      <PageContent>
        <Grid templateColumns={"repeat(2, 1fr)"} gap={4}>
          <GridItem colSpan={2}>
            <Heading size="md">Property Owner Contact Details</Heading>
          </GridItem>

          <GridItem colSpan={2}>
            <Divider />
          </GridItem>

          <GridItem>
            <Text>Property Owner First Name</Text>
          </GridItem>
          <GridItem>
            <Text>{propertyOrganizationQuery.data.ownerFirstName}</Text>
          </GridItem>

          <GridItem>
            <Text>Property Owner Last Name</Text>
          </GridItem>
          <GridItem>
            <Text>{propertyOrganizationQuery.data.ownerLastName}</Text>
          </GridItem>

          <GridItem>
            <Text>Property Owner Billing Address</Text>
          </GridItem>
          <GridItem>
            <Text>{propertyOrganizationQuery.data.ownerAddress}</Text>
          </GridItem>

          <GridItem>
            <Text>Property Owner City</Text>
          </GridItem>
          <GridItem>
            <Text>{propertyOrganizationQuery.data.ownerCity}</Text>
          </GridItem>

          <GridItem>
            <Text>Property Owner State</Text>
          </GridItem>
          <GridItem>
            <Text>{propertyOrganizationQuery.data.ownerState}</Text>
          </GridItem>

          <GridItem>
            <Text>Property Owner Zip Code</Text>
          </GridItem>
          <GridItem>
            <Text>{propertyOrganizationQuery.data.ownerZip}</Text>
          </GridItem>

          <GridItem>
            <Text>Property Owner Email</Text>
          </GridItem>
          <GridItem>
            <Text>{propertyOrganizationQuery.data.ownerEmail}</Text>
          </GridItem>

          <GridItem>
            <Text>Property Owner Phone</Text>
          </GridItem>
          <GridItem>
            <Text>
              {formatPhoneNumber(propertyOrganizationQuery.data.ownerPhone)}
            </Text>
          </GridItem>
        </Grid>
      </PageContent>

      <PageContent>
        <Grid templateColumns={"repeat(2, 1fr)"} gap={4}>
          <GridItem colSpan={2}>
            <Heading size="md">Property Admin Contact Details</Heading>
          </GridItem>

          <GridItem colSpan={2}>
            <Divider />
          </GridItem>

          <GridItem>
            <Text>Property Admin First Name</Text>
          </GridItem>
          <GridItem>
            <Text>{propertyAdminQuery.data.user.firstName}</Text>
          </GridItem>

          <GridItem>
            <Text>Property Admin Last Name</Text>
          </GridItem>
          <GridItem>
            <Text>{propertyAdminQuery.data.user.lastName}</Text>
          </GridItem>

          <GridItem>
            <Text>Property Admin Phone Number</Text>
          </GridItem>
          <GridItem>
            <Text>{formatPhoneNumber(propertyAdminQuery.data.user.phone)}</Text>
          </GridItem>

          <GridItem>
            <Text>Property Admin Email</Text>
          </GridItem>
          <GridItem>
            <Text>{propertyAdminQuery.data.user.email}</Text>
          </GridItem>
        </Grid>
      </PageContent>
    </>
  );
};

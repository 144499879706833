import { Box, Divider, forwardRef } from "@chakra-ui/react";
import React, { PropsWithChildren } from "react";

export type WizardStepNavbarViewProps = PropsWithChildren;

export const WizardStepNavbarView = forwardRef(
  (props: WizardStepNavbarViewProps, ref) => {
    return (
      <Box
        ref={ref}
        mt={"auto"}
        bottom={4}
        backgroundColor={"white"}
        position={"sticky"}
      >
        <Divider borderColor={"gray.400"} mb={4} />
        {props.children}
      </Box>
    );
  }
);

import React from "react";
import { createIcon } from "@chakra-ui/react";

export const Camera = createIcon({
  displayName: "Camera",
  viewBox: "0 0 34 34",
  path: (
    <path
      fill="currentColor"
      d="M15.958 11.958H29.958C29.208 9.93067 28.0067 8.16701 26.354 6.66701C24.7013 5.16701 22.8193 4.12534 20.708 3.54201L15.958 11.958ZM12.083 15.375L19.125 3.20801C18.8197 3.18067 18.4723 3.16001 18.083 3.14601C17.6943 3.13201 17.3333 3.12501 17 3.12501C15.0553 3.12501 13.2637 3.47934 11.625 4.18801C9.98634 4.89601 8.51401 5.87501 7.20801 7.12501L12.083 15.375ZM3.54201 20.542H13.125L6.20801 8.29201C5.20801 9.48601 4.44434 10.8263 3.91701 12.313C3.38901 13.799 3.12501 15.3613 3.12501 17C3.12501 17.5833 3.15267 18.1807 3.20801 18.792C3.26401 19.4027 3.37534 19.986 3.54201 20.542ZM13.292 30.417L18.125 22.042H4.04201C4.81934 24.0693 6.02767 25.833 7.66701 27.333C9.30567 28.833 11.1807 29.861 13.292 30.417ZM17 30.875C18.9447 30.875 20.7433 30.5207 22.396 29.812C24.0487 29.104 25.514 28.125 26.792 26.875L21.958 18.625L14.875 30.792C15.2083 30.8193 15.5557 30.84 15.917 30.854C16.2777 30.868 16.6387 30.875 17 30.875ZM27.833 25.708C28.7497 24.5693 29.486 23.243 30.042 21.729C30.5973 20.215 30.875 18.6387 30.875 17C30.875 16.3887 30.8473 15.7847 30.792 15.188C30.736 14.5907 30.6387 14.014 30.5 13.458H20.875L27.833 25.708ZM17 33.667C14.722 33.667 12.5693 33.2293 10.542 32.354C8.51401 31.4793 6.74301 30.285 5.22901 28.771C3.71501 27.257 2.52067 25.486 1.64601 23.458C0.770674 21.4307 0.333008 19.278 0.333008 17C0.333008 14.6947 0.770674 12.535 1.64601 10.521C2.52067 8.50701 3.71501 6.74301 5.22901 5.22901C6.74301 3.71501 8.51401 2.52067 10.542 1.64601C12.5693 0.770674 14.722 0.333008 17 0.333008C19.3053 0.333008 21.465 0.770674 23.479 1.64601C25.493 2.52067 27.257 3.71501 28.771 5.22901C30.285 6.74301 31.4793 8.50701 32.354 10.521C33.2293 12.535 33.667 14.6947 33.667 17C33.667 19.278 33.2293 21.4307 32.354 23.458C31.4793 25.486 30.285 27.257 28.771 28.771C27.257 30.285 25.493 31.4793 23.479 32.354C21.465 33.2293 19.3053 33.667 17 33.667Z"
    />
  ),
});

import { useQuery } from "react-query";
import { useLocation, useParams } from "react-router-dom";

import { queryStringify } from "src/common/formatters";
import {
  DealerUser,
  IntercomActivation,
  IntercomComponent,
  IntercomHealth,
  IntercomServiceLog,
  PaginationOptions,
} from "src/common/types";
import { PaginatedResult, PropertyOrganization } from "src/common/types";
import { HTTPError, useKy } from "src/common/ky";

type PropertyOrganizationResponse = PaginatedResult<PropertyOrganization>;

export const GET_DEALER_STAFF_LIST_QUERY_KEY = "GET_DEALER_USERS";

export const getPropertyOrganizationsQueryKey = (
  dealerOrganizationId?: string,
  searchParams?: URLSearchParams
) => ["DEALER_PROPERTIES", dealerOrganizationId, searchParams?.toString()];
export const usePropertyOrganizationsQuery = (
  dealerOrganizationId?: string
) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const ky = useKy();

  if (!searchParams.has("pageNumber")) {
    searchParams.set("pageNumber", "1");
  }

  return useQuery<PropertyOrganizationResponse, HTTPError>(
    getPropertyOrganizationsQueryKey(dealerOrganizationId, searchParams),
    () =>
      ky
        .get(
          `property-organizations/search-by-dealer-organization/${dealerOrganizationId}`,
          { searchParams }
        )
        .json<PropertyOrganizationResponse>()
  );
};

export const getDealerStaffUsersQueryKey = (
  dealerOrganizationId?: string,
  searchParams?: URLSearchParams
) => [
  GET_DEALER_STAFF_LIST_QUERY_KEY,
  dealerOrganizationId,
  searchParams?.toString(),
];
export const useDealerStaffUsersQuery = (dealerOrganizationId?: string) => {
  const location = useLocation();
  const ky = useKy();
  const searchParams = new URLSearchParams(location.search);
  searchParams.append("dealerOrganizationId", dealerOrganizationId || "");

  return useQuery<DealerUser[]>(
    getDealerStaffUsersQueryKey(dealerOrganizationId, searchParams),
    () =>
      ky
        .get(`dealer-users`, {
          searchParams,
        })
        .json<DealerUser[]>()
  );
};

export const getIntercomActivationsQueryKey = (
  propertyOrganizationId?: string
) => ["INTERCOM_ACTIVATIONS", propertyOrganizationId];
export const useIntercomActivationsQuery = () => {
  const { propertyOrganizationId } = useParams();
  const ky = useKy();
  return useQuery<IntercomActivation[], HTTPError>(
    getIntercomActivationsQueryKey(propertyOrganizationId),
    () =>
      ky
        .get(
          `product-unit-activations/property-organization/${propertyOrganizationId}`
        )
        .json<IntercomActivation[]>()
  );
};

export const getIntercomHealthQueryKey = (intercomActivationId?: string) => [
  "INTERCOM_ACTIVATIONS",
  intercomActivationId,
  "HEALTH",
];
export const useIntercomHealthQuery = () => {
  const { intercomActivationId } = useParams();
  const ky = useKy();
  return useQuery<IntercomHealth, HTTPError>(
    getIntercomHealthQueryKey(intercomActivationId),
    () =>
      ky
        .get(`product-unit-activations/${intercomActivationId}/health`)
        .json<IntercomHealth>()
  );
};

export const getIntercomServiceLogsQueryKey = (
  intercomActivationId?: string
) => ["INTERCOM_ACTIVATIONS", intercomActivationId, "SERVICE_LOGS"];
export const useIntercomServiceLogsQuery = (
  params: PaginationOptions = {
    pageSize: 4,
    sortBy: "startedAt",
    sortDir: "DESC",
  }
) => {
  const { intercomActivationId } = useParams();
  const ky = useKy();
  return useQuery<PaginatedResult<IntercomServiceLog>, HTTPError>(
    getIntercomServiceLogsQueryKey(intercomActivationId),
    () =>
      ky
        .get(
          `product-unit-activations/${intercomActivationId}/sessions${queryStringify(
            params
          )}`
        )
        .json<PaginatedResult<IntercomServiceLog>>()
  );
};

export const getProductActivationComponentsKey = (
  intercomActivationId?: string
) => ["INTERCOM_ACTIVATION", intercomActivationId, "COMPONENTS"];
export const useProductActivationComponentsQuery = () => {
  const { intercomActivationId } = useParams();
  const ky = useKy();
  return useQuery<IntercomComponent[], HTTPError>(
    getProductActivationComponentsKey(intercomActivationId),
    () =>
      ky
        .get(`product-unit-activations/${intercomActivationId}/components`)
        .json<IntercomComponent[]>()
  );
};

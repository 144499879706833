import * as React from "react";
import { Box } from "@chakra-ui/react";
import {
  Link as RouterLink,
  useLocation,
  useResolvedPath,
} from "react-router-dom";

type TabLinkProps = {
  to: string;
  label: string;
  end?: boolean;
};

export const TabLink = (props: TabLinkProps) => {
  const location = useLocation();
  const path = useResolvedPath(props.to);

  const locationPathname = location.pathname.toLowerCase();
  const toPathname = path.pathname.toLowerCase();

  const isActive =
    locationPathname === toPathname ||
    (!props.end &&
      locationPathname.startsWith(toPathname) &&
      locationPathname.charAt(toPathname.length) === "/");

  return (
    <Box
      as={RouterLink}
      to={props.to}
      aria-current={isActive ? "page" : undefined}
      fontWeight="semibold"
      px="4"
      py="3"
      color="gray.600"
      borderBottom="2px solid transparent"
      transition="all 0.2s"
      _hover={{
        borderColor: "gray.400",
      }}
      _activeLink={{
        color: "brand.blue.600",
        borderColor: "currentColor",
      }}
    >
      {props.label}
    </Box>
  );
};

import React, { useEffect, useState } from "react";
import { HTTPError } from "ky";
import { Code, Flex, LinkBox, LinkOverlay, Text } from "@chakra-ui/react";

import { CopyToClipboard } from "src/common/icons/CopyToClipboard";

type ResponseJson = {
  message: string;
  path: string;
  requestId: string;
  statusCode: number;
  timestamp: string;
};
type Props = {
  error: HTTPError;
};
export const CopyableErrorResponse = ({ error }: Props) => {
  const [responseBody, setResponseBody] = useState<ResponseJson | null>(null);

  useEffect(() => {
    const parseResponseBody = async () => {
      if (!error.response.bodyUsed) {
        const json = await error.response.json();
        setResponseBody(json);
      }
    };
    parseResponseBody();
  }, []);

  if (!responseBody) {
    return null;
  }

  const errorText = `${responseBody.statusCode} - ${responseBody.message}\nRequest ID: ${responseBody.requestId}\nTimestamp: ${responseBody.timestamp}\nPath: ${responseBody.path}`;
  return (
    <Flex mt="40px" width="100%" flexDirection="column" justifyContent="center">
      <Flex>
        <LinkBox>
          <LinkOverlay
            href="#"
            color="brand.blue.500"
            onClick={() => {
              navigator.clipboard.writeText(errorText);
            }}
          >
            <CopyToClipboard h="18px" w="16px" />{" "}
            <Text as={"span"}>Copy to clipboard</Text>
          </LinkOverlay>
        </LinkBox>
      </Flex>
      <Code
        whiteSpace="pre-wrap"
        fontSize="16px"
        fontWeight={600}
        overflowY="scroll"
        maxH="240px"
        w="100%"
        p="16px"
      >
        {errorText}
      </Code>
    </Flex>
  );
};

import React, { useMemo, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Portal,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { sortBy } from "lodash";
import { useQueryClient } from "react-query";
import { HiOutlinePencil, HiOutlineTrash } from "react-icons/hi";

import {
  getEntryScheduleQueryKey,
  getEntrySchedulesQueryKey,
  useGetEntrySchedulesQuery,
} from "src/routes/Property/queries";
import { Loading } from "src/common/Loading";
import { noop } from "src/common/util";
import { handleHookFormHTTPError, handleHTTPError } from "src/common/form";
import { WizardStepFormView } from "src/routes/Property/AccessControl/Wizard/components/WizardStepFormView";
import { useDeleteEntrySchedule } from "src/routes/Property/hooks";
import { CreateEntryScheduleForm } from "src/routes/Property/AccessControl/EntrySchedules/components/CreateEntryScheduleForm";
import { EditEntryScheduleForm } from "src/routes/Property/AccessControl/EntrySchedules/components/EditEntryScheduleForm";
import { HTTPError } from "src/common/ky";

export type CreateSchedulesStepProps = {
  propertyOrganizationId: string;
  navbarPortalRef: React.RefObject<HTMLElement | null>;
  Footer: (props: { addEntrySchedule: () => void }) => React.ReactElement;
};

export const CreateEntrySchedulesStep = (props: CreateSchedulesStepProps) => {
  const { propertyOrganizationId, navbarPortalRef, Footer } = props;
  const entrySchedulesQuery = useGetEntrySchedulesQuery(
    propertyOrganizationId as string
  );
  const toast = useToast();
  const queryClient = useQueryClient();

  const [editingEntryScheduleId, setEditingEntryScheduleId] =
    useState<string>();
  const [creatingEntrySchedule, setCreatingEntrySchedule] = useState(false);

  const entrySchedules = useMemo(
    () =>
      sortBy(entrySchedulesQuery.data ?? [], (v) =>
        v.displayName.toLowerCase()
      ),
    [entrySchedulesQuery.data]
  );

  const {
    isDeletingEntrySchedule,
    deleteEntryScheduleMutation,
    setDeletingEntryScheduleId,
  } = useDeleteEntrySchedule({
    onSuccess: () => {
      queryClient.invalidateQueries(
        getEntrySchedulesQueryKey(propertyOrganizationId as string)
      );
      setDeletingEntryScheduleId(undefined);
      toast({
        description: "Entry Schedule deleted",
        status: "success",
      });
    },
    onError: async (error: HTTPError) => {
      await handleHTTPError(toast)(error);
      setDeletingEntryScheduleId(undefined);
    },
  });

  if (entrySchedulesQuery.isLoading || !entrySchedulesQuery.isSuccess) {
    return <Loading />;
  }

  return (
    <>
      <Stack>
        <Text fontSize="xl" fontWeight={600}>
          Entry Schedule Creation
        </Text>
        <Text fontSize="md" color={"gray.500"}>
          Select the days and times you want entry points to remain open.
        </Text>
      </Stack>

      {creatingEntrySchedule ? (
        <WizardStepFormView>
          <CreateEntryScheduleForm
            propertyOrganizationId={propertyOrganizationId as string}
            mutationOptionsBuilder={({ setError, getValues }) => ({
              onSuccess: () => {
                toast({
                  description: "Entry Schedule added",
                  status: "success",
                });
                queryClient.invalidateQueries(
                  getEntrySchedulesQueryKey(propertyOrganizationId)
                );
                setCreatingEntrySchedule(false);
              },
              onError: handleHookFormHTTPError(setError, getValues, toast),
            })}
            hideFooterDivider={true}
            Footer={({ isSubmitting, onSubmit }) => (
              <Portal containerRef={navbarPortalRef}>
                <ButtonGroup display="flex" justifyContent="flex-end">
                  <Button onClick={() => setCreatingEntrySchedule(false)}>
                    Cancel
                  </Button>
                  <Button
                    onClick={() => onSubmit()}
                    colorScheme="brand.blue"
                    isLoading={isSubmitting}
                  >
                    Save
                  </Button>
                </ButtonGroup>
              </Portal>
            )}
          />
        </WizardStepFormView>
      ) : null}

      {editingEntryScheduleId ? (
        <WizardStepFormView>
          <EditEntryScheduleForm
            entryScheduleId={editingEntryScheduleId}
            mutationOptionsBuilder={({ setError, getValues }) => ({
              onSuccess: () => {
                toast({
                  description: "Entry Schedule updated",
                  status: "success",
                });
                queryClient.invalidateQueries(
                  getEntryScheduleQueryKey(editingEntryScheduleId as string)
                );
                queryClient.invalidateQueries(
                  getEntrySchedulesQueryKey(propertyOrganizationId as string)
                );
                setEditingEntryScheduleId(undefined);
              },
              onError: handleHookFormHTTPError(setError, getValues, toast),
            })}
            hideFooterDivider={true}
            Footer={({ isSubmitting, onSubmit }) => (
              <Portal containerRef={navbarPortalRef}>
                <ButtonGroup display="flex" justifyContent="flex-end">
                  <Button onClick={() => setEditingEntryScheduleId(undefined)}>
                    Cancel
                  </Button>
                  <Button
                    onClick={() => onSubmit()}
                    colorScheme="brand.blue"
                    isLoading={isSubmitting}
                  >
                    Save Changes
                  </Button>
                </ButtonGroup>
              </Portal>
            )}
          />
        </WizardStepFormView>
      ) : null}

      {!creatingEntrySchedule && !editingEntryScheduleId ? (
        <>
          <Box overflowX={"auto"}>
            <Table>
              <Thead>
                <Tr>
                  <Th>Display Name</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {entrySchedules.length ? (
                  entrySchedules.map((entrySchedule) => {
                    return (
                      <Tr key={entrySchedule.entryScheduleId}>
                        <Td>{entrySchedule.displayName}</Td>

                        <Td textAlign={"right"}>
                          <ButtonGroup>
                            <IconButton
                              onClick={() =>
                                setEditingEntryScheduleId(
                                  entrySchedule.entryScheduleId
                                )
                              }
                              aria-label="Actions"
                              icon={<HiOutlinePencil />}
                              variant="clear"
                            />
                            <IconButton
                              onClick={() =>
                                setDeletingEntryScheduleId(
                                  entrySchedule.entryScheduleId
                                )
                              }
                              aria-label="Actions"
                              icon={<HiOutlineTrash />}
                              variant="clear"
                            />
                          </ButtonGroup>
                        </Td>
                      </Tr>
                    );
                  })
                ) : (
                  <Tr>
                    <Td colSpan={2} textAlign={"center"}>
                      <Text fontStyle={"italic"}>
                        There are no entry schedules
                      </Text>
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </Box>
          <Portal containerRef={navbarPortalRef}>
            {Footer({
              addEntrySchedule: () => setCreatingEntrySchedule(true),
            })}
          </Portal>
        </>
      ) : null}

      <Modal
        isOpen={isDeletingEntrySchedule}
        onClose={() => {
          setDeletingEntryScheduleId(undefined);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Remove Entry Schedule</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure? You can&apos;t undo this action.</ModalBody>

          <ModalFooter>
            <Button
              colorScheme="brand.lightGray"
              color="gray.700"
              mr={3}
              onClick={() => {
                setDeletingEntryScheduleId(undefined);
              }}
            >
              Cancel
            </Button>
            <Button
              colorScheme="brand.red"
              onClick={() =>
                deleteEntryScheduleMutation.mutateAsync().catch(noop)
              }
              isDisabled={deleteEntryScheduleMutation.isLoading}
            >
              Remove Entry Schedule
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

import React, { useMemo } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useQuery } from "react-query";
import { useLocation, useParams } from "react-router";

import { Loading } from "src/common/Loading";
import { DealerOrganization } from "src/common/types";
import { trimTrailingSlash } from "src/common/util";
import { SelectDealerOrganization } from "src/routes/DealerOrganizationSelector/SelectDealerOrganization";
import { useKy } from "src/common/ky";

export const MY_DEALER_ORGANIZATIONS_QUERY_KEY = "MY_DEALER_ORGANIZATIONS";

export const DealerOrganizationSelector = () => {
  const location = useLocation();
  const myDealerOrganizations = useDealerOrganizations();

  if (!myDealerOrganizations.isSuccess) {
    return <Loading />;
  }

  if (trimTrailingSlash(location.pathname) === "/dealer-organizations") {
    return myDealerOrganizations.data.length === 1 ? (
      <Navigate to={myDealerOrganizations.data[0].dealerOrganizationId} />
    ) : (
      <SelectDealerOrganization
        dealerOrganizations={myDealerOrganizations.data}
      />
    );
  }

  return <Outlet />;
};

export const useDealerOrganizations = () => {
  const ky = useKy();
  return useQuery<DealerOrganization[]>(
    MY_DEALER_ORGANIZATIONS_QUERY_KEY,
    () =>
      ky
        .get("dealer-organizations/my-dealer-organizations")
        .json<DealerOrganization[]>(),
    {
      staleTime: 1000 * 60,
    }
  );
};

export const useCurrentDealerOrganization = () => {
  const myDealerOrganizations = useDealerOrganizations();
  const { dealerOrganizationId } = useParams();

  return useMemo(() => {
    if (myDealerOrganizations.isSuccess) {
      return myDealerOrganizations.data.find(
        (dealerOrganization) =>
          dealerOrganization.dealerOrganizationId === dealerOrganizationId
      );
    }

    return null;
  }, [myDealerOrganizations, dealerOrganizationId]);
};

export const useCurrentDealerUser = () => {
  const dealerOrganization = useCurrentDealerOrganization();

  return useMemo(() => {
    if (dealerOrganization) {
      return dealerOrganization.dealerUser;
    }

    return null;
  }, [dealerOrganization]);
};

export const useDealerUserRole = () => {
  const dealerUser = useCurrentDealerUser();

  return useMemo(() => {
    if (dealerUser) {
      return dealerUser.role;
    }

    return null;
  }, [dealerUser]);
};

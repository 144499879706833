import React, { useState } from "react";
import {
  Box,
  Button,
  Center,
  Checkbox,
  Container,
  Divider,
  Heading,
  Icon,
  Link,
  Text,
} from "@chakra-ui/react";
import { HiExternalLink } from "react-icons/hi";
import { Navigate, useParams, Outlet } from "react-router-dom";
import { useMutation } from "react-query";

import { useDealerOrganizations } from "src/routes/DealerOrganizationSelector";
import { Loading } from "src/common/Loading";
import { TopBar } from "src/layout/DealerOrganizationLayout";
import { AppErrorBoundary } from "src/common/ErrorBoundary/AppErrorBoundary";
import { useKy } from "src/common/ky";

export const DealerAgreementWall = () => {
  const { dealerOrganizationId } = useParams();
  const [hasAgreed, setAgreed] = useState(false);
  const myDealerOrganizations = useDealerOrganizations();
  const ky = useKy();

  const acceptDealerAgreement = useMutation(
    () =>
      ky.post(
        `dealer-organizations/${dealerOrganizationId}/accept-dealer-agreement`
      ),
    {
      onSuccess: () => myDealerOrganizations.refetch(),
    }
  );

  if (!myDealerOrganizations.isSuccess) {
    return <Loading />;
  }

  const activeDealerOrg = myDealerOrganizations.data.find(
    (org) => org.dealerOrganizationId === dealerOrganizationId
  );

  if (!activeDealerOrg) {
    return <Navigate to="/dealer-organizations" replace />;
  }

  if (activeDealerOrg.dealerAgreementAccepted) {
    return <Outlet />;
  }

  return (
    <>
      <TopBar hideNavigation />
      <AppErrorBoundary>
        <Container maxW="7xl">
          <Center flexDirection="column" pt="134px">
            <Heading mb="16px">Welcome to GateHawk</Heading>
            <Text mb="32px" fontWeight={400} fontSize="16px">
              You must read GateHawk&apos;s Dealer Agreement before you can use
              your account.
            </Text>
            <Box bg="white" p="6" rounded="lg" shadow="base">
              <Center flexDirection="column">
                <Link
                  fontSize="18px"
                  href="https://gatehawk.com/legal/dealer-agreement.html"
                  isExternal
                >
                  GateHawk&apos;s Dealer Agreement <Icon as={HiExternalLink} />
                </Link>
                <Divider mt="34px" mb="40px" />
                <Checkbox
                  isDisabled={acceptDealerAgreement.isLoading}
                  onChange={(e) => setAgreed(e.target.checked)}
                  mb="40px"
                  fontWeight={600}
                >
                  I have read and agree to GateHawk&apos;s Dealer Agreement
                </Checkbox>
                <Button
                  onClick={() => acceptDealerAgreement.mutate()}
                  isDisabled={!hasAgreed || acceptDealerAgreement.isLoading}
                  w="100%"
                  colorScheme="brand.blue"
                >
                  {acceptDealerAgreement.isLoading ? "Submitting..." : "Submit"}
                </Button>
              </Center>
            </Box>
          </Center>
        </Container>
      </AppErrorBoundary>
    </>
  );
};

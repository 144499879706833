import { useAuth0 } from "@auth0/auth0-react";
import ky, { HTTPError } from "ky";

import config from "src/common/config";

export type KyOptions = {
  noLogoutOnForbidden: boolean;
};

export const useKy = (kyOptions?: KyOptions) => {
  const { getAccessTokenSilently, logout } = useAuth0();

  return ky.create({
    prefixUrl: config.apiUrl,
    retry: {
      // Adds 401 and 403 to the defaults
      statusCodes: [401, 403, 408, 413, 429, 500, 502, 503, 504],
      // Adds 'post' to the defaults
      methods: ["get", "put", "post", "head", "delete", "options", "trace"],
    },
    hooks: {
      beforeRequest: [
        async (request) => {
          if (!request.url.endsWith("/dealer-applications")) {
            const accessToken = await getAccessTokenSilently();
            request.headers.set("Authorization", `Bearer ${accessToken}`);
          }
        },
      ],
      beforeRetry: [
        // Never retry POST /token
        async ({ request, error }) => {
          if (request.method === "POST" && request.url.endsWith("/token")) {
            throw error;
          }
        },
        // Only retry POST requests on 401 errors
        async ({ request, error }) => {
          const isUnauthorized =
            error instanceof HTTPError && error.response.status === 401;
          if (request.method === "POST" && !isUnauthorized) {
            throw error;
          }
        },
        // Log out user on 403 errors
        async ({ error }) => {
          const isForbidden =
            error instanceof HTTPError && error.response.status === 403;
          if (isForbidden && !kyOptions?.noLogoutOnForbidden) {
            logout({
              logoutParams: { returnTo: window.location.origin },
            });
          }
        },
      ],
    },
  });
};

export { HTTPError };

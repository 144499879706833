import React from "react";
import { createIcon } from "@chakra-ui/react";

export const MainBoard = createIcon({
  displayName: "MainBoard",
  viewBox: "0 0 34 30",
  path: (
    <path
      fill="currentColor"
      d="M3.375 30C2.625 30 1.97933 29.7223 1.438 29.167C0.896 28.611 0.625 27.958 0.625 27.208V2.792C0.625 2.042 0.896 1.389 1.438 0.833C1.97933 0.277667 2.625 0 3.375 0H27.833C28.583 0 29.236 0.277667 29.792 0.833C30.3473 1.389 30.625 2.042 30.625 2.792V5.542H33.375V8.333H30.625V13.625H33.375V16.375H30.625V21.667H33.375V24.458H30.625V27.208C30.625 27.958 30.3473 28.611 29.792 29.167C29.236 29.7223 28.583 30 27.833 30H3.375ZM3.375 27.208H27.833V2.792H3.375V27.208ZM6.167 24.458H15.958V16.667H6.167V24.458ZM17.375 11.208H25.042V5.542H17.375V11.208ZM6.167 15.292H15.958V5.542H6.167V15.292ZM17.375 24.458H25.042V12.625H17.375V24.458Z"
    />
  ),
});

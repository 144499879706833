import React from "react";
import {
  Button,
  ButtonGroup,
  Divider,
  Grid,
  GridItem,
  Heading,
  Link,
  Text,
} from "@chakra-ui/react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { useQuery } from "react-query";

import { PageContent } from "src/layout/PageContent";
import { TabLink } from "src/layout/TabLink";
import {
  PageHeader,
  PageHeaderTabs,
  PageHeaderTitle,
} from "src/layout/PageHeader";
import { Loading } from "src/common/Loading";
import { HTTPError, useKy } from "src/common/ky";
import { DealerOrganization as DealerOrganizationType } from "src/common/types";
import { useIsAdmin } from "src/common/hooks/useIsAdmin";
import { formatPhoneNumber } from "src/common/phone-numbers";

export const dealerOrganizationByIdQueryKey = (
  dealerOrganizationId: string
) => {
  return `DEALER_ORGANIZATION_BY_ID:${dealerOrganizationId}`;
};

export const Profile = () => {
  const { dealerOrganizationId } = useParams();
  const isAdmin = useIsAdmin();
  const ky = useKy();

  if (!dealerOrganizationId) {
    return null;
  }

  const query = useQuery<DealerOrganizationType, HTTPError>(
    dealerOrganizationByIdQueryKey(dealerOrganizationId),
    () =>
      ky
        .get(`dealer-organizations/${dealerOrganizationId}`)
        .json<DealerOrganizationType>()
  );

  if (!query.isSuccess || query.isLoading) {
    return <Loading />;
  }

  const dealerOrganization = query.data;

  return (
    <>
      <PageHeader
        buttons={
          <ButtonGroup>
            {isAdmin && (
              <Link
                as={RouterLink}
                to={`/dealer-organizations/${dealerOrganizationId}/profile/details/edit`}
              >
                <Button colorScheme="brand.blue">
                  Edit Dealer Profile Details
                </Button>
              </Link>
            )}
          </ButtonGroup>
        }
      >
        <PageHeaderTitle>Dealer Profile</PageHeaderTitle>
        <PageHeaderTabs>
          <TabLink
            key="profile"
            to={`/dealer-organizations/${dealerOrganizationId}/profile/details`}
            label="Details"
          />
          {isAdmin && (
            <TabLink
              key="financials"
              to={`/dealer-organizations/${dealerOrganizationId}/profile/financials`}
              label="Financials"
            />
          )}
        </PageHeaderTabs>
      </PageHeader>
      <PageContent>
        <Grid templateColumns={"repeat(2, 1fr)"} gap={4}>
          <GridItem colSpan={2}>
            <Heading size="md">Company Information</Heading>
          </GridItem>

          <GridItem colSpan={2}>
            <Divider />
          </GridItem>

          <GridItem>
            <Text>Company Name</Text>
          </GridItem>
          <GridItem>
            <Text>{dealerOrganization.name || "-"}</Text>
          </GridItem>

          <GridItem>
            <Text>Company DBA</Text>
          </GridItem>
          <GridItem>
            <Text>{dealerOrganization.dba || "-"}</Text>
          </GridItem>

          <GridItem>
            <Text>Account ID</Text>
          </GridItem>
          <GridItem>
            <Text>{dealerOrganization.accountId}</Text>
          </GridItem>

          <GridItem>
            <Text>Address Line 1</Text>
          </GridItem>
          <GridItem>
            <Text>{dealerOrganization.addressLine1 || "-"}</Text>
          </GridItem>

          <GridItem>
            <Text>Address Line 2</Text>
          </GridItem>
          <GridItem>
            <Text>{dealerOrganization.addressLine2 || "-"}</Text>
          </GridItem>

          <GridItem>
            <Text>City</Text>
          </GridItem>
          <GridItem>
            <Text>{dealerOrganization.city || "-"}</Text>
          </GridItem>

          <GridItem>
            <Text>State</Text>
          </GridItem>
          <GridItem>
            <Text>{dealerOrganization.state || "-"}</Text>
          </GridItem>

          <GridItem>
            <Text>Zip Code</Text>
          </GridItem>
          <GridItem>
            <Text>{dealerOrganization.zipCode || "-"}</Text>
          </GridItem>

          <GridItem>
            <Text>Company Phone Number</Text>
          </GridItem>
          <GridItem>
            <Text>{formatPhoneNumber(dealerOrganization.phone)}</Text>
          </GridItem>

          <GridItem>
            <Text>Company Website</Text>
          </GridItem>
          <GridItem>
            <Text>{dealerOrganization.website || "-"}</Text>
          </GridItem>
        </Grid>
      </PageContent>

      <PageContent>
        <Grid templateColumns={"repeat(2, 1fr)"} gap={4}>
          <GridItem colSpan={2}>
            <Heading size="md">Contact Information</Heading>
          </GridItem>

          <GridItem colSpan={2}>
            <Divider />
          </GridItem>

          <GridItem>
            <Text>Contact First Name</Text>
          </GridItem>
          <GridItem>
            <Text>
              {dealerOrganization.pointOfContact.user.firstName || "-"}
            </Text>
          </GridItem>

          <GridItem>
            <Text>Contact Last Name</Text>
          </GridItem>
          <GridItem>
            <Text>
              {dealerOrganization.pointOfContact.user.lastName || "-"}
            </Text>
          </GridItem>

          <GridItem>
            <Text>Contact Phone Number</Text>
          </GridItem>
          <GridItem>
            <Text>
              {formatPhoneNumber(dealerOrganization.pointOfContact.user.phone)}
            </Text>
          </GridItem>

          <GridItem>
            <Text>Contact Email</Text>
          </GridItem>
          <GridItem>
            <Text>{dealerOrganization.pointOfContact.user.email || "-"}</Text>
          </GridItem>
        </Grid>
      </PageContent>
    </>
  );
};

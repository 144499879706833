import React from "react";
import { createIcon } from "@chakra-ui/react";

export const IntercomUnhealthy = createIcon({
  displayName: "IntercomUnhealthy",
  viewBox: "0 0 174 232",
  path: [
    <path
      key="unhealthy-intercom-1"
      d="M155.89 15.1001H4.25023C2.86399 15.1001 1.74023 16.2239 1.74023 17.6101V228.79C1.74023 230.176 2.86399 231.3 4.25023 231.3H155.89C157.276 231.3 158.4 230.176 158.4 228.79V17.6101C158.4 16.2239 157.276 15.1001 155.89 15.1001Z"
      fill="#E7B122"
    />,
    <path
      key="unhealthy-intercom-2"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.25023 16.1001C3.41628 16.1001 2.74023 16.7761 2.74023 17.6101V228.79C2.74023 229.624 3.41629 230.3 4.25023 230.3H155.89C156.724 230.3 157.4 229.624 157.4 228.79V17.6101C157.4 16.7761 156.724 16.1001 155.89 16.1001H4.25023ZM0.740234 17.6101C0.740234 15.6716 2.31171 14.1001 4.25023 14.1001H155.89C157.829 14.1001 159.4 15.6716 159.4 17.6101V228.79C159.4 230.729 157.829 232.3 155.89 232.3H4.25023C2.3117 232.3 0.740234 230.729 0.740234 228.79V17.6101Z"
      fill="black"
    />,
    <path
      key="unhealthy-intercom-3"
      d="M58.8602 36.1249C58.8602 32.1568 55.6434 28.9399 51.6752 28.9399C47.7071 28.9399 44.4902 32.1568 44.4902 36.1249C44.4902 40.0931 47.7071 43.3099 51.6752 43.3099C55.6434 43.3099 58.8602 40.0931 58.8602 36.1249Z"
      fill="url(#paint0_linear_2257_20731)"
    />,
    <path
      key="unhealthy-intercom-4"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.6752 29.9399C48.2594 29.9399 45.4902 32.7091 45.4902 36.1249C45.4902 39.5408 48.2594 42.3099 51.6752 42.3099C55.0911 42.3099 57.8602 39.5408 57.8602 36.1249C57.8602 32.7091 55.0911 29.9399 51.6752 29.9399ZM43.4902 36.1249C43.4902 31.6045 47.1548 27.9399 51.6752 27.9399C56.1957 27.9399 59.8602 31.6045 59.8602 36.1249C59.8602 40.6454 56.1957 44.3099 51.6752 44.3099C47.1548 44.3099 43.4902 40.6454 43.4902 36.1249Z"
      fill="black"
    />,
    <path
      key="unhealthy-intercom-5"
      d="M39.3203 28.9399H19.5002V43.3099H39.3203V28.9399Z"
      fill="url(#paint1_linear_2257_20731)"
    />,
    <path
      key="unhealthy-intercom-6"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5002 27.9399H40.3203V44.3099H18.5002V27.9399ZM20.5002 29.9399V42.3099H38.3203V29.9399H20.5002Z"
      fill="black"
    />,
    <path
      key="unhealthy-intercom-7"
      d="M143.48 28.9399H64.8003V43.3099H143.48V28.9399Z"
      fill="url(#paint2_linear_2257_20731)"
    />,
    <path
      key="unhealthy-intercom-8"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M63.8003 27.9399H144.48V44.3099H63.8003V27.9399ZM65.8003 29.9399V42.3099H142.48V29.9399H65.8003Z"
      fill="black"
    />,
    <path
      key="unhealthy-intercom-9"
      d="M140.65 52.5801H19.5002V143.86H140.65V52.5801Z"
      fill="url(#paint3_linear_2257_20731)"
    />,
    <path
      key="unhealthy-intercom-10"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5002 51.5801H141.65V144.86H18.5002V51.5801ZM20.5002 53.5801V142.86H139.65V53.5801H20.5002Z"
      fill="black"
    />,
    <path
      key="unhealthy-intercom-11"
      d="M141 169H19.0002V212H141V169Z"
      fill="url(#paint4_linear_2257_20731)"
    />,
    <path
      key="unhealthy-intercom-12"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.0002 168H142V213H18.0002V168ZM20.0002 170V211H140V170H20.0002Z"
      fill="black"
    />,
    <path
      key="unhealthy-intercom-13"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.1474 99.4682C51.4359 98.9972 52.0515 98.8492 52.5225 99.1376L86.7525 120.098C87.2235 120.386 87.3715 121.002 87.0831 121.473C86.7947 121.944 86.1791 122.092 85.7081 121.803L51.4781 100.843C51.0071 100.555 50.859 99.9392 51.1474 99.4682Z"
      fill="black"
    />,
    <path
      key="unhealthy-intercom-14"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48.3117 80.0607C48.6041 79.5921 49.2209 79.4492 49.6895 79.7416L111.879 118.542C112.348 118.834 112.491 119.451 112.199 119.919C111.906 120.388 111.289 120.531 110.821 120.238L48.6308 81.4384C48.1623 81.1461 48.0194 80.5292 48.3117 80.0607Z"
      fill="black"
    />,
    <path
      key="unhealthy-intercom-15"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M74.0586 77.1796C74.357 76.7149 74.9756 76.58 75.4404 76.8783L110.13 99.1483C110.595 99.4467 110.73 100.065 110.432 100.53C110.133 100.995 109.515 101.13 109.05 100.831L74.3599 78.5614C73.8952 78.263 73.7603 77.6444 74.0586 77.1796Z"
      fill="black"
    />,
    <path
      key="unhealthy-intercom-16"
      d="M150.58 44.88C162.697 44.88 172.52 35.0571 172.52 22.94C172.52 10.8229 162.697 1 150.58 1C138.463 1 128.64 10.8229 128.64 22.94C128.64 35.0571 138.463 44.88 150.58 44.88Z"
      fill="url(#paint5_linear_2257_20731)"
    />,
    <path
      key="unhealthy-intercom-17"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M150.58 2C139.016 2 129.64 11.3752 129.64 22.94C129.64 34.5048 139.016 43.88 150.58 43.88C162.145 43.88 171.52 34.5048 171.52 22.94C171.52 11.3752 162.145 2 150.58 2ZM127.64 22.94C127.64 10.2706 137.911 0 150.58 0C163.25 0 173.52 10.2706 173.52 22.94C173.52 35.6094 163.25 45.88 150.58 45.88C137.911 45.88 127.64 35.6094 127.64 22.94Z"
      fill="black"
    />,
    <path
      key="unhealthy-intercom-18"
      d="M148.53 30.8798H152.62V34.9698H148.53V30.8798ZM148.53 10.8398H152.62V26.7898H148.53V10.8398Z"
      fill="#231F20"
    />,
    <defs key="unhealthy-intercom-19">
      <linearGradient
        id="paint0_linear_2257_20731"
        x1="48.0802"
        y1="29.8999"
        x2="55.2602"
        y2="42.3399"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCD800" />
        <stop offset="1" stopColor="#B29741" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2257_20731"
        x1="23.8202"
        y1="26.4399"
        x2="34.9902"
        y2="45.7999"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCD800" />
        <stop offset="1" stopColor="#B29741" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2257_20731"
        x1="91.1903"
        y1="13.6999"
        x2="117.08"
        y2="58.5399"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCD800" />
        <stop offset="1" stopColor="#B29741" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_2257_20731"
        x1="45.1602"
        y1="37.7601"
        x2="114.98"
        y2="158.68"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCD800" />
        <stop offset="1" stopColor="#B29741" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_2257_20731"
        x1="47.8804"
        y1="159.795"
        x2="71.5795"
        y2="234.125"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCD800" />
        <stop offset="1" stopColor="#B29741" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_2257_20731"
        x1="128.64"
        y1="22.94"
        x2="172.51"
        y2="22.94"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCD800" />
        <stop offset="1" stopColor="#B29741" />
      </linearGradient>
    </defs>,
  ],
});

import React, { useCallback, useMemo } from "react";
import {
  Checkbox,
  CheckboxGroup,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Stack,
  Switch,
  Text,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { HTTPError } from "ky";
import { intersection } from "lodash";

import {
  useGetEntryGroupsQuery,
  useGetEntrySchedulesQuery,
} from "src/routes/Property/queries";
import { useKy } from "src/common/ky";
import { AddAccessProfileValidationSchema } from "src/routes/Property/schema";
import { Loading } from "src/common/Loading";
import { noop } from "src/common/util";
import { AccessControlFormProps } from "src/routes/Property/AccessControl/types";
import { AccessProfileDMPProperties } from "src/common/types";

export interface AddAccessProfileValues {
  displayName: string;
  propertyOrganizationId: string;
  entryGroupIds: string[];
  entryScheduleIds: string[];
  dmpProperties: AccessProfileDMPProperties | undefined;
}

export type CreateAccessProfileFormProps =
  AccessControlFormProps<AddAccessProfileValues> & {
    propertyOrganizationId: string;
  };

export const CreateAccessProfileForm = (
  props: CreateAccessProfileFormProps
) => {
  const {
    propertyOrganizationId,
    Footer,
    mutationOptionsBuilder,
    hideFooterDivider,
  } = props;

  const ky = useKy();
  const entryGroupsQuery = useGetEntryGroupsQuery(
    propertyOrganizationId as string
  );
  const entrySchedulesQuery = useGetEntrySchedulesQuery(
    propertyOrganizationId as string
  );

  const hookForm = useForm<AddAccessProfileValues>({
    defaultValues: {
      displayName: "",
      propertyOrganizationId,
      entryGroupIds: [],
      entryScheduleIds: [],
      dmpProperties: {
        sensorReset: false,
      },
    },
    resolver: yupResolver(AddAccessProfileValidationSchema),
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = hookForm;

  const mutationOptions = useMemo(
    () => mutationOptionsBuilder && mutationOptionsBuilder(hookForm),
    [mutationOptionsBuilder, hookForm]
  );

  const onSubmit = useCallback(
    handleSubmit(
      async (values: AddAccessProfileValues) =>
        await addAccessProfile.mutateAsync(values).catch(noop)
    ),
    [handleSubmit]
  );

  const addAccessProfile = useMutation<void, HTTPError, AddAccessProfileValues>(
    async (values: AddAccessProfileValues) => {
      await ky.post("access-control/profile", {
        json: values,
      });
    },
    mutationOptions
  );

  const validEntryGroupIds = useMemo(
    () =>
      (entryGroupsQuery.data ?? []).map(
        (entryGroup) => entryGroup.entryGroupId
      ),
    [entryGroupsQuery.data]
  );

  const validEntryScheduleIds = useMemo(
    () => (entrySchedulesQuery.data ?? []).map((es) => es.entryScheduleId),
    [entrySchedulesQuery.data]
  );

  if (entryGroupsQuery.isLoading || !entryGroupsQuery.isSuccess) {
    return <Loading />;
  }

  return (
    <form onSubmit={onSubmit}>
      <Grid gap={4} width={{ base: "100%", xl: "50%" }}>
        <GridItem>
          <Controller
            name={"displayName"}
            control={control}
            render={({ field, fieldState }) => (
              <FormControl isInvalid={!!fieldState.error} isRequired>
                <FormLabel htmlFor="displayName">Display Name</FormLabel>
                <Input {...field} id="displayName" />
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </GridItem>
        <GridItem>
          <Controller
            name={"dmpProperties.sensorReset"}
            control={control}
            render={({ field: { value, ...field }, fieldState }) => (
              <FormControl isInvalid={!!fieldState.error}>
                <Flex alignItems={"center"}>
                  <FormLabel htmlFor="fireDoorRelease" mb={0}>
                    Fire Door Release
                  </FormLabel>
                  <Switch isChecked={value} {...field} id="fireDoorRelease" />
                </Flex>
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </GridItem>
        <Divider />
        <GridItem>
          <Controller
            name={"entryGroupIds"}
            control={control}
            render={({ field, fieldState }) => (
              <FormControl isInvalid={!!fieldState.error}>
                <FormLabel htmlFor="entryGroupIds">Entry Groups</FormLabel>
                <Stack spacing={5}>
                  {entryGroupsQuery.data?.length ? (
                    <CheckboxGroup defaultValue={field.value}>
                      {entryGroupsQuery.data.map((entryGroup) => (
                        <Checkbox
                          key={entryGroup.entryGroupId}
                          value={entryGroup.entryGroupId}
                          onChange={(event) => {
                            const isChecked = event.target.checked;
                            const value = event.target.value;

                            field.onChange(
                              isChecked
                                ? intersection(validEntryGroupIds, [
                                    ...field.value,
                                    value,
                                  ])
                                : field.value.filter((id) => id !== value)
                            );
                          }}
                        >
                          {entryGroup.displayName}
                        </Checkbox>
                      ))}
                    </CheckboxGroup>
                  ) : (
                    <Text>No Entry Groups</Text>
                  )}
                  <FormErrorMessage>
                    {fieldState.error?.message}
                  </FormErrorMessage>
                </Stack>
              </FormControl>
            )}
          />
        </GridItem>
        <GridItem>
          <Divider />
        </GridItem>
        <GridItem>
          <Controller
            name={"entryScheduleIds"}
            control={control}
            render={({ field, fieldState }) => (
              <FormControl isInvalid={!!fieldState.error}>
                <FormLabel htmlFor="entryScheduleIds">
                  Entry Schedules
                </FormLabel>
                <Stack spacing={5}>
                  {entrySchedulesQuery.data?.length ? (
                    <CheckboxGroup value={field.value}>
                      {entrySchedulesQuery.data.map((entrySchedule) => (
                        <Checkbox
                          key={entrySchedule.entryScheduleId}
                          value={entrySchedule.entryScheduleId}
                          onChange={(event) => {
                            const isChecked = event.target.checked;
                            const value = event.target.value;

                            field.onChange(
                              isChecked
                                ? intersection(validEntryScheduleIds, [
                                    ...field.value,
                                    value,
                                  ])
                                : intersection(
                                    validEntryScheduleIds,
                                    field.value
                                  ).filter((id) => id !== value)
                            );
                          }}
                        >
                          {entrySchedule.displayName}
                        </Checkbox>
                      ))}
                    </CheckboxGroup>
                  ) : (
                    <Text>No Entry Schedules</Text>
                  )}
                  <FormErrorMessage>
                    {fieldState.error?.message}
                  </FormErrorMessage>
                </Stack>
              </FormControl>
            )}
          />
        </GridItem>
      </Grid>
      {Footer ? (
        <Grid gap={4} mt={4}>
          {!hideFooterDivider ? (
            <GridItem>
              <Divider />
            </GridItem>
          ) : null}
          <GridItem>{Footer({ isSubmitting, onSubmit })}</GridItem>
        </Grid>
      ) : null}
    </form>
  );
};

import React from "react";
import { createIcon } from "@chakra-ui/react";

export const Thermostat = createIcon({
  displayName: "Thermostat",
  viewBox: "0 0 18 34",
  path: (
    <path
      fill="currentColor"
      d="M8.99999 33.667C6.69466 33.667 4.72933 32.8543 3.10399 31.229C1.47933 29.6043 0.666992 27.639 0.666992 25.333C0.666992 23.9443 0.979326 22.639 1.60399 21.417C2.22933 20.1943 3.12533 19.2497 4.29199 18.583V5.04201C4.29199 3.73601 4.75033 2.62467 5.66699 1.70801C6.58366 0.791341 7.69466 0.333008 8.99999 0.333008C10.3053 0.333008 11.4163 0.791341 12.333 1.70801C13.2497 2.62467 13.708 3.73601 13.708 5.04201V18.583C14.8747 19.2497 15.7707 20.1943 16.396 21.417C17.0207 22.639 17.333 23.9443 17.333 25.333C17.333 27.639 16.5207 29.6043 14.896 31.229C13.2707 32.8543 11.3053 33.667 8.99999 33.667ZM7.08299 15.458H10.917V13.458H8.99999V11.792H10.917V8.29201H8.99999V6.66701H10.917V5.04201C10.917 4.48601 10.7363 4.02767 10.375 3.66701C10.0137 3.30567 9.55533 3.12501 8.99999 3.12501C8.44466 3.12501 7.98633 3.30567 7.62499 3.66701C7.26366 4.02767 7.08299 4.48601 7.08299 5.04201V15.458Z"
    />
  ),
});

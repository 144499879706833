import React, { useState } from "react";
import {
  Button,
  ButtonGroup,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  ModalHeader,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Text,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  Link as RouterLink,
  useParams,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import {
  HiOutlineDotsVertical,
  HiOutlinePencil,
  HiOutlineTrash,
  HiEye,
} from "react-icons/hi";

import { PageContent } from "src/layout/PageContent";
import {
  PageHeader,
  PageHeaderTabs,
  PageHeaderTitle,
} from "src/layout/PageHeader";
import { Loading } from "src/common/Loading";
import { PropertyOrganization, IntercomActivation } from "src/common/types";
import { HTTPError, useKy } from "src/common/ky";
import { Cellular } from "src/common/icons/Cellular";
import { useIntercomActivationsQuery } from "src/common/queries";
import { useFlags } from "src/common/hooks/useFlags";
import { useCurrentDealerOrganization } from "src/routes/DealerOrganizationSelector";
import PropertyHeaderTabs from "src/routes/Property/PropertyHeaderTabs";

export const Intercoms = () => {
  const { dealerOrganizationId, propertyOrganizationId } = useParams();
  const intercomActivationsQuery = useIntercomActivationsQuery();
  const { intercomActivations } =
    useOutletContext<{ intercomActivations: IntercomActivation[] }>();
  const navigate = useNavigate();
  const toast = useToast();
  const ky = useKy();
  const flags = useFlags();

  const dealerOrganization = useCurrentDealerOrganization();
  const financialsIsSet = dealerOrganization
    ? dealerOrganization.dealerFinancialsIsSet
    : false;

  const propertyOrganizationQuery = useQuery<PropertyOrganization, HTTPError>(
    ["PROPERTY_ORGANIZATION", propertyOrganizationId, dealerOrganizationId],
    () =>
      ky
        .get(
          `property-organizations/${propertyOrganizationId}/dealer-organization/${dealerOrganizationId}`
        )
        .json<PropertyOrganization>()
  );

  const [removingIntercomId, setRemovingIntercomId] = useState<
    string | undefined
  >();
  const removeIntercom = useMutation(
    (id: string | undefined) => ky.delete(`product-unit-activations/${id}`),
    {
      onSuccess: () => {
        intercomActivationsQuery.refetch();
        setRemovingIntercomId(undefined);
        onCloseRemoveModal();
        toast({
          description: "Intercom successfully removed",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      },
    }
  );

  const {
    isOpen: removeModalIsOpen,
    onOpen: onOpenRemoveModal,
    onClose: onCloseRemoveModal,
  } = useDisclosure();

  if (
    propertyOrganizationQuery.isLoading ||
    !propertyOrganizationQuery.isSuccess
  ) {
    return <Loading />;
  }

  const baseUrl = `/dealer-organizations/${dealerOrganizationId}/properties/${propertyOrganizationId}/intercoms`;

  return (
    <>
      <PageHeader
        buttons={
          <ButtonGroup>
            {financialsIsSet && (
              <Link as={RouterLink} to={`add`}>
                <Button colorScheme="brand.blue">Add Intercom</Button>
              </Link>
            )}
          </ButtonGroup>
        }
      >
        <Link
          as={RouterLink}
          to={`/dealer-organizations/${dealerOrganizationId}/properties`}
        >
          &#8249; Back to list
        </Link>
        <PageHeaderTitle>{propertyOrganizationQuery.data.name}</PageHeaderTitle>
        <PageHeaderTabs>
          <PropertyHeaderTabs />
        </PageHeaderTabs>
      </PageHeader>
      <PageContent>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Serial Number</Th>
              <Th>Intercom Model</Th>
              <Th>Cell Kit</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {intercomActivations.map((intercomActivation) => (
              <Tr
                key={intercomActivation.intercomActivationId}
                _hover={{ bg: "brand.lightGray.100", cursor: "pointer" }}
                onClick={
                  flags.gen4DealerPortal
                    ? () =>
                        navigate(
                          `${baseUrl}/${intercomActivation.intercomActivationId}`
                        )
                    : undefined
                }
              >
                <Td>
                  <Text>{intercomActivation.name}</Text>
                </Td>
                <Td>
                  <Text>{intercomActivation.serialNumber}</Text>
                </Td>
                <Td>
                  <Text>{intercomActivation.intercomModel}</Text>
                </Td>
                <Td>
                  <Text>{intercomActivation.assignedCellKit ?? "-"}</Text>
                </Td>
                <Td>
                  <Menu>
                    <MenuButton
                      as={IconButton}
                      aria-label="Actions"
                      icon={<HiOutlineDotsVertical />}
                      variant="clear"
                      onClick={(e) => e.stopPropagation()}
                    >
                      Actions
                    </MenuButton>
                    <MenuList>
                      {flags.gen4DealerPortal && (
                        <MenuItem
                          justifyContent="space-between"
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(
                              `${baseUrl}/${intercomActivation.intercomActivationId}`
                            );
                          }}
                        >
                          View Intercom
                          <HiEye />
                        </MenuItem>
                      )}
                      <MenuItem
                        isDisabled={!!intercomActivation.assignedCellKit}
                        justifyContent="space-between"
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(
                            `${baseUrl}/${intercomActivation.intercomActivationId}/cell-kits/add`
                          );
                        }}
                      >
                        Assign Cell Kit
                        <Cellular />
                      </MenuItem>
                      <MenuItem
                        justifyContent="space-between"
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(
                            `${baseUrl}/${intercomActivation.intercomActivationId}/edit`
                          );
                        }}
                      >
                        Edit Intercom Details
                        <HiOutlinePencil />
                      </MenuItem>
                      <MenuItem
                        justifyContent="space-between"
                        onClick={(e) => {
                          e.stopPropagation();
                          setRemovingIntercomId(
                            intercomActivation.intercomActivationId
                          );
                          onOpenRemoveModal();
                        }}
                      >
                        Remove Intercom
                        <HiOutlineTrash />
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </PageContent>

      <Modal
        isOpen={removeModalIsOpen}
        onClose={() => {
          onCloseRemoveModal();
          setRemovingIntercomId(undefined);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Remove Intercom</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure? You can&apos;t undo this action afterwards.
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="brand.lightGray"
              color="gray.700"
              mr={3}
              onClick={() => {
                onCloseRemoveModal();
                setRemovingIntercomId(undefined);
              }}
            >
              Close
            </Button>
            <Button
              colorScheme="brand.red"
              onClick={() => removeIntercom.mutate(removingIntercomId)}
              isDisabled={removeIntercom.isLoading}
            >
              Accept
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

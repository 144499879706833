import React, { useLayoutEffect, useState } from "react";
import {
  Link as RouterLink,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Container,
  Link,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";

import { Building as BuildingIcon } from "src/common/icons/Building";
import { Loading } from "src/common/Loading";
import {
  PageHeader,
  PageHeaderTabs,
  PageHeaderTitle,
} from "src/layout/PageHeader";
import PropertyHeaderTabs from "src/routes/Property/PropertyHeaderTabs";
import {
  useGetPropertyAccessPanelQuery,
  usePropertyOrganizationQuery,
} from "src/routes/Property/queries";
import { AccessPanel } from "src/routes/Property/AccessControl/Panels/AccessPanel";
import { ProfileList } from "src/routes/Property/AccessControl/Profiles/ProfileList";
import { EntryScheduleList } from "src/routes/Property/AccessControl/EntrySchedules/EntryScheduleList";
import { EntryGroupList } from "src/routes/Property/AccessControl/EntryGroups/EntryGroupList";
import { EntryPointList } from "src/routes/Property/AccessControl/EntryPoints/EntryPointList";
import { Settings } from "src/routes/Property/AccessControl/Settings/Settings";
import { PageContent } from "src/layout/PageContent";
import { useShouldUseAccessControlWizard } from "src/routes/Property/useShouldUseAccessControl";
import { AccessControlWizard } from "src/routes/Property/AccessControl/Wizard";

const PANEL_TABS = [
  "entry-groups",
  "entry-points",
  "entry-schedules",
  "profiles",
  "settings",
];

export const AccessControl: React.FC = () => {
  const { dealerOrganizationId, propertyOrganizationId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const toast = useToast();

  const propertyOrganizationQuery = usePropertyOrganizationQuery({
    propertyOrganizationId: propertyOrganizationId as string,
    dealerOrganizationId: dealerOrganizationId as string,
  });
  const propertyPanelQuery = useGetPropertyAccessPanelQuery(
    propertyOrganizationId as string
  );
  const [panelTabIndex, setTabIndex] = useState(0);

  const {
    invalidate: invalidateShouldUseAccessControlWizard,
    shouldUseAccessControlWizard,
    isLoading: isLoadingShouldUseAccessControlWizard,
  } = useShouldUseAccessControlWizard(
    propertyOrganizationId as string,
    dealerOrganizationId as string
  );

  const onTabChange = (tabIndex: number) => {
    setSearchParams((sp) => ({
      ...sp,
      panelTab: PANEL_TABS[tabIndex],
    }));
  };

  useLayoutEffect(() => {
    const tabIndex = PANEL_TABS.findIndex(
      (t) => t === searchParams.get("panelTab")
    );
    if (tabIndex !== -1) {
      setTabIndex(tabIndex);
    }
  }, [searchParams.get("panelTab")]);

  if (
    isLoadingShouldUseAccessControlWizard ||
    propertyOrganizationQuery.isLoading ||
    !propertyOrganizationQuery.isSuccess ||
    propertyPanelQuery.isLoading ||
    !propertyPanelQuery.isSuccess
  ) {
    return <Loading />;
  }

  return (
    <>
      <PageHeader>
        <Link
          as={RouterLink}
          to={`/dealer-organizations/${dealerOrganizationId}/properties`}
        >
          &#8249; Back to list
        </Link>
        <PageHeaderTitle>{propertyOrganizationQuery.data.name}</PageHeaderTitle>
        <PageHeaderTabs>
          <PropertyHeaderTabs />
        </PageHeaderTabs>
      </PageHeader>
      {propertyPanelQuery.data ? (
        shouldUseAccessControlWizard ? (
          <Container maxW={"7xl"} py={4}>
            <AccessControlWizard
              propertyOrganizationId={propertyOrganizationId as string}
              onComplete={() => {
                toast({
                  description: "Access Control onboarding completed",
                  status: "success",
                });
                invalidateShouldUseAccessControlWizard();
                navigate(
                  `/dealer-organizations/${dealerOrganizationId}/properties/${propertyOrganizationId}/access-control`
                );
              }}
            />
          </Container>
        ) : (
          <>
            <PageContent>
              <AccessPanel panel={propertyPanelQuery.data} />
            </PageContent>
            <Tabs
              colorScheme={"brand.blue"}
              index={panelTabIndex}
              onChange={onTabChange}
              variant={"enclosed"}
            >
              <PageContent>
                <TabList>
                  <Tab>Entry Groups</Tab>
                  <Tab>Entry Points</Tab>
                  <Tab>Entry Schedules</Tab>
                  <Tab>Entry Profiles</Tab>
                  <Tab>Settings</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <EntryGroupList />
                  </TabPanel>
                  <TabPanel>
                    <EntryPointList />
                  </TabPanel>
                  <TabPanel>
                    <EntryScheduleList />
                  </TabPanel>
                  <TabPanel>
                    <ProfileList />
                  </TabPanel>
                  <TabPanel>
                    <Settings />
                  </TabPanel>
                </TabPanels>
              </PageContent>
            </Tabs>
          </>
        )
      ) : shouldUseAccessControlWizard ? (
        <Container maxW={"7xl"} py={4}>
          <AccessControlWizard
            propertyOrganizationId={propertyOrganizationId as string}
            onComplete={() => {
              toast({
                description: "Access Control onboarding completed",
                status: "success",
              });
              invalidateShouldUseAccessControlWizard();
              navigate(
                `/dealer-organizations/${dealerOrganizationId}/properties/${propertyOrganizationId}/access-control`
              );
            }}
          />
        </Container>
      ) : (
        <VStack mt={4} spacing={4}>
          <BuildingIcon />
          <Text
            color="gray.500"
            fontWeight="medium"
            fontSize="lg"
            align="center"
          >
            Access Control is not configured at this property. <br />
          </Text>
          <ButtonGroup>
            <Link
              as={RouterLink}
              to={`/dealer-organizations/${dealerOrganizationId}/properties/${propertyOrganizationId}/access-control/add`}
            >
              <Button colorScheme="brand.blue">Configure Access Control</Button>
            </Link>
          </ButtonGroup>
        </VStack>
      )}
    </>
  );
};

import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { useIsAdmin } from "src/common/hooks/useIsAdmin";

export const AdminRoleGate = () => {
  const navigate = useNavigate();
  const isAdmin = useIsAdmin();

  useEffect(() => {
    if (!isAdmin) {
      navigate("/");
    }
  }, []);

  return <Outlet />;
};

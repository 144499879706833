import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ButtonGroup,
  Checkbox,
  CheckboxGroup,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Input,
  Link,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { HiOutlinePlus, HiTrash } from "react-icons/hi";
import { difference, intersection } from "lodash";

import { Loading } from "src/common/Loading";
import {
  PageHeader,
  PageHeaderBackLink,
  PageHeaderSubtitle,
  PageHeaderTitle,
} from "src/layout/PageHeader";
import { PageContent } from "src/layout/PageContent";
import { noop } from "src/common/util";
import { EditPropertyUserValidationSchema } from "src/routes/Property/schema";
import { EditPropertyUserValues } from "src/routes/Property/interfaces";
import { useUpdatePropertyUser } from "src/routes/Property/hooks";
import {
  useGetAccessProfilesQuery,
  useGetPropertyUserQuery,
} from "src/routes/Property/queries";
import { PropertyUser } from "src/common/types";
import { useFlags } from "src/common/hooks/useFlags";
import { useHasAccessControl } from "src/common/useHasAccessControl";
import { PhoneNumberInput } from "src/common/PhoneNumbers";

export const GET_USER_QUERY_KEY = "GET_USER";

export const EditPropertyStaff = () => {
  const { propertyOrganizationId, dealerOrganizationId } = useParams();
  const flags = useFlags();
  const getAccessProfilesQuery = flags.accessControl
    ? useGetAccessProfilesQuery(propertyOrganizationId as string)
    : null;
  const hasAccessControl = useHasAccessControl({
    propertyOrganizationId: propertyOrganizationId as string,
    dealerOrganizationId: dealerOrganizationId as string,
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty, errors },
    getValues,
    reset,
    setError,
  } = useForm<EditPropertyUserValues>({
    defaultValues: {
      propertyOrganizationId,
      firstName: "",
      lastName: "",
      phone: "",
      role: "",
      physicalAccessCredentials: [],
      accessProfileIds: [],
    },
    resolver: yupResolver(EditPropertyUserValidationSchema),
  });

  const { updatePropertyUserMutation } = useUpdatePropertyUser(
    setError,
    getValues
  );

  const [removeCredentialsConfirmed, setRemoveCredentialsConfirmed] =
    useState<boolean>(false);

  const {
    fields: physicalAccessCredentialFields,
    append: appendPhysicalAccessCredentialField,
    remove: removePhysicalAccessCredentialField,
  } = useFieldArray({
    control,
    name: "physicalAccessCredentials",
  });

  const credentialsChangedDisclosure = useDisclosure();
  const cancelRef = useRef<HTMLButtonElement>(null);

  const validAccessProfileIds = useMemo(
    () =>
      (getAccessProfilesQuery?.data ?? []).map(
        (profile) => profile.accessProfileId
      ),
    [getAccessProfilesQuery?.data]
  );

  const getUserQuery = useGetPropertyUserQuery((propertyUser: PropertyUser) => {
    if (!isDirty) {
      reset({
        propertyOrganizationId,
        firstName: propertyUser.user.firstName || "",
        lastName: propertyUser.user.lastName || "",
        phone: propertyUser.user.phone || "",
        role: propertyUser.role || "",
        physicalAccessCredentials: propertyUser.physicalAccessCredentials || [],
        accessProfileIds: propertyUser.accessProfileIds || [],
      });
    }
  });

  const onSubmit = useCallback(
    (values: EditPropertyUserValues) => {
      setRemoveCredentialsConfirmed(false);
      return updatePropertyUserMutation.mutateAsync(values).catch(noop);
    },
    [updatePropertyUserMutation]
  );

  useEffect(() => {
    if (removeCredentialsConfirmed) {
      onSubmit(getValues());
    }
  }, [removeCredentialsConfirmed]);

  if (
    getUserQuery.isLoading ||
    !getUserQuery.isSuccess ||
    (flags.accessControl &&
      (getAccessProfilesQuery?.isLoading || !getAccessProfilesQuery?.isSuccess))
  ) {
    return <Loading />;
  }

  return (
    <>
      <PageHeader>
        <PageHeaderBackLink>
          <Link
            as={RouterLink}
            to={`/dealer-organizations/${dealerOrganizationId}/properties/${propertyOrganizationId}/staff`}
          >
            &#8249; Back to list
          </Link>
        </PageHeaderBackLink>
        <PageHeaderTitle>Edit Staff User</PageHeaderTitle>
        <PageHeaderSubtitle>
          Enter the user&apos;s details here
        </PageHeaderSubtitle>
      </PageHeader>
      <PageContent>
        <form
          onSubmit={handleSubmit((values) => {
            const credentialsChanged = difference(
              getUserQuery.data.physicalAccessCredentials.map(
                (pac) => pac.code
              ),
              values.physicalAccessCredentials.map((pac) => pac.code)
            ).length;

            if (credentialsChanged) {
              credentialsChangedDisclosure.onOpen();
            } else {
              return onSubmit(values);
            }
          })}
        >
          <Grid gap={4}>
            <GridItem>
              <Heading as={"h3"} fontSize="24px" mt={4} mb={4}>
                Staff User Details
              </Heading>
            </GridItem>

            <GridItem>
              <Divider />
            </GridItem>

            <GridItem>
              <Controller
                name="firstName"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl
                    isRequired
                    isInvalid={fieldState.invalid && fieldState.isTouched}
                  >
                    <FormLabel htmlFor="firstName">First Name</FormLabel>
                    <Input
                      width={["100%", "100%", "50%", "50%"]}
                      {...field}
                      id="firstName"
                    />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>

            <GridItem>
              <Controller
                name="lastName"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl
                    isRequired
                    isInvalid={fieldState.invalid && fieldState.isTouched}
                  >
                    <FormLabel htmlFor="lastName">Last Name</FormLabel>
                    <Input
                      width={["100%", "100%", "50%", "50%"]}
                      {...field}
                      id="lastName"
                    />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>

            <GridItem>
              <Controller
                name="phone"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl
                    isRequired
                    isInvalid={fieldState.invalid && fieldState.isTouched}
                  >
                    <FormLabel htmlFor="phone">Phone</FormLabel>
                    <PhoneNumberInput
                      width={["100%", "100%", "50%", "50%"]}
                      {...field}
                      id="phone"
                    />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>

            <GridItem>
              <FormLabel htmlFor="email">Email</FormLabel>
              <Input
                width={["100%", "100%", "50%", "50%"]}
                isDisabled
                value={getUserQuery.data.user.email}
                id="email"
              />
            </GridItem>

            <GridItem>
              <Controller
                name="role"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl
                    isRequired
                    isInvalid={fieldState.invalid && fieldState.isTouched}
                  >
                    <FormLabel htmlFor="role">Role</FormLabel>
                    <RadioGroup id="role" {...field}>
                      <Stack direction="column">
                        <Radio value="admin">Admin</Radio>
                        <Radio value="staff">Staff</Radio>
                      </Stack>
                    </RadioGroup>
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>

            <GridItem
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              width={["100%", "100%", "50%", "50%"]}
            >
              <FormLabel>Access Credentials</FormLabel>
              {physicalAccessCredentialFields.length ? (
                <Button
                  leftIcon={<HiOutlinePlus />}
                  variant="outline"
                  colorScheme="brand.blue"
                  onClick={() =>
                    appendPhysicalAccessCredentialField({
                      code: "",
                      propertyOrganizationId,
                    })
                  }
                >
                  Add Credential
                </Button>
              ) : null}
            </GridItem>

            {physicalAccessCredentialFields.map((item, index) => (
              <GridItem
                display="flex"
                key={item.id}
                width={["100%", "100%", "50%", "50%"]}
              >
                <Controller
                  name={`physicalAccessCredentials.${index}.code`}
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl
                      isInvalid={!!fieldState.error}
                      isDisabled={!!item.accessCredentialId}
                    >
                      <Input
                        {...field}
                        maxLength={5}
                        isDisabled={!!item.accessCredentialId}
                      />
                      <FormErrorMessage>
                        {fieldState.error?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
                <IconButton
                  colorScheme="brand.red"
                  variant="link"
                  aria-label="Delete access credential"
                  icon={<HiTrash />}
                  size="lg"
                  onClick={() => removePhysicalAccessCredentialField(index)}
                />
              </GridItem>
            ))}

            {errors[`physicalAccessCredentials`]?.message ? (
              <GridItem display="flex" width={["100%", "100%", "50%", "50%"]}>
                <FormControl isInvalid={true}>
                  <FormErrorMessage>
                    {errors[`physicalAccessCredentials`]?.message}
                  </FormErrorMessage>
                </FormControl>
              </GridItem>
            ) : null}

            {!physicalAccessCredentialFields.length ? (
              <GridItem
                textAlign="center"
                width={["100%", "100%", "50%", "50%"]}
              >
                <Text>This user doesn’t have any</Text>
                <Text marginBottom="8px">access credentials yet</Text>
                <Button
                  leftIcon={<HiOutlinePlus />}
                  variant="outline"
                  colorScheme="brand.blue"
                  onClick={() =>
                    appendPhysicalAccessCredentialField({
                      code: "",
                      propertyOrganizationId,
                    })
                  }
                >
                  Add Credential
                </Button>
              </GridItem>
            ) : null}

            {flags.accessControl &&
            hasAccessControl &&
            getAccessProfilesQuery?.data?.length ? (
              <GridItem>
                <Controller
                  name="accessProfileIds"
                  control={control}
                  render={({ field, fieldState }) => {
                    return (
                      <FormControl isInvalid={!!fieldState.error}>
                        <FormLabel htmlFor="access-profiles">
                          Add Access Profiles
                        </FormLabel>
                        <CheckboxGroup
                          defaultValue={getUserQuery.data.accessProfileIds}
                        >
                          <Stack pl={1} mt={1} spacing={5}>
                            {getAccessProfilesQuery.data.map((profile) => (
                              <Checkbox
                                key={profile.accessProfileId}
                                value={profile.accessProfileId}
                                onChange={(e) => {
                                  const isChecked = e.target.checked;
                                  const value = e.target.value;
                                  const fieldValue = field.value;

                                  field.onChange(
                                    isChecked
                                      ? intersection(validAccessProfileIds, [
                                          ...fieldValue,
                                          value,
                                        ])
                                      : intersection(
                                          validAccessProfileIds,
                                          fieldValue
                                        ).filter((id) => id !== value)
                                  );
                                }}
                                colorScheme="brand.blue"
                                fontWeight={400}
                              >
                                {profile.displayName}
                              </Checkbox>
                            ))}
                          </Stack>
                        </CheckboxGroup>
                        <FormErrorMessage>
                          {fieldState.error?.message}
                        </FormErrorMessage>
                      </FormControl>
                    );
                  }}
                />
              </GridItem>
            ) : null}

            <GridItem>
              <Divider />
            </GridItem>

            <GridItem>
              <ButtonGroup display="flex" justifyContent="flex-end">
                <Link
                  as={RouterLink}
                  to={`/dealer-organizations/${dealerOrganizationId}/properties/${propertyOrganizationId}/staff`}
                >
                  <Button>Cancel</Button>
                </Link>
                <Button
                  type="submit"
                  colorScheme="brand.blue"
                  isLoading={isSubmitting}
                >
                  Save User
                </Button>
              </ButtonGroup>
            </GridItem>
          </Grid>
        </form>
      </PageContent>

      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={credentialsChangedDisclosure.onClose}
        isOpen={credentialsChangedDisclosure.isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Changes to Access Credentials</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            Are you sure? You can&apos;t undo this action.
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={credentialsChangedDisclosure.onClose}
            >
              Cancel
            </Button>
            <Button
              colorScheme="brand.red"
              ml="3"
              onClick={() => {
                setRemoveCredentialsConfirmed(true);
              }}
            >
              Save User
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

import React, { useCallback, useMemo } from "react";
import { Flex, Spacer, TableColumnHeaderProps, Th } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { GoTriangleDown, GoTriangleUp } from "react-icons/go";
import { FaSort } from "react-icons/fa";

type Props = TableColumnHeaderProps & {
  sortKey: string;
};
export const SortableTh = ({
  sortKey,
  children,
  ...tableColumnHeaderProps
}: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const onSort = useCallback(() => {
    const sortBy = searchParams.get("sortBy");
    const sortDir = searchParams.get("sortDir");
    const nextSortBy = sortKey;

    let nextSortDir: string;
    if (sortBy !== sortKey) {
      nextSortDir = "ASC";
    } else {
      switch (sortDir) {
        case "ASC":
          nextSortDir = "DESC";
          break;
        case "DESC":
          nextSortDir = "";
          break;
        default:
          nextSortDir = "ASC";
      }
    }

    const newSp = new URLSearchParams(location.search);
    if (nextSortDir) {
      newSp.set("sortBy", nextSortBy);
      newSp.set("sortDir", nextSortDir);
    } else {
      newSp.delete("sortBy");
      newSp.delete("sortDir");
    }

    newSp.delete("pageNumber");
    searchParams.sort();
    newSp.sort();

    if (searchParams.toString() !== newSp.toString()) {
      navigate(`?${newSp.toString()}`);
    }
  }, [searchParams]);

  const isSortingAsc =
    searchParams.get("sortBy") === sortKey &&
    searchParams.get("sortDir") === "ASC";
  const isSortingDesc =
    searchParams.get("sortBy") === sortKey &&
    searchParams.get("sortDir") === "DESC";

  return (
    <Th
      {...tableColumnHeaderProps}
      style={{ cursor: "pointer", userSelect: "none" }}
      onClick={onSort}
      _hover={{ backgroundColor: "#f5f5f5" }}
    >
      <Flex alignItems="center">
        {children}
        <Spacer width="5px" />
        {isSortingAsc && <GoTriangleUp />}
        {isSortingDesc && <GoTriangleDown />}
        {!isSortingDesc && !isSortingAsc && (
          <FaSort style={{ color: "#A0AEC0" }} />
        )}
      </Flex>
    </Th>
  );
};

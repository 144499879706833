import React from "react";
import { createIcon } from "@chakra-ui/react";

export const Monitor = createIcon({
  displayName: "Monitor",
  viewBox: "0 0 34 30",
  path: (
    <path
      fill="currentColor"
      d="M22 21.667H28.667V15H26.708V19.708H22V21.667ZM5.33301 11.667H7.29201V6.958H12V5H5.33301V11.667ZM10.625 30V26.667H3.12501C2.37501 26.667 1.72234 26.389 1.16701 25.833C0.611008 25.2777 0.333008 24.625 0.333008 23.875V2.792C0.333008 2.042 0.611008 1.389 1.16701 0.833C1.72234 0.277667 2.37501 0 3.12501 0H30.875C31.625 0 32.2777 0.277667 32.833 0.833C33.389 1.389 33.667 2.042 33.667 2.792V23.875C33.667 24.625 33.389 25.2777 32.833 25.833C32.2777 26.389 31.625 26.667 30.875 26.667H23.375V30H10.625ZM3.12501 23.875H30.875V2.792H3.12501V23.875Z"
    />
  ),
});

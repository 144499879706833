import React from "react";
import { createIcon } from "@chakra-ui/react";

export const ErrorOutline = createIcon({
  displayName: "ErrorOutline",
  viewBox: "0 0 54 55",
  path: (
    <path
      fill="currentColor"
      d="M24.333 35.4999H29.6663V40.8333H24.333V35.4999ZM24.333 14.1666H29.6663V30.1666H24.333V14.1666ZM26.9997 0.833252C12.253 0.833252 0.333008 12.8333 0.333008 27.4999C0.333008 34.5724 3.14252 41.3551 8.14349 46.3561C10.6197 48.8323 13.5594 50.7966 16.7948 52.1367C20.0301 53.4768 23.4978 54.1666 26.9997 54.1666C34.0721 54.1666 40.8549 51.3571 45.8559 46.3561C50.8568 41.3551 53.6663 34.5724 53.6663 27.4999C53.6663 23.998 52.9766 20.5304 51.6365 17.295C50.2963 14.0597 48.3321 11.12 45.8559 8.64374C43.3796 6.16751 40.4399 4.20326 37.2046 2.86313C33.9692 1.52301 30.5016 0.833252 26.9997 0.833252ZM26.9997 48.8333C21.3417 48.8333 15.9155 46.5856 11.9147 42.5849C7.91395 38.5841 5.66634 33.1579 5.66634 27.4999C5.66634 21.842 7.91395 16.4158 11.9147 12.415C15.9155 8.4142 21.3417 6.16659 26.9997 6.16659C32.6576 6.16659 38.0838 8.4142 42.0846 12.415C46.0854 16.4158 48.333 21.842 48.333 27.4999C48.333 33.1579 46.0854 38.5841 42.0846 42.5849C38.0838 46.5856 32.6576 48.8333 26.9997 48.8333Z"
    />
  ),
});

import React from "react";
import { Stack, Text } from "@chakra-ui/react";

import { ComponentReport, IntercomComponentStatus } from "src/common/types";
import { Card } from "src/layout/Card";
import {
  COMPONENT_STATUS_DISPLAY_NAMES,
  COMPONENT_TYPE_DISPLAY_NAMES,
  COMPONENT_TYPE_ICONS,
} from "src/common/constants";

const STATUS_TO_COLORS: Record<
  IntercomComponentStatus,
  { bg: string; border: string }
> = {
  [IntercomComponentStatus.ONLINE]: { bg: "#F2F5F7", border: "#D9DDDF" },
  [IntercomComponentStatus.OFFLINE]: {
    bg: "rgba(167, 56, 60, 0.20)",
    border: "rgba(167, 56, 60, 0.20)",
  },
  [IntercomComponentStatus.UNKNOWN]: { bg: "#D9DDDF", border: "#808080" },
};

type ComponentStatusItemProps = {
  component: ComponentReport;
};

export const ComponentStatusItem = ({
  component,
}: ComponentStatusItemProps) => {
  const Icon = COMPONENT_TYPE_ICONS[component.componentType];
  const colors = STATUS_TO_COLORS[component.status];
  const componentName = COMPONENT_TYPE_DISPLAY_NAMES[component.componentType];
  const statusText = COMPONENT_STATUS_DISPLAY_NAMES[component.status];

  return (
    <Card
      bg={colors.bg}
      border={`1.5px solid ${colors.border}`}
      maxH="38px"
      p={2}
    >
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Stack alignItems="center" direction="row" gap={0.25}>
          <Icon />
          <Text fontSize="14px" fontWeight="medium">
            {componentName}
          </Text>
        </Stack>
        <Text fontSize="12px" fontWeight="light">
          {statusText}
        </Text>
      </Stack>
    </Card>
  );
};

import React from "react";
import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Flex,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import {
  HiOutlineDotsVertical,
  HiOutlinePencil,
  HiOutlineTrash,
  HiOutlineUserCircle,
} from "react-icons/hi";

import { PageContent } from "src/layout/PageContent";
import {
  PageHeader,
  PageHeaderTabs,
  PageHeaderTitle,
} from "src/layout/PageHeader";
import { Loading } from "src/common/Loading";
import PropertyHeaderTabs from "src/routes/Property/PropertyHeaderTabs";
import {
  useAllPropertyStaffForDealerAdminQuery,
  usePropertyOrganizationQuery,
} from "src/routes/Property/queries";
import {
  useDeletePropertyUser,
  useResetPropertyUserPassword,
  useUpdatePropertyContact,
} from "src/routes/Property/hooks";
import { PropertyUserRole } from "src/common/types";
import { useIsAdmin } from "src/common/hooks/useIsAdmin";
import { ResetPassword } from "src/common/icons/ResetPassword";
import { CircleInfo } from "src/common/icons/CircleInfo";
import { formatPhoneNumber } from "src/common/phone-numbers";

export const Staff = () => {
  const navigate = useNavigate();
  const { dealerOrganizationId, propertyOrganizationId } = useParams();
  const propertyOrganizationQuery = usePropertyOrganizationQuery({
    propertyOrganizationId: propertyOrganizationId as string,
    dealerOrganizationId: dealerOrganizationId as string,
  });
  const staffQuery = useAllPropertyStaffForDealerAdminQuery();
  const isAdmin = useIsAdmin();

  const {
    isDeletingPropertyUser,
    deletePropertyUserMutation,
    setDeletingPropertyUserId,
  } = useDeletePropertyUser();

  const {
    isUpdatingPropertyContact,
    updatingPropertyContact,
    updatePropertyContactMutation,
    setUpdatingPropertyContact,
  } = useUpdatePropertyContact();

  const {
    isResettingPropertyUserPassword,
    resetPropertyUserPasswordMutation,
    setResettingPropertyUserId,
  } = useResetPropertyUserPassword();

  if (
    propertyOrganizationQuery.isLoading ||
    !propertyOrganizationQuery.isSuccess ||
    staffQuery.isLoading ||
    !staffQuery.isSuccess
  ) {
    return <Loading />;
  }

  return (
    <>
      <PageHeader
        buttons={
          <ButtonGroup>
            <Link
              as={RouterLink}
              to={`/dealer-organizations/${dealerOrganizationId}/properties/${propertyOrganizationId}/staff/add`}
            >
              <Button colorScheme="brand.blue">
                Add Property Staff Member
              </Button>
            </Link>
          </ButtonGroup>
        }
      >
        <Link
          as={RouterLink}
          to={`/dealer-organizations/${dealerOrganizationId}/properties`}
        >
          &#8249; Back to list
        </Link>
        <PageHeaderTitle>{propertyOrganizationQuery.data.name}</PageHeaderTitle>
        <PageHeaderTabs>
          <PropertyHeaderTabs />
        </PageHeaderTabs>
      </PageHeader>
      <PageContent>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Phone</Th>
              <Th>Email</Th>
              <Th>Role</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {staffQuery.data.map((propertyUser) => (
              <Tr key={propertyUser.propertyUserId}>
                <Td>
                  <Flex>
                    <Text>{`${propertyUser.user.firstName} ${propertyUser.user.lastName}`}</Text>
                    {propertyOrganizationQuery.data.contactId ===
                      propertyUser.propertyUserId && (
                      <>
                        <Box width={5} />
                        <Badge>Property Contact</Badge>
                      </>
                    )}
                  </Flex>
                </Td>
                <Td>
                  <Text>{formatPhoneNumber(propertyUser.user.phone)}</Text>
                </Td>
                <Td>
                  <Text>{propertyUser.user.email}</Text>
                </Td>
                <Td>
                  <Text>{propertyUser.role}</Text>
                </Td>
                <Td>
                  {isAdmin && (
                    <Menu>
                      <MenuButton
                        as={IconButton}
                        aria-label="Actions"
                        icon={<HiOutlineDotsVertical />}
                        variant="clear"
                      >
                        Actions
                      </MenuButton>
                      <MenuList w="320px">
                        <MenuItem
                          justifyContent="space-between"
                          onClick={() => {
                            navigate(
                              `/dealer-organizations/${dealerOrganizationId}/properties/${propertyOrganizationId}/staff/${propertyUser.propertyUserId}/edit`
                            );
                          }}
                        >
                          Edit Staff Member
                          <HiOutlinePencil />
                        </MenuItem>
                        {propertyOrganizationQuery.data.contactId !==
                          propertyUser.propertyUserId &&
                          propertyUser.role === PropertyUserRole.admin && (
                            <MenuItem
                              justifyContent="space-between"
                              onClick={() => {
                                setUpdatingPropertyContact(propertyUser);
                              }}
                            >
                              Set as Property Contact
                              <HiOutlineUserCircle />
                            </MenuItem>
                          )}
                        <MenuItem
                          justifyContent="space-between"
                          onClick={() => {
                            setResettingPropertyUserId(
                              propertyUser.propertyUserId
                            );
                          }}
                        >
                          Reset Password
                          <ResetPassword />
                        </MenuItem>
                        <MenuItem
                          opacity={
                            propertyOrganizationQuery.data.contactId ===
                            propertyUser.propertyUserId
                              ? 0.4
                              : 1
                          }
                          justifyContent="space-between"
                          onClick={() => {
                            // Mimicking a disabled state. We can't use the isDisabled prop because of a Chakra UI bug
                            // which prevents the tooltip from working correctly on disabled menu items.
                            if (
                              propertyOrganizationQuery.data.contactId !==
                              propertyUser.propertyUserId
                            ) {
                              setDeletingPropertyUserId(
                                propertyUser.propertyUserId
                              );
                            }
                          }}
                        >
                          <Flex>
                            <Text>Delete Staff Member</Text>
                            {propertyOrganizationQuery.data.contactId ===
                              propertyUser.propertyUserId && (
                              <>
                                <Box width={1} />
                                <Tooltip label="This staff member cannot be deleted because they are listed as the primary contact for this property.">
                                  <CircleInfo />
                                </Tooltip>
                              </>
                            )}
                          </Flex>
                          <HiOutlineTrash />
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </PageContent>

      <Modal
        isOpen={isDeletingPropertyUser}
        onClose={() => {
          setDeletingPropertyUserId(undefined);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Property Staff Member</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure? You can&apos;t undo this action afterwards.
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="brand.lightGray"
              color="gray.700"
              mr={3}
              onClick={() => {
                setDeletingPropertyUserId(undefined);
              }}
            >
              Close
            </Button>
            <Button
              colorScheme="brand.red"
              onClick={() => deletePropertyUserMutation.mutate()}
              isDisabled={deletePropertyUserMutation.isLoading}
            >
              Accept
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isUpdatingPropertyContact}
        onClose={() => {
          setUpdatingPropertyContact(undefined);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update Property Contact?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Set{" "}
            {`${updatingPropertyContact?.user.firstName} ${updatingPropertyContact?.user.lastName}`}{" "}
            as the new Property Contact?
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="brand.lightGray"
              color="gray.700"
              mr={3}
              onClick={() => {
                setUpdatingPropertyContact(undefined);
              }}
            >
              Close
            </Button>
            <Button
              colorScheme="brand.blue"
              onClick={() => updatePropertyContactMutation.mutate()}
              isDisabled={updatePropertyContactMutation.isLoading}
            >
              Accept
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isResettingPropertyUserPassword}
        onClose={() => {
          setResettingPropertyUserId(undefined);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Reset Property Staff Password</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you would like to reset this user&apos;s password? You
            can&apos;t undo this action afterwards.
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="brand.lightGray"
              color="gray.700"
              mr={3}
              onClick={() => {
                setResettingPropertyUserId(undefined);
              }}
            >
              Close
            </Button>
            <Button
              colorScheme="brand.red"
              onClick={() => resetPropertyUserPasswordMutation.mutate()}
              isDisabled={resetPropertyUserPasswordMutation.isLoading}
            >
              Accept
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

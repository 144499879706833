import * as React from "react";
import { useEffect } from "react";
import { Spinner, Center } from "@chakra-ui/react";
import { useAuth0 } from "@auth0/auth0-react";

export const Logout = () => {
  useLogoutOnMount();

  return (
    <>
      <Center paddingTop="25vh">
        <Spinner />
      </Center>
      <Center>Logging Out</Center>
    </>
  );
};

const useLogoutOnMount = () => {
  const { logout } = useAuth0();

  useEffect(() => {
    logout({
      logoutParams: { returnTo: window.location.origin },
    });
  }, []);
};

import React from "react";
import { Stack, Text } from "@chakra-ui/react";

import { IntercomHealthy } from "src/common/icons/IntercomHealthy";
import { IntercomUnhealthy } from "src/common/icons/IntercomUnhealthy";
import { IntercomComponentStatus, IntercomHealth } from "src/common/types";
import { formatDate } from "src/common/formatters";

type IntercomStatusSummaryProps = {
  intercomHealth: IntercomHealth;
};

export const IntercomStatusSummary = ({
  intercomHealth,
}: IntercomStatusSummaryProps) => {
  const isHealthy = intercomHealth.componentReports.every(
    (c) => c.status === IntercomComponentStatus.ONLINE
  );

  const Icon = isHealthy ? IntercomHealthy : IntercomUnhealthy;
  const statusText = isHealthy
    ? "Intercom health is great!"
    : "Intercom needs attention";
  const timestamp = intercomHealth.createdAt
    ? formatDate(intercomHealth.createdAt)
    : "Timestamp unknown";

  return (
    <Stack alignItems="center" direction="row" gap={3}>
      <Icon w="125px" h="170px" />
      <Stack>
        <Text fontSize="24px" fontWeight="bold">
          {statusText}
        </Text>
        <Text>{timestamp}</Text>
      </Stack>
    </Stack>
  );
};

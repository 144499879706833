import React from "react";
import {
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";

import { Loading } from "src/common/Loading";
import { useIntercomServiceLogsQuery } from "src/common/queries";
import { Card } from "src/layout/Card";
import { formatDate, truncate } from "src/common/formatters";

export const IntercomServiceLogs = () => {
  const serviceLogsQuery = useIntercomServiceLogsQuery();

  if (serviceLogsQuery.isLoading) {
    return <Loading />;
  }

  if (!serviceLogsQuery.data || serviceLogsQuery.data.result.length === 0) {
    return (
      <Card>
        <Text>No service logs are available for this intercom</Text>
      </Card>
    );
  }

  return (
    <Table
      backgroundColor="white"
      borderBottomRadius="12px"
      shadow="base"
      variant="simple"
    >
      <Thead backgroundColor={"gray.50"}>
        <Tr>
          <Th>Name</Th>
          <Th>Intercom</Th>
          <Th>Message</Th>
          <Th>Date</Th>
        </Tr>
      </Thead>
      <Tbody>
        {serviceLogsQuery.data.result.map((log) => (
          <Tr key={log.serviceSessionId}>
            <Td fontSize="14px" fontWeight="semibold">
              {log.technicianName}
            </Td>
            <Td fontSize="14px" fontWeight="semibold">
              {log.intercomName}
            </Td>
            <Td maxW={250} fontSize="14px" fontWeight="semibold">
              <Tooltip label={log.technicianSummary}>
                {truncate(log.technicianSummary)}
              </Tooltip>
            </Td>
            <Td fontSize="14px" fontWeight="semibold">
              {formatDate(log.startedAt, "short")}
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

import React from "react";
import { createIcon } from "@chakra-ui/react";

export const IntercomHealthy = createIcon({
  displayName: "IntercomHealthy",
  viewBox: "0 0 174 232",
  path: [
    <path
      key="healthy-intercom-1"
      d="M155.15 14.1001H3.51C2.12377 14.1001 1 15.2239 1 16.6101V227.79C1 229.176 2.12377 230.3 3.51 230.3H155.15C156.536 230.3 157.66 229.176 157.66 227.79V16.6101C157.66 15.2239 156.536 14.1001 155.15 14.1001Z"
      fill="#2F4A53"
    />,
    <path
      key="healthy-intercom-2"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.51 15.1001C2.67605 15.1001 2 15.7761 2 16.6101V227.79C2 228.624 2.67606 229.3 3.51 229.3H155.15C155.984 229.3 156.66 228.624 156.66 227.79V16.6101C156.66 15.7762 155.984 15.1001 155.15 15.1001H3.51ZM0 16.6101C0 14.6716 1.57148 13.1001 3.51 13.1001H155.15C157.089 13.1001 158.66 14.6716 158.66 16.6101V227.79C158.66 229.729 157.089 231.3 155.15 231.3H3.51C1.57147 231.3 0 229.729 0 227.79V16.6101Z"
      fill="black"
    />,
    <path
      key="healthy-intercom-3"
      d="M58.12 35.1249C58.12 31.1568 54.9032 27.9399 50.935 27.9399C46.9668 27.9399 43.75 31.1568 43.75 35.1249C43.75 39.0931 46.9668 42.3099 50.935 42.3099C54.9032 42.3099 58.12 39.0931 58.12 35.1249Z"
      fill="url(#paint0_linear_2257_20709)"
    />,
    <path
      key="healthy-intercom-4"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50.935 28.9399C47.5191 28.9399 44.75 31.7091 44.75 35.1249C44.75 38.5408 47.5191 41.3099 50.935 41.3099C54.3509 41.3099 57.12 38.5408 57.12 35.1249C57.12 31.7091 54.3509 28.9399 50.935 28.9399ZM42.75 35.1249C42.75 30.6045 46.4146 26.9399 50.935 26.9399C55.4555 26.9399 59.12 30.6045 59.12 35.1249C59.12 39.6454 55.4555 43.3099 50.935 43.3099C46.4146 43.3099 42.75 39.6454 42.75 35.1249Z"
      fill="black"
    />,
    <path
      key="healthy-intercom-5"
      d="M37 28H19V42H37V28Z"
      fill="url(#paint1_linear_2257_20709)"
    />,
    <path
      key="healthy-intercom-6"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 27H38V43H18V27ZM20 29V41H36V29H20Z"
      fill="black"
    />,
    <path
      key="healthy-intercom-7"
      d="M141 28H65V42H141V28Z"
      fill="url(#paint2_linear_2257_20709)"
    />,
    <path
      key="healthy-intercom-8"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64 27H142V43H64V27ZM66 29V41H140V29H66Z"
      fill="black"
    />,
    <path
      key="healthy-intercom-9"
      d="M139.91 51.5801H18.76V142.86H139.91V51.5801Z"
      fill="url(#paint3_linear_2257_20709)"
    />,
    <path
      key="healthy-intercom-10"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.76 50.5801H140.91V143.86H17.76V50.5801ZM19.76 52.5801V141.86H138.91V52.5801H19.76Z"
      fill="black"
    />,
    <path
      key="healthy-intercom-11"
      d="M138 168H19V211H138V168Z"
      fill="url(#paint4_linear_2257_20709)"
    />,
    <path
      key="healthy-intercom-12"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 167H139V212H18V167ZM20 169V210H137V169H20Z"
      fill="black"
    />,
    <path
      key="healthy-intercom-13"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.4072 98.4682C51.6956 97.9972 52.3112 97.8492 52.7822 98.1376L87.0122 119.098C87.4832 119.386 87.6313 120.002 87.3428 120.473C87.0544 120.944 86.4388 121.092 85.9678 120.803L51.7378 99.8432C51.2668 99.5548 51.1188 98.9392 51.4072 98.4682Z"
      fill="black"
    />,
    <path
      key="healthy-intercom-14"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48.5715 79.0607C48.8639 78.5921 49.4807 78.4492 49.9493 78.7416L112.139 117.542C112.608 117.834 112.751 118.451 112.458 118.919C112.166 119.388 111.549 119.531 111.081 119.238L48.8906 80.4384C48.422 80.1461 48.2792 79.5292 48.5715 79.0607Z"
      fill="black"
    />,
    <path
      key="healthy-intercom-15"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M74.3184 76.1796C74.6168 75.7149 75.2354 75.58 75.7001 75.8783L110.39 98.1483C110.855 98.4467 110.99 99.0653 110.691 99.5301C110.393 99.9948 109.774 100.13 109.31 99.8314L74.6197 77.5614C74.1549 77.263 74.02 76.6444 74.3184 76.1796Z"
      fill="black"
    />,
    <path
      key="healthy-intercom-16"
      d="M150.84 44.88C162.957 44.88 172.78 35.0571 172.78 22.94C172.78 10.8229 162.957 1 150.84 1C138.723 1 128.9 10.8229 128.9 22.94C128.9 35.0571 138.723 44.88 150.84 44.88Z"
      fill="url(#paint5_linear_2257_20709)"
    />,
    <path
      key="healthy-intercom-17"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M150.84 2C139.275 2 129.9 11.3752 129.9 22.94C129.9 34.5048 139.275 43.88 150.84 43.88C162.405 43.88 171.78 34.5048 171.78 22.94C171.78 11.3752 162.405 2 150.84 2ZM127.9 22.94C127.9 10.2706 138.17 0 150.84 0C163.509 0 173.78 10.2706 173.78 22.94C173.78 35.6094 163.509 45.88 150.84 45.88C138.17 45.88 127.9 35.6094 127.9 22.94Z"
      fill="black"
    />,
    <path
      key="healthy-intercom-18"
      d="M146.14 27.6101L140.03 21.5001L137.95 23.5701L146.14 31.7601L163.73 14.1701L161.66 12.1001L146.14 27.6001V27.6101Z"
      fill="#231F20"
    />,
    <path
      key="healthy-intercom-19"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M161.66 11.7466L164.083 14.17L146.14 32.1136L137.596 23.5696L140.03 21.1469L146.135 27.2516L161.66 11.7466ZM146.39 27.7037V28.2136L140.03 21.8531L138.304 23.5704L146.14 31.4065L163.376 14.17L161.66 12.4535L146.39 27.7037Z"
      fill="black"
    />,
    <defs key="healthy-intercom-20">
      <linearGradient
        id="paint0_linear_2257_20709"
        x1="47.34"
        y1="28.8999"
        x2="54.52"
        y2="41.3399"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5FC6CA" />
        <stop offset="1" stopColor="#319795" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2257_20709"
        x1="22.9233"
        y1="25.5644"
        x2="34.1738"
        y2="43.7412"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5FC6CA" />
        <stop offset="1" stopColor="#319795" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2257_20709"
        x1="90.4351"
        y1="13.4642"
        x2="115.165"
        y2="56.7548"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5FC6CA" />
        <stop offset="1" stopColor="#319795" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_2257_20709"
        x1="44.42"
        y1="36.7601"
        x2="114.24"
        y2="157.68"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5FC6CA" />
        <stop offset="1" stopColor="#319795" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_2257_20709"
        x1="47.17"
        y1="158.795"
        x2="71.3526"
        y2="232.777"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5FC6CA" />
        <stop offset="1" stopColor="#319795" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_2257_20709"
        x1="128.9"
        y1="22.94"
        x2="172.77"
        y2="22.94"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5FC6CA" />
        <stop offset="1" stopColor="#319795" />
      </linearGradient>
    </defs>,
  ],
});

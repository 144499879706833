import {
  Checkbox,
  CheckboxGroup,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Select,
  Stack,
  Switch,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import React, { useCallback, useMemo } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "react-query";
import { HTTPError } from "ky";
import { intersection } from "lodash";

import { useKy } from "src/common/ky";
import { noop } from "src/common/util";
import { AccessControlFormProps } from "src/routes/Property/AccessControl/types";
import { EntryPointDMPProperties } from "src/common/types";
import { Loading } from "src/common/Loading";
import { useGetEntryGroupsQuery } from "src/routes/Property/queries";
import { AddEntryPointValidationSchema } from "src/routes/Property/schema";

export interface AddEntryPointValues {
  displayName: string;
  propertyOrganizationId: string;
  strikeTime: number;
  delayTime: number;
  entryGroupIds: string[];
  dmpProperties: EntryPointDMPProperties | undefined;
  fireExitRelease: boolean;
  publicEntryPoint: boolean;
  notes?: string;
  moduleConnectionType: "direct" | "network";
  readerId: string;
}

export type CreateEntryPointFormProps =
  AccessControlFormProps<AddEntryPointValues> & {
    propertyOrganizationId: string;
  };

export const CreateEntryPointForm = (props: CreateEntryPointFormProps) => {
  const {
    propertyOrganizationId,
    hideFooterDivider,
    Footer,
    mutationOptionsBuilder,
  } = props;

  const ky = useKy();
  const entryGroupsQuery = useGetEntryGroupsQuery(
    propertyOrganizationId as string
  );

  const hookForm = useForm<AddEntryPointValues>({
    defaultValues: {
      displayName: "",
      propertyOrganizationId,
      strikeTime: 5,
      delayTime: 0,
      entryGroupIds: [],
      dmpProperties: {
        deviceNumber: 0,
      },
      fireExitRelease: false,
      publicEntryPoint: false,
      notes: "",
      moduleConnectionType: "direct",
      readerId: "",
    },
    resolver: yupResolver(AddEntryPointValidationSchema),
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = hookForm;

  const mutationOptions = useMemo(
    () => mutationOptionsBuilder && mutationOptionsBuilder(hookForm),
    [mutationOptionsBuilder, hookForm]
  );

  const onSubmit = useCallback(
    handleSubmit(
      async (values: AddEntryPointValues) =>
        await addEntryPoint.mutateAsync(values).catch(noop)
    ),
    [handleSubmit]
  );

  const addEntryPoint = useMutation<void, HTTPError, AddEntryPointValues>(
    async (values: AddEntryPointValues) => {
      await ky.post(`access-control/entry-point`, {
        json: values,
      });
    },
    mutationOptions
  );

  const validEntryGroupIds = useMemo(
    () => (entryGroupsQuery.data ?? []).map((eg) => eg.entryGroupId),
    [entryGroupsQuery.data]
  );

  if (entryGroupsQuery.isLoading || !entryGroupsQuery.isSuccess) {
    return <Loading />;
  }

  return (
    <form
      onSubmit={handleSubmit(async (values: AddEntryPointValues) =>
        addEntryPoint.mutateAsync(values).catch(noop)
      )}
    >
      <Grid gap={4} width={{ base: "100%", xl: "50%" }}>
        <GridItem>
          <Controller
            name={"displayName"}
            control={control}
            render={({ field, fieldState }) => (
              <FormControl isInvalid={!!fieldState.error}>
                <FormLabel htmlFor="displayName">Display Name</FormLabel>
                <Input {...field} id="displayName" placeholder="Display Name" />
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </GridItem>
        <GridItem>
          <Controller
            name={"readerId"}
            control={control}
            render={({ field, fieldState }) => (
              <FormControl isInvalid={!!fieldState.error} isRequired>
                <FormLabel htmlFor="readerId">Reader ID</FormLabel>
                <Input {...field} id="readerId" placeholder="Reader ID" />
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </GridItem>
        <GridItem>
          <Controller
            name={"strikeTime"}
            control={control}
            render={({ field, fieldState }) => (
              <FormControl isInvalid={!!fieldState.error}>
                <FormLabel htmlFor="strikeTime">Strike Time</FormLabel>
                <Input
                  {...field}
                  id="strikeTime"
                  step={1}
                  min={0}
                  type={"number"}
                  placeholder="Strike Time"
                />
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </GridItem>
        <GridItem>
          <Controller
            name={"delayTime"}
            control={control}
            render={({ field, fieldState }) => (
              <FormControl isInvalid={!!fieldState.error}>
                <FormLabel htmlFor="delayTime">Delay Time</FormLabel>
                <Input
                  {...field}
                  step={1}
                  min={0}
                  type={"number"}
                  id="delayTime"
                  placeholder="Delay Time"
                />
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </GridItem>
        <GridItem>
          <Controller
            name={"dmpProperties.deviceNumber"}
            control={control}
            render={({ field, fieldState }) => (
              <FormControl isInvalid={!!fieldState.error}>
                <FormLabel htmlFor="deviceNumber">Device Number</FormLabel>
                <Input
                  {...field}
                  type={"number"}
                  step={1}
                  min={0}
                  id="deviceNumber"
                  placeholder="Device Number"
                />
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </GridItem>
        <GridItem>
          <Controller
            name={"moduleConnectionType"}
            control={control}
            render={({ field, fieldState }) => (
              <FormControl isInvalid={!!fieldState.error}>
                <FormLabel htmlFor="moduleConnectionType">
                  Module Connection Type
                </FormLabel>
                <Select {...field} id="moduleConnectionType">
                  <option value="direct">Direct</option>
                  <option value="network">Network</option>
                </Select>
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </GridItem>
        <GridItem>
          <Controller
            name={"fireExitRelease"}
            control={control}
            render={({ field: { value, ...field }, fieldState }) => (
              <FormControl isInvalid={!!fieldState.error}>
                <Flex alignItems={"center"}>
                  <FormLabel htmlFor="fireExitRelease" mb={0}>
                    Fire Exit Release
                  </FormLabel>
                  <Switch isChecked={value} {...field} id="fireExitRelease" />
                </Flex>
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </GridItem>
        <GridItem>
          <Controller
            name={"publicEntryPoint"}
            control={control}
            render={({ field: { value, ...field }, fieldState }) => (
              <FormControl isInvalid={!!fieldState.error}>
                <Flex alignItems={"center"}>
                  <FormLabel htmlFor="publicEntryPoint" mb={0}>
                    Public Entry Point
                  </FormLabel>
                  <Switch isChecked={value} {...field} id="publicEntryPoint" />
                </Flex>
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </GridItem>
        <GridItem>
          <Controller
            name={"notes"}
            control={control}
            render={({ field, fieldState }) => (
              <FormControl isInvalid={!!fieldState.error}>
                <FormLabel htmlFor="notes">Notes</FormLabel>
                <Textarea {...field} id="notes" placeholder="Notes" />
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </GridItem>
        <Divider />
        <GridItem>
          <Controller
            name={"entryGroupIds"}
            control={control}
            render={({ field, fieldState }) => (
              <FormControl isInvalid={!!fieldState.error}>
                <FormLabel htmlFor="entryGroupIds">Entry Groups</FormLabel>
                <Stack spacing={5}>
                  {entryGroupsQuery.data?.length ? (
                    <CheckboxGroup defaultValue={field.value}>
                      {entryGroupsQuery.data.map((entryGroup) => (
                        <Checkbox
                          key={entryGroup.entryGroupId}
                          value={entryGroup.entryGroupId}
                          onChange={(event) => {
                            const isChecked = event.target.checked;
                            const value = event.target.value;

                            field.onChange(
                              isChecked
                                ? intersection(validEntryGroupIds, [
                                    ...field.value,
                                    value,
                                  ])
                                : field.value.filter((id) => id !== value)
                            );
                          }}
                        >
                          {entryGroup.displayName}
                        </Checkbox>
                      ))}
                    </CheckboxGroup>
                  ) : (
                    <Text>No Entry Groups</Text>
                  )}
                  <FormErrorMessage>
                    {fieldState.error?.message}
                  </FormErrorMessage>
                </Stack>
              </FormControl>
            )}
          />
        </GridItem>
      </Grid>
      {Footer ? (
        <Grid gap={4} mt={4}>
          {!hideFooterDivider ? (
            <GridItem>
              <Divider />
            </GridItem>
          ) : null}
          <GridItem>{Footer({ isSubmitting, onSubmit })}</GridItem>
        </Grid>
      ) : null}
    </form>
  );
};

import * as React from "react";
import { Flex, Spinner } from "@chakra-ui/react";

export const Loading = () => {
  return (
    <>
      <Flex align="center" justify="center" m={8}>
        <Spinner />
      </Flex>
    </>
  );
};

import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useToast } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import { GET_DEALER_STAFF_LIST_QUERY_KEY } from "src/common/queries";
import { useKy } from "src/common/ky";

export const useDeleteDealerUser = () => {
  const queryClient = useQueryClient();
  const { dealerOrganizationId } = useParams();
  const ky = useKy();

  const [deletingDealerUserId, setDeletingDealerUserId] = useState<
    string | undefined
  >();
  const deleteDealerUserMutation = useMutation(
    () =>
      ky.delete(`dealer-users/${deletingDealerUserId}`, {
        json: { dealerOrganizationId },
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_DEALER_STAFF_LIST_QUERY_KEY);
        setDeletingDealerUserId(undefined);
      },
    }
  );

  return {
    isDeletingDealerUser: !!deletingDealerUserId,
    deleteDealerUserMutation,
    setDeletingDealerUserId,
  };
};

export const useResetDealerUserPassword = () => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const ky = useKy();

  const [resettingDealerUserId, setResettingDealerUserId] = useState<
    string | undefined
  >();

  const resetDealerUserPasswordMutation = useMutation(
    () =>
      ky.post(`dealer-users/${resettingDealerUserId}/reset-password`, {
        json: {},
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_DEALER_STAFF_LIST_QUERY_KEY);
        setResettingDealerUserId(undefined);
        toast({
          description: "Password Reset Successful!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      },
    }
  );

  return {
    isResettingDealerUser: !!resettingDealerUserId,
    resetDealerUserPasswordMutation,
    setResettingDealerUserId,
  };
};

import React from "react";
import {
  Button,
  ButtonGroup,
  Divider,
  Grid,
  GridItem,
  Heading,
  Link,
  Text,
} from "@chakra-ui/react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { useQuery } from "react-query";

import { PageContent } from "src/layout/PageContent";
import { TabLink } from "src/layout/TabLink";
import {
  PageHeader,
  PageHeaderTabs,
  PageHeaderTitle,
} from "src/layout/PageHeader";
import { Loading } from "src/common/Loading";
import { DealerFinancials } from "src/common/types";
import { HTTPError, useKy } from "src/common/ky";
import { formatPhoneNumber } from "src/common/phone-numbers";

export const Financials = () => {
  const { dealerOrganizationId } = useParams();
  const ky = useKy();

  const query = useQuery<DealerFinancials, HTTPError>(
    ["DEALER_FINANCIALS", dealerOrganizationId],
    () =>
      ky
        .get(`dealer-financials/${dealerOrganizationId}`)
        .json<DealerFinancials>()
  );

  if (query.isLoading || !query.isSuccess) {
    return <Loading />;
  }

  return (
    <>
      <PageHeader
        buttons={
          <ButtonGroup>
            <Link
              as={RouterLink}
              to={`/dealer-organizations/${dealerOrganizationId}/profile/financials/edit`}
            >
              <Button colorScheme="brand.blue">
                Edit Dealer Profile Financials
              </Button>
            </Link>
          </ButtonGroup>
        }
      >
        <PageHeaderTitle>Dealer Profile</PageHeaderTitle>
        <PageHeaderTabs>
          <TabLink
            key="profile"
            to={`/dealer-organizations/${dealerOrganizationId}/profile/details`}
            label="Details"
          />
          <TabLink
            key="financials"
            to={`/dealer-organizations/${dealerOrganizationId}/profile/financials`}
            label="Financials"
          />
        </PageHeaderTabs>
      </PageHeader>
      <PageContent>
        <Grid templateColumns={"repeat(2, 1fr)"} gap={4}>
          <GridItem colSpan={2}>
            <Heading size="md">Billing Information</Heading>
          </GridItem>

          <GridItem colSpan={2}>
            <Divider />
          </GridItem>

          <GridItem>
            <Text>Name of Business</Text>
          </GridItem>
          <GridItem>
            <Text>{query.data.businessName || "N/A"}</Text>
          </GridItem>

          <GridItem>
            <Text>Business License Number</Text>
          </GridItem>
          <GridItem>
            <Text>{query.data.licenseNumber || "N/A"}</Text>
          </GridItem>

          <GridItem>
            <Text>Tax I.D. Number</Text>
          </GridItem>
          <GridItem>
            <Text>{query.data.taxIdNumber || "N/A"}</Text>
          </GridItem>

          <GridItem>
            <Text>Business is Tax Exempt</Text>
          </GridItem>
          <GridItem>
            <Text>{query.data.isTaxExempt ? "Yes" : "No"}</Text>
          </GridItem>
        </Grid>
      </PageContent>

      <PageContent>
        <Grid templateColumns={"repeat(2, 1fr)"} gap={4}>
          <GridItem colSpan={2}>
            <Heading size="md">Business Contact Information</Heading>
          </GridItem>

          <GridItem colSpan={2}>
            <Divider />
          </GridItem>

          <GridItem>
            <Text>Name</Text>
          </GridItem>
          <GridItem>
            <Text>{query.data.billingContactName || "N/A"}</Text>
          </GridItem>

          <GridItem>
            <Text>Phone</Text>
          </GridItem>
          <GridItem>
            <Text>
              {formatPhoneNumber(query.data.billingContactPhone, {
                filler: "N/A",
              })}
            </Text>
          </GridItem>

          <GridItem>
            <Text>Email</Text>
          </GridItem>
          <GridItem>
            <Text>{query.data.billingContactEmail || "N/A"}</Text>
          </GridItem>

          <GridItem>
            <Text>Billing Address</Text>
          </GridItem>
          <GridItem>
            <Text>{query.data.billingContactAddress || "N/A"}</Text>
          </GridItem>

          <GridItem>
            <Text>City</Text>
          </GridItem>
          <GridItem>
            <Text>{query.data.billingContactCity || "N/A"}</Text>
          </GridItem>

          <GridItem>
            <Text>State</Text>
          </GridItem>
          <GridItem>
            <Text>{query.data.billingContactState || "N/A"}</Text>
          </GridItem>

          <GridItem>
            <Text>ZIP Code</Text>
          </GridItem>
          <GridItem>
            <Text>{query.data.billingContactZipCode || "N/A"}</Text>
          </GridItem>

          <GridItem>
            <Text>Invoicing Method</Text>
          </GridItem>
          <GridItem>
            <Text>{query.data.invoicingMethod || "N/A"}</Text>
          </GridItem>
        </Grid>
      </PageContent>
    </>
  );
};

import React, { useMemo, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Portal,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { sortBy } from "lodash";
import { useQueryClient } from "react-query";
import { HiOutlinePencil, HiOutlineTrash } from "react-icons/hi";

import {
  getEntryPointQueryKey,
  getEntryPointsQueryKey,
  useGetEntryGroupsQuery,
  useGetEntryPointsQuery,
} from "src/routes/Property/queries";
import { Loading } from "src/common/Loading";
import { noop } from "src/common/util";
import { handleHookFormHTTPError, handleHTTPError } from "src/common/form";
import { WizardStepFormView } from "src/routes/Property/AccessControl/Wizard/components/WizardStepFormView";
import { useDeleteEntryPoint } from "src/routes/Property/hooks";
import { CreateEntryPointForm } from "src/routes/Property/AccessControl/EntryPoints/components/CreateEntryPointForm";
import { EditEntryPointForm } from "src/routes/Property/AccessControl/EntryPoints/components/EditEntryPointForm";
import { EntryGroup } from "src/common/types";
import { HTTPError } from "src/common/ky";

export type CreateEntryPointsStepProps = {
  propertyOrganizationId: string;
  navbarPortalRef: React.RefObject<HTMLElement | null>;
  Footer: (props: { addEntryPoint: () => void }) => React.ReactElement;
};

export const CreateEntryPointsStep = (props: CreateEntryPointsStepProps) => {
  const { propertyOrganizationId, navbarPortalRef, Footer } = props;
  const entryPointsQuery = useGetEntryPointsQuery(propertyOrganizationId);
  const toast = useToast();
  const queryClient = useQueryClient();

  const [editingEntryPointId, setEditingEntryPointId] = useState<string>();
  const [creatingEntryPoint, setCreatingEntryPoint] = useState(false);

  const entryPoints = useMemo(
    () =>
      sortBy(entryPointsQuery.data ?? [], (v) => v.displayName.toLowerCase()),
    [entryPointsQuery.data]
  );

  const entryGroupsQuery = useGetEntryGroupsQuery(
    propertyOrganizationId as string
  );

  const entryGroups = useMemo(
    () => entryGroupsQuery.data ?? [],
    [entryGroupsQuery.data]
  );

  const {
    isDeletingEntryPoint,
    deleteEntryPointMutation,
    setDeletingEntryPointId,
  } = useDeleteEntryPoint({
    onSuccess: () => {
      queryClient.invalidateQueries(
        getEntryPointsQueryKey(propertyOrganizationId as string)
      );
      setDeletingEntryPointId(undefined);
      toast({
        description: "Entry Point deleted",
        status: "success",
      });
    },
    onError: async (error: HTTPError) => {
      await handleHTTPError(toast)(error);
      setDeletingEntryPointId(undefined);
    },
  });

  if (
    entryPointsQuery.isLoading ||
    !entryPointsQuery.isSuccess ||
    entryGroupsQuery.isLoading ||
    !entryGroupsQuery.isSuccess
  ) {
    return <Loading />;
  }

  return (
    <>
      <Stack>
        <Text fontSize="xl" fontWeight={600}>
          Entry Point Creation
        </Text>
        <Text fontSize="md" color={"gray.500"}>
          Create Entry Points to determine all the access locations on a
          property.
        </Text>
      </Stack>

      {creatingEntryPoint ? (
        <WizardStepFormView>
          <CreateEntryPointForm
            propertyOrganizationId={propertyOrganizationId as string}
            mutationOptionsBuilder={({ setError, getValues }) => ({
              onSuccess: () => {
                toast({
                  description: "Entry Point added",
                  status: "success",
                });
                queryClient.invalidateQueries(
                  getEntryPointsQueryKey(propertyOrganizationId)
                );
                setCreatingEntryPoint(false);
              },
              onError: handleHookFormHTTPError(setError, getValues, toast),
            })}
            hideFooterDivider={true}
            Footer={({ isSubmitting, onSubmit }) => (
              <Portal containerRef={navbarPortalRef}>
                <ButtonGroup display="flex" justifyContent="flex-end">
                  <Button onClick={() => setCreatingEntryPoint(false)}>
                    Cancel
                  </Button>
                  <Button
                    onClick={() => onSubmit()}
                    colorScheme="brand.blue"
                    isLoading={isSubmitting}
                  >
                    Save
                  </Button>
                </ButtonGroup>
              </Portal>
            )}
          />
        </WizardStepFormView>
      ) : null}

      {editingEntryPointId ? (
        <WizardStepFormView>
          <EditEntryPointForm
            entryPointId={editingEntryPointId}
            propertyOrganizationId={propertyOrganizationId}
            mutationOptionsBuilder={({ setError, getValues }) => ({
              onSuccess: () => {
                toast({
                  description: "Entry Point updated",
                  status: "success",
                });
                queryClient.invalidateQueries(
                  getEntryPointQueryKey(editingEntryPointId as string)
                );
                queryClient.invalidateQueries(
                  getEntryPointsQueryKey(propertyOrganizationId as string)
                );
                setEditingEntryPointId(undefined);
              },
              onError: handleHookFormHTTPError(setError, getValues, toast),
            })}
            hideFooterDivider={true}
            Footer={({ isSubmitting, onSubmit }) => (
              <Portal containerRef={navbarPortalRef}>
                <ButtonGroup display="flex" justifyContent="flex-end">
                  <Button onClick={() => setEditingEntryPointId(undefined)}>
                    Cancel
                  </Button>
                  <Button
                    onClick={() => onSubmit()}
                    colorScheme="brand.blue"
                    isLoading={isSubmitting}
                  >
                    Save Changes
                  </Button>
                </ButtonGroup>
              </Portal>
            )}
          />
        </WizardStepFormView>
      ) : null}

      {!creatingEntryPoint && !editingEntryPointId ? (
        <>
          <Box overflowX={"auto"}>
            <Table>
              <Thead>
                <Tr>
                  <Th>Display Name</Th>
                  <Th>Device Number</Th>
                  <Th>Entry Groups</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {entryPoints.length ? (
                  entryPoints.map((entryPoint) => {
                    return (
                      <Tr key={entryPoint.entryPointId}>
                        <Td>{entryPoint.displayName}</Td>
                        <Td>{entryPoint.dmpProperties?.deviceNumber}</Td>
                        <Td>
                          {(
                            entryPoint.entryGroupIds
                              .map((egId) =>
                                entryGroups.find(
                                  (group) => group.entryGroupId === egId
                                )
                              )
                              .filter((v) => v) as EntryGroup[]
                          ).map((entryGroup) => (
                            <Text key={entryGroup.entryGroupId}>
                              {entryGroup.displayName}
                            </Text>
                          ))}
                        </Td>
                        <Td textAlign={"right"}>
                          <ButtonGroup>
                            <IconButton
                              onClick={() =>
                                setEditingEntryPointId(entryPoint.entryPointId)
                              }
                              aria-label="Actions"
                              icon={<HiOutlinePencil />}
                              variant="clear"
                            />
                            <IconButton
                              onClick={() =>
                                setDeletingEntryPointId(entryPoint.entryPointId)
                              }
                              aria-label="Actions"
                              icon={<HiOutlineTrash />}
                              variant="clear"
                            />
                          </ButtonGroup>
                        </Td>
                      </Tr>
                    );
                  })
                ) : (
                  <Tr>
                    <Td colSpan={4} textAlign={"center"}>
                      <Text fontStyle={"italic"}>
                        There are no entry points
                      </Text>
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </Box>
          <Portal containerRef={navbarPortalRef}>
            {Footer({
              addEntryPoint: () => setCreatingEntryPoint(true),
            })}
          </Portal>
        </>
      ) : null}

      <Modal
        isOpen={isDeletingEntryPoint}
        onClose={() => {
          setDeletingEntryPointId(undefined);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Remove Entry Point</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure? You can&apos;t undo this action.</ModalBody>

          <ModalFooter>
            <Button
              colorScheme="brand.lightGray"
              color="gray.700"
              mr={3}
              onClick={() => {
                setDeletingEntryPointId(undefined);
              }}
            >
              Cancel
            </Button>
            <Button
              colorScheme="brand.red"
              onClick={() => deleteEntryPointMutation.mutateAsync().catch(noop)}
              isDisabled={deleteEntryPointMutation.isLoading}
            >
              Remove Entry Point
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

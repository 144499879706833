import {
  useGetPropertyAccessPanelQuery,
  usePropertyOrganizationQuery,
} from "src/routes/Property/queries";

export const useHasAccessControl = (args: {
  propertyOrganizationId: string;
  dealerOrganizationId: string;
}) => {
  const accessPanelQuery = useGetPropertyAccessPanelQuery(
    args.propertyOrganizationId,
    {
      kyOptions: {
        noLogoutOnForbidden: true,
      },
    }
  );

  const propertyOrganizationQuery = usePropertyOrganizationQuery(
    {
      propertyOrganizationId: args.propertyOrganizationId,
      dealerOrganizationId: args.dealerOrganizationId,
    },
    {
      kyOptions: {
        noLogoutOnForbidden: true,
      },
    }
  );

  return (
    !!accessPanelQuery.data &&
    !!propertyOrganizationQuery.data?.accessControlOnboardedAt
  );
};

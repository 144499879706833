import { useQueryClient } from "react-query";
import { useCallback } from "react";

import {
  getPropertyOrganizationQueryKey,
  usePropertyOrganizationQuery,
} from "src/routes/Property/queries";

export const useShouldUseAccessControlWizard = (
  propertyOrganizationId: string,
  dealerOrganizationId: string
) => {
  const queryClient = useQueryClient();

  const propertyOrganizationQuery = usePropertyOrganizationQuery(
    {
      propertyOrganizationId,
      dealerOrganizationId,
    },
    {
      kyOptions: {
        noLogoutOnForbidden: true,
      },
    }
  );

  const invalidate = useCallback(
    () =>
      queryClient.invalidateQueries(
        getPropertyOrganizationQueryKey(
          propertyOrganizationId,
          dealerOrganizationId
        )
      ),
    [propertyOrganizationId, dealerOrganizationId]
  );

  return {
    invalidate,
    isLoading: propertyOrganizationQuery.isLoading,
    shouldUseAccessControlWizard:
      propertyOrganizationQuery?.data?.accessControlOnboardedAt === null,
  };
};

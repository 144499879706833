import React from "react";
import { createIcon } from "@chakra-ui/react";

export const CopyToClipboard = createIcon({
  displayName: "ErrorOutlineIcon",
  viewBox: "0 0 17 19",
  path: (
    <path
      fill="currentColor"
      d="M1.83366 5.33325V16.9999H13.5003V18.6666H1.83366C0.916992 18.6666 0.166992 17.9166 0.166992 16.9999V5.33325H1.83366ZM15.167 1.99992C16.0837 1.99992 16.8337 2.74992 16.8337 3.66659V13.6666C16.8337 14.5833 16.0837 15.3333 15.167 15.3333H5.16699C4.25032 15.3333 3.50032 14.5833 3.50032 13.6666V3.66659C3.50032 2.74992 4.25032 1.99992 5.16699 1.99992H7.81699C8.16699 1.03325 9.08366 0.333252 10.167 0.333252C11.2503 0.333252 12.167 1.03325 12.517 1.99992H15.167ZM10.167 1.99992C9.70866 1.99992 9.33366 2.37492 9.33366 2.83325C9.33366 3.29159 9.70866 3.66659 10.167 3.66659C10.6253 3.66659 11.0003 3.29159 11.0003 2.83325C11.0003 2.37492 10.6253 1.99992 10.167 1.99992ZM6.83366 5.33325V3.66659H5.16699V13.6666H15.167V3.66659H13.5003V5.33325H6.83366ZM11.0003 11.9999H6.83366V10.3333H11.0003V11.9999ZM13.5003 8.66659H6.83366V6.99992H13.5003V8.66659Z"
    />
  ),
});

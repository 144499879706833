import React from "react";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  ButtonGroup,
  Container,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Link,
  useToast,
} from "@chakra-ui/react";
import {
  Link as RouterLink,
  useParams,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import { useMutation } from "react-query";
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { CellKitAssignmentCreateSchema } from "src/routes/Property/schema";
import { PageContent } from "src/layout/PageContent";
import {
  PageHeader,
  PageHeaderBackLink,
  PageHeaderTitle,
} from "src/layout/PageHeader";
import { IntercomActivation } from "src/common/types";
import { HTTPError, useKy } from "src/common/ky";
import { noop } from "src/common/util";

type AddCellKitAssignmentValues = {
  serialNumber: string;
};

const AddCellKitAssignmentSchema = Yup.object().shape(
  CellKitAssignmentCreateSchema
);

interface ValidationError {
  property: keyof AddCellKitAssignmentValues;
  messages: string[];
}

export const CellKitAdd = () => {
  const { dealerOrganizationId, propertyOrganizationId, intercomActivationId } =
    useParams();
  const toast = useToast();
  const ky = useKy();
  const navigate = useNavigate();
  const { currentActivation } =
    useOutletContext<{ currentActivation: IntercomActivation }>();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isSubmitted, isValid },
    setError,
  } = useForm<AddCellKitAssignmentValues>({
    defaultValues: AddCellKitAssignmentSchema.getDefault(),
    resolver: yupResolver(AddCellKitAssignmentSchema),
  });

  const assignCellKit = useMutation<
    void,
    HTTPError,
    AddCellKitAssignmentValues
  >(
    async (values: AddCellKitAssignmentValues) => {
      await ky.post(`cell-kit-assignments`, {
        json: {
          ...values,
          intercomActivationId,
        },
      });
    },
    {
      onError: async (error: HTTPError) => {
        if (error.response.status < 500) {
          const { validationErrors }: { validationErrors: ValidationError[] } =
            await error.response.json();

          if (validationErrors) {
            validationErrors.map((error: ValidationError) =>
              error.messages.map((message) =>
                setError("serialNumber", { message })
              )
            );
          }
        }
      },
      onSuccess: () => {
        navigate(
          `/dealer-organizations/${dealerOrganizationId}/properties/${propertyOrganizationId}/intercoms`
        );
        toast({
          description: "Cell Kit Successfully Assigned",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      },
    }
  );

  return (
    <>
      <PageHeader>
        <PageHeaderBackLink>
          <Link
            as={RouterLink}
            to={`/dealer-organizations/${dealerOrganizationId}/properties/${propertyOrganizationId}/intercoms`}
          >
            &#8249; Back to list
          </Link>
        </PageHeaderBackLink>
        <PageHeaderTitle>
          {currentActivation.name} - Add Cell Kit
        </PageHeaderTitle>
      </PageHeader>

      {isSubmitted && !isValid && (
        <Container maxW="7xl">
          <Alert status="error">
            <AlertIcon />
            <AlertDescription>
              Please fix any errors in the form and try again.
            </AlertDescription>
          </Alert>
        </Container>
      )}

      <PageContent>
        <form
          onSubmit={handleSubmit((data) =>
            assignCellKit.mutateAsync(data).catch(noop)
          )}
        >
          <Grid templateColumns={"repeat(4 1fr)"} gap={6}>
            <GridItem>
              <Controller
                name="serialNumber"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl
                    isRequired
                    isInvalid={fieldState.invalid && fieldState.isTouched}
                  >
                    <FormLabel htmlFor="serialNumber">Serial Number</FormLabel>
                    <Input
                      {...field}
                      id="serialNumber"
                      onChange={(event) =>
                        field.onChange(event.target.value.toUpperCase())
                      }
                      placeholder="Enter Serial Number"
                    />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>

            <GridItem colSpan={4}>
              <Divider />
            </GridItem>

            <GridItem colSpan={4}>
              <ButtonGroup display="flex" justifyContent="flex-end">
                <Link
                  as={RouterLink}
                  to={`/dealer-organizations/${dealerOrganizationId}/properties/${propertyOrganizationId}/intercoms`}
                >
                  <Button>Cancel</Button>
                </Link>
                <Button
                  type="submit"
                  colorScheme="brand.blue"
                  isLoading={isSubmitting}
                >
                  Save
                </Button>
              </ButtonGroup>
            </GridItem>
          </Grid>
        </form>
      </PageContent>
    </>
  );
};

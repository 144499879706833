import React, { useCallback, useMemo } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  Input,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Spacer,
  useBoolean,
  VStack,
} from "@chakra-ui/react";
import { HiOutlineFilter } from "react-icons/hi";
import { Controller, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";

type FilterFormValues = {
  addedFrom: string;
  addedTo: string;
};

interface Props {
  onSubmit(searchParams: URLSearchParams): void;
}

export const PropertiesFilterPopover = ({ onSubmit }: Props) => {
  const [isEditing, setIsEditing] = useBoolean();
  const location = useLocation();
  const sp = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const { control, handleSubmit } = useForm<FilterFormValues>({
    defaultValues: {
      addedFrom: sp.get("addedFrom") || "",
      addedTo: sp.get("addedTo") || "",
    },
  });

  const parseParameters = useCallback(
    (data: FilterFormValues) => {
      const newSp = new URLSearchParams(location.search);

      for (const [key, value] of Object.entries(data)) {
        if (value) {
          newSp.set(key, value as string);
        } else {
          newSp.delete(key);
        }
      }

      onSubmit(newSp);
      setIsEditing.off();
    },
    [location.search]
  );

  return (
    <Popover
      isOpen={isEditing}
      onOpen={setIsEditing.on}
      onClose={setIsEditing.off}
      closeOnBlur={false}
      isLazy
      lazyBehavior="keepMounted"
    >
      <PopoverTrigger>
        <Button leftIcon={<HiOutlineFilter />} style={{ marginLeft: 16 }}>
          Filters
        </Button>
      </PopoverTrigger>

      <PopoverContent display="flex">
        <PopoverHeader>Filters</PopoverHeader>
        <PopoverCloseButton />
        <PopoverBody>
          <form onSubmit={handleSubmit(parseParameters)}>
            <VStack align="start" gap={6}>
              <Box width="100%">
                <Controller
                  name="addedFrom"
                  control={control}
                  render={({ field }) => (
                    <FormControl>
                      <FormLabel htmlFor="addedFrom">
                        Date Added (GMT)
                      </FormLabel>
                      <FormLabel fontSize="12" htmlFor="addedFrom">
                        From
                      </FormLabel>
                      <Input {...field} id="addedFrom" type="date" />
                    </FormControl>
                  )}
                />
                <Spacer mt={2} />
                <Controller
                  name="addedTo"
                  control={control}
                  render={({ field }) => (
                    <FormControl>
                      <FormLabel fontSize="12" htmlFor="addedTo">
                        To
                      </FormLabel>
                      <Input {...field} id="addedTo" type="date" />
                    </FormControl>
                  )}
                />
              </Box>

              <Box width="100%">
                <ButtonGroup display="flex" justifyContent="flex-end">
                  <Button type="button" onClick={setIsEditing.off}>
                    Cancel
                  </Button>
                  <Button type="submit" colorScheme="brand.blue">
                    Apply Filters
                  </Button>
                </ButtonGroup>
              </Box>
            </VStack>
          </form>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

import React from "react";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Container,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  Link,
  NumberInput,
  NumberInputField,
  SimpleGrid,
  Switch,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import useGeolocation from "react-hook-geolocation";
import { Wrapper } from "@googlemaps/react-wrapper";

import { IntercomActivationCreateSchema } from "src/routes/Property/schema";
import { PageContent } from "src/layout/PageContent";
import {
  PageHeader,
  PageHeaderBackLink,
  PageHeaderSubtitle,
  PageHeaderTitle,
} from "src/layout/PageHeader";
import { Loading } from "src/common/Loading";
import { IntercomActivation, PropertyOrganization } from "src/common/types";
import { HTTPError, useKy } from "src/common/ky";
import { noop } from "src/common/util";
import config from "src/common/config";
import { MapMarker } from "src/common/MapMarker";
import { useFlags } from "src/common/hooks/useFlags";
import { handleHookFormHTTPError } from "src/common/form";

type AddIntercomToPropertyValues = Pick<
  IntercomActivation,
  keyof typeof IntercomActivationCreateSchema
>;

const AddIntercomToPropertySchema = Yup.object().shape(
  IntercomActivationCreateSchema
);

export const IntercomAdd = () => {
  const { dealerOrganizationId, propertyOrganizationId } = useParams();
  const toast = useToast();
  const ky = useKy();
  const navigate = useNavigate();
  const { defaultMobileIntercom } = useFlags();
  const position = useGeolocation(
    {
      enableHighAccuracy: true,
      maximumAge: 15000,
      timeout: 12000,
    },
    undefined,
    defaultMobileIntercom
  );

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isSubmitted, isValid },
    setError,
    getValues,
    watch,
  } = useForm<AddIntercomToPropertyValues>({
    defaultValues: AddIntercomToPropertySchema.getDefault(),
    resolver: yupResolver(AddIntercomToPropertySchema),
  });

  const propertyOrganizationQuery = useQuery<PropertyOrganization, HTTPError>(
    ["PROPERTY_ORGANIZATION", propertyOrganizationId, dealerOrganizationId],
    () =>
      ky
        .get(
          `property-organizations/${propertyOrganizationId}/dealer-organization/${dealerOrganizationId}`
        )
        .json<PropertyOrganization>()
  );

  const activateIntercom = useMutation<
    void,
    HTTPError,
    AddIntercomToPropertyValues
  >(
    async (values: AddIntercomToPropertyValues) => {
      await ky.post(
        `product-unit-activations/create-for-property-organization/${propertyOrganizationId}`,
        {
          json: values,
        }
      );
    },
    {
      onError: handleHookFormHTTPError(setError, getValues, toast),
      onSuccess: () => {
        navigate(
          `/dealer-organizations/${dealerOrganizationId}/properties/${propertyOrganizationId}/intercoms`
        );
        toast({
          description: "Intercom Successfully Added",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      },
    }
  );

  const [dmiEnabled, lat, long, locationThresholdFeet] = watch([
    "enableDMI",
    "lat",
    "long",
    "locationThresholdFeet",
  ]);

  if (
    propertyOrganizationQuery.isLoading ||
    !propertyOrganizationQuery.isSuccess
  ) {
    return <Loading />;
  }

  return (
    <>
      <PageHeader>
        <PageHeaderBackLink>
          <Link
            as={RouterLink}
            to={`/dealer-organizations/${dealerOrganizationId}/properties/${propertyOrganizationId}/intercoms`}
          >
            &#8249; Back to list
          </Link>
        </PageHeaderBackLink>
        <PageHeaderTitle>
          {propertyOrganizationQuery.data.name} - Add Intercom
        </PageHeaderTitle>
        <PageHeaderSubtitle>Enter Intercom details here</PageHeaderSubtitle>
      </PageHeader>

      {isSubmitted && !isValid && (
        <Container maxW="7xl">
          <Alert status="error">
            <AlertIcon />
            <AlertDescription>
              {activateIntercom.error?.message ||
                "Please fix any errors in the form and try again."}
            </AlertDescription>
          </Alert>
        </Container>
      )}

      <PageContent>
        <form
          onSubmit={handleSubmit((data) =>
            activateIntercom.mutateAsync(data).catch(noop)
          )}
        >
          <Grid templateColumns={"repeat(4 1fr)"} gap={4}>
            <GridItem>
              <Box display="flex" flexDirection="column" gap={4}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl
                      isRequired
                      isInvalid={fieldState.invalid && fieldState.isTouched}
                    >
                      <FormLabel htmlFor="name">Name</FormLabel>
                      <Input {...field} id="name" />
                      <FormHelperText>
                        Name will appear in the Tenant mobile app
                      </FormHelperText>
                      <FormErrorMessage>
                        {fieldState.error?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
                <Controller
                  name="serialNumber"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl
                      isRequired
                      isInvalid={fieldState.invalid && fieldState.isTouched}
                    >
                      <FormLabel htmlFor="serialNumber">
                        Serial Number
                      </FormLabel>
                      <Input
                        {...field}
                        onChange={(event) =>
                          field.onChange(event.target.value.toUpperCase())
                        }
                        id="serialNumber"
                      />
                      <FormErrorMessage>
                        {fieldState.error?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
                <FormControl as={SimpleGrid} columns={2} spacingY={4}>
                  <Controller
                    name="enableResidentDirectory"
                    control={control}
                    render={({ field, fieldState }) => (
                      <>
                        <FormLabel htmlFor="enableResidentDirectory" mb="0">
                          Enable Tenant Directory
                        </FormLabel>
                        <Switch
                          id="enableResidentDirectory"
                          isChecked={field.value}
                          onChange={field.onChange}
                        />
                        <FormErrorMessage>
                          {fieldState.error?.message}
                        </FormErrorMessage>
                      </>
                    )}
                  />
                  <Controller
                    name="enableNumericCodes"
                    control={control}
                    render={({ field, fieldState }) => (
                      <>
                        <FormLabel htmlFor="enableNumericCodes" mb="0">
                          Enable Numeric Codes
                        </FormLabel>
                        <Switch
                          id="enableNumericCodes"
                          isChecked={field.value}
                          onChange={field.onChange}
                        />
                        <FormErrorMessage>
                          {fieldState.error?.message}
                        </FormErrorMessage>
                      </>
                    )}
                  />
                  <Controller
                    name="enableQrCodes"
                    control={control}
                    render={({ field, fieldState }) => (
                      <>
                        <FormLabel htmlFor="enableQrCodes" mb="0">
                          Enable QR Codes
                        </FormLabel>
                        <Switch
                          id="enableQrCodes"
                          isChecked={field.value}
                          onChange={field.onChange}
                        />
                        <FormErrorMessage>
                          {fieldState.error?.message}
                        </FormErrorMessage>
                      </>
                    )}
                  />
                  <Controller
                    name="enableCallManager"
                    control={control}
                    render={({ field, fieldState }) => (
                      <>
                        <FormLabel htmlFor="enableCallManager" mb="0">
                          Enable Call a Manager
                        </FormLabel>
                        <Switch
                          id="enableCallManager"
                          isChecked={field.value}
                          onChange={field.onChange}
                        />
                        <FormErrorMessage>
                          {fieldState.error?.message}
                        </FormErrorMessage>
                      </>
                    )}
                  />
                  <Controller
                    name="enableVideoCalls"
                    control={control}
                    render={({ field, fieldState }) => (
                      <>
                        <FormLabel htmlFor="enableVideoCalls" mb="0">
                          Enable Video Calls
                        </FormLabel>
                        <Switch
                          id="enableVideoCalls"
                          isChecked={field.value}
                          onChange={field.onChange}
                        />
                        <FormErrorMessage>
                          {fieldState.error?.message}
                        </FormErrorMessage>
                      </>
                    )}
                  />
                  <Controller
                    name="requireNfcTap"
                    control={control}
                    render={({ field, fieldState }) => (
                      <>
                        <FormLabel htmlFor="requireNfcTap" mb="0">
                          Require NFC Tap
                        </FormLabel>
                        <Switch
                          id="requireNfcTap"
                          isChecked={field.value}
                          onChange={field.onChange}
                        />
                        <FormErrorMessage>
                          {fieldState.error?.message}
                        </FormErrorMessage>
                      </>
                    )}
                  />
                </FormControl>
              </Box>
            </GridItem>
            {defaultMobileIntercom && (
              <>
                <GridItem mt={12} colSpan={4}>
                  <Heading as={"h3"} fontSize="24px" mb={4}>
                    Virtual Intercom Settings
                  </Heading>
                  <Text color="gray.500" fontWeight="medium" fontSize="md">
                    Enter the settings below for the Virtual Intercom.
                  </Text>
                </GridItem>
                <GridItem colSpan={4}>
                  <Flex flexDirection="row" flexWrap="wrap" alignItems="start">
                    <Flex flexDirection="column" gap={4} flexBasis="40%">
                      <Controller
                        name="enableDMI"
                        control={control}
                        render={({ field, fieldState }) => (
                          <FormControl>
                            <Flex flexDirection="row" gap={2}>
                              <FormLabel htmlFor="enableDMI" mb="0">
                                Enable Virtual Intercom
                              </FormLabel>
                              <Switch
                                id="enableDMI"
                                isChecked={field.value}
                                onChange={field.onChange}
                              />
                            </Flex>
                            <FormErrorMessage>
                              {fieldState.error?.message}
                            </FormErrorMessage>
                            {!dmiEnabled && (
                              <FormHelperText sx={{ gridColumn: "2 span" }}>
                                Users will no longer be able to view the
                                directory or make calls through the Virtual
                                Intercom.
                              </FormHelperText>
                            )}
                          </FormControl>
                        )}
                      />
                      <Controller
                        name="enableDMIUA"
                        control={control}
                        render={({ field, fieldState }) => (
                          <FormControl>
                            <Flex flexDirection="row" gap={2}>
                              <FormLabel htmlFor="enableDMIUA" mb="0">
                                Enable Mobile Device Check
                              </FormLabel>
                              <Switch
                                isDisabled={!dmiEnabled}
                                id="enableDMIUA"
                                isChecked={field.value}
                                onChange={field.onChange}
                              />
                            </Flex>
                            <FormErrorMessage>
                              {fieldState.error?.message}
                            </FormErrorMessage>
                            <FormHelperText sx={{ gridColumn: "2 span" }}>
                              This check increases security by only allowing
                              mobile device users to access the Virtual
                              Intercom.
                            </FormHelperText>
                          </FormControl>
                        )}
                      />
                      <Controller
                        name="lat"
                        control={control}
                        render={({ field, fieldState }) => (
                          <FormControl
                            isInvalid={
                              fieldState.invalid && fieldState.isTouched
                            }
                          >
                            <FormLabel htmlFor="lat" mb="0">
                              Latitude
                            </FormLabel>
                            <NumberInput
                              {...field}
                              value={field.value === null ? "" : field.value}
                              min={-90}
                              max={90}
                              id="lat"
                            >
                              <NumberInputField pattern="(-)?[0-9]*(.[0-9]+)?" />
                            </NumberInput>
                            {position?.latitude && (
                              <Text as="i">
                                Your latitude: {position?.latitude}
                              </Text>
                            )}
                            <FormErrorMessage>
                              {fieldState.error?.message}
                            </FormErrorMessage>
                            <FormHelperText>
                              {
                                "Please input this entry point's exact location associated to this Virtual Intercom."
                              }
                            </FormHelperText>
                          </FormControl>
                        )}
                      />
                      <Controller
                        name="long"
                        control={control}
                        render={({ field, fieldState }) => (
                          <FormControl
                            isInvalid={
                              fieldState.invalid && fieldState.isTouched
                            }
                          >
                            <FormLabel htmlFor="long" mb="0">
                              Longitude
                            </FormLabel>
                            <NumberInput
                              {...field}
                              value={field.value === null ? "" : field.value}
                              min={-180}
                              max={180}
                              id="long"
                            >
                              <NumberInputField pattern="(-)?[0-9]*(.[0-9]+)?" />
                            </NumberInput>
                            {position?.longitude && (
                              <Text as="i">
                                Your longitude: {position?.longitude}
                              </Text>
                            )}
                            <FormErrorMessage>
                              {fieldState.error?.message}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      />
                      <Controller
                        name="locationThresholdFeet"
                        control={control}
                        render={({ field, fieldState }) => (
                          <FormControl
                            isInvalid={
                              fieldState.invalid && fieldState.isTouched
                            }
                          >
                            <FormLabel htmlFor="locationThresholdFeet" mb="0">
                              Maximum Distance from Intercom
                            </FormLabel>
                            <NumberInput {...field} id="locationThresholdFeet">
                              <NumberInputField />
                            </NumberInput>
                            <FormErrorMessage>
                              {fieldState.error?.message}
                            </FormErrorMessage>
                            <FormHelperText>
                              Please set the radius (in feet) that the user has
                              to be within in order to use the Virtual Intercom.
                              Recommended distance 30ft.
                            </FormHelperText>
                          </FormControl>
                        )}
                      />
                    </Flex>
                    <Box
                      height={300}
                      width={300}
                      marginLeft="auto"
                      marginRight="auto"
                    >
                      <Wrapper apiKey={config.googleMapsKey}>
                        <MapMarker
                          zoom={18}
                          lat={lat}
                          long={long}
                          radius={locationThresholdFeet}
                        />
                      </Wrapper>
                    </Box>
                  </Flex>
                </GridItem>
              </>
            )}
            <GridItem />
            <GridItem />
            <GridItem />

            <GridItem colSpan={4}>
              <Divider />
            </GridItem>

            <GridItem colSpan={4}>
              <ButtonGroup display="flex" justifyContent="flex-end">
                <Link
                  as={RouterLink}
                  to={`/dealer-organizations/${dealerOrganizationId}/properties/${propertyOrganizationId}/intercoms`}
                >
                  <Button>Cancel</Button>
                </Link>
                <Button
                  type="submit"
                  colorScheme="brand.blue"
                  isLoading={isSubmitting}
                >
                  Save
                </Button>
              </ButtonGroup>
            </GridItem>
          </Grid>
        </form>
      </PageContent>
    </>
  );
};

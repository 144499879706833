import React from "react";
import {
  Box,
  Container,
  Flex,
  Link,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

import { DealerOrganization } from "src/common/types";
import {
  PageHeader,
  PageHeaderSubtitle,
  PageHeaderTitle,
} from "src/layout/PageHeader";
import { PageContent } from "src/layout/PageContent";
import { TopBar } from "src/layout/DealerOrganizationLayout";
import { AppErrorBoundary } from "src/common/ErrorBoundary/AppErrorBoundary";
import { ErrorOutline } from "src/common/icons/ErrorOutline";

const NoDealerOrganizationsFound = () => (
  <Container maxW="7xl" h="calc(80vh)" centerContent={true}>
    <Flex
      h="100%"
      w="50%"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <ErrorOutline w={53} h={53} color="#FF595E" mb={30} />
      <Box>
        <Text fontSize="30px" fontWeight={600} textAlign="center">
          It seems you don&apos;t have permission to log in to this portal.
        </Text>
      </Box>
    </Flex>
  </Container>
);

type DealerOrganizationPickerProps = {
  dealerOrganizations: DealerOrganization[];
};
export const SelectDealerOrganization = ({
  dealerOrganizations,
}: DealerOrganizationPickerProps) => {
  const contents = dealerOrganizations.length ? (
    <>
      <PageHeader>
        <PageHeaderTitle>Select Dealer Organization</PageHeaderTitle>
        <PageHeaderSubtitle>
          Select the Dealer Organization you wish to view:
        </PageHeaderSubtitle>
      </PageHeader>
      <PageContent>
        <Table variant="simple">
          <Tbody>
            {dealerOrganizations.map((organization) => (
              <Tr key={organization.dealerOrganizationId}>
                <Td>
                  <Link
                    as={RouterLink}
                    to={`${organization.dealerOrganizationId}`}
                  >
                    <Text>{organization.displayName}</Text>
                  </Link>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </PageContent>
    </>
  ) : (
    <NoDealerOrganizationsFound />
  );

  return (
    <>
      <TopBar hideNavigation />
      <AppErrorBoundary>{contents}</AppErrorBoundary>
    </>
  );
};

import React from "react";
import { createIcon } from "@chakra-ui/react";

export const VolumeUp = createIcon({
  displayName: "VolumeUp",
  viewBox: "0 0 30 29",
  path: (
    <path
      fill="currentColor"
      d="M18.333 29.542V26.667C20.9723 25.917 23.1113 24.4653 24.75 22.312C26.3887 20.1593 27.208 17.708 27.208 14.958C27.208 12.208 26.3887 9.74967 24.75 7.583C23.1113 5.41633 20.9723 3.972 18.333 3.25V0.375C21.7777 1.153 24.5833 2.896 26.75 5.604C28.9167 8.31267 30 11.4307 30 14.958C30 18.486 28.9167 21.604 26.75 24.312C24.5833 27.0207 21.7777 28.764 18.333 29.542ZM0 20V10H6.667L15 1.667V28.333L6.667 20H0ZM17.792 21.875V8.042C19.236 8.56933 20.3817 9.472 21.229 10.75C22.0763 12.028 22.5 13.4447 22.5 15C22.5 16.528 22.0693 17.9307 21.208 19.208C20.3473 20.486 19.2087 21.375 17.792 21.875ZM12.208 8.583L7.875 12.792H2.792V17.208H7.875L12.208 21.458V8.583Z"
    />
  ),
});

import React, { useRef, useState } from "react";
import {
  Button,
  ButtonGroup,
  Flex,
  Spacer,
  Stack,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import { FaChevronLeft, FaChevronRight, FaPlus } from "react-icons/fa";
import { useMutation } from "react-query";

import { Card } from "src/layout/Card";
import { AddAccessPanelStep } from "src/routes/Property/AccessControl/Wizard/steps/AddAccessPanelStep";
import { CreateEntryGroupsStep } from "src/routes/Property/AccessControl/Wizard/steps/CreateEntryGroupsStep";
import { CreateEntryPointsStep } from "src/routes/Property/AccessControl/Wizard/steps/CreateEntryPointsStep";
import { CreateAccessProfilesStep } from "src/routes/Property/AccessControl/Wizard/steps/CreateAccessProfilesStep";
import { CreateEntrySchedulesStep } from "src/routes/Property/AccessControl/Wizard/steps/CreateEntrySchedulesStep";
import { WizardSidebarStepView } from "src/routes/Property/AccessControl/Wizard/components/WizardSidebarStepView";
import { useGetPropertyAccessPanelQuery } from "src/routes/Property/queries";
import { Loading } from "src/common/Loading";
import { WizardStepNavbarView } from "src/routes/Property/AccessControl/Wizard/components/WizardStepNavbarView";
import { useKy } from "src/common/ky";
import { handleHTTPError } from "src/common/form";
import { noop } from "src/common/util";

export enum AccessControlWizardStep {
  AddAccessPanel = "add-access-panel",
  CreateEntryGroups = "create-entry-groups",
  CreateEntryPoints = "create-entry-points",
  CreateEntrySchedules = "create-entry-schedules",
  CreateAccessProfiles = "create-access-profiles",
}

export enum AccessControlWizardStepState {
  Completed = "completed",
  Active = "active",
  Inactive = "inactive",
}

export const ACCESS_CONTROL_WIZARD_ORDERED_STEPS = [
  AccessControlWizardStep.AddAccessPanel,
  AccessControlWizardStep.CreateEntryGroups,
  AccessControlWizardStep.CreateEntryPoints,
  AccessControlWizardStep.CreateEntrySchedules,
  AccessControlWizardStep.CreateAccessProfiles,
];

export const ACCESS_CONTROL_WIZARD_STEP_LABELS: Record<
  AccessControlWizardStep,
  string
> = {
  [AccessControlWizardStep.AddAccessPanel]: "Add Access Panel",
  [AccessControlWizardStep.CreateEntryGroups]: "Create Entry Groups",
  [AccessControlWizardStep.CreateEntryPoints]: "Create Entry Points",
  [AccessControlWizardStep.CreateEntrySchedules]: "Create Entry Schedules",
  [AccessControlWizardStep.CreateAccessProfiles]: "Create Profiles",
};

export type AccessControlWizardProps = {
  propertyOrganizationId: string;
  onComplete: () => void;
};

export const AccessControlWizard = (props: AccessControlWizardProps) => {
  const { propertyOrganizationId, onComplete } = props;
  const [currentStep, setCurrentStep] = useState<AccessControlWizardStep>(
    AccessControlWizardStep.AddAccessPanel
  );
  const isMobile = useBreakpointValue({ base: true, md: false });
  const initializedRef = useRef(false);
  const navbarPortalRef = useRef(null);
  const ky = useKy();
  const toast = useToast();

  const propertyAccessPanelQuery = useGetPropertyAccessPanelQuery(
    propertyOrganizationId,
    {
      queryOptions: {
        onSettled: (data) => {
          if (data && !initializedRef.current) {
            nextStep();
          }

          initializedRef.current = true;
        },
      },
    }
  );

  const getStepState = (step: AccessControlWizardStep) => {
    if (currentStep === step) {
      return AccessControlWizardStepState.Active;
    } else if (
      ACCESS_CONTROL_WIZARD_ORDERED_STEPS.indexOf(currentStep) >
      ACCESS_CONTROL_WIZARD_ORDERED_STEPS.indexOf(step)
    ) {
      return AccessControlWizardStepState.Completed;
    } else {
      return AccessControlWizardStepState.Inactive;
    }
  };

  const nextStep = () => {
    setCurrentStep(
      ACCESS_CONTROL_WIZARD_ORDERED_STEPS[
        ACCESS_CONTROL_WIZARD_ORDERED_STEPS.indexOf(currentStep) + 1
      ]
    );
  };

  const previousStep = () => {
    setCurrentStep(
      ACCESS_CONTROL_WIZARD_ORDERED_STEPS[
        ACCESS_CONTROL_WIZARD_ORDERED_STEPS.indexOf(currentStep) - 1
      ]
    );
  };

  const completeOnboarding = useMutation(
    () =>
      ky.patch(
        `property-organizations/${propertyOrganizationId}/complete-access-control-onboarding`
      ),
    {
      onSuccess: onComplete,
      onError: handleHTTPError(toast),
    }
  );

  if (
    propertyAccessPanelQuery.isLoading ||
    !propertyAccessPanelQuery.isSuccess ||
    !initializedRef.current
  ) {
    return <Loading />;
  }

  return (
    <Flex flexDirection={{ base: "column", md: "row" }} gap={4}>
      <Card
        position={"sticky"}
        top={4}
        alignSelf={{ base: undefined, md: "start" }}
      >
        <Stack
          spacing={4}
          wrap={isMobile ? "wrap" : undefined}
          direction={isMobile ? "row" : "column"}
          width={isMobile ? undefined : "225px"}
        >
          {ACCESS_CONTROL_WIZARD_ORDERED_STEPS.map((step, idx) => (
            <WizardSidebarStepView
              key={step}
              withArrow={idx !== ACCESS_CONTROL_WIZARD_ORDERED_STEPS.length - 1}
              stepState={getStepState(step)}
              step={step}
            />
          ))}
        </Stack>
      </Card>
      <Card flex={1}>
        <Stack spacing={4} height={"100%"}>
          {currentStep === AccessControlWizardStep.AddAccessPanel ? (
            <>
              <AddAccessPanelStep
                navbarPortalRef={navbarPortalRef}
                propertyOrganizationId={propertyOrganizationId}
                Footer={() => (
                  <ButtonGroup display="flex" justifyContent="flex-end">
                    <Button
                      leftIcon={<FaChevronLeft />}
                      onClick={() => previousStep()}
                      isDisabled={true}
                    >
                      Previous
                    </Button>
                    <Button
                      rightIcon={<FaChevronRight />}
                      onClick={() => nextStep()}
                    >
                      Next
                    </Button>
                  </ButtonGroup>
                )}
              />
              <WizardStepNavbarView ref={navbarPortalRef} />
            </>
          ) : null}
          {currentStep === AccessControlWizardStep.CreateEntryGroups ? (
            <>
              <CreateEntryGroupsStep
                propertyOrganizationId={propertyOrganizationId}
                navbarPortalRef={navbarPortalRef}
                Footer={({ addEntryGroup }) => (
                  <ButtonGroup display="flex" justifyContent="flex-end">
                    <Button
                      leftIcon={<FaPlus />}
                      variant={"outline"}
                      colorScheme={"brand.blue"}
                      onClick={() => addEntryGroup()}
                    >
                      Add Entry Group
                    </Button>
                    <Spacer />
                    <Button
                      leftIcon={<FaChevronLeft />}
                      onClick={() => previousStep()}
                      isDisabled={
                        ACCESS_CONTROL_WIZARD_ORDERED_STEPS.indexOf(
                          currentStep
                        ) === 0
                      }
                    >
                      Previous
                    </Button>
                    <Button
                      rightIcon={<FaChevronRight />}
                      onClick={() => nextStep()}
                    >
                      Next
                    </Button>
                  </ButtonGroup>
                )}
              />
              <WizardStepNavbarView ref={navbarPortalRef} />
            </>
          ) : null}
          {currentStep === AccessControlWizardStep.CreateEntryPoints ? (
            <>
              <CreateEntryPointsStep
                propertyOrganizationId={propertyOrganizationId}
                navbarPortalRef={navbarPortalRef}
                Footer={({ addEntryPoint }) => (
                  <ButtonGroup display="flex" justifyContent="flex-end">
                    <Button
                      leftIcon={<FaPlus />}
                      variant={"outline"}
                      colorScheme={"brand.blue"}
                      onClick={() => addEntryPoint()}
                    >
                      Add Entry Point
                    </Button>
                    <Spacer />
                    <Button
                      leftIcon={<FaChevronLeft />}
                      onClick={() => previousStep()}
                      isDisabled={
                        ACCESS_CONTROL_WIZARD_ORDERED_STEPS.indexOf(
                          currentStep
                        ) === 0
                      }
                    >
                      Previous
                    </Button>
                    <Button
                      rightIcon={<FaChevronRight />}
                      onClick={() => nextStep()}
                    >
                      Next
                    </Button>
                  </ButtonGroup>
                )}
              />
              <WizardStepNavbarView ref={navbarPortalRef} />
            </>
          ) : null}
          {currentStep === AccessControlWizardStep.CreateEntrySchedules ? (
            <>
              <CreateEntrySchedulesStep
                propertyOrganizationId={propertyOrganizationId}
                navbarPortalRef={navbarPortalRef}
                Footer={({ addEntrySchedule }) => (
                  <ButtonGroup display="flex" justifyContent="flex-end">
                    <Button
                      leftIcon={<FaPlus />}
                      variant={"outline"}
                      colorScheme={"brand.blue"}
                      onClick={() => addEntrySchedule()}
                    >
                      Add Entry Schedule
                    </Button>
                    <Spacer />
                    <Button
                      leftIcon={<FaChevronLeft />}
                      onClick={() => previousStep()}
                      isDisabled={
                        ACCESS_CONTROL_WIZARD_ORDERED_STEPS.indexOf(
                          currentStep
                        ) === 0
                      }
                    >
                      Previous
                    </Button>
                    <Button
                      rightIcon={<FaChevronRight />}
                      onClick={() => nextStep()}
                    >
                      Next
                    </Button>
                  </ButtonGroup>
                )}
              />
              <WizardStepNavbarView ref={navbarPortalRef} />
            </>
          ) : null}
          {currentStep === AccessControlWizardStep.CreateAccessProfiles ? (
            <>
              <CreateAccessProfilesStep
                propertyOrganizationId={propertyOrganizationId}
                navbarPortalRef={navbarPortalRef}
                Footer={({ addAccessProfile }) => (
                  <ButtonGroup display="flex" justifyContent="flex-end">
                    <Button
                      leftIcon={<FaPlus />}
                      variant={"outline"}
                      colorScheme={"brand.blue"}
                      onClick={() => addAccessProfile()}
                    >
                      Add Profile
                    </Button>
                    <Spacer />
                    <Button
                      leftIcon={<FaChevronLeft />}
                      onClick={() => previousStep()}
                      isDisabled={
                        ACCESS_CONTROL_WIZARD_ORDERED_STEPS.indexOf(
                          currentStep
                        ) === 0
                      }
                    >
                      Previous
                    </Button>
                    <Button
                      colorScheme={"brand.blue"}
                      isDisabled={completeOnboarding.isLoading}
                      onClick={() =>
                        completeOnboarding.mutateAsync().catch(noop)
                      }
                    >
                      Complete
                    </Button>
                  </ButtonGroup>
                )}
              />
              <WizardStepNavbarView ref={navbarPortalRef} />
            </>
          ) : null}
        </Stack>
      </Card>
    </Flex>
  );
};

import React, { useMemo, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Portal,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { sortBy } from "lodash";
import { useQueryClient } from "react-query";
import { HiOutlinePencil, HiOutlineTrash } from "react-icons/hi";

import {
  getEntryGroupQueryKey,
  getEntryGroupsQueryKey,
  useGetEntryGroupsQuery,
} from "src/routes/Property/queries";
import { useDeleteEntryGroup } from "src/routes/Property/hooks";
import { Loading } from "src/common/Loading";
import { noop } from "src/common/util";
import { handleHookFormHTTPError, handleHTTPError } from "src/common/form";
import { CreateEntryGroupForm } from "src/routes/Property/AccessControl/EntryGroups/components/CreateEntryGroupForm";
import { EditEntryGroupForm } from "src/routes/Property/AccessControl/EntryGroups/components/EditEntryGroupForm";
import { WizardStepFormView } from "src/routes/Property/AccessControl/Wizard/components/WizardStepFormView";
import { HTTPError } from "src/common/ky";

export type CreateEntryGroupsStepProps = {
  propertyOrganizationId: string;
  navbarPortalRef: React.RefObject<HTMLElement | null>;
  Footer: (props: { addEntryGroup: () => void }) => React.ReactElement;
};

export const CreateEntryGroupsStep = (props: CreateEntryGroupsStepProps) => {
  const { propertyOrganizationId, navbarPortalRef, Footer } = props;
  const entryGroupsQuery = useGetEntryGroupsQuery(propertyOrganizationId);
  const toast = useToast();
  const queryClient = useQueryClient();

  const [editingEntryGroupId, setEditingEntryGroupId] = useState<string>();
  const [creatingEntryGroup, setCreatingEntryGroup] = useState(false);

  const entryGroups = useMemo(
    () =>
      sortBy(entryGroupsQuery.data ?? [], (v) => v.displayName.toLowerCase()),
    [entryGroupsQuery.data]
  );

  const {
    isDeletingEntryGroup,
    deleteEntryGroupMutation,
    setDeletingEntryGroupId,
  } = useDeleteEntryGroup({
    onSuccess: () => {
      queryClient.invalidateQueries(
        getEntryGroupsQueryKey(propertyOrganizationId as string)
      );
      setDeletingEntryGroupId(undefined);
      toast({
        description: "Entry Group deleted",
        status: "success",
      });
    },
    onError: async (error: HTTPError) => {
      await handleHTTPError(toast)(error);
      setDeletingEntryGroupId(undefined);
    },
  });

  if (entryGroupsQuery.isLoading || !entryGroupsQuery.isSuccess) {
    return <Loading />;
  }

  return (
    <>
      <Stack>
        <Text fontSize="xl" fontWeight={600}>
          Entry Group Creation
        </Text>
        <Text fontSize="md" color={"gray.500"}>
          Create Entry Groups to distinguish different sections or areas on a
          property. EX: Pool, Clubhouse, vehicle gates, etc.
        </Text>
      </Stack>

      {creatingEntryGroup ? (
        <WizardStepFormView>
          <CreateEntryGroupForm
            propertyOrganizationId={propertyOrganizationId as string}
            mutationOptionsBuilder={({ setError, getValues }) => ({
              onSuccess: () => {
                toast({
                  description: "Entry Group added",
                  status: "success",
                });
                queryClient.invalidateQueries(
                  getEntryGroupsQueryKey(propertyOrganizationId)
                );
                setCreatingEntryGroup(false);
              },
              onError: handleHookFormHTTPError(setError, getValues, toast),
            })}
            hideFooterDivider={true}
            Footer={({ isSubmitting, onSubmit }) => (
              <Portal containerRef={navbarPortalRef}>
                <ButtonGroup display="flex" justifyContent="flex-end">
                  <Button onClick={() => setCreatingEntryGroup(false)}>
                    Cancel
                  </Button>
                  <Button
                    onClick={() => onSubmit()}
                    colorScheme="brand.blue"
                    isLoading={isSubmitting}
                  >
                    Save
                  </Button>
                </ButtonGroup>
              </Portal>
            )}
          />
        </WizardStepFormView>
      ) : null}

      {editingEntryGroupId ? (
        <WizardStepFormView>
          <EditEntryGroupForm
            entryGroupId={editingEntryGroupId}
            propertyOrganizationId={propertyOrganizationId as string}
            mutationOptionsBuilder={({ setError, getValues }) => ({
              onSuccess: () => {
                toast({
                  description: "Entry Group updated",
                  status: "success",
                });
                queryClient.invalidateQueries(
                  getEntryGroupQueryKey(editingEntryGroupId as string)
                );
                queryClient.invalidateQueries(
                  getEntryGroupsQueryKey(propertyOrganizationId as string)
                );
                setEditingEntryGroupId(undefined);
              },
              onError: handleHookFormHTTPError(setError, getValues, toast),
            })}
            hideFooterDivider={true}
            Footer={({ isSubmitting, onSubmit }) => (
              <Portal containerRef={navbarPortalRef}>
                <ButtonGroup display="flex" justifyContent="flex-end">
                  <Button onClick={() => setEditingEntryGroupId(undefined)}>
                    Cancel
                  </Button>
                  <Button
                    onClick={() => onSubmit()}
                    colorScheme="brand.blue"
                    isLoading={isSubmitting}
                  >
                    Save Changes
                  </Button>
                </ButtonGroup>
              </Portal>
            )}
          />
        </WizardStepFormView>
      ) : null}

      {!creatingEntryGroup && !editingEntryGroupId ? (
        <>
          <Box overflowX={"auto"}>
            <Table>
              <Thead>
                <Tr>
                  <Th>Display Name</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {entryGroups.length ? (
                  entryGroups.map((entryGroup) => {
                    return (
                      <Tr key={entryGroup.entryGroupId}>
                        <Td>{entryGroup.displayName}</Td>
                        <Td textAlign={"right"}>
                          <ButtonGroup>
                            <IconButton
                              onClick={() =>
                                setEditingEntryGroupId(entryGroup.entryGroupId)
                              }
                              aria-label="Actions"
                              icon={<HiOutlinePencil />}
                              variant="clear"
                            />
                            <IconButton
                              onClick={() =>
                                setDeletingEntryGroupId(entryGroup.entryGroupId)
                              }
                              aria-label="Actions"
                              icon={<HiOutlineTrash />}
                              variant="clear"
                            />
                          </ButtonGroup>
                        </Td>
                      </Tr>
                    );
                  })
                ) : (
                  <Tr>
                    <Td colSpan={2} textAlign={"center"}>
                      <Text fontStyle={"italic"}>
                        There are no entry groups
                      </Text>
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </Box>
          <Portal containerRef={navbarPortalRef}>
            {Footer({
              addEntryGroup: () => setCreatingEntryGroup(true),
            })}
          </Portal>
        </>
      ) : null}

      <Modal
        isOpen={isDeletingEntryGroup}
        onClose={() => {
          setDeletingEntryGroupId(undefined);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Remove Entry Group</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure? You can&apos;t undo this action.</ModalBody>

          <ModalFooter>
            <Button
              colorScheme="brand.lightGray"
              color="gray.700"
              mr={3}
              onClick={() => {
                setDeletingEntryGroupId(undefined);
              }}
            >
              Cancel
            </Button>
            <Button
              colorScheme="brand.red"
              onClick={() => deleteEntryGroupMutation.mutateAsync().catch(noop)}
              isDisabled={deleteEntryGroupMutation.isLoading}
            >
              Remove Entry Group
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

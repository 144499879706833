import React from "react";
import {
  Button,
  ButtonGroup,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  Link,
  Select,
  Stack,
  Switch,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery, useQueryClient } from "react-query";

import {
  PageHeader,
  PageHeaderBackLink,
  PageHeaderTabs,
  PageHeaderTitle,
} from "src/layout/PageHeader";
import { PageContent } from "src/layout/PageContent";
import { TabLink } from "src/layout/TabLink";
import { Loading } from "src/common/Loading";
import { DealerFinancials } from "src/common/types";
import { HTTPError, useKy } from "src/common/ky";
import { states } from "src/common/us-states";
import { MY_DEALER_ORGANIZATIONS_QUERY_KEY } from "src/routes/DealerOrganizationSelector";
import { noop } from "src/common/util";
import { handleHookFormHTTPError } from "src/common/form";
import { PhoneNumberInput } from "src/common/PhoneNumbers";
import { YupPossiblePhoneNumber } from "src/common/phone-numbers";

interface UpddateDealerFinancialsValues {
  businessName: string;
  licenseNumber: string;
  isTaxExempt: boolean;
  taxIdNumber: string;
  billingContactName: string;
  billingContactPhone: string;
  billingContactEmail: string;
  billingContactAddress: string;
  billingContactCity: string;
  billingContactState: string;
  billingContactZipCode: string;
  invoicingMethod: string;
}

const AddStaffMemberValidationSchema = Yup.object().shape({
  businessName: Yup.string().required().label("Business Name"),
  licenseNumber: Yup.string().required().label("License Number"),
  isTaxExempt: Yup.boolean().required(),
  taxIdNumber: Yup.string().label("Tax I.D. Number").required(),
  billingContactName: Yup.string().required().label("Name"),
  billingContactPhone: YupPossiblePhoneNumber({
    label: "Phone",
    required: true,
  }),
  billingContactEmail: Yup.string().email().required().label("Email"),
  billingContactAddress: Yup.string().required().label("Billing Address"),
  billingContactCity: Yup.string().required().label("City"),
  billingContactState: Yup.string().required().label("State"),
  billingContactZipCode: Yup.string().length(5).required().label("ZIP Code"),
  invoicingMethod: Yup.string().label("Invoicing Method"),
});

export const FinancialsEdit = () => {
  const queryClient = useQueryClient();
  const { dealerOrganizationId } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const ky = useKy();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty },
    reset,
    setError,
    getValues,
  } = useForm<UpddateDealerFinancialsValues>({
    defaultValues: {
      businessName: "",
      licenseNumber: "",
      isTaxExempt: false,
      taxIdNumber: "",
      billingContactName: "",
      billingContactPhone: "",
      billingContactEmail: "",
      billingContactAddress: "",
      billingContactCity: "",
      billingContactState: "",
      billingContactZipCode: "",
      invoicingMethod: "",
    },
    resolver: yupResolver(AddStaffMemberValidationSchema),
  });

  const updateDealerFinancials = useMutation<
    void,
    HTTPError,
    UpddateDealerFinancialsValues
  >(
    async (values: UpddateDealerFinancialsValues) => {
      await ky.put(`dealer-financials/${dealerOrganizationId}`, {
        json: values,
      });
    },
    {
      onSuccess: () => {
        navigate(
          `/dealer-organizations/${dealerOrganizationId}/profile/financials`
        );
        toast({
          description: "Dealer Financials Successfully Updated",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        queryClient.invalidateQueries(MY_DEALER_ORGANIZATIONS_QUERY_KEY);
      },
      onError: handleHookFormHTTPError(setError, getValues, toast),
    }
  );

  const query = useQuery<DealerFinancials, HTTPError>(
    `DEALER_ORGANIZATIONS:${dealerOrganizationId}`,
    () =>
      ky
        .get(`dealer-financials/${dealerOrganizationId}`)
        .json<DealerFinancials>(),
    {
      onSuccess: (data) => {
        if (!isDirty) {
          reset({
            businessName: data.businessName || "",
            licenseNumber: data.licenseNumber || "",
            isTaxExempt: data.isTaxExempt,
            taxIdNumber: data.taxIdNumber || "",
            billingContactName: data.billingContactName || "",
            billingContactPhone: data.billingContactPhone || "",
            billingContactEmail: data.billingContactEmail || "",
            billingContactAddress: data.billingContactAddress || "",
            billingContactCity: data.billingContactCity || "",
            billingContactState: data.billingContactState || "",
            billingContactZipCode: data.billingContactZipCode || "",
            invoicingMethod: data.invoicingMethod || "",
          });
        }
      },
    }
  );

  if (query.isLoading || !query.isSuccess) {
    return <Loading />;
  }

  return (
    <>
      <PageHeader>
        <PageHeaderBackLink>
          <Link
            as={RouterLink}
            to={`/dealer-organizations/${dealerOrganizationId}/profile/financials`}
          >
            &#8249; Back to details
          </Link>
        </PageHeaderBackLink>
        <PageHeaderTitle>Dealer Profile</PageHeaderTitle>
        <PageHeaderTabs>
          <TabLink
            key="profile"
            to={`/dealer-organizations/${dealerOrganizationId}/profile/details`}
            label="Details"
          />
          <TabLink
            key="financials"
            to={`/dealer-organizations/${dealerOrganizationId}/profile/financials`}
            label="Financials"
          />
        </PageHeaderTabs>
      </PageHeader>
      <PageContent>
        <form
          onSubmit={handleSubmit((data) =>
            updateDealerFinancials
              .mutateAsync({
                businessName: data.businessName,
                licenseNumber: data.licenseNumber,
                isTaxExempt: data.isTaxExempt,
                taxIdNumber: data.taxIdNumber,
                billingContactName: data.billingContactName,
                billingContactPhone: data.billingContactPhone,
                billingContactEmail: data.billingContactEmail,
                billingContactAddress: data.billingContactAddress,
                billingContactCity: data.billingContactCity,
                billingContactState: data.billingContactState,
                billingContactZipCode: data.billingContactZipCode,
                invoicingMethod: data.invoicingMethod,
              })
              .catch(noop)
          )}
        >
          <Grid templateColumns={"repeat(4 1fr)"} gap={6}>
            <GridItem colSpan={4}>
              <Heading size="md">Billing Information</Heading>
              <Text color="gray.500" fontSize="sm">
                Please fill out the information below
              </Text>
            </GridItem>
            <GridItem colSpan={4}>
              <Divider />
            </GridItem>

            <GridItem>
              <Stack gap={4}>
                <Controller
                  name="businessName"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl
                      isRequired
                      isInvalid={fieldState.invalid && fieldState.isTouched}
                    >
                      <FormLabel htmlFor="businessName">
                        Name of Business
                      </FormLabel>
                      <Input {...field} id="businessName" />
                      <FormErrorMessage>
                        {fieldState.error?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
                <Controller
                  name="licenseNumber"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl
                      isRequired
                      isInvalid={fieldState.invalid && fieldState.isTouched}
                    >
                      <FormLabel htmlFor="licenseNumber">
                        Business License Number
                      </FormLabel>
                      <Input {...field} id="licenseNumber" />
                      <FormErrorMessage>
                        {fieldState.error?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
                <Controller
                  name="taxIdNumber"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl
                      isRequired
                      isInvalid={fieldState.invalid && fieldState.isTouched}
                    >
                      <FormLabel htmlFor="taxIdNumber">
                        Tax I.D. Number
                      </FormLabel>

                      <Input {...field} id="taxIdNumber" />
                      <Text color="gray.500" fontSize="sm">
                        Please email accounting@gatehawk.com to submit or
                        request your state&apos;s required tax documents.
                      </Text>
                      <FormErrorMessage>
                        {fieldState.error?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />

                <Controller
                  name="isTaxExempt"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl display="flex" alignItems="center">
                      <FormLabel htmlFor="isTaxExempt" mb="0">
                        Business is Tax Exempt
                      </FormLabel>
                      <Switch
                        id="isTaxExempt"
                        onChange={field.onChange}
                        isChecked={field.value}
                      />
                      <FormErrorMessage>
                        {fieldState.error?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
              </Stack>
            </GridItem>
            <GridItem></GridItem>
            <GridItem></GridItem>
            <GridItem></GridItem>

            <GridItem colSpan={4}>
              <Heading size="md">Business Contact Information</Heading>
            </GridItem>
            <GridItem colSpan={4}>
              <Divider />
            </GridItem>

            <GridItem>
              <Stack gap={4}>
                <Controller
                  name="billingContactName"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl
                      isRequired
                      isInvalid={fieldState.invalid && fieldState.isTouched}
                    >
                      <FormLabel htmlFor="billingContactName">Name</FormLabel>
                      <Input {...field} id="billingContactName" />
                      <FormErrorMessage>
                        {fieldState.error?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />

                <Controller
                  name="billingContactPhone"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl
                      isRequired
                      isInvalid={fieldState.invalid && fieldState.isTouched}
                    >
                      <FormLabel htmlFor="billingContactPhone">Phone</FormLabel>
                      <PhoneNumberInput {...field} id="billingContactPhone" />
                      <FormErrorMessage>
                        {fieldState.error?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />

                <Controller
                  name="billingContactEmail"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl
                      isRequired
                      isInvalid={fieldState.invalid && fieldState.isTouched}
                    >
                      <FormLabel htmlFor="billingContactEmail">Email</FormLabel>
                      <Input {...field} id="billingContactEmail" />
                      <FormErrorMessage>
                        {fieldState.error?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
                <Controller
                  name="billingContactAddress"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl
                      isRequired
                      isInvalid={fieldState.invalid && fieldState.isTouched}
                    >
                      <FormLabel htmlFor="billingContactAddress">
                        Billing Address
                      </FormLabel>
                      <Input {...field} id="billingContactAddress" />
                      <FormErrorMessage>
                        {fieldState.error?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
                <Controller
                  name="billingContactCity"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl
                      isRequired
                      isInvalid={fieldState.invalid && fieldState.isTouched}
                    >
                      <FormLabel htmlFor="billingContactCity">City</FormLabel>
                      <Input {...field} id="billingContactCity" />
                      <FormErrorMessage>
                        {fieldState.error?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
                <Controller
                  name="billingContactState"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl
                      isRequired
                      isInvalid={fieldState.invalid && fieldState.isTouched}
                    >
                      <FormLabel htmlFor="billingContactState">State</FormLabel>
                      <Select {...field} id="billingContactState">
                        <option value="" />
                        {states.map((state) => (
                          <option
                            key={state.abbreviation}
                            value={state.abbreviation}
                          >
                            {state.name}
                          </option>
                        ))}
                      </Select>
                      <FormErrorMessage>
                        {fieldState.error?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
                <Controller
                  name="billingContactZipCode"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl
                      isRequired
                      isInvalid={fieldState.invalid && fieldState.isTouched}
                    >
                      <FormLabel htmlFor="billingContactZipCode">
                        ZIP Code
                      </FormLabel>
                      <Input {...field} id="billingContactZipCode" />
                      <FormErrorMessage>
                        {fieldState.error?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
                <Controller
                  name="invoicingMethod"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl
                      isInvalid={fieldState.invalid && fieldState.isTouched}
                    >
                      <FormLabel htmlFor="invoicingMethod">
                        Invoicing Method
                      </FormLabel>
                      <Input {...field} id="invoicingMethod" />
                      <Text color="gray.500" fontSize="sm">
                        A GateHawk Representative will reach out to you directly
                        to collect payments.
                      </Text>
                      <FormErrorMessage>
                        {fieldState.error?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
              </Stack>
            </GridItem>
            <GridItem></GridItem>
            <GridItem></GridItem>
            <GridItem></GridItem>

            <GridItem colSpan={4}>
              <Divider />
            </GridItem>

            <GridItem colSpan={4}>
              <ButtonGroup display="flex" justifyContent="flex-end">
                <Link
                  as={RouterLink}
                  to={`/dealer-organizations/${dealerOrganizationId}/profile/financials`}
                >
                  <Button>Cancel</Button>
                </Link>
                <Button
                  type="submit"
                  colorScheme="brand.blue"
                  isLoading={isSubmitting}
                >
                  Save
                </Button>
              </ButtonGroup>
            </GridItem>
          </Grid>
        </form>
      </PageContent>
    </>
  );
};

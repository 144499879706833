import {
  Checkbox,
  CheckboxGroup,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import React, { useCallback, useMemo } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "react-query";
import { HTTPError } from "ky";
import { intersection } from "lodash";

import { noop } from "src/common/util";
import { EditEntryGroupValidationSchema } from "src/routes/Property/schema";
import {
  useGetEntryGroupQuery,
  useGetEntryPointsQuery,
} from "src/routes/Property/queries";
import { Loading } from "src/common/Loading";
import { useKy } from "src/common/ky";
import { AccessControlFormProps } from "src/routes/Property/AccessControl/types";

export interface UpdateEntryGroupValues {
  displayName: string;
  entryPointIds: string[];
}

export type EditEntryGroupFormProps =
  AccessControlFormProps<UpdateEntryGroupValues> & {
    entryGroupId: string;
    propertyOrganizationId: string;
  };

export const EditEntryGroupForm = (props: EditEntryGroupFormProps) => {
  const {
    entryGroupId,
    hideFooterDivider,
    Footer,
    mutationOptionsBuilder,
    children,
    propertyOrganizationId,
  } = props;

  const ky = useKy();

  const hookForm = useForm<UpdateEntryGroupValues>({
    defaultValues: {
      displayName: "",
      entryPointIds: [],
    },
    resolver: yupResolver(EditEntryGroupValidationSchema),
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty },
    getValues,
    reset,
  } = hookForm;

  const mutationOptions = useMemo(
    () => mutationOptionsBuilder && mutationOptionsBuilder(hookForm),
    [mutationOptionsBuilder, hookForm]
  );

  const entryGroupQuery = useGetEntryGroupQuery(entryGroupId, {
    onSuccess: (entryGroup) => {
      if (!isDirty) {
        reset({
          ...getValues(),
          displayName: entryGroup.displayName,
        });
      }
    },
  });

  const entryPointsQuery = useGetEntryPointsQuery(
    propertyOrganizationId as string,
    {
      onSuccess: (entryPoints) => {
        if (!isDirty) {
          reset({
            ...getValues(),
            entryPointIds: entryPoints
              .filter((entryPoint) =>
                entryPoint.entryGroupIds.includes(entryGroupId)
              )
              .map((entryPoint) => entryPoint.entryPointId),
          });
        }
      },
    }
  );

  const onSubmit = useCallback(
    handleSubmit(async (data) => {
      await updateEntryGroupMutation.mutateAsync(data).catch(noop);
    }),
    [handleSubmit]
  );

  const updateEntryGroupMutation = useMutation<
    void,
    HTTPError,
    UpdateEntryGroupValues
  >(async (values: UpdateEntryGroupValues) => {
    await ky.patch(`access-control/entry-group/${entryGroupId}`, {
      json: values,
    });
  }, mutationOptions);

  const validEntryPointIds = useMemo(
    () =>
      (entryPointsQuery.data ?? []).map(
        (entryPoint) => entryPoint.entryPointId
      ),
    [entryPointsQuery.data]
  );

  if (
    entryGroupQuery.isLoading ||
    !entryGroupQuery.isSuccess ||
    entryPointsQuery.isLoading ||
    !entryPointsQuery.isSuccess
  ) {
    return <Loading />;
  }

  return (
    <form onSubmit={onSubmit}>
      <Grid gap={4} width={{ base: "100%", xl: "50%" }}>
        <GridItem>
          <Controller
            name="displayName"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl isRequired isInvalid={!!fieldState.error}>
                <FormLabel htmlFor="displayName">Display Name</FormLabel>
                <Input {...field} id="displayName" placeholder="Display Name" />
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </GridItem>
        <Divider />
        <GridItem>
          <Controller
            name={"entryPointIds"}
            control={control}
            render={({ field, fieldState }) => (
              <FormControl isInvalid={!!fieldState.error}>
                <FormLabel htmlFor="entryPointIds">Entry Points</FormLabel>
                <Stack spacing={5}>
                  {entryPointsQuery.data?.length ? (
                    <CheckboxGroup value={field.value}>
                      {entryPointsQuery.data
                        .sort((a, b) =>
                          a.displayName.localeCompare(b.displayName)
                        )
                        .map((entryPoint) => (
                          <Checkbox
                            key={entryPoint.entryPointId}
                            value={entryPoint.entryPointId}
                            onChange={(event) => {
                              const isChecked = event.target.checked;
                              const value = event.target.value;

                              field.onChange(
                                isChecked
                                  ? intersection(validEntryPointIds, [
                                      ...field.value,
                                      value,
                                    ])
                                  : field.value.filter((id) => id !== value)
                              );
                            }}
                          >
                            {entryPoint.displayName}
                          </Checkbox>
                        ))}
                    </CheckboxGroup>
                  ) : (
                    <Text>No Entry Points</Text>
                  )}
                </Stack>
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </GridItem>
      </Grid>
      {Footer ? (
        <Grid gap={4} mt={4}>
          {!hideFooterDivider ? (
            <GridItem>
              <Divider />
            </GridItem>
          ) : null}
          <GridItem>{Footer({ isSubmitting, onSubmit })}</GridItem>
        </Grid>
      ) : null}
      {children
        ? typeof children === "function"
          ? children({ isSubmitting, onSubmit })
          : children
        : null}
    </form>
  );
};

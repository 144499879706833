import { extendTheme } from "@chakra-ui/react";

export default extendTheme({
  styles: {
    global: {
      body: {
        bg: "gray.50",
      },
    },
  },
  colors: {
    brand: {
      lightGray: {
        50: "#fcfcfc",
        100: "#f9f9f9",
        200: "#f5f5f5",
        300: "#f0f0f0",
        400: "#ededed",
        500: "#eaeaea",
        600: "#e7e7e7",
        700: "#e4e4e4",
        800: "#e1e1e1",
        900: "#dbdbdb",
      },
      darkGray: "#9EAAAD",
      white: "#FFFFFF",
      navy: "#192E33",
      blue: {
        // #367281
        50: "#e1f8fd",
        100: "#c4e3eb",
        200: "#a3cfda",
        300: "#81bcca",
        400: "#60a8ba",
        500: "#478fa1",
        600: "#356f7e",
        700: "#234f5b",
        800: "#0f3138",
        900: "#001217",
      },
      green: "#606C38",
      blueGreen: {
        50: "#65CDCB",
        100: "#56C8C6",
        200: "#46C3C1",
        300: "#3CB9B7",
        400: "#37A9A8",
        500: "#319795",
        600: "#2D8B89",
        700: "#287B7A",
        800: "#236C6B",
        900: "#1E5C5B",
      },
      yellow: "#E8B10C",
      red: {
        50: "#f4e0e0",
        100: "#e3b3b3",
        200: "#d18080",
        300: "#bf4d4d",
        400: "#b12626",
        500: "#a30000",
        600: "#9b0000",
        700: "#910000",
        800: "#880000",
        900: "#770000",
      },
    },
  },
  components: {
    Link: {
      baseStyle: {
        color: "brand.blue.600",
        fontWeight: "medium",
      },
    },
  },
  fonts: {
    heading: "Roboto",
    body: "Roboto",
  },
  fontWeights: {
    hairline: 100,
    thin: 100,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 500,
    bold: 700,
    extrabold: 700,
    black: 900,
  },
});

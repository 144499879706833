import React, { FC, useEffect, useRef, useState } from "react";
import { Box } from "@chakra-ui/react";

import { safeParse } from "src/common/util";

const FOOT_TO_METER = 0.3048;
const CIRCLE_COLOR = "red";

/**
 * This component handles creating the Google Map components for the marker and circle.
 */
export const MapMarker: FC<{
  lat: number | null;
  long: number | null;
  zoom: number;
  radius: number;
}> = ({ zoom, lat, long, radius }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();
  const [marker, setMarker] = useState<google.maps.Marker>();
  const [circle, setCircle] = useState<google.maps.Circle>();

  useEffect(() => {
    if (marker && circle && map) {
      const position = new google.maps.LatLng({
        lat: safeParse(lat),
        lng: safeParse(long),
      });
      marker.setPosition(position);
      circle.setCenter(position);
      circle.setRadius(safeParse(radius) * FOOT_TO_METER);
      map.setCenter(position);
    }
  }, [map, marker, circle, lat, long, radius]);

  useEffect(() => {
    const position = new google.maps.LatLng({
      lat: 0,
      lng: 0,
    });
    if (ref.current && !map) {
      const map = new google.maps.Map(ref.current, {
        center: position,
        zoom,
      });
      setMap(map);
      setMarker(
        new google.maps.Marker({
          map,
          position,
        })
      );
      setCircle(
        new google.maps.Circle({
          strokeColor: CIRCLE_COLOR,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: CIRCLE_COLOR,
          fillOpacity: 0.35,
          map,
          center: position,
          radius: 30 * FOOT_TO_METER,
        })
      );
    }
  }, [ref]);

  return <Box ref={ref} width={300} height={300} id="map" />;
};

import React, { useMemo, useState } from "react";
import {
  Button,
  ButtonGroup,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Portal,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { useQueryClient } from "react-query";
import { HiOutlinePencil, HiOutlineTrash } from "react-icons/hi";
import { HTTPError } from "ky";

import { handleHookFormHTTPError, handleHTTPError } from "src/common/form";
import { ActivateAccessPanelForm } from "src/routes/Property/AccessControl/Panels/components/ActivateAccessPanelForm";
import {
  getGetAccessPanelQueryKey,
  getPropertyAccessPanelQueryKey,
  useGetPropertyAccessPanelQuery,
} from "src/routes/Property/queries";
import { Loading } from "src/common/Loading";
import { EditAccessPanelForm } from "src/routes/Property/AccessControl/Panels/components/EditAccessPanelForm";
import { useDeleteAccessPanel } from "src/routes/Property/hooks";
import { WizardStepFormView } from "src/routes/Property/AccessControl/Wizard/components/WizardStepFormView";

export type AddAccessPanelStepProps = {
  propertyOrganizationId: string;
  navbarPortalRef: React.RefObject<HTMLElement | null>;
  Footer: () => React.ReactElement;
};

export const AddAccessPanelStep = (props: AddAccessPanelStepProps) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const { propertyOrganizationId, Footer, navbarPortalRef } = props;
  const propertyAccessPanelQuery = useGetPropertyAccessPanelQuery(
    propertyOrganizationId
  );

  const [editingAccessPanelId, setEditingAccessPanelId] = useState<string>();
  const accessPanel = useMemo(
    () => propertyAccessPanelQuery.data,
    [propertyAccessPanelQuery.data]
  );

  const {
    isDeletingAccessPanel,
    deleteAccessPanelMutation,
    setDeletingAccessPanelId,
  } = useDeleteAccessPanel({
    onSuccess: () => {
      queryClient.invalidateQueries(
        getPropertyAccessPanelQueryKey(propertyOrganizationId)
      );
      toast({
        description: "Access Panel removed",
        status: "success",
      });
      setDeletingAccessPanelId(undefined);
    },
    onError: async (error: HTTPError) => {
      await handleHTTPError(toast)(error);
      setDeletingAccessPanelId(undefined);
    },
  });

  if (
    propertyAccessPanelQuery.isLoading ||
    !propertyAccessPanelQuery.isSuccess
  ) {
    return <Loading />;
  }

  if (accessPanel && editingAccessPanelId) {
    return (
      <>
        <Stack>
          <Text fontSize="xl" fontWeight={600}>
            Edit Access Panel
          </Text>
        </Stack>
        <WizardStepFormView>
          <EditAccessPanelForm
            accessPanelId={accessPanel.panelId}
            mutationOptionsBuilder={({ setError, getValues }) => ({
              onSuccess: () => {
                toast({
                  description: "Access Panel updated",
                  status: "success",
                });
                queryClient.invalidateQueries(
                  getPropertyAccessPanelQueryKey(propertyOrganizationId)
                );
                queryClient.invalidateQueries(
                  getGetAccessPanelQueryKey(accessPanel.panelId)
                );
                setEditingAccessPanelId(undefined);
              },
              onError: handleHookFormHTTPError(setError, getValues, toast),
            })}
            hideFooterDivider={true}
            Footer={({ isSubmitting, onSubmit }) => (
              <Portal containerRef={navbarPortalRef}>
                <ButtonGroup display="flex" justifyContent="flex-end">
                  <Button onClick={() => setEditingAccessPanelId(undefined)}>
                    Cancel
                  </Button>
                  <Button
                    onClick={() => onSubmit()}
                    colorScheme="brand.blue"
                    isLoading={isSubmitting}
                  >
                    Save
                  </Button>
                </ButtonGroup>
              </Portal>
            )}
          />
        </WizardStepFormView>
      </>
    );
  } else if (accessPanel) {
    return (
      <>
        <Stack>
          <Text fontSize="xl" fontWeight={600}>
            Access Panel
          </Text>
        </Stack>
        <Table>
          <Thead>
            <Tr>
              <Th>Serial Number</Th>
              <Th>IP Address</Th>
              <Th>Port Number</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>{accessPanel.serialNumber}</Td>
              <Td>{accessPanel.dmpProperties?.integratorPort?.ipAddress}</Td>
              <Td>{accessPanel.dmpProperties?.integratorPort?.port}</Td>
              <Td textAlign={"right"}>
                <ButtonGroup>
                  <IconButton
                    onClick={() => setEditingAccessPanelId(accessPanel.panelId)}
                    aria-label="Actions"
                    icon={<HiOutlinePencil />}
                    variant="clear"
                  />
                  <IconButton
                    onClick={() =>
                      setDeletingAccessPanelId(accessPanel.panelId)
                    }
                    aria-label="Actions"
                    icon={<HiOutlineTrash />}
                    variant="clear"
                  />
                </ButtonGroup>
              </Td>
            </Tr>
          </Tbody>
        </Table>
        <Portal containerRef={navbarPortalRef}>{Footer()}</Portal>
        <Modal
          isOpen={isDeletingAccessPanel}
          onClose={() => {
            setDeletingAccessPanelId(undefined);
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Remove Access Panel</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              Are you sure? You can&apos;t undo this action.
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="brand.lightGray"
                color="gray.700"
                mr={3}
                onClick={() => {
                  setDeletingAccessPanelId(undefined);
                }}
              >
                Cancel
              </Button>
              <Button
                colorScheme="brand.red"
                onClick={() => deleteAccessPanelMutation.mutate()}
                isDisabled={deleteAccessPanelMutation.isLoading}
              >
                Remove Access Panel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  } else {
    return (
      <>
        <Stack>
          <Text fontSize="xl" fontWeight={600}>
            Add Access Panel
          </Text>
        </Stack>
        <WizardStepFormView>
          <ActivateAccessPanelForm
            propertyOrganizationId={propertyOrganizationId as string}
            mutationOptionsBuilder={({ setError, getValues }) => ({
              onSuccess: () => {
                toast({
                  description: "Access Panel added",
                  status: "success",
                });
                queryClient.invalidateQueries(
                  getPropertyAccessPanelQueryKey(propertyOrganizationId)
                );
              },
              onError: handleHookFormHTTPError(setError, getValues, toast),
            })}
            hideFooterDivider={true}
            Footer={({ isSubmitting, onSubmit }) => (
              <Portal containerRef={navbarPortalRef}>
                <ButtonGroup display="flex" justifyContent="flex-end">
                  <Button
                    colorScheme="brand.blue"
                    onClick={() => onSubmit()}
                    isLoading={isSubmitting}
                  >
                    Save & Continue
                  </Button>
                </ButtonGroup>
              </Portal>
            )}
          />
        </WizardStepFormView>
      </>
    );
  }
};

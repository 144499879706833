import { IntercomActivation } from "src/common/types";

const DEFAULT_DETAIL = "Unknown";

export const extractIntercomDetails = (
  intercomActivation?: IntercomActivation
) => {
  if (!intercomActivation)
    return { generation: DEFAULT_DETAIL, screenSize: DEFAULT_DETAIL };

  const [generation, screenSize] = intercomActivation?.intercomModel?.split(
    " "
  ) || [DEFAULT_DETAIL, DEFAULT_DETAIL];

  return { generation, screenSize };
};

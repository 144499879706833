export const trimTrailingSlash = (pathname: string) =>
  pathname.replace(/\/$/g, "");

export const pluralize = (
  count: number,
  singular: string,
  plural: string
): string => {
  if (count === 1) {
    return singular;
  }

  return plural;
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = () => {};

/**
 * Simple function that parses a string or number to a float. Will default to 0 if not a number.
 */
export const safeParse = (numberString: string | number | null): number => {
  const number =
    typeof numberString === "string" ? parseFloat(numberString) : numberString;
  return number === null || isNaN(number) ? 0 : number;
};

export function omit<T extends object, K extends keyof T>(
  obj: T,
  paths: K[]
): Omit<T, K> {
  return {
    ...paths.reduce(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (mem, key) => ((k: K, { [k]: ignored, ...rest }) => rest)(key, mem),
      obj as object
    ),
  } as Omit<T, K>;
}

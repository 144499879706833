import React, { useEffect, useMemo } from "react";
import {
  useParams,
  Outlet,
  useOutletContext,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { Loading } from "src/common/Loading";
import { IntercomActivation, IntercomGeneration } from "src/common/types";
import { extractIntercomDetails } from "src/routes/IntercomUnits/IntercomActivation/util";

export const IntercomActivationLoader = () => {
  const { dealerOrganizationId, propertyOrganizationId, intercomActivationId } =
    useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { intercomActivations } =
    useOutletContext<{ intercomActivations: IntercomActivation[] }>();

  const currentActivation = useMemo(() => {
    if (intercomActivations.length && intercomActivationId) {
      return intercomActivations.find(
        (activation) => activation.intercomActivationId === intercomActivationId
      );
    }
  }, [intercomActivationId, intercomActivations]);

  if (intercomActivationId && !currentActivation) {
    return <Loading />;
  }

  const { generation } = extractIntercomDetails(currentActivation);

  useEffect(() => {
    if (!currentActivation) {
      return;
    }

    if (!location.pathname.match(new RegExp(`${intercomActivationId}$`))) {
      return;
    }

    const path = generation === IntercomGeneration.GEN_4 ? "details" : "edit";
    navigate(
      `/dealer-organizations/${dealerOrganizationId}/properties/${propertyOrganizationId}/intercoms/${intercomActivationId}/${path}`
    );
  }, [currentActivation, location.pathname, generation]);

  return <Outlet context={{ currentActivation }} />;
};

import React from "react";
import {
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import {
  HiOutlinePencil,
  HiOutlineTrash,
  HiOutlineDotsVertical,
} from "react-icons/hi";
import { useAuth0 } from "@auth0/auth0-react";

import { PageContent } from "src/layout/PageContent";
import { PageHeader, PageHeaderTitle } from "src/layout/PageHeader";
import { Loading } from "src/common/Loading";
import {
  useDeleteDealerUser,
  useResetDealerUserPassword,
} from "src/routes/DealerStaffList/hooks";
import { ResetPassword } from "src/common/icons/ResetPassword";
import { StaffFilterBar } from "src/routes/DealerStaffList/StaffFilterBar";
import { useDealerStaffUsersQuery } from "src/common/queries";
import { useIsAdmin } from "src/common/hooks/useIsAdmin";
import { formatPhoneNumber } from "src/common/phone-numbers";

export const DealerStaffList = () => {
  const { dealerOrganizationId } = useParams();
  const { user } = useAuth0();
  const isAdmin = useIsAdmin();
  const navigate = useNavigate();

  const {
    isDeletingDealerUser,
    deleteDealerUserMutation,
    setDeletingDealerUserId,
  } = useDeleteDealerUser();
  const {
    isResettingDealerUser,
    resetDealerUserPasswordMutation,
    setResettingDealerUserId,
  } = useResetDealerUserPassword();

  const query = useDealerStaffUsersQuery(dealerOrganizationId);

  if (query.isLoading || !query.data) {
    return <Loading />;
  }

  if (query.isError) {
    return <>Error</>;
  }

  const dealerUsers = query.data || [];

  return (
    <>
      <PageHeader buttons={<StaffFilterBar />}>
        <PageHeaderTitle>Staff Members</PageHeaderTitle>
      </PageHeader>
      <PageContent>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Phone</Th>
              <Th>Email</Th>
              <Th>Role</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {dealerUsers.map((dealerUser) => (
              <Tr key={dealerUser.dealerUserId}>
                <Td>
                  <Text>
                    {dealerUser.user.firstName} {dealerUser.user.lastName}
                  </Text>
                </Td>
                <Td>{formatPhoneNumber(dealerUser.user.phone)}</Td>
                <Td>{dealerUser.user.email}</Td>
                <Td>
                  {dealerUser.role.substring(0, 1).toUpperCase() +
                    dealerUser.role.substring(1).toLowerCase()}
                </Td>
                <Td>
                  {isAdmin && (
                    <Menu>
                      <MenuButton
                        as={IconButton}
                        aria-label="Actions"
                        icon={<HiOutlineDotsVertical />}
                        variant="clear"
                      >
                        Actions
                      </MenuButton>
                      <MenuList>
                        <MenuItem
                          justifyContent="space-between"
                          onClick={() => {
                            navigate(
                              `/dealer-organizations/${dealerOrganizationId}/staff/${dealerUser.dealerUserId}/edit`
                            );
                          }}
                        >
                          Edit Staff Member
                          <HiOutlinePencil />
                        </MenuItem>
                        <MenuItem
                          isDisabled={user?.sub === dealerUser.user.idpUserId}
                          justifyContent="space-between"
                          onClick={() => {
                            setResettingDealerUserId(dealerUser.dealerUserId);
                          }}
                        >
                          Reset Password
                          <ResetPassword />
                        </MenuItem>
                        <MenuItem
                          isDisabled={user?.sub === dealerUser.user.idpUserId}
                          justifyContent="space-between"
                          onClick={() => {
                            setDeletingDealerUserId(dealerUser.dealerUserId);
                          }}
                        >
                          Delete Staff Member
                          <HiOutlineTrash />
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </PageContent>

      <Modal
        isOpen={isDeletingDealerUser}
        onClose={() => {
          setDeletingDealerUserId(undefined);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Staff Member</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure? You can&apos;t undo this action afterwards.
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="brand.lightGray"
              color="gray.700"
              mr={3}
              onClick={() => {
                setDeletingDealerUserId(undefined);
              }}
            >
              Close
            </Button>
            <Button
              colorScheme="brand.red"
              onClick={() => deleteDealerUserMutation.mutate()}
              disabled={deleteDealerUserMutation.isLoading}
            >
              Accept
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isResettingDealerUser}
        onClose={() => {
          setResettingDealerUserId(undefined);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Reset Staff Password</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure? This will immediately email the user a link to change
            their password.
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="brand.lightGray"
              color="gray.700"
              mr={3}
              onClick={() => {
                setResettingDealerUserId(undefined);
              }}
            >
              Close
            </Button>
            <Button
              colorScheme="brand.red"
              onClick={() => resetDealerUserPasswordMutation.mutate()}
              isDisabled={resetDealerUserPasswordMutation.isLoading}
            >
              {resetDealerUserPasswordMutation.isLoading
                ? "Resetting..."
                : "Accept"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

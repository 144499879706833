import React, { useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useMutation } from "react-query";

import { IntercomActivation } from "src/common/types";
import { HTTPError, useKy } from "src/common/ky";
import { noop } from "src/common/util";

interface IntercomSupportButtonsProps {
  intercomActivation: IntercomActivation;
}

type RestartIntercomVariables = {
  intercomActivationId: string;
};

export const IntercomSupportButtons: React.FC<IntercomSupportButtonsProps> = ({
  intercomActivation,
}) => {
  const toast = useToast();
  const ky = useKy();
  const [openModalName, setOpenModalName] = useState<
    "restart" | "power-cycle" | null
  >(null);
  const {
    isOpen: modalIsOpen,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();
  const restartIntercom = useMutation<
    void,
    HTTPError,
    RestartIntercomVariables
  >(
    async ({ intercomActivationId }) => {
      await ky.post(
        `product-unit-activations/${intercomActivationId}/reboot`,
        {}
      );
    },
    {
      onSuccess: () => {
        onCloseModal();
        setOpenModalName(null);
        toast({
          description: "Intercom is restarting.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      },
    }
  );
  const powerCycleIntercom = useMutation<
    void,
    HTTPError,
    RestartIntercomVariables
  >(
    async ({ intercomActivationId }) => {
      await ky.post(
        `product-unit-activations/${intercomActivationId}/power-cycle`,
        {}
      );
    },
    {
      onSuccess: () => {
        onCloseModal();
        setOpenModalName(null);
        toast({
          description: "Intercom is power cycling.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      },
    }
  );
  const isMutating = restartIntercom.isLoading || powerCycleIntercom.isLoading;
  return (
    <Box position="relative">
      <Modal
        isOpen={modalIsOpen}
        onClose={() => {
          onCloseModal();
          setOpenModalName(null);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{`${
            openModalName === "restart" ? "Restart" : "Power Cycle"
          } ${intercomActivation.name}`}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {openModalName === "restart"
              ? "Restarting can take several minutes. Are you sure you want to restart?"
              : "Are you sure you want to power cycle?"}
          </ModalBody>
          <ModalFooter>
            <Button
              color="black"
              colorScheme="brand.lightGray"
              mr={3}
              onClick={() => {
                onCloseModal();
                setOpenModalName(null);
              }}
            >
              Cancel
            </Button>
            <Button
              colorScheme="brand.red"
              onClick={() => {
                const mutation =
                  openModalName === "restart"
                    ? restartIntercom
                    : powerCycleIntercom;
                return mutation
                  .mutateAsync({
                    intercomActivationId:
                      intercomActivation.intercomActivationId,
                  })
                  .catch(noop);
              }}
              isDisabled={isMutating}
            >
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <ButtonGroup position="absolute" right="0">
        <Button
          colorScheme="brand.blue"
          onClick={() => {
            onOpenModal();
            setOpenModalName("restart");
          }}
        >
          Software Restart
        </Button>
        <Button
          colorScheme="brand.blueGreen"
          onClick={() => {
            onOpenModal();
            setOpenModalName("power-cycle");
          }}
        >
          Power Cycle
        </Button>
      </ButtonGroup>
    </Box>
  );
};

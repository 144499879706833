import { useQuery, UseQueryOptions } from "react-query";
import { useLocation, useParams } from "react-router-dom";

import { HTTPError, KyOptions, useKy } from "src/common/ky";
import {
  AccessProfile,
  EntryGroup,
  EntryPoint,
  EntrySchedule,
  LockUnlockSchedule,
  Panel,
  PropertyOrganization,
  PropertyUser,
  AccessCredential,
} from "src/common/types";

export const getPropertyOrganizationStaffListQueryKey = (
  propertyOrganizationId: string
) => {
  return ["ALL_PROPERTY_STAFF_QUERY", propertyOrganizationId];
};

export const useAllPropertyStaffForDealerAdminQuery = () => {
  const { propertyOrganizationId } = useParams();
  const ky = useKy();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  if (propertyOrganizationId) {
    searchParams.set("propertyOrganizationId", propertyOrganizationId);
  }

  searchParams.sort();

  return useQuery<PropertyUser[]>(
    [
      getPropertyOrganizationStaffListQueryKey(
        propertyOrganizationId as string
      ),
      searchParams.toString(),
    ],
    () =>
      ky
        .get(`property-users`, {
          searchParams,
        })
        .json<PropertyUser[]>()
  );
};

export const getPropertyOrganizationQueryKey = (
  propertyOrganizationId: string | undefined,
  dealerOrganizationId: string | undefined
) => {
  return [
    "PROPERTY_ORGANIZATION",
    propertyOrganizationId,
    dealerOrganizationId,
  ];
};

export const usePropertyOrganizationQuery = (
  params: { dealerOrganizationId: string; propertyOrganizationId: string },
  config?: {
    kyOptions?: KyOptions;
  }
) => {
  const { dealerOrganizationId, propertyOrganizationId } = params;
  const ky = useKy(config?.kyOptions);

  return useQuery<PropertyOrganization, HTTPError>(
    getPropertyOrganizationQueryKey(
      propertyOrganizationId,
      dealerOrganizationId
    ),
    () =>
      ky
        .get(
          `property-organizations/${propertyOrganizationId}/dealer-organization/${dealerOrganizationId}`
        )
        .json<PropertyOrganization>()
  );
};

export const getPropertyUserQueryKey = (propertyUserId: string) => {
  return ["GET_USER_QUERY_KEY", propertyUserId];
};

export const useGetPropertyUserQuery = (
  getUserOnSuccess: (propertyUser: PropertyUser) => void
) => {
  const { propertyUserId } = useParams();
  const ky = useKy();

  return useQuery<PropertyUser, HTTPError>(
    getPropertyUserQueryKey(propertyUserId as string),
    () => ky.get(`property-users/${propertyUserId}`).json<PropertyUser>(),
    {
      onSuccess: getUserOnSuccess,
    }
  );
};

export const getPropertyAccessPanelQueryKey = (
  propertyOrganizationId: string | undefined
) => {
  return ["PROPERTY_ACCESS_PANEL_QUERY", propertyOrganizationId];
};

export const useGetPropertyAccessPanelQuery = (
  propertyOrganizationId: string,
  config?: {
    kyOptions?: KyOptions;
    queryOptions?: UseQueryOptions<Panel | null, HTTPError>;
  }
) => {
  const ky = useKy(config?.kyOptions);

  return useQuery<Panel | null, HTTPError>(
    getPropertyAccessPanelQueryKey(propertyOrganizationId),
    async () =>
      await ky
        .get(
          `access-control/panel/property-organization/${propertyOrganizationId}`
        )
        .json<Panel | null>(),
    config?.queryOptions
  );
};

export const getGetAccessPanelQueryKey = (accessPanelId: string) => [
  "GET_ACCESS_PANEL_QUERY_KEY",
  accessPanelId,
];

export const useGetAccessPanelQuery = (
  accessPanelId: string,
  options?: UseQueryOptions<Panel, HTTPError>
) => {
  const ky = useKy();

  return useQuery<Panel, HTTPError>(
    getGetAccessPanelQueryKey(accessPanelId as string),
    async () =>
      await ky.get(`access-control/panel/${accessPanelId}`).json<Panel>(),
    options
  );
};

export const getAccessProfilesQueryKey = (propertyOrganizationId: string) => {
  return ["ACCESS_PROFILES", propertyOrganizationId];
};

export const useGetAccessProfilesQuery = (propertyOrganizationId: string) => {
  const ky = useKy();
  const searchParams = new URLSearchParams();

  if (propertyOrganizationId) {
    searchParams.set("propertyOrganizationId", propertyOrganizationId);
  }

  return useQuery(getAccessProfilesQueryKey(propertyOrganizationId), () =>
    ky.get(`access-control/profile`, { searchParams }).json<AccessProfile[]>()
  );
};

export const getEntryGroupsQueryKey = (propertyOrganizationId: string) => [
  "GET_ENTRY_GROUP_QUERY",
  propertyOrganizationId,
];

export const useGetEntryGroupsQuery = (propertyOrganizationId: string) => {
  const ky = useKy();

  return useQuery(
    getEntryGroupsQueryKey(propertyOrganizationId as string),
    async () =>
      await ky
        .get(
          `access-control/entry-group/property-organization/${propertyOrganizationId}`
        )
        .json<EntryGroup[]>()
  );
};

export const getEntryGroupQueryKey = (entryGroupId: string | undefined) => [
  "GET_ENTRY_GROUP_QUERY_KEY",
  entryGroupId,
];

export const useGetEntryGroupQuery = (
  entryGroupId: string,
  options?: UseQueryOptions<EntryGroup, HTTPError>
) => {
  const ky = useKy();

  return useQuery<EntryGroup, HTTPError>(
    getEntryGroupQueryKey(entryGroupId),
    async () =>
      await ky
        .get(`access-control/entry-group/${entryGroupId}`)
        .json<EntryGroup>(),
    options
  );
};

export const getEntrySchedulesQueryKey = (propertyOrganizationId: string) => [
  "GET_ENTRY_SCHEDULE_QUERY",
  propertyOrganizationId,
];

export const useGetEntrySchedulesQuery = (propertyOrganizationId: string) => {
  const ky = useKy();

  return useQuery(
    getEntrySchedulesQueryKey(propertyOrganizationId as string),
    async () =>
      await ky
        .get(
          `access-control/entry-schedule/property-organization/${propertyOrganizationId}`
        )
        .json<EntrySchedule[]>()
  );
};

export const getEntryScheduleQueryKey = (entryScheduleId: string) => [
  "GET_ENTRY_SCHEDULE_QUERY_KEY",
  entryScheduleId,
];

export const useGetEntryScheduleQuery = (
  entryScheduleId: string,
  options?: UseQueryOptions<EntrySchedule, HTTPError>
) => {
  const ky = useKy();

  return useQuery<EntrySchedule, HTTPError>(
    getEntryScheduleQueryKey(entryScheduleId as string),
    async () =>
      await ky
        .get(`access-control/entry-schedule/${entryScheduleId}`)
        .json<EntrySchedule>(),
    options
  );
};

export const getLockUnlockSchedulesQueryKey = (
  propertyOrganizationId: string
) => ["GET_LOCK_UNLOCK_SCHEDULE_QUERY", propertyOrganizationId];

export const useGetLockUnlockSchedulesQuery = (
  propertyOrganizationId: string
) => {
  const ky = useKy();

  return useQuery(
    getLockUnlockSchedulesQueryKey(propertyOrganizationId as string),
    async () =>
      await ky
        .get(
          `access-control/lock-unlock-schedule/property-organization/${propertyOrganizationId}`
        )
        .json<LockUnlockSchedule[]>()
  );
};

export const getLockUnlockScheduleQueryKey = (entryPointId: string) => [
  "GET_LOCK_UNLOCK_SCHEDULE_QUERY_KEY",
  entryPointId,
];

export const useGetLockUnlockScheduleQuery = (
  entryPointId: string,
  options?: UseQueryOptions<LockUnlockSchedule, HTTPError>
) => {
  const ky = useKy();

  return useQuery<LockUnlockSchedule, HTTPError>(
    getLockUnlockScheduleQueryKey(entryPointId as string),
    async () =>
      await ky
        .get(`access-control/lock-unlock-schedule/${entryPointId}`)
        .json<LockUnlockSchedule>(),
    options
  );
};

export const getEntryPointsQueryKey = (propertyOrganizationId: string) => [
  "GET_ENTRY_POINTS_QUERY",
  propertyOrganizationId,
];

export const useGetEntryPointsQuery = (
  propertyOrganizationId: string,
  options?: UseQueryOptions<EntryPoint[], HTTPError>
) => {
  const ky = useKy();

  return useQuery<EntryPoint[], HTTPError>(
    getEntryPointsQueryKey(propertyOrganizationId as string),
    async () =>
      await ky
        .get(
          `access-control/entry-point/property-organization/${propertyOrganizationId}`
        )
        .json<EntryPoint[]>(),
    options
  );
};

export const getEntryPointQueryKey = (entryPointId: string) => [
  "GET_ENTRY_POINT_QUERY_KEY",
  entryPointId,
];

export const useGetEntryPointByIdQuery = (
  entryPointId: string,
  options?: UseQueryOptions<EntryPoint, HTTPError>
) => {
  const ky = useKy();

  return useQuery<EntryPoint, HTTPError>(
    getEntryPointQueryKey(entryPointId as string),
    async () =>
      await ky
        .get(`access-control/entry-point/${entryPointId}`)
        .json<EntryPoint>(),
    options
  );
};

export const getAccessProfilesByPropertyQueryKey = () => {
  return ["ACCESS_PROFILES_BY_PROPERTY"];
};

export const useGetAccessProfilesByPropertyQuery = () => {
  const { propertyOrganizationId } = useParams();
  const searchParams = new URLSearchParams();
  const ky = useKy();

  if (propertyOrganizationId) {
    searchParams.set("propertyOrganizationId", propertyOrganizationId);
  }

  return useQuery(
    getAccessProfilesByPropertyQueryKey(),
    async () =>
      await ky
        .get(`access-control/profile`, { searchParams })
        .json<AccessProfile[]>()
  );
};

export const getAccessProfileQueryKey = (profileId: string) => [
  "GET_ACCESS_PROFILE_QUERY_KEY",
  profileId,
];
export const useGetAccessProfileQuery = (
  accessProfileId: string,
  options?: UseQueryOptions<AccessProfile, HTTPError>
) => {
  const ky = useKy();

  return useQuery<AccessProfile, HTTPError>(
    getAccessProfileQueryKey(accessProfileId as string),
    async () =>
      await ky
        .get(`access-control/profile/${accessProfileId}`)
        .json<AccessProfile>(),
    options
  );
};

export const useGetSettingsQuery = (propertyOrganizationId: string) => {
  const ky = useKy();

  return useQuery(
    getSettingsQueryKey(),
    async () =>
      await ky.get(`access-control/settings/${propertyOrganizationId}`).json<{
        lockdownCredentials: AccessCredential[];
        alarmResetCredentials: AccessCredential[];
        combinedCredentials: AccessCredential[];
      }>()
  );
};

export const getSettingsQueryKey = () => ["SETTINGS_BY_PROPERTY"];

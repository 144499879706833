import React, { ReactNode } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { Container, Link } from "@chakra-ui/react";

import { IntercomGeneration } from "src/common/types";
import { PageContent } from "src/layout/PageContent";
import {
  PageHeader,
  PageHeaderTitle,
  PageHeaderTabs,
  PageHeaderSubtitle,
} from "src/layout/PageHeader";
import { TabLink } from "src/layout/TabLink";
import useIntercomDetails from "src/routes/IntercomUnits/IntercomActivation/useIntercomDetails";

type IntercomActivationLayoutProps = {
  children?: React.ReactNode;
  title: string;
  additionalContent?: React.ReactNode;
  includeCard?: boolean;
  subtitle?: string;
};

export const IntercomActivationLayout = ({
  children,
  title,
  additionalContent,
  includeCard = true,
  subtitle,
}: IntercomActivationLayoutProps) => {
  const { dealerOrganizationId, propertyOrganizationId, intercomActivationId } =
    useParams();
  const basePath = `/dealer-organizations/${dealerOrganizationId}/properties/${propertyOrganizationId}/intercoms/${intercomActivationId}`;
  const { generation } = useIntercomDetails();
  const isGen4Plus = generation === IntercomGeneration.GEN_4;

  const Content = includeCard
    ? PageContent
    : ({ children }: { children: ReactNode }) => (
        <Container as="main" maxW="7xl" pb={20}>
          {children}
        </Container>
      );

  return (
    <>
      <PageHeader>
        <Link
          as={RouterLink}
          to={basePath.substring(0, basePath.lastIndexOf("/"))}
        >
          &#8249; Back to list
        </Link>
        <PageHeaderTitle>{title}</PageHeaderTitle>
        {subtitle ? <PageHeaderSubtitle>{subtitle}</PageHeaderSubtitle> : null}
        <PageHeaderTabs>
          <TabLink
            key="details"
            to={`${basePath}/details`}
            label="Details"
            end
          />
          {isGen4Plus && (
            <TabLink
              key="system"
              to={`${basePath}/system`}
              label="System"
              end
            />
          )}
          <TabLink
            key="cell-kit"
            to={`${basePath}/cell-kit`}
            label="Cell Upgrade Kit"
            end
          />
          <TabLink
            key="edit"
            to={`${basePath}/edit`}
            label="Virtual Intercom"
            end
          />
        </PageHeaderTabs>
      </PageHeader>
      {additionalContent ? additionalContent : null}
      {children ? <Content>{children}</Content> : null}
    </>
  );
};

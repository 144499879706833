import React from "react";
import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  Link,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery, useQueryClient } from "react-query";

import {
  PageHeader,
  PageHeaderBackLink,
  PageHeaderSubtitle,
  PageHeaderTitle,
} from "src/layout/PageHeader";
import { PageContent } from "src/layout/PageContent";
import { HTTPError, useKy } from "src/common/ky";
import { states } from "src/common/us-states";
import { getPropertyOrganizationsQueryKey } from "src/common/queries";
import { PropertyOrganization } from "src/common/types";
import { Loading } from "src/common/Loading";
import { PROPERTY_ORGANIZATION_QUERY_KEY } from "src/routes/Property";
import { noop } from "src/common/util";
import { handleHookFormHTTPError } from "src/common/form";
import { PhoneNumberInput } from "src/common/PhoneNumbers";
import { YupPossiblePhoneNumber } from "src/common/phone-numbers";

interface EditPropertyOrganizationValues {
  name: string;
  phone: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  email: string;
  ownerFirstName: string;
  ownerLastName: string;
  ownerAddress: string;
  ownerCity: string;
  ownerState: string;
  ownerZip: string;
  ownerPhone: string;
  ownerEmail: string;
  dealerOrganizationId: string;
}

const EditPropertyOrganizationValidationSchema = Yup.object().shape({
  name: Yup.string().required().label("Property Name"),
  phone: YupPossiblePhoneNumber({
    label: "Property Phone Number",
    required: true,
  }),
  address: Yup.string().required().label("Property Address"),
  city: Yup.string().required().label("Property City"),
  state: Yup.string().required().label("Property State"),
  zip: Yup.string().min(5).required().label("Property Zip Code"),
  email: Yup.string().email().required().label("Property Email"),
  ownerFirstName: Yup.string().required().label("Property Owner First Name"),
  ownerLastName: Yup.string().required().label("Property Owner Last Name"),
  ownerAddress: Yup.string().required().label("Property Owner Address"),
  ownerCity: Yup.string().required().label("Property Owner City"),
  ownerState: Yup.string().required().label("Property Owner State"),
  ownerZip: Yup.string().min(5).required().label("Property Owner Zip Code"),
  ownerPhone: YupPossiblePhoneNumber({
    label: "Property Owner Phone",
    required: true,
  }),
  ownerEmail: Yup.string().email().required().label("Property Owner Email"),
});

export const EditPropertyOrganization = () => {
  const { dealerOrganizationId, propertyOrganizationId } = useParams();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty },
    reset,
    setError,
    getValues,
  } = useForm<EditPropertyOrganizationValues>({
    defaultValues: {
      dealerOrganizationId,
      name: "",
      phone: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      email: "",
      ownerFirstName: "",
      ownerLastName: "",
      ownerAddress: "",
      ownerCity: "",
      ownerState: "",
      ownerZip: "",
      ownerPhone: "",
      ownerEmail: "",
    },
    resolver: yupResolver(EditPropertyOrganizationValidationSchema),
  });

  const navigate = useNavigate();
  const toast = useToast();
  const ky = useKy();
  const queryClient = useQueryClient();

  const responsiveWidths = ["100%", "100%", "40%", "40%"];

  const showSuccessToastAndNavigateToPropertiesList = async () => {
    await queryClient.invalidateQueries(
      getPropertyOrganizationsQueryKey(dealerOrganizationId)
    );
    navigate(`/dealer-organizations/${dealerOrganizationId}/properties`);
  };

  const updatePropertyOrganization = useMutation<
    void,
    HTTPError,
    EditPropertyOrganizationValues
  >(
    async (values: EditPropertyOrganizationValues) => {
      await ky.patch(
        `property-organizations/${propertyOrganizationId}/dealer-organization/${dealerOrganizationId}`,
        {
          json: values,
        }
      );
    },
    {
      onSuccess: () => {
        navigate(
          `/dealer-organizations/${dealerOrganizationId}/properties/${propertyOrganizationId}`
        );
        toast({
          description: "Property Successfully Updated",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        queryClient.invalidateQueries(PROPERTY_ORGANIZATION_QUERY_KEY);
      },
      onError: handleHookFormHTTPError(setError, getValues, toast),
    }
  );

  const getPropertyOrganizationQuery = useQuery<
    PropertyOrganization,
    HTTPError
  >(
    [
      PROPERTY_ORGANIZATION_QUERY_KEY,
      propertyOrganizationId,
      dealerOrganizationId,
    ],
    () =>
      ky
        .get(
          `property-organizations/${propertyOrganizationId}/dealer-organization/${dealerOrganizationId}`
        )
        .json<PropertyOrganization>(),
    {
      onSuccess: (data) => {
        if (!isDirty) {
          reset({
            name: data.name || "",
            phone: data.phone || "",
            address: data.address || "",
            city: data.city || "",
            state: data.state || "",
            zip: data.zip || "",
            email: data.email || "",
            ownerFirstName: data.ownerFirstName || "",
            ownerLastName: data.ownerLastName || "",
            ownerAddress: data.ownerAddress || "",
            ownerCity: data.ownerCity || "",
            ownerState: data.ownerState || "",
            ownerZip: data.ownerZip || "",
            ownerPhone: data.ownerPhone || "",
            ownerEmail: data.ownerEmail || "",
          });
        }
      },
    }
  );

  if (
    getPropertyOrganizationQuery.isLoading ||
    !getPropertyOrganizationQuery.isSuccess
  ) {
    return <Loading />;
  }

  return (
    <>
      <PageHeader>
        <PageHeaderBackLink>
          <Link
            as={RouterLink}
            to={`/dealer-organizations/${dealerOrganizationId}/properties`}
          >
            &#8249; Back to list
          </Link>
        </PageHeaderBackLink>
        <PageHeaderTitle>Edit Property Organization</PageHeaderTitle>
        <PageHeaderSubtitle>
          Enter the new property details here
        </PageHeaderSubtitle>
      </PageHeader>
      <PageContent>
        <form
          onSubmit={handleSubmit((values) =>
            updatePropertyOrganization
              .mutateAsync(values, {
                onSuccess: showSuccessToastAndNavigateToPropertiesList,
                onError: async (error) => {
                  if (error.response.status < 500) {
                    const body: {
                      error: keyof EditPropertyOrganizationValues;
                      message: string;
                    } = await error.response.json();
                    setError(body.error, {
                      message: body.message,
                    });
                  }
                },
              })
              .catch(noop)
          )}
        >
          <Grid gap={4}>
            <GridItem>
              <Heading as={"h3"} fontSize="24px" mt={4} mb={4}>
                Property Organization Details
              </Heading>
            </GridItem>

            <GridItem>
              <Divider />
            </GridItem>

            <GridItem>
              <Controller
                name="name"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl
                    isRequired
                    isInvalid={fieldState.invalid && fieldState.isTouched}
                  >
                    <FormLabel htmlFor="name">Property Name</FormLabel>
                    <Input width={responsiveWidths} {...field} id="name" />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>

            <GridItem>
              <Controller
                name="phone"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl
                    isRequired
                    isInvalid={fieldState.invalid && fieldState.isTouched}
                  >
                    <FormLabel htmlFor="phone">Property Phone</FormLabel>
                    <PhoneNumberInput
                      width={responsiveWidths}
                      {...field}
                      id="phone"
                    />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>

            <GridItem>
              <Controller
                name="address"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl
                    isRequired
                    isInvalid={fieldState.invalid && fieldState.isTouched}
                  >
                    <FormLabel htmlFor="address">Property Address</FormLabel>
                    <Input width={responsiveWidths} {...field} id="address" />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>

            <GridItem>
              <Controller
                name="city"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl
                    isRequired
                    isInvalid={fieldState.invalid && fieldState.isTouched}
                  >
                    <FormLabel htmlFor="city">Property City</FormLabel>
                    <Input width={responsiveWidths} {...field} id="city" />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>

            <GridItem>
              <Controller
                name="state"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl
                    isRequired
                    isInvalid={fieldState.invalid && fieldState.isTouched}
                  >
                    <FormLabel htmlFor="state">Property State</FormLabel>
                    <Select
                      placeholder="- Select State -"
                      width={responsiveWidths}
                      {...field}
                      id="state"
                    >
                      {states.map((state) => (
                        <option
                          key={state.abbreviation}
                          value={state.abbreviation}
                        >
                          {state.name}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>

            <GridItem>
              <Controller
                name="zip"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl
                    isRequired
                    isInvalid={fieldState.invalid && fieldState.isTouched}
                  >
                    <FormLabel htmlFor="zip">Property ZIP Code</FormLabel>
                    <Input width={responsiveWidths} {...field} id="zip" />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>

            <GridItem>
              <Controller
                name="email"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl
                    isRequired
                    isInvalid={fieldState.invalid && fieldState.isTouched}
                  >
                    <FormLabel htmlFor="email">Property Email</FormLabel>
                    <Input width={responsiveWidths} {...field} id="email" />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>

            <GridItem mt={12}>
              <Heading as={"h3"} fontSize="24px" mb={4}>
                Property Owner Contact Information
              </Heading>
              <Text color="gray.500" fontWeight="medium" fontSize="md">
                {"Please enter the Property Owner's Contact information."}
              </Text>
            </GridItem>

            <GridItem>
              <Controller
                name="ownerFirstName"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl
                    isRequired
                    isInvalid={fieldState.invalid && fieldState.isTouched}
                  >
                    <FormLabel htmlFor="ownerFirstName">
                      Property Owner First Name
                    </FormLabel>
                    <Input
                      width={responsiveWidths}
                      {...field}
                      id="ownerFirstName"
                    />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>

            <GridItem>
              <Controller
                name="ownerLastName"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl
                    isRequired
                    isInvalid={fieldState.invalid && fieldState.isTouched}
                  >
                    <FormLabel htmlFor="ownerLastName">
                      Property Owner Last Name
                    </FormLabel>
                    <Input
                      width={responsiveWidths}
                      {...field}
                      id="ownerLastName"
                    />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>

            <GridItem>
              <Controller
                name="ownerAddress"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl
                    isRequired
                    isInvalid={fieldState.invalid && fieldState.isTouched}
                  >
                    <FormLabel htmlFor="ownerAddress">
                      Property Owner Billing Address
                    </FormLabel>
                    <Input
                      width={responsiveWidths}
                      {...field}
                      id="ownerAddress"
                    />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>

            <GridItem>
              <Controller
                name="ownerCity"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl
                    isRequired
                    isInvalid={fieldState.invalid && fieldState.isTouched}
                  >
                    <FormLabel htmlFor="ownerCity">
                      Property Owner City
                    </FormLabel>
                    <Input width={responsiveWidths} {...field} id="ownerCity" />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>

            <GridItem>
              <Controller
                name="ownerState"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl
                    isRequired
                    isInvalid={fieldState.invalid && fieldState.isTouched}
                  >
                    <FormLabel htmlFor="ownerState">
                      Property Owner State
                    </FormLabel>
                    <Select
                      placeholder="- Select State -"
                      width={responsiveWidths}
                      {...field}
                      id="ownerState"
                    >
                      {states.map((state) => (
                        <option
                          key={state.abbreviation}
                          value={state.abbreviation}
                        >
                          {state.name}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>

            <GridItem>
              <Controller
                name="ownerZip"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl
                    isRequired
                    isInvalid={fieldState.invalid && fieldState.isTouched}
                  >
                    <FormLabel htmlFor="ownerZip">
                      Property Owner ZIP Code
                    </FormLabel>
                    <Input width={responsiveWidths} {...field} id="ownerZip" />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>

            <GridItem>
              <Controller
                name="ownerEmail"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl
                    isRequired
                    isInvalid={fieldState.invalid && fieldState.isTouched}
                  >
                    <FormLabel htmlFor="ownerEmail">
                      Property Owner Email
                    </FormLabel>
                    <Input
                      width={responsiveWidths}
                      {...field}
                      id="ownerEmail"
                    />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>

            <GridItem>
              <Controller
                name="ownerPhone"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl
                    isRequired
                    isInvalid={fieldState.invalid && fieldState.isTouched}
                  >
                    <FormLabel htmlFor="ownerPhone">
                      Property Owner Phone Number
                    </FormLabel>
                    <PhoneNumberInput
                      width={responsiveWidths}
                      {...field}
                      id="ownerPhone"
                    />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>

            {updatePropertyOrganization.error && (
              <GridItem>
                <Alert status="error">
                  <AlertIcon />
                  {updatePropertyOrganization.error.message}
                </Alert>
              </GridItem>
            )}

            <GridItem>
              <Divider />
            </GridItem>

            <GridItem>
              <ButtonGroup display="flex" justifyContent="flex-end">
                <Link
                  as={RouterLink}
                  to={`/dealer-organizations/${dealerOrganizationId}/properties`}
                >
                  <Button>Cancel</Button>
                </Link>
                <Button
                  type="submit"
                  colorScheme="brand.blue"
                  isLoading={isSubmitting}
                >
                  Save Property
                </Button>
              </ButtonGroup>
            </GridItem>
          </Grid>
        </form>
      </PageContent>
    </>
  );
};

import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Navigate, Route, Routes } from "react-router-dom";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { useAuth0 } from "@auth0/auth0-react";

import { EditLockUnlockSchedule } from "src/routes/Property/AccessControl/EntryPoints/EditLockUnlockSchedule";
import { EditEntryPoint } from "src/routes/Property/AccessControl/EntryPoints/EditEntryPoint";
import { AddEntryPoint } from "src/routes/Property/AccessControl/EntryPoints/AddEntryPoint";
import { AuthGate } from "src/common/AuthGate";
import { DealerOrganizationLayout } from "src/layout/DealerOrganizationLayout";
import { Financials } from "src/routes/Financials";
import { FinancialsEdit } from "src/routes/FinancialsEdit";
import { Profile } from "src/routes/Profile";
import { Property } from "src/routes/Property";
import { Intercoms } from "src/routes/IntercomUnits/Intercoms";
import { IntercomAdd } from "src/routes/Property/IntercomAdd";
import { IntercomEdit } from "src/routes/IntercomUnits/IntercomActivation/IntercomEdit/IntercomEdit";
import { IntercomDetails } from "src/routes/IntercomUnits/IntercomActivation/IntercomDetails/IntercomDetails";
import { Properties } from "src/routes/Properties";
import { Logout } from "src/routes/Logout";
import { AddPropertyOrganization } from "src/routes/AddPropertyOrganization";
import { DealerOrganizationSelector } from "src/routes/DealerOrganizationSelector";
import { DealerStaffList } from "src/routes/DealerStaffList";
import { AddDealerStaffMember } from "src/routes/AddDealerStaffMember";
import { EditPropertyOrganization } from "src/routes/EditPropertyOrganization";
import { ProfileEdit } from "src/routes/ProfileEdit";
import { EulaAgreementWall } from "src/routes/EulaAgreementWall";
import { AdminRoleGate } from "src/common/AdminRoleGate";
import { EditDealerUser } from "src/routes/EditDealerUser";
import { DealerAgreementWall } from "src/routes/DealerAgreementWall";
import { GlobalErrorBoundary } from "src/common/ErrorBoundary/GlobalErrorBoundary";
import { usePageTracking } from "src/common/hooks/usePageTracking";
import { CellKitAdd } from "src/routes/IntercomUnits/IntercomActivation/CellKitAdd/CellKitAdd";
import { GateHawkUserLoader } from "src/common/GateHawkUserLoader";
import { Staff } from "src/routes/Property/Staff";
import { AddPropertyStaff } from "src/routes/Property/AddPropertyStaff";
import { EditPropertyStaff } from "src/routes/Property/EditPropertyStaff";
import { IntercomUnitsLoader } from "src/routes/IntercomUnits/IntercomUnitsLoader";
import { IntercomActivationLoader } from "src/routes/IntercomUnits/IntercomActivation/IntercomActivationLoader";
import { CellKit } from "src/routes/IntercomUnits/IntercomActivation/CellKit/CellKit";
import { IntercomSystem } from "src/routes/IntercomUnits/IntercomActivation/IntercomSystem/IntercomSystem";
import { useFlags } from "src/common/hooks/useFlags";
import { AccessControl } from "src/routes/Property/AccessControl";
import { AddAccessPanel } from "src/routes/Property/AccessControl/Panels/AddAccessPanel";
import { EditAccessPanel } from "src/routes/Property/AccessControl/Panels/EditAccessPanel";
import { AddEntryGroup } from "src/routes/Property/AccessControl/EntryGroups/AddEntryGroup";
import { EditEntryGroup } from "src/routes/Property/AccessControl/EntryGroups/EditEntryGroup";
import { AddAccessProfile } from "src/routes/Property/AccessControl/Profiles/AddAccessProfile";
import { EditAccessProfile } from "src/routes/Property/AccessControl/Profiles/EditAccessProfile";
import { AddEntrySchedule } from "src/routes/Property/AccessControl/EntrySchedules/AddEntrySchedule";
import { EditEntrySchedule } from "src/routes/Property/AccessControl/EntrySchedules/EditEntrySchedule";

function App() {
  usePageTracking();
  const flags = useFlags();

  const { user } = useAuth0();
  const ldClient = useLDClient();
  if (user) {
    ldClient?.identify({
      kind: "user",
      key: user.sub,
    });
  }

  return (
    <GlobalErrorBoundary>
      <Routes>
        <Route element={<AuthGate />}>
          <Route
            index
            element={<Navigate to="/dealer-organizations" replace />}
          />
          <Route element={<GateHawkUserLoader />}>
            <Route
              path="/dealer-organizations"
              element={<DealerOrganizationSelector />}
            >
              <Route element={<DealerAgreementWall />}>
                <Route element={<EulaAgreementWall />}>
                  <Route element={<DealerOrganizationLayout />}>
                    <Route path=":dealerOrganizationId">
                      <Route
                        index
                        element={<Navigate to="profile" replace />}
                      />
                      <Route path="profile">
                        <Route
                          index
                          element={<Navigate to="details" replace />}
                        />
                        <Route path="details" element={<Profile />} />
                        <Route element={<AdminRoleGate />}>
                          <Route
                            path="details/edit"
                            element={<ProfileEdit />}
                          />
                          <Route path="financials" element={<Financials />} />
                          <Route
                            path="financials/edit"
                            element={<FinancialsEdit />}
                          />
                        </Route>
                      </Route>
                      <Route path="properties">
                        <Route index element={<Properties />} />
                        <Route
                          path="add"
                          element={<AddPropertyOrganization />}
                        />
                        <Route path=":propertyOrganizationId">
                          <Route index element={<Property />} />
                          <Route
                            path="intercoms"
                            element={<IntercomUnitsLoader />}
                          >
                            <Route index element={<Intercoms />} />
                            <Route path="add" element={<IntercomAdd />} />
                            <Route
                              path=":intercomActivationId"
                              element={<IntercomActivationLoader />}
                            >
                              {flags.gen4DealerPortal ? (
                                <>
                                  <Route
                                    path="system"
                                    element={<IntercomSystem />}
                                  />
                                  <Route
                                    path="cell-kit"
                                    element={<CellKit />}
                                  />
                                </>
                              ) : null}
                              <Route
                                path="cell-kits/add"
                                element={<CellKitAdd />}
                              />
                              <Route path="edit" element={<IntercomEdit />} />
                              <Route
                                path="details"
                                element={<IntercomDetails />}
                              />
                            </Route>
                          </Route>
                          <Route
                            path="edit"
                            element={<EditPropertyOrganization />}
                          />
                          <Route path="staff">
                            <Route index element={<Staff />} />
                            <Route path="add" element={<AddPropertyStaff />} />
                            <Route
                              path=":propertyUserId/edit"
                              element={<EditPropertyStaff />}
                            />
                          </Route>
                          {flags.accessControl && (
                            <Route path="access-control">
                              <Route index element={<AccessControl />} />
                              <Route path="add" element={<AddAccessPanel />} />
                              <Route
                                path="entry-groups/add"
                                element={<AddEntryGroup />}
                              />
                              <Route
                                path="entry-groups/:entryGroupId/edit"
                                element={<EditEntryGroup />}
                              />
                              <Route
                                path="entry-schedules/add"
                                element={<AddEntrySchedule />}
                              />
                              <Route
                                path="entry-schedules/:entryScheduleId/edit"
                                element={<EditEntrySchedule />}
                              />
                              <Route
                                path=":accessPanelId/edit"
                                element={<EditAccessPanel />}
                              />
                              <Route
                                path={"entry-points/add"}
                                element={<AddEntryPoint />}
                              />
                              <Route
                                path={"entry-points/:entryPointId/edit"}
                                element={<EditEntryPoint />}
                              />
                              <Route
                                path={
                                  "entry-points/:entryPointId/lock-unlock-schedule"
                                }
                                element={<EditLockUnlockSchedule />}
                              />
                              <Route
                                path="profiles/add"
                                element={<AddAccessProfile />}
                              />
                              <Route
                                path="profiles/:accessProfileId/edit"
                                element={<EditAccessProfile />}
                              />
                            </Route>
                          )}
                        </Route>
                      </Route>
                      <Route path="staff" element={<DealerStaffList />} />
                      <Route element={<AdminRoleGate />}>
                        <Route
                          path="staff/:dealerUserId/edit"
                          element={<EditDealerUser />}
                        />
                      </Route>
                      <Route element={<AdminRoleGate />}>
                        <Route
                          path="staff/add"
                          element={<AddDealerStaffMember />}
                        />
                      </Route>
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Route>

            <Route path="/logout" element={<Logout />} />
          </Route>
        </Route>
      </Routes>
    </GlobalErrorBoundary>
  );
}

export default App;

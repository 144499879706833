import React from "react";
import {
  Center,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import {
  HiOutlineDotsVertical,
  HiOutlinePencil,
  HiOutlineTrash,
} from "react-icons/hi";
import {
  Link as RouterLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { formatInTimeZone } from "date-fns-tz";

import { PageContent } from "src/layout/PageContent";
import { PageHeader, PageHeaderTitle } from "src/layout/PageHeader";
import { Pagination } from "src/common/Pagination";
import { Loading } from "src/common/Loading";
import { usePropertyOrganizationsQuery } from "src/common/queries";
import { SortableTh } from "src/common/SortableTh";
import { PropertiesFilterBar } from "src/routes/PropertiesFilterBar";
import { PanelSyncStatus } from "src/common/PanelSyncStatus";

export const Properties = () => {
  const { dealerOrganizationId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const query = usePropertyOrganizationsQuery(dealerOrganizationId);

  if (query.isLoading || !query.isSuccess) {
    return <Loading />;
  }

  return (
    <>
      <PageHeader buttons={<PropertiesFilterBar />}>
        <PageHeaderTitle>Property Organizations</PageHeaderTitle>
      </PageHeader>
      <PageContent>
        <Table variant="simple">
          <Thead>
            <Tr>
              <SortableTh sortKey="name">Name</SortableTh>
              <SortableTh sortKey="contactName">Contact Name</SortableTh>
              <SortableTh sortKey="address">Address</SortableTh>
              <SortableTh sortKey="type">Property Type</SortableTh>
              <SortableTh sortKey="createdAt">Date Added (GMT)</SortableTh>
              <Th minWidth="fit-content" paddingInline="var(--chakra-space-3)">
                <Text>Panel Status</Text>
              </Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {query.data.result.map((propertyOrganization) => {
              return (
                <Tr key={propertyOrganization.propertyOrganizationId}>
                  <Td>
                    <Link
                      as={RouterLink}
                      to={propertyOrganization.propertyOrganizationId}
                    >
                      <Text>{propertyOrganization.name}</Text>
                    </Link>
                  </Td>
                  <Td>
                    <Text>
                      {propertyOrganization.ownerFirstName}{" "}
                      {propertyOrganization.ownerLastName}
                    </Text>
                  </Td>
                  <Td>
                    <Text>
                      {propertyOrganization.address},{" "}
                      {propertyOrganization.city}, {propertyOrganization.state},{" "}
                      {propertyOrganization.zip}
                    </Text>
                  </Td>
                  <Td>
                    <Text>{propertyOrganization.propertyType}</Text>
                  </Td>
                  <Td>
                    <Text>
                      {formatInTimeZone(
                        propertyOrganization.createdAt,
                        "GMT",
                        "MM/dd/yyyy"
                      )}
                    </Text>
                  </Td>
                  <Td>
                    {propertyOrganization.panel?.syncStatus ? (
                      <PanelSyncStatus
                        status={propertyOrganization.panel.syncStatus}
                        toolTip={true}
                      />
                    ) : (
                      <Center>{"-"}</Center>
                    )}
                  </Td>
                  <Td>
                    <Menu>
                      <MenuButton
                        as={IconButton}
                        aria-label="Actions"
                        icon={<HiOutlineDotsVertical />}
                        variant="clear"
                      >
                        Actions
                      </MenuButton>
                      <MenuList>
                        <MenuItem
                          justifyContent="space-between"
                          onClick={() => {
                            navigate(
                              `/dealer-organizations/${dealerOrganizationId}/properties/${propertyOrganization.propertyOrganizationId}/edit`
                            );
                          }}
                        >
                          Edit Property Details
                          <HiOutlinePencil />
                        </MenuItem>
                        <MenuItem isDisabled justifyContent="space-between">
                          Remove Property
                          <HiOutlineTrash />
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <Pagination
          unitName="property"
          unitPlural="properties"
          total={query.data.total}
          pageNumber={query.data.pageNumber}
          pageSize={query.data.pageSize}
          onPageChange={(nextPageNumber) => {
            navigate(`${location.pathname}?pageNumber=${nextPageNumber}`);
          }}
        />
      </PageContent>
    </>
  );
};

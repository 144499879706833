import React, { useMemo, useState } from "react";
import {
  Badge,
  Button,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import {
  HiOutlineDotsVertical,
  HiOutlineSearch,
  HiPencil,
} from "react-icons/hi";
import { GoX } from "react-icons/go";
import { sortBy } from "lodash";
import { useQueryClient } from "react-query";

import { useDeleteEntryPoint } from "src/routes/Property/hooks";
import {
  getEntryPointsQueryKey,
  useGetEntryGroupsQuery,
  useGetEntryPointsQuery,
  useGetLockUnlockSchedulesQuery,
} from "src/routes/Property/queries";
import { Loading } from "src/common/Loading";
import { EntryGroup } from "src/common/types";
import { noop } from "src/common/util";
import { HTTPError } from "src/common/ky";
import { handleHTTPError } from "src/common/form";

export const EntryPointList = () => {
  const { dealerOrganizationId, propertyOrganizationId } = useParams();
  const entryPointsQuery = useGetEntryPointsQuery(
    propertyOrganizationId as string
  );
  const lockUnlockSchedulesQuery = useGetLockUnlockSchedulesQuery(
    propertyOrganizationId as string
  );
  const entryGroupsQuery = useGetEntryGroupsQuery(
    propertyOrganizationId as string
  );
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const toast = useToast();
  const {
    isDeletingEntryPoint,
    deleteEntryPointMutation,
    setDeletingEntryPointId,
  } = useDeleteEntryPoint({
    onSuccess: () => {
      queryClient.invalidateQueries(
        getEntryPointsQueryKey(propertyOrganizationId as string)
      );
      setDeletingEntryPointId(undefined);
      toast({
        description: "Entry Point deleted",
        status: "success",
      });
    },
    onError: async (error: HTTPError) => {
      await handleHTTPError(toast)(error);
      setDeletingEntryPointId(undefined);
    },
  });

  const [search, setSearch] = useState("");
  const entryPoints = useMemo(
    () =>
      sortBy(entryPointsQuery.data ?? [], [
        "dmpProperties.deviceNumber",
        (v) => v.displayName.toString(),
      ]),
    [entryPointsQuery.data]
  );
  const entryGroups = useMemo(
    () => entryGroupsQuery.data ?? [],
    [entryGroupsQuery.data]
  );
  const lockUnlockSchedules = useMemo(
    () => lockUnlockSchedulesQuery.data ?? [],
    [lockUnlockSchedulesQuery.data]
  );

  const filteredEntryPoints = useMemo(
    () =>
      entryPoints.filter(
        (ep) =>
          ep.displayName
            .trim()
            .toLowerCase()
            .includes(search.trim().toLowerCase()) ||
          (ep.dmpProperties?.deviceNumber &&
            String(ep.dmpProperties?.deviceNumber).includes(
              search.trim().toLowerCase()
            )) ||
          ep.entryGroupIds
            .map(
              (id) =>
                entryGroups.find((eg) => eg.entryGroupId === id)?.displayName
            )
            .some(
              (egName) =>
                egName &&
                egName.toLowerCase().includes(search.trim().toLowerCase())
            )
      ),
    [search, entryPoints, entryGroups]
  );

  if (
    entryPointsQuery.isLoading ||
    !entryPointsQuery.isSuccess ||
    entryGroupsQuery.isLoading ||
    !entryGroupsQuery.isSuccess
  ) {
    return <Loading />;
  }

  return (
    <>
      <Stack
        direction={{ base: "column", lg: "row" }}
        alignItems={{ base: "stretch", lg: "center" }}
        justifyContent={"space-between"}
      >
        <Text fontSize="xl" fontWeight={600}>
          Entry Points
        </Text>
        <HStack flex={1} justifyContent={"end"}>
          <InputGroup width={"100%"} maxWidth={500}>
            <InputLeftElement pointerEvents="none" color="gray.300">
              <HiOutlineSearch />
            </InputLeftElement>
            <Input
              value={search}
              onChange={({ currentTarget: { value } }) => setSearch(value)}
              placeholder="Search by Name, Device Number, or Entry Group"
            />
            <InputRightElement>
              <Button variant="ghost" onClick={() => setSearch("")}>
                <GoX />
              </Button>
            </InputRightElement>
          </InputGroup>
          <Button
            flexShrink={0}
            margin={2}
            onClick={() =>
              navigate(
                `/dealer-organizations/${dealerOrganizationId}/properties/${propertyOrganizationId}/access-control/entry-points/add`
              )
            }
          >
            Add Entry Point
          </Button>
        </HStack>
      </Stack>
      {filteredEntryPoints.length ? (
        <Table>
          <Thead>
            <Tr>
              <Th>Display Name</Th>
              <Th>Device Number</Th>
              <Th>Entry Groups</Th>
              <Th>Module Connection Type</Th>
              <Th>Lock/Unlock Schedule Status</Th>
              <Th></Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredEntryPoints.map((entryPoint) => {
              return (
                <Tr key={entryPoint.entryPointId}>
                  <Td>{entryPoint.displayName}</Td>
                  <Td>{entryPoint.dmpProperties?.deviceNumber}</Td>
                  <Td>
                    {(
                      entryPoint.entryGroupIds
                        .map((egId) =>
                          entryGroupsQuery.data.find(
                            (group) => group.entryGroupId === egId
                          )
                        )
                        .filter((v) => v) as EntryGroup[]
                    ).map((entryGroup) => (
                      <Text key={entryGroup.entryGroupId}>
                        {entryGroup.displayName}
                      </Text>
                    ))}
                  </Td>
                  <Td>{entryPoint.moduleConnectionType}</Td>
                  <Td>
                    {lockUnlockSchedules.find(
                      (lus) => lus.entryPointId === entryPoint.entryPointId
                    )?.schedule?.length ? (
                      <Badge variant="solid" colorScheme="green" paddingX={2}>
                        Schedule Set
                      </Badge>
                    ) : (
                      <Badge variant="solid" colorScheme="orange" paddingX={2}>
                        No Schedule Set
                      </Badge>
                    )}
                  </Td>
                  <Td>
                    {entryPoint.fireExitRelease && (
                      <Badge variant="solid" colorScheme="red" paddingX={2}>
                        Fire Exit
                      </Badge>
                    )}
                  </Td>
                  <Td>
                    <Menu>
                      <MenuButton
                        as={IconButton}
                        aria-label="Actions"
                        icon={<HiOutlineDotsVertical />}
                        variant="clear"
                      >
                        Actions
                      </MenuButton>
                      <MenuList>
                        <MenuItem
                          justifyContent="space-between"
                          onClick={() => {
                            navigate(
                              `/dealer-organizations/${dealerOrganizationId}/properties/${propertyOrganizationId}/access-control/entry-points/${entryPoint.entryPointId}/lock-unlock-schedule`
                            );
                          }}
                        >
                          Set Schedule
                          <HiPencil />
                        </MenuItem>
                        <MenuItem
                          justifyContent={"space-between"}
                          isDisabled={false}
                          onClick={() => {
                            navigate(
                              `/dealer-organizations/${dealerOrganizationId}/properties/${propertyOrganizationId}/access-control/entry-points/${entryPoint.entryPointId}/edit`
                            );
                          }}
                        >
                          Edit
                        </MenuItem>
                        <MenuItem
                          justifyContent={"space-between"}
                          isDisabled={false}
                          onClick={() =>
                            setDeletingEntryPointId(entryPoint.entryPointId)
                          }
                        >
                          Delete
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      ) : entryPoints.length ? (
        <Text>There are no entry points matching your search</Text>
      ) : (
        <Text>There are no entry points</Text>
      )}
      <Modal
        isOpen={isDeletingEntryPoint}
        onClose={() => {
          setDeletingEntryPointId(undefined);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Remove Entry Point</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure? You can&apos;t undo this action</ModalBody>

          <ModalFooter>
            <Button
              colorScheme={"brand.lightGray"}
              color={"gray.700"}
              mr={3}
              onClick={() => {
                setDeletingEntryPointId(undefined);
              }}
            >
              Cancel
            </Button>
            <Button
              colorScheme={"brand.red"}
              isDisabled={deleteEntryPointMutation.isLoading}
              onClick={() => deleteEntryPointMutation.mutateAsync().catch(noop)}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

import React from "react";
import { createIcon } from "@chakra-ui/react";

export const Dashboard = createIcon({
  displayName: "Dashboard",
  viewBox: "0 0 30 30",
  path: (
    <path
      fill="currentColor"
      d="M16.375 10.833V0H30V10.833H16.375ZM0 16.375V0H13.625V16.375H0ZM16.375 30V13.625H30V30H16.375ZM0 30V19.167H13.625V30H0ZM2.792 13.625H10.833V2.792H2.792V13.625ZM19.167 27.208H27.208V16.375H19.167V27.208ZM19.167 8.042H27.208V2.792H19.167V8.042ZM2.792 27.208H10.833V21.958H2.792V27.208Z"
    />
  ),
});

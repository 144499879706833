import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/react";
import { HTTPError } from "ky";
import { Auth0Provider } from "@auth0/auth0-react";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { createRoot } from "react-dom/client";

import App from "src/App";
import reportWebVitals from "src/reportWebVitals";
import theme from "src/common/theme";
import config from "src/common/config";

(async () => {
  if (config.sentryDsn && config.sentryEnvironment) {
    Sentry.init({
      dsn: config.sentryDsn,
      environment: config.sentryEnvironment,
      integrations: [new BrowserTracing()],
      tracesSampleRate: 1.0,
    });
  }

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false,
        useErrorBoundary: (error) => {
          return error instanceof HTTPError && error.response.status >= 500;
        },
      },
      mutations: {
        useErrorBoundary: (error) => {
          return error instanceof HTTPError && error.response.status >= 500;
        },
      },
    },
  });

  const LDProvider = await asyncWithLDProvider({
    clientSideID: config.launchDarklyKey,
    options: {
      streaming: false,
    },
  });

  createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={theme}>
          <BrowserRouter>
            <LDProvider>
              <Auth0Provider
                domain={config.auth0Domain}
                clientId={config.auth0ClientId}
                cacheLocation="localstorage"
                authorizationParams={{
                  redirect_uri: window.location.origin,
                  audience: `${config.auth0Audience}`,
                  scope:
                    "read:current_user update:current_user_metadata email profile",
                }}
              >
                <App />
              </Auth0Provider>
            </LDProvider>
          </BrowserRouter>
        </ChakraProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </React.StrictMode>
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

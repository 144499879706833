import { Box, Flex, HStack } from "@chakra-ui/react";
import * as React from "react";
import { useParams } from "react-router-dom";

import { NavItem } from "src/layout/NavItem";

type MobileNavMenuProps = { isOpen?: boolean; closeMenu?: () => void };
const MobileNavMenu = ({ isOpen, closeMenu }: MobileNavMenuProps) => {
  const params = useParams();
  return (
    <Flex
      hidden={!isOpen}
      as="nav"
      direction="column"
      bg="brand.navy"
      position="fixed"
      height="calc(100vh - 4rem)"
      top="16"
      insetX="0"
      zIndex={10}
      w="full"
    >
      <Box px="4">
        <NavItem.Mobile
          label="Profile"
          to={`/dealer-organizations/${params.dealerOrganizationId}/profile`}
          closeMenu={closeMenu}
        />
        <NavItem.Mobile
          label="Properties"
          to={`/dealer-organizations/${params.dealerOrganizationId}/properties`}
          closeMenu={closeMenu}
        />
        <NavItem.Mobile
          label="Staff"
          to={`/dealer-organizations/${params.dealerOrganizationId}/staff`}
          closeMenu={closeMenu}
        />
      </Box>
    </Flex>
  );
};

const DesktopNavMenu = () => {
  const params = useParams();
  return (
    <HStack spacing="3" flex="1" display={{ base: "none", lg: "flex" }}>
      <NavItem.Desktop
        label="Profile"
        to={`/dealer-organizations/${params.dealerOrganizationId}/profile`}
      />
      <NavItem.Desktop
        label="Properties"
        to={`/dealer-organizations/${params.dealerOrganizationId}/properties`}
      />
      <NavItem.Desktop
        label="Staff"
        to={`/dealer-organizations/${params.dealerOrganizationId}/staff`}
      />
    </HStack>
  );
};

export const NavMenu = {
  Mobile: MobileNavMenu,
  Desktop: DesktopNavMenu,
};

import {
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Textarea,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import React, { useCallback, useMemo, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "react-query";
import { HTTPError } from "ky";
import { FiEye, FiEyeOff } from "react-icons/fi";

import { useGetAccessPanelQuery } from "src/routes/Property/queries";
import { EditAccessPanelValidationSchema } from "src/routes/Property/schema";
import { Panel } from "src/common/types";
import { noop } from "src/common/util";
import { Loading } from "src/common/Loading";
import { useKy } from "src/common/ky";
import { AccessControlFormProps } from "src/routes/Property/AccessControl/types";
import {
  getNormalizedUSTimezone,
  USTimezoneSelect,
} from "src/common/USTimezoneSelect";

export type UpdateAccessPanelValues = {
  ipAddress: string;
  port: string;
  timezone: string;
  notes?: string;
  listenPort734n: string;
  passphrase734n: string;
};

export type EditAccessPanelFormProps =
  AccessControlFormProps<UpdateAccessPanelValues> & {
    accessPanelId: string;
  };

export const EditAccessPanelForm = (props: EditAccessPanelFormProps) => {
  const {
    accessPanelId,
    hideFooterDivider,
    Footer,
    mutationOptionsBuilder,
    children,
  } = props;

  const hookForm = useForm<UpdateAccessPanelValues>({
    resolver: yupResolver(EditAccessPanelValidationSchema),
    defaultValues: {
      ipAddress: "",
      port: "",
      timezone: "",
      listenPort734n: "",
      passphrase734n: "",
      notes: "",
    },
  });

  const ky = useKy();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty },
    reset,
  } = hookForm;

  const getAccessPanelQuery = useGetAccessPanelQuery(accessPanelId, {
    onSuccess: (accessPanel: Panel) => {
      if (!isDirty) {
        reset({
          ipAddress: accessPanel.dmpProperties?.integratorPort?.ipAddress ?? "",
          port: String(accessPanel.dmpProperties?.integratorPort?.port ?? ""),
          timezone: getNormalizedUSTimezone(accessPanel.timezone) ?? "",
          listenPort734n: String(
            accessPanel.dmpProperties?.listenPort734n ?? ""
          ),
          passphrase734n: accessPanel.dmpProperties?.passphrase734n ?? "",
          notes: accessPanel?.notes || "",
        });
      }
    },
  });

  const [passphrase734nVisible, setPassphrase734nVisible] = useState(false);

  const mutationOptions = useMemo(
    () => mutationOptionsBuilder && mutationOptionsBuilder(hookForm),
    [mutationOptionsBuilder, hookForm]
  );

  const updateAccessPanelMutation = useMutation<
    void,
    HTTPError,
    UpdateAccessPanelValues
  >(async (values: UpdateAccessPanelValues) => {
    await ky.patch(`access-control/panel/${accessPanelId}`, { json: values });
  }, mutationOptions);

  const onSubmit = useCallback(
    handleSubmit((data) =>
      updateAccessPanelMutation.mutateAsync(data).catch(noop)
    ),
    [handleSubmit]
  );

  if (getAccessPanelQuery.isLoading || !getAccessPanelQuery.isSuccess) {
    return <Loading />;
  }

  return (
    <form onSubmit={onSubmit}>
      <Grid gap={4} width={{ base: "100%", xl: "50%" }}>
        <GridItem>
          <Controller
            name="ipAddress"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl isRequired isInvalid={!!fieldState.error}>
                <FormLabel htmlFor="ipAddress">IP Address</FormLabel>
                <Input {...field} id="ipAddress" />
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </GridItem>

        <GridItem>
          <Controller
            name="port"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl isRequired isInvalid={!!fieldState.error}>
                <FormLabel htmlFor="port">Port Number</FormLabel>
                <Input {...field} id="port" />
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </GridItem>

        <GridItem>
          <Controller
            name="timezone"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl isRequired isInvalid={!!fieldState.error}>
                <FormLabel htmlFor="timezone">Timezone</FormLabel>
                <USTimezoneSelect
                  placeholder={"Select Timezone"}
                  {...field}
                  id="timezone"
                ></USTimezoneSelect>
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </GridItem>

        <GridItem>
          <Controller
            name="listenPort734n"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl isRequired isInvalid={!!fieldState.error}>
                <FormLabel htmlFor="listenPort734n">
                  Network Module Listen Port
                </FormLabel>
                <Input {...field} id="listenPort734n" />
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </GridItem>

        <GridItem>
          <Controller
            name="passphrase734n"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl isRequired isInvalid={!!fieldState.error}>
                <FormLabel htmlFor="passphrase734n">
                  Network Module Passphrase
                </FormLabel>
                <InputGroup>
                  <Input
                    type={passphrase734nVisible ? "text" : "password"}
                    {...field}
                    autoComplete={"off"}
                    id="passphrase734n"
                  />
                  <InputRightElement>
                    <IconButton
                      size="sm"
                      variant={"ghost"}
                      icon={passphrase734nVisible ? <FiEyeOff /> : <FiEye />}
                      onClick={() =>
                        setPassphrase734nVisible(!passphrase734nVisible)
                      }
                      aria-label={"toggle-visible"}
                    />
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </GridItem>

        <GridItem>
          <Controller
            name="notes"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl isInvalid={!!fieldState.error}>
                <FormLabel htmlFor="notes">Notes</FormLabel>
                <Textarea {...field} id="notes" />
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </GridItem>
      </Grid>
      {Footer ? (
        <Grid gap={4} mt={4}>
          {!hideFooterDivider ? (
            <GridItem>
              <Divider />
            </GridItem>
          ) : null}
          <GridItem>{Footer({ isSubmitting, onSubmit })}</GridItem>
        </Grid>
      ) : null}
      {children
        ? typeof children === "function"
          ? children({ isSubmitting, onSubmit })
          : children
        : null}
    </form>
  );
};

import React, { Component } from "react";
import { Container } from "@chakra-ui/react";

import { SomethingWentWrong } from "src/common/ErrorBoundary/SomethingWentWrong";

type Props = {
  children: React.ReactNode;
};

export class AppErrorBoundary extends Component<
  Props,
  { error: Error | null }
> {
  constructor(props: Props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error) {
    console.error(error);
  }

  render() {
    if (this.state.error) {
      return (
        <Container maxW="7xl" h="calc(100vh)" centerContent={true}>
          <SomethingWentWrong error={this.state.error} />
        </Container>
      );
    }

    return this.props.children;
  }
}

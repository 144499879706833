import {
  Checkbox,
  CheckboxGroup,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Select,
  Stack,
  Switch,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import React, { useCallback, useMemo } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "react-query";
import { HTTPError } from "ky";
import { intersection } from "lodash";

import { noop } from "src/common/util";
import { EditEntryPointValidationSchema } from "src/routes/Property/schema";
import {
  useGetEntryGroupsQuery,
  useGetEntryPointByIdQuery,
} from "src/routes/Property/queries";
import { EntryPoint, EntryPointDMPProperties } from "src/common/types";
import { Loading } from "src/common/Loading";
import { useKy } from "src/common/ky";
import { AccessControlFormProps } from "src/routes/Property/AccessControl/types";

export interface EditEntryPointValues {
  displayName: string;
  strikeTime: number;
  delayTime: number;
  entryGroupIds: string[];
  dmpProperties: EntryPointDMPProperties | undefined;
  fireExitRelease: boolean;
  publicEntryPoint: boolean;
  notes?: string;
  readerId: string;
}

export type EditEntryPointFormProps =
  AccessControlFormProps<EditEntryPointValues> & {
    entryPointId: string;
    propertyOrganizationId: string;
  };

export const EditEntryPointForm = (props: EditEntryPointFormProps) => {
  const {
    entryPointId,
    propertyOrganizationId,
    hideFooterDivider,
    Footer,
    mutationOptionsBuilder,
    children,
  } = props;

  const ky = useKy();
  const entryGroupsQuery = useGetEntryGroupsQuery(
    propertyOrganizationId as string
  );

  const entryPointQuery = useGetEntryPointByIdQuery(entryPointId, {
    onSuccess: (entryPoint: EntryPoint) => {
      reset({
        displayName: entryPoint.displayName,
        strikeTime: entryPoint.strikeTime,
        delayTime: entryPoint.delayTime,
        entryGroupIds: entryPoint.entryGroupIds,
        dmpProperties: {
          deviceNumber: entryPoint?.dmpProperties?.deviceNumber || 0,
        },
        fireExitRelease: entryPoint.fireExitRelease,
        publicEntryPoint: entryPoint.publicEntryPoint,
        notes: entryPoint?.notes || "",
        readerId: entryPoint?.readerId || "",
      });
    },
  });
  const hookForm = useForm<EditEntryPointValues>({
    defaultValues: {
      displayName: "",
      strikeTime: 5,
      delayTime: 0,
      entryGroupIds: [],
      dmpProperties: {
        deviceNumber: 0,
      },
      fireExitRelease: false,
      publicEntryPoint: false,
      notes: "",
      readerId: "",
    },
    resolver: yupResolver(EditEntryPointValidationSchema),
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = hookForm;

  const mutationOptions = useMemo(
    () => mutationOptionsBuilder && mutationOptionsBuilder(hookForm),
    [mutationOptionsBuilder, hookForm]
  );

  const onSubmit = useCallback(
    handleSubmit(
      async (values: EditEntryPointValues) =>
        await editEntryPoint.mutateAsync(values).catch(noop)
    ),
    [handleSubmit]
  );

  const editEntryPoint = useMutation<void, HTTPError, EditEntryPointValues>(
    async (values: EditEntryPointValues) => {
      await ky.put(`access-control/entry-point/${entryPointId}`, {
        json: { ...values },
      });
    },
    mutationOptions
  );

  const validEntryGroupIds = useMemo(
    () => (entryGroupsQuery.data ?? []).map((eg) => eg.entryGroupId),
    [entryGroupsQuery.data]
  );

  if (
    entryPointQuery.isLoading ||
    !entryPointQuery.isSuccess ||
    entryGroupsQuery.isLoading ||
    !entryGroupsQuery.isSuccess
  ) {
    return <Loading />;
  }

  return (
    <form onSubmit={onSubmit}>
      <Grid gap={4} width={{ base: "100%", xl: "50%" }}>
        <GridItem>
          <Controller
            name={"displayName"}
            control={control}
            render={({ field, fieldState }) => (
              <FormControl isInvalid={!!fieldState.error}>
                <FormLabel htmlFor="displayName">Display Name</FormLabel>
                <Input {...field} id="displayName" placeholder="Display Name" />
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </GridItem>
        <GridItem>
          <Controller
            name={"readerId"}
            control={control}
            render={({ field, fieldState }) => (
              <FormControl isInvalid={!!fieldState.error} isRequired>
                <FormLabel htmlFor="readerId">Reader ID</FormLabel>
                <Input {...field} id="readerId" placeholder="Reader ID" />
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </GridItem>
        <GridItem>
          <Controller
            name={"strikeTime"}
            control={control}
            render={({ field, fieldState }) => (
              <FormControl isInvalid={!!fieldState.error}>
                <FormLabel htmlFor="strikeTime">Strike Time</FormLabel>
                <Input
                  {...field}
                  id="strikeTime"
                  type={"number"}
                  step={1}
                  min={0}
                  placeholder="Strike Time"
                />
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </GridItem>
        <GridItem>
          <Controller
            name={"delayTime"}
            control={control}
            render={({ field, fieldState }) => (
              <FormControl isInvalid={!!fieldState.error}>
                <FormLabel htmlFor="delayTime">Delay Time</FormLabel>
                <Input
                  {...field}
                  type={"number"}
                  step={1}
                  min={0}
                  id="delayTime"
                  placeholder="Delay Time"
                />
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </GridItem>
        <GridItem>
          <Controller
            name={"dmpProperties.deviceNumber"}
            control={control}
            render={({ field, fieldState }) => (
              <FormControl isInvalid={!!fieldState.error}>
                <FormLabel htmlFor="deviceNumber">Device Number</FormLabel>
                <Input
                  {...field}
                  type={"number"}
                  step={1}
                  min={0}
                  id="deviceNumber"
                  placeholder="Device Number"
                />
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </GridItem>
        <GridItem>
          <FormLabel>Module Connection Type</FormLabel>
          <Select
            placeholder={entryPointQuery.data?.moduleConnectionType}
            disabled={true}
          />
        </GridItem>
        <GridItem>
          <Controller
            name={"fireExitRelease"}
            control={control}
            render={({ field: { value, ...field }, fieldState }) => (
              <FormControl isInvalid={!!fieldState.error}>
                <Flex alignItems={"center"}>
                  <FormLabel htmlFor="fireExitRelease" mb={0}>
                    Fire Exit Release
                  </FormLabel>
                  <Switch isChecked={value} {...field} id="fireExitRelease" />
                </Flex>
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </GridItem>
        <GridItem>
          <Controller
            name={"publicEntryPoint"}
            control={control}
            render={({ field: { value, ...field }, fieldState }) => (
              <FormControl isInvalid={!!fieldState.error}>
                <Flex alignItems={"center"}>
                  <FormLabel htmlFor="publicEntryPoint" mb={0}>
                    Public Entry Point
                  </FormLabel>
                  <Switch isChecked={value} {...field} id="publicEntryPoint" />
                </Flex>
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </GridItem>
        <GridItem>
          <Controller
            name={"notes"}
            control={control}
            render={({ field, fieldState }) => (
              <FormControl isInvalid={!!fieldState.error}>
                <FormLabel htmlFor="notes">Notes</FormLabel>
                <Textarea {...field} id="notes" placeholder="Notes" />
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </GridItem>
        <Divider />
        <GridItem>
          <Controller
            name={"entryGroupIds"}
            control={control}
            render={({ field, fieldState }) => (
              <FormControl isInvalid={!!fieldState.error}>
                <FormLabel htmlFor="entryGroupIds">Entry Groups</FormLabel>
                <Stack spacing={5}>
                  {entryGroupsQuery.data?.length ? (
                    <CheckboxGroup value={field.value}>
                      {entryGroupsQuery.data.map((entryGroup) => (
                        <Checkbox
                          key={entryGroup.entryGroupId}
                          value={entryGroup.entryGroupId}
                          onChange={(event) => {
                            const isChecked = event.target.checked;
                            const value = event.target.value;

                            field.onChange(
                              isChecked
                                ? intersection(validEntryGroupIds, [
                                    ...field.value,
                                    value,
                                  ])
                                : field.value.filter((id) => id !== value)
                            );
                          }}
                        >
                          {entryGroup.displayName}
                        </Checkbox>
                      ))}
                    </CheckboxGroup>
                  ) : (
                    <Text>No Entry Groups</Text>
                  )}
                  <FormErrorMessage>
                    {fieldState.error?.message}
                  </FormErrorMessage>
                </Stack>
              </FormControl>
            )}
          />
        </GridItem>
      </Grid>
      {Footer ? (
        <Grid gap={4} mt={4}>
          {!hideFooterDivider ? (
            <GridItem>
              <Divider />
            </GridItem>
          ) : null}
          <GridItem>{Footer({ isSubmitting, onSubmit })}</GridItem>
        </Grid>
      ) : null}
      {children
        ? typeof children === "function"
          ? children({ isSubmitting, onSubmit })
          : children
        : null}
    </form>
  );
};

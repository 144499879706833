import {
  Checkbox,
  CheckboxGroup,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import React, { useCallback, useMemo } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "react-query";
import { HTTPError } from "ky";
import { intersection } from "lodash";

import { useKy } from "src/common/ky";
import { AddEntryGroupValidationSchema } from "src/routes/Property/schema";
import { noop } from "src/common/util";
import { AddEntryGroupValues } from "src/routes/Property/AccessControl/EntryGroups/AddEntryGroup";
import { AccessControlFormProps } from "src/routes/Property/AccessControl/types";
import { useGetEntryPointsQuery } from "src/routes/Property/queries";
import { Loading } from "src/common/Loading";

export type CreateEntryGroupFormProps =
  AccessControlFormProps<AddEntryGroupValues> & {
    propertyOrganizationId: string;
  };

export const CreateEntryGroupForm = (props: CreateEntryGroupFormProps) => {
  const {
    propertyOrganizationId,
    hideFooterDivider,
    Footer,
    mutationOptionsBuilder,
  } = props;
  const ky = useKy();
  const getEntryPointsQuery = useGetEntryPointsQuery(
    propertyOrganizationId as string
  );

  const hookForm = useForm<AddEntryGroupValues>({
    defaultValues: {
      displayName: "",
      propertyOrganizationId,
      entryPointIds: [],
    },
    resolver: yupResolver(AddEntryGroupValidationSchema),
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = hookForm;

  const validEntryPointIds = useMemo(
    () =>
      (getEntryPointsQuery.data ?? []).map(
        (entryPoint) => entryPoint.entryPointId
      ),
    [getEntryPointsQuery.data]
  );

  const mutationOptions = useMemo(
    () => mutationOptionsBuilder && mutationOptionsBuilder(hookForm),
    [mutationOptionsBuilder, hookForm]
  );

  const onSubmit = useCallback(
    handleSubmit((data) => addEntryGroup.mutateAsync(data).catch(noop)),
    [handleSubmit]
  );

  const addEntryGroup = useMutation<void, HTTPError, AddEntryGroupValues>(
    async (values: AddEntryGroupValues) => {
      await ky.post(`access-control/entry-group/create`, {
        json: { ...values },
      });
    },
    mutationOptions
  );

  if (getEntryPointsQuery.isLoading || !getEntryPointsQuery.isSuccess) {
    return <Loading />;
  }

  return (
    <form onSubmit={onSubmit}>
      <Grid gap={4} width={{ base: "100%", xl: "50%" }}>
        <GridItem>
          <Controller
            name="displayName"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl isInvalid={!!fieldState.error}>
                <FormLabel htmlFor="displayName">Display Name</FormLabel>
                <Input {...field} id="displayName" placeholder="Display Name" />
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </GridItem>
        <Divider />
        <GridItem>
          <Controller
            name={"entryPointIds"}
            control={control}
            render={({ field, fieldState }) => (
              <FormControl isInvalid={!!fieldState.error}>
                <FormLabel htmlFor="entryPointIds">Entry Points</FormLabel>
                <Stack spacing={5}>
                  {getEntryPointsQuery.data?.length ? (
                    <CheckboxGroup defaultValue={field.value}>
                      {getEntryPointsQuery.data
                        .sort((a, b) =>
                          a.displayName.localeCompare(b.displayName)
                        )
                        .map((entryPoint) => (
                          <Checkbox
                            key={entryPoint.entryPointId}
                            value={entryPoint.entryPointId}
                            onChange={(event) => {
                              const isChecked = event.target.checked;
                              const value = event.target.value;

                              field.onChange(
                                isChecked
                                  ? intersection(validEntryPointIds, [
                                      ...field.value,
                                      value,
                                    ])
                                  : field.value.filter((id) => id !== value)
                              );
                            }}
                          >
                            {entryPoint.displayName}
                          </Checkbox>
                        ))}
                    </CheckboxGroup>
                  ) : (
                    <Text>No Entry Points</Text>
                  )}
                </Stack>
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </GridItem>
      </Grid>
      {Footer ? (
        <Grid gap={4} mt={4}>
          {!hideFooterDivider ? (
            <GridItem>
              <Divider />
            </GridItem>
          ) : null}
          <GridItem>{Footer({ isSubmitting, onSubmit })}</GridItem>
        </Grid>
      ) : null}
    </form>
  );
};

import * as React from "react";
import { useParams } from "react-router-dom";

import { TabLink } from "src/layout/TabLink";
import { useFlags } from "src/common/hooks/useFlags";

const PropertyHeaderTabs = () => {
  const { dealerOrganizationId, propertyOrganizationId } = useParams();
  const flags = useFlags();

  return (
    <>
      <TabLink
        key="details"
        to={`/dealer-organizations/${dealerOrganizationId}/properties/${propertyOrganizationId}`}
        label="Details"
        end
      />
      <TabLink
        key="intercoms"
        to={`/dealer-organizations/${dealerOrganizationId}/properties/${propertyOrganizationId}/intercoms`}
        label="Intercoms"
      />
      <TabLink
        key="staff"
        to={`/dealer-organizations/${dealerOrganizationId}/properties/${propertyOrganizationId}/staff`}
        label="Staff"
      />
      {flags.accessControl && (
        <TabLink
          key="access-control"
          to={`/dealer-organizations/${dealerOrganizationId}/properties/${propertyOrganizationId}/access-control`}
          label="Access Control"
        />
      )}
    </>
  );
};

export default PropertyHeaderTabs;

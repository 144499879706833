import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  FormControl,
  FormErrorMessage,
  HStack,
  IconButton,
  Input,
  Spacer,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { HiOutlinePlus, HiTrash } from "react-icons/hi";
import { useQueryClient } from "react-query";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "react-query";
import * as Yup from "yup";

import { useGetSettingsQuery } from "src/routes/Property/queries";
import { Loading } from "src/common/Loading";
import { AccessCredential } from "src/common/types";
import { noop } from "src/common/util";
import { HTTPError, useKy } from "src/common/ky";
import { handleHookFormHTTPError } from "src/common/form";

interface TroubleCredentials {
  lockdownCredentials: Partial<AccessCredential>[];
  alarmResetCredentials: Partial<AccessCredential>[];
  combinedCredentials?: Partial<AccessCredential>[];
}

const credentialValidator = Yup.array(
  Yup.object().shape({
    code: Yup.string()
      .length(5)
      .matches(/\d\d\d\d\d/, "${label} must be numeric")
      .test(
        "is-valid-code",
        "${label} must be between 00000 and 65535",
        (code) => Boolean(code && code >= "00000" && code <= "65535")
      )
      .required()
      .label("Access Code"),
  })
);

const TroubleCredentialsValidationSchema = Yup.object().shape({
  lockdownCredentials: credentialValidator,
  alarmResetCredentials: credentialValidator,
});

export const Settings = () => {
  const ky = useKy();
  const credentialsChangedDisclosure = useDisclosure();
  const cancelRef = useRef<HTMLButtonElement>(null);
  const [removeCredentialsConfirmed, setRemoveCredentialsConfirmed] =
    useState<boolean>(false);
  const { propertyOrganizationId } = useParams();
  const settingsQuery = useGetSettingsQuery(propertyOrganizationId as string);

  const toast = useToast();
  const queryClient = useQueryClient();

  const {
    control,
    handleSubmit,
    getValues,
    formState: { isSubmitting, errors },
    setError,
    reset,
  } = useForm<TroubleCredentials>({
    defaultValues: {
      lockdownCredentials: [],
      alarmResetCredentials: [],
    },
    resolver: yupResolver(TroubleCredentialsValidationSchema),
  });

  const {
    fields: lockdownCredentialFields,
    append: appendLockdownCredentialField,
    remove: removeLockdownCredentialField,
  } = useFieldArray({
    control,
    name: "lockdownCredentials",
  });

  const {
    fields: alarmResetCredentialFields,
    append: appendAlarmResetCredentialField,
    remove: removeAlarmResetCredentialField,
  } = useFieldArray({
    control,
    name: "alarmResetCredentials",
  });

  const onSubmit = useCallback(
    (values: TroubleCredentials) => {
      setRemoveCredentialsConfirmed(false);
      const backfillCredentialIds = (
        newCredentials: Partial<AccessCredential>[],
        oldCredentials: AccessCredential[] | undefined
      ) =>
        newCredentials.map((cred) => {
          const accessCredentialId = oldCredentials?.find(
            ({ code }) => code === cred.code
          )?.accessCredentialId;
          return {
            ...cred,
            ...(accessCredentialId && { accessCredentialId }),
          };
        });
      return editSettings
        .mutateAsync({
          lockdownCredentials: backfillCredentialIds(
            values.lockdownCredentials,
            settingsQuery.data?.lockdownCredentials
          ),
          alarmResetCredentials: backfillCredentialIds(
            values.alarmResetCredentials,
            settingsQuery.data?.alarmResetCredentials
          ),
        })
        .catch(noop);
    },
    [handleSubmit]
  );

  const editSettings = useMutation<void, HTTPError, TroubleCredentials>({
    mutationFn: async (values: TroubleCredentials) => {
      await ky.patch(`access-control/settings`, {
        json: {
          ...values,
          propertyOrganizationId,
        },
      });
    },
    onSuccess: () => {
      toast({
        description: "Settings updated",
        status: "success",
      });
      queryClient.invalidateQueries();
    },
    onError: handleHookFormHTTPError(setError, getValues, toast),
  });

  useEffect(() => {
    if (!settingsQuery.isFetching && settingsQuery.isSuccess) {
      reset({
        lockdownCredentials: settingsQuery.data.lockdownCredentials.map(
          ({ accessCredentialId, code, propertyOrganizationId, type }) => ({
            accessCredentialId,
            code,
            propertyOrganizationId,
            type,
          })
        ),
        alarmResetCredentials: settingsQuery.data.alarmResetCredentials.map(
          ({ accessCredentialId, code, propertyOrganizationId, type }) => ({
            accessCredentialId,
            code,
            propertyOrganizationId,
            type,
          })
        ),
        combinedCredentials: settingsQuery.data.combinedCredentials,
      });
    }
  }, [settingsQuery.isFetching]);

  useEffect(() => {
    if (removeCredentialsConfirmed) {
      onSubmit(getValues());
      credentialsChangedDisclosure.onClose();
    }
  }, [removeCredentialsConfirmed]);

  return (
    <>
      <form
        onSubmit={handleSubmit((values) => {
          let credentialsDeleted;

          if (settingsQuery.data) {
            const oldCodes = [
              ...settingsQuery.data.lockdownCredentials,
              ...settingsQuery.data.alarmResetCredentials,
            ].map((credential) => credential.accessCredentialId);
            const newCodes = new Set(
              [
                ...values.lockdownCredentials,
                ...values.alarmResetCredentials,
              ].map((credential) => credential.accessCredentialId)
            );
            credentialsDeleted = oldCodes.filter(
              (credentialId) => !newCodes.has(credentialId)
            ).length;
          }

          if (credentialsDeleted) {
            credentialsChangedDisclosure.onOpen();
          } else {
            return onSubmit(values);
          }
        })}
      >
        <VStack align="left" spacing={50}>
          <VStack align="left">
            <HStack width="100%">
              <Text fontSize="xl" fontWeight={600}>
                Lockdown Credentials
              </Text>
              <Spacer />
              <Button
                leftIcon={<HiOutlinePlus />}
                variant="outline"
                colorScheme="brand.blue"
                onClick={() =>
                  appendLockdownCredentialField({
                    code: "",
                    propertyOrganizationId,
                  })
                }
              >
                Add Credential
              </Button>
            </HStack>

            {settingsQuery.isLoading && <Loading />}

            {!settingsQuery.isLoading && !lockdownCredentialFields.length && (
              <Text>There are no lockdown credentials</Text>
            )}

            {lockdownCredentialFields.map((credential, i) => (
              <Controller
                key={credential.id}
                name={`lockdownCredentials.${i}.code`}
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl
                    isInvalid={!!fieldState.error}
                    isDisabled={!!credential.accessCredentialId}
                  >
                    <HStack width="50%">
                      <Input
                        {...field}
                        maxLength={5}
                        isDisabled={!!credential.accessCredentialId}
                      />
                      <IconButton
                        colorScheme="brand.red"
                        variant="link"
                        aria-label="Delete access credential"
                        icon={<HiTrash />}
                        size="lg"
                        onClick={() => removeLockdownCredentialField(i)}
                      />
                    </HStack>
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            ))}

            {!errors.lockdownCredentials &&
            !errors.alarmResetCredentials &&
            errors.combinedCredentials ? (
              <FormControl isInvalid={true}>
                <FormErrorMessage>
                  {errors.combinedCredentials.message}
                </FormErrorMessage>
              </FormControl>
            ) : null}

            {errors.lockdownCredentials?.message ? (
              <FormControl isInvalid={true}>
                <FormErrorMessage>
                  {errors.lockdownCredentials.message}
                </FormErrorMessage>
              </FormControl>
            ) : null}
          </VStack>
          <VStack align="left">
            <HStack width="100%">
              <Text fontSize="xl" fontWeight={600}>
                Alarm Reset Credentials
              </Text>
              <Spacer />
              <Button
                leftIcon={<HiOutlinePlus />}
                variant="outline"
                colorScheme="brand.blue"
                onClick={() =>
                  appendAlarmResetCredentialField({
                    code: "",
                    propertyOrganizationId,
                  })
                }
              >
                Add Credential
              </Button>
            </HStack>

            {settingsQuery.isLoading && <Loading />}

            {!settingsQuery.isLoading && !alarmResetCredentialFields.length && (
              <Text>There are no alarm reset credentials</Text>
            )}

            {alarmResetCredentialFields.map((credential, i) => (
              <Controller
                key={credential.id}
                name={`alarmResetCredentials.${i}.code`}
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl
                    isInvalid={!!fieldState.error}
                    isDisabled={!!credential.accessCredentialId}
                  >
                    <HStack width="50%">
                      <Input
                        {...field}
                        maxLength={5}
                        isDisabled={!!credential.accessCredentialId}
                      />
                      <IconButton
                        colorScheme="brand.red"
                        variant="link"
                        aria-label="Delete access credential"
                        icon={<HiTrash />}
                        size="lg"
                        onClick={() => removeAlarmResetCredentialField(i)}
                      />
                    </HStack>
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            ))}

            {!errors.lockdownCredentials &&
            !errors.alarmResetCredentials &&
            errors.combinedCredentials ? (
              <FormControl isInvalid={true}>
                <FormErrorMessage>
                  {errors.combinedCredentials.message}
                </FormErrorMessage>
              </FormControl>
            ) : null}

            {errors.alarmResetCredentials?.message ? (
              <FormControl isInvalid={true}>
                <FormErrorMessage>
                  {errors.alarmResetCredentials.message}
                </FormErrorMessage>
              </FormControl>
            ) : null}
          </VStack>
          <HStack>
            <Spacer />
            <Button
              type="submit"
              colorScheme="brand.blue"
              isLoading={isSubmitting}
            >
              Save Credentials
            </Button>
          </HStack>
        </VStack>
      </form>

      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={credentialsChangedDisclosure.onClose}
        isOpen={credentialsChangedDisclosure.isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Changes to Access Credentials</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            Are you sure? You can&apos;t undo this action.
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={credentialsChangedDisclosure.onClose}
            >
              Cancel
            </Button>
            <Button
              colorScheme="brand.red"
              ml="3"
              onClick={() => {
                setRemoveCredentialsConfirmed(true);
              }}
            >
              Save Settings
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

import React from "react";
import { Badge, Stack, Text, Tooltip } from "@chakra-ui/react";

import { CircleInfo } from "src/common/icons/CircleInfo";
import { SyncStatus } from "src/common/types";

type StatusBadgeProps = {
  status: SyncStatus | undefined;
  toolTip: boolean;
};

export const PanelSyncStatus = ({ status, toolTip }: StatusBadgeProps) => {
  const isSynced = status?.code === "Success";
  const isError = status?.code === "Error";

  const badgeColorScheme = isSynced ? "green" : isError ? "red" : "gray";
  const statusText = isSynced ? "Online" : isError ? "Error" : "Unknown";
  const errorTextColor = "#718096";

  return (
    <Stack direction="row" spacing={4} align="center">
      <Badge
        variant="solid"
        colorScheme={badgeColorScheme}
        width="fit-content"
        textAlign="center"
        paddingX={2}
      >
        <Text>{statusText}</Text>
      </Badge>
      {isError &&
        (!toolTip ? (
          <Text fontSize="12" fontWeight={700} color={errorTextColor}>
            {status.message}
          </Text>
        ) : (
          <Tooltip label={status.message} aria-label="error">
            <CircleInfo />
          </Tooltip>
        ))}
    </Stack>
  );
};

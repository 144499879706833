import React, { useCallback, useMemo } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import {
  Checkbox,
  CheckboxGroup,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Stack,
  Switch,
  Text,
} from "@chakra-ui/react";
import { intersection } from "lodash";
import { useMutation } from "react-query";

import { EditAccessProfileValidationSchema } from "src/routes/Property/schema";
import {
  useGetAccessProfileQuery,
  useGetEntryGroupsQuery,
  useGetEntrySchedulesQuery,
} from "src/routes/Property/queries";
import { AccessProfile, AccessProfileDMPProperties } from "src/common/types";
import { noop } from "src/common/util";
import { AccessControlFormProps } from "src/routes/Property/AccessControl/types";
import { HTTPError, useKy } from "src/common/ky";

export type UpdateAccessProfileValues = {
  displayName: string;
  entryGroupIds: string[];
  entryScheduleIds: string[];
  dmpProperties: AccessProfileDMPProperties | undefined;
};

export type EditAccessProfileFormProps =
  AccessControlFormProps<UpdateAccessProfileValues> & {
    accessProfileId: string;
    propertyOrganizationId: string;
  };

export const EditAccessProfileForm = (props: EditAccessProfileFormProps) => {
  const {
    accessProfileId,
    propertyOrganizationId,
    Footer,
    hideFooterDivider,
    mutationOptionsBuilder,
    children,
  } = props;

  const ky = useKy();

  const entryGroupsQuery = useGetEntryGroupsQuery(
    propertyOrganizationId as string
  );
  const entrySchedulesQuery = useGetEntrySchedulesQuery(
    propertyOrganizationId as string
  );

  const hookForm = useForm<UpdateAccessProfileValues>({
    defaultValues: {
      displayName: "",
      entryGroupIds: [],
      entryScheduleIds: [],
      dmpProperties: {
        sensorReset: false,
      },
    },
    resolver: yupResolver(EditAccessProfileValidationSchema),
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty },
    reset,
  } = hookForm;

  const validEntryGroupIds = useMemo(
    () => (entryGroupsQuery.data ?? []).map((eg) => eg.entryGroupId),
    [entryGroupsQuery.data]
  );

  const validEntryScheduleIds = useMemo(
    () => (entrySchedulesQuery.data ?? []).map((es) => es.entryScheduleId),
    [entrySchedulesQuery.data]
  );

  useGetAccessProfileQuery(accessProfileId, {
    onSuccess: (accessProfile: AccessProfile) => {
      if (!isDirty) {
        reset({
          displayName: accessProfile.displayName || "",
          entryGroupIds: accessProfile.entryGroupIds || [],
          entryScheduleIds: accessProfile.entryScheduleIds || [],
          dmpProperties: accessProfile.dmpProperties ?? { sensorReset: false },
        });
      }
    },
  });

  const mutationOptions = useMemo(
    () => mutationOptionsBuilder && mutationOptionsBuilder(hookForm),
    [mutationOptionsBuilder, hookForm]
  );

  const onSubmit = useCallback(
    handleSubmit(
      async (values: UpdateAccessProfileValues) =>
        await updateAccessProfile.mutateAsync(values).catch(noop)
    ),
    [handleSubmit]
  );

  const updateAccessProfile = useMutation<
    void,
    HTTPError,
    UpdateAccessProfileValues
  >(async (values: UpdateAccessProfileValues) => {
    await ky.put(`access-control/profile/${accessProfileId}`, {
      json: values,
    });
  }, mutationOptions);

  return (
    <form onSubmit={onSubmit}>
      <Grid gap={4} width={{ base: "100%", xl: "50%" }}>
        <GridItem>
          <Controller
            name="displayName"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl isInvalid={!!fieldState.error}>
                <FormLabel htmlFor="displayName">Display Name</FormLabel>
                <Input {...field} id="displayName" placeholder="Display Name" />
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </GridItem>
        <GridItem>
          <Controller
            name={"dmpProperties.sensorReset"}
            control={control}
            render={({ field: { value, ...field }, fieldState }) => (
              <FormControl isInvalid={!!fieldState.error}>
                <Flex alignItems={"center"}>
                  <FormLabel htmlFor="fireDoorRelease" mb={0}>
                    Fire Door Release
                  </FormLabel>
                  <Switch isChecked={value} {...field} id="fireDoorRelease" />
                </Flex>
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </GridItem>
        <GridItem>
          <Divider />
        </GridItem>

        <GridItem>
          <Controller
            name={"entryGroupIds"}
            control={control}
            render={({ field, fieldState }) => (
              <FormControl isInvalid={!!fieldState.error}>
                <FormLabel htmlFor="entryGroupIds">Entry Groups</FormLabel>
                <Stack spacing={5}>
                  {entryGroupsQuery.data?.length ? (
                    <CheckboxGroup value={field.value}>
                      {entryGroupsQuery.data.map((entryGroup) => (
                        <Checkbox
                          key={entryGroup.entryGroupId}
                          value={entryGroup.entryGroupId}
                          onChange={(event) => {
                            const isChecked = event.target.checked;
                            const value = event.target.value;

                            field.onChange(
                              isChecked
                                ? intersection(validEntryGroupIds, [
                                    ...field.value,
                                    value,
                                  ])
                                : intersection(
                                    validEntryGroupIds,
                                    field.value
                                  ).filter((id) => id !== value)
                            );
                          }}
                        >
                          {entryGroup.displayName}
                        </Checkbox>
                      ))}
                    </CheckboxGroup>
                  ) : (
                    <Text>No Entry Groups</Text>
                  )}
                  <FormErrorMessage>
                    {fieldState.error?.message}
                  </FormErrorMessage>
                </Stack>
              </FormControl>
            )}
          />
        </GridItem>
        <GridItem>
          <Divider />
        </GridItem>
        <GridItem>
          <Controller
            name={"entryScheduleIds"}
            control={control}
            render={({ field, fieldState }) => (
              <FormControl isInvalid={!!fieldState.error}>
                <FormLabel htmlFor="entryScheduleIds">
                  Entry Schedules
                </FormLabel>
                <Stack spacing={5}>
                  {entrySchedulesQuery.data?.length ? (
                    <CheckboxGroup value={field.value}>
                      {entrySchedulesQuery.data.map((entrySchedule) => (
                        <Checkbox
                          key={entrySchedule.entryScheduleId}
                          value={entrySchedule.entryScheduleId}
                          onChange={(event) => {
                            const isChecked = event.target.checked;
                            const value = event.target.value;

                            field.onChange(
                              isChecked
                                ? intersection(validEntryScheduleIds, [
                                    ...field.value,
                                    value,
                                  ])
                                : intersection(
                                    validEntryScheduleIds,
                                    field.value
                                  ).filter((id) => id !== value)
                            );
                          }}
                        >
                          {entrySchedule.displayName}
                        </Checkbox>
                      ))}
                    </CheckboxGroup>
                  ) : (
                    <Text>No Entry Schedules</Text>
                  )}
                  <FormErrorMessage>
                    {fieldState.error?.message}
                  </FormErrorMessage>
                </Stack>
              </FormControl>
            )}
          />
        </GridItem>
      </Grid>
      {Footer ? (
        <Grid gap={4} mt={4}>
          {!hideFooterDivider ? (
            <GridItem>
              <Divider />
            </GridItem>
          ) : null}
          <GridItem>{Footer({ isSubmitting, onSubmit })}</GridItem>
        </Grid>
      ) : null}
      {children
        ? typeof children === "function"
          ? children({ isSubmitting, onSubmit })
          : children
        : null}
    </form>
  );
};

import { SimpleGrid } from "@chakra-ui/react";
import React, { useMemo } from "react";

import { ComponentReport, IntercomComponentStatus } from "src/common/types";
import { ComponentStatusItem } from "src/routes/IntercomUnits/IntercomActivation/IntercomSystem/IntercomHealthStatus/ComponentStatusItem";

type ComponentStatusListProps = {
  components?: ComponentReport[];
};

export const ComponentStatusList = ({
  components = [],
}: ComponentStatusListProps) => {
  const sortedComponents = useMemo(
    () =>
      components.sort((a, b) => {
        // Sort by OFFLINE/UNKNOWN status first
        if (
          a.status !== IntercomComponentStatus.ONLINE &&
          b.status === IntercomComponentStatus.ONLINE
        ) {
          return -1;
        } else if (
          a.status === IntercomComponentStatus.ONLINE &&
          b.status !== IntercomComponentStatus.ONLINE
        ) {
          return 1;
        } else {
          // If both components have the same status or both are not "ONLINE",
          // then sort alphabetically by componentType
          return a.componentType.localeCompare(b.componentType);
        }
      }),
    [components]
  );

  return (
    <SimpleGrid columns={[1, 1, 2]} spacing={1.5}>
      {sortedComponents.map((component) => (
        <ComponentStatusItem
          key={component.componentType}
          component={component}
        />
      ))}
    </SimpleGrid>
  );
};

import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import {
  HiOutlineDotsVertical,
  HiOutlinePencil,
  HiOutlineTrash,
} from "react-icons/hi";
import { HTTPError } from "ky";
import { useQueryClient } from "react-query";

import { useDeleteAccessPanel } from "src/routes/Property/hooks";
import { Panel } from "src/common/types";
import { PanelSyncStatus } from "src/common/PanelSyncStatus";
import { formatDate } from "src/common/formatters";
import { getPropertyAccessPanelQueryKey } from "src/routes/Property/queries";
import { handleHTTPError } from "src/common/form";

export type AccessPanelProps = {
  panel: Panel;
};

export function AccessPanel({ panel }: AccessPanelProps) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const toast = useToast();
  const { dealerOrganizationId, propertyOrganizationId } = useParams();

  const {
    isDeletingAccessPanel,
    deleteAccessPanelMutation,
    setDeletingAccessPanelId,
  } = useDeleteAccessPanel({
    onSuccess: () => {
      queryClient.invalidateQueries(
        getPropertyAccessPanelQueryKey(propertyOrganizationId)
      );
      toast({
        description: "Panel removed",
        status: "success",
      });
      setDeletingAccessPanelId(undefined);
    },
    onError: async (error: HTTPError) => {
      await handleHTTPError(toast)(error);
      setDeletingAccessPanelId(undefined);
    },
  });

  return (
    <>
      <Box>
        <HStack justifyContent={"space-between"}>
          <Text fontSize="xl" fontWeight={600}>
            Access Panel
          </Text>
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Actions"
              icon={<HiOutlineDotsVertical />}
              variant="clear"
            >
              Actions
            </MenuButton>
            <MenuList w="320px">
              <MenuItem
                justifyContent="space-between"
                onClick={() => {
                  navigate(
                    `/dealer-organizations/${dealerOrganizationId}/properties/${propertyOrganizationId}/access-control/${panel.panelId}/edit`
                  );
                }}
              >
                Edit Panel Details
                <HiOutlinePencil />
              </MenuItem>
              <MenuItem
                opacity={1}
                justifyContent="space-between"
                onClick={() => {
                  return setDeletingAccessPanelId(panel.panelId);
                }}
              >
                <Flex>
                  <Text>Remove Access Panel</Text>
                </Flex>
                <HiOutlineTrash />
              </MenuItem>
            </MenuList>
          </Menu>
        </HStack>
        <Divider />
        <Table>
          <Thead>
            <Tr>
              <Th>Status</Th>
              <Th>Last Synced</Th>
              <Th>Serial Number</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>
                <PanelSyncStatus status={panel.syncStatus} toolTip={true} />
              </Td>
              <Td>
                {panel.syncStatus
                  ? formatDate(panel.syncStatus.date, "medium")
                  : "N/A"}
              </Td>
              <Td>{panel.serialNumber}</Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
      <Modal
        isOpen={isDeletingAccessPanel}
        onClose={() => {
          setDeletingAccessPanelId(undefined);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Remove Access Panel</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure? You can&apos;t undo this action.</ModalBody>

          <ModalFooter>
            <Button
              colorScheme="brand.lightGray"
              color="gray.700"
              mr={3}
              onClick={() => {
                setDeletingAccessPanelId(undefined);
              }}
            >
              Cancel
            </Button>
            <Button
              colorScheme="brand.red"
              onClick={() => deleteAccessPanelMutation.mutate()}
              isDisabled={deleteAccessPanelMutation.isLoading}
            >
              Remove Access Panel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

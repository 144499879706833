import React, { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Flex,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import {
  HiOutlineDotsVertical,
  HiOutlinePencil,
  HiOutlineSearch,
  HiOutlineTrash,
} from "react-icons/hi";
import { GoX } from "react-icons/go";
import { sortBy } from "lodash";
import { useQueryClient } from "react-query";

import { Loading } from "src/common/Loading";
import {
  getEntryGroupsQueryKey,
  useGetEntryGroupsQuery,
} from "src/routes/Property/queries";
import { useDeleteEntryGroup } from "src/routes/Property/hooks";
import { noop } from "src/common/util";
import { HTTPError } from "src/common/ky";
import { handleHTTPError } from "src/common/form";

export function EntryGroupList() {
  const navigate = useNavigate();
  const { dealerOrganizationId, propertyOrganizationId } = useParams();
  const entryGroupsQuery = useGetEntryGroupsQuery(
    propertyOrganizationId as string
  );
  const queryClient = useQueryClient();
  const toast = useToast();

  const [search, setSearch] = useState("");
  const entryGroups = useMemo(
    () =>
      sortBy(entryGroupsQuery.data ?? [], (v) => v.displayName.toLowerCase()),
    [entryGroupsQuery.data]
  );
  const filteredEntryGroups = useMemo(
    () =>
      entryGroups.filter((eg) =>
        eg.displayName
          .trim()
          .toLowerCase()
          .includes(search.trim().toLowerCase())
      ),
    [search, entryGroups]
  );

  const showLoadingIndicator = useMemo(
    () => entryGroupsQuery.isLoading || !entryGroupsQuery.isSuccess,
    [entryGroupsQuery.isLoading, entryGroupsQuery.isSuccess]
  );

  const {
    isDeletingEntryGroup,
    deleteEntryGroupMutation,
    setDeletingEntryGroupId,
  } = useDeleteEntryGroup({
    onSuccess: () => {
      queryClient.invalidateQueries(
        getEntryGroupsQueryKey(propertyOrganizationId as string)
      );
      setDeletingEntryGroupId(undefined);
      toast({
        description: "Entry Group deleted",
        status: "success",
      });
    },
    onError: async (error: HTTPError) => {
      await handleHTTPError(toast)(error);
      setDeletingEntryGroupId(undefined);
    },
  });

  return (
    <>
      <Stack
        direction={{ base: "column", lg: "row" }}
        alignItems={{ base: "stretch", lg: "center" }}
        justifyContent={"space-between"}
      >
        <Text fontSize="xl" fontWeight={600}>
          Entry Groups
        </Text>
        <HStack flex={1} justifyContent={"end"}>
          <InputGroup width={"100%"} maxWidth={500}>
            <InputLeftElement pointerEvents="none" color="gray.300">
              <HiOutlineSearch />
            </InputLeftElement>
            <Input
              value={search}
              onChange={({ currentTarget: { value } }) => setSearch(value)}
              placeholder="Search by Name"
            />
            <InputRightElement>
              <Button variant="ghost" onClick={() => setSearch("")}>
                <GoX />
              </Button>
            </InputRightElement>
          </InputGroup>
          <Button
            flexShrink={0}
            margin={2}
            onClick={() =>
              navigate(
                `/dealer-organizations/${dealerOrganizationId}/properties/${propertyOrganizationId}/access-control/entry-groups/add`
              )
            }
          >
            Add Entry Group
          </Button>
        </HStack>
      </Stack>
      {showLoadingIndicator ? (
        <Loading />
      ) : filteredEntryGroups.length ? (
        <Table>
          <Thead>
            <Tr>
              <Th width={"100%"}>Display Name</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredEntryGroups.map((entryGroup) => {
              return (
                <Tr key={entryGroup.entryGroupId}>
                  <Td>{entryGroup.displayName}</Td>
                  <Td>
                    <Menu>
                      <MenuButton
                        as={IconButton}
                        aria-label="Actions"
                        icon={<HiOutlineDotsVertical />}
                        variant="clear"
                      >
                        Actions
                      </MenuButton>
                      <MenuList w="320px">
                        <MenuItem
                          justifyContent="space-between"
                          isDisabled={false}
                          onClick={() => {
                            navigate(
                              `/dealer-organizations/${dealerOrganizationId}/properties/${propertyOrganizationId}/access-control/entry-groups/${entryGroup.entryGroupId}/edit`
                            );
                          }}
                        >
                          Edit Entry Group
                          <HiOutlinePencil />
                        </MenuItem>
                        <MenuItem
                          opacity={1}
                          justifyContent="space-between"
                          onClick={() =>
                            setDeletingEntryGroupId(entryGroup.entryGroupId)
                          }
                        >
                          <Flex>
                            <Text>Remove Entry Group</Text>
                          </Flex>
                          <HiOutlineTrash />
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      ) : entryGroups.length ? (
        <Text>There are no entry groups matching your search</Text>
      ) : (
        <Text>There are no entry groups</Text>
      )}
      <Modal
        isOpen={isDeletingEntryGroup}
        onClose={() => {
          setDeletingEntryGroupId(undefined);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Remove Entry Group</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure? You can&apos;t undo this action.</ModalBody>

          <ModalFooter>
            <Button
              colorScheme="brand.lightGray"
              color="gray.700"
              mr={3}
              onClick={() => {
                setDeletingEntryGroupId(undefined);
              }}
            >
              Cancel
            </Button>
            <Button
              colorScheme="brand.red"
              onClick={() => deleteEntryGroupMutation.mutateAsync().catch(noop)}
              isDisabled={deleteEntryGroupMutation.isLoading}
            >
              Remove Entry Group
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

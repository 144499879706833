import {
  Center,
  HStack,
  Icon,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { FaCircleCheck } from "react-icons/fa6";
import { BsArrowDown, BsArrowRight } from "react-icons/bs";
import React from "react";

import { ACCESS_CONTROL_WIZARD_ORDERED_STEPS } from "..";

import {
  ACCESS_CONTROL_WIZARD_STEP_LABELS,
  AccessControlWizardStep,
  AccessControlWizardStepState,
} from "src/routes/Property/AccessControl/Wizard/index";

export const WizardSidebarStepView = (props: {
  step: AccessControlWizardStep;
  stepState: AccessControlWizardStepState;
  withArrow: boolean;
}) => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Stack spacing={0} opacity={props.stepState === "inactive" ? 0.5 : 1}>
      <HStack spacing={4} mb={!isMobile ? 4 : 0}>
        {props.stepState === "completed" ? (
          <Icon as={FaCircleCheck} color={"brand.blue.600"} boxSize={39} />
        ) : (
          <Center border={"2px solid black"} h={39} w={39} rounded={"full"}>
            <Text fontWeight={"bold"}>
              {ACCESS_CONTROL_WIZARD_ORDERED_STEPS.indexOf(props.step) + 1}
            </Text>
          </Center>
        )}
        {!isMobile ? (
          <Text
            fontWeight={"bold"}
            color={
              props.stepState === "completed" ? "brand.blue.600" : undefined
            }
          >
            {ACCESS_CONTROL_WIZARD_STEP_LABELS[props.step]}
          </Text>
        ) : props.withArrow ? (
          <Icon
            color={
              props.stepState === "completed" ? "brand.blue.600" : undefined
            }
            alignSelf={"center"}
            as={BsArrowRight}
            boxSize={25}
          />
        ) : null}
      </HStack>
      {!isMobile && props.withArrow ? (
        <Icon
          color={props.stepState === "completed" ? "brand.blue.600" : undefined}
          alignSelf={"center"}
          as={BsArrowDown}
          boxSize={25}
        />
      ) : null}
    </Stack>
  );
};

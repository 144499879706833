import React, { useState } from "react";
import {
  Button,
  ButtonGroup,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { useQueryClient } from "react-query";

import {
  PageHeader,
  PageHeaderBackLink,
  PageHeaderSubtitle,
  PageHeaderTitle,
} from "src/layout/PageHeader";
import { PageContent } from "src/layout/PageContent";
import { getEntryScheduleQueryKey } from "src/routes/Property/queries";
import { EditEntryScheduleForm } from "src/routes/Property/AccessControl/EntrySchedules/components/EditEntryScheduleForm";
import { handleHookFormHTTPError } from "src/common/form";

export const EditEntrySchedule = () => {
  const { propertyOrganizationId, dealerOrganizationId, entryScheduleId } =
    useParams();

  const toast = useToast();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <PageHeader>
        <PageHeaderBackLink>
          <Link
            as={RouterLink}
            to={`/dealer-organizations/${dealerOrganizationId}/properties/${propertyOrganizationId}/access-control?panelTab=entry-schedules`}
          >
            &#8249; Back to list
          </Link>
        </PageHeaderBackLink>
        <PageHeaderTitle>Edit Entry Schedule</PageHeaderTitle>
        <PageHeaderSubtitle>
          Enter entry schedule details below
        </PageHeaderSubtitle>
      </PageHeader>
      <PageContent>
        <EditEntryScheduleForm
          entryScheduleId={entryScheduleId as string}
          mutationOptionsBuilder={({ setError, getValues }) => ({
            onSuccess: () => {
              toast({
                description: "Entry Schedule updated",
                status: "success",
              });
              queryClient.invalidateQueries(
                getEntryScheduleQueryKey(entryScheduleId as string)
              );
              navigate(
                `/dealer-organizations/${dealerOrganizationId}/properties/${propertyOrganizationId}/access-control?panelTab=entry-schedules`
              );
            },
            onError: handleHookFormHTTPError(setError, getValues, toast),
          })}
          Footer={({ isSubmitting }) => (
            <ButtonGroup display="flex" justifyContent="flex-end">
              <Link
                as={RouterLink}
                to={`/dealer-organizations/${dealerOrganizationId}/properties/${propertyOrganizationId}/access-control?panelTab=entry-schedules`}
              >
                <Button>Cancel</Button>
              </Link>
              <Button
                colorScheme="brand.blue"
                isLoading={isSubmitting}
                onClick={() => setIsModalOpen(true)}
              >
                Save Changes
              </Button>
            </ButtonGroup>
          )}
        >
          {({ onSubmit }) => (
            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Edit Entry Schedule</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  Are you sure you want to edit this entry schedule?
                </ModalBody>
                <ModalFooter>
                  <Flex justifyContent={"flex-end"}>
                    <Button
                      colorScheme="brand.lightGray"
                      color="gray.700"
                      mr={3}
                      onClick={() => setIsModalOpen(false)}
                    >
                      Close
                    </Button>
                    <Button
                      colorScheme="brand.blue"
                      onClick={async () => {
                        setIsModalOpen(false);
                        await onSubmit();
                      }}
                      type={"submit"}
                      ml={4}
                    >
                      Save Changes
                    </Button>
                  </Flex>
                </ModalFooter>
              </ModalContent>
            </Modal>
          )}
        </EditEntryScheduleForm>
      </PageContent>
    </>
  );
};

import React, { PropsWithChildren } from "react";
import { Box } from "@chakra-ui/react";

export type WizardStepFormViewProps = PropsWithChildren;

export const WizardStepFormView = (props: WizardStepFormViewProps) => {
  return (
    <Box mt={10} mb={20}>
      {props.children}
    </Box>
  );
};

import React from "react";
import { forwardRef, Select, SelectProps } from "@chakra-ui/react";
import moment from "moment-timezone";

const utcOffset = (timeZone: string) => moment.tz(timeZone).utcOffset() / 60;

const observesDST = (timeZone: string) =>
  moment.tz([moment.tz(timeZone).year(), 0], timeZone).utcOffset() !==
  moment.tz([moment.tz(timeZone).year(), 5], timeZone).utcOffset();

const NORMALIZED_US_TIMEZONES = [
  {
    name: "America/Los_Angeles",
    label: "Pacific Time",
  },
  {
    name: "America/Denver",
    label: "Mountain Time",
  },
  {
    name: "America/Phoenix",
    label: "Mountain Time",
  },
  {
    name: "America/Chicago",
    label: "Central Time",
  },
  {
    name: "America/New_York",
    label: "Eastern Time",
  },
  {
    name: "America/Anchorage",
    label: "Alaska Time",
  },
  {
    name: "America/Adak",
    label: "Hawaii Time",
  },
  {
    name: "Pacific/Honolulu",
    label: "Hawaii Time",
  },
].map((tz) => ({
  ...tz,
  observesDST: observesDST(tz.name),
  utcOffset: utcOffset(tz.name),
}));

const VALID_OPTIONS = NORMALIZED_US_TIMEZONES.map((tz) => ({
  ...tz,
  label: `${tz.label} ${!tz.observesDST ? "(No DST)" : ""}`.trim(),
}));

export const getNormalizedUSTimezone = (timeZone?: string) => {
  try {
    const _timeZone = timeZone ?? moment.tz.guess(true);
    const validOption = NORMALIZED_US_TIMEZONES.find(
      (ntz) =>
        ntz.name === _timeZone ||
        (ntz.utcOffset === utcOffset(_timeZone) &&
          ntz.observesDST === observesDST(_timeZone))
    );

    return validOption?.name;
  } catch (e) {
    return undefined;
  }
};

export const USTimezoneSelect = forwardRef((props: SelectProps, ref) => {
  return (
    <Select ref={ref} {...props}>
      {VALID_OPTIONS.map((option) => (
        <option key={option.name} value={option.name}>
          {option.label}
        </option>
      ))}
    </Select>
  );
});

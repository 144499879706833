import React, { useMemo, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Portal,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { sortBy } from "lodash";
import { useQueryClient } from "react-query";
import { HiOutlinePencil, HiOutlineTrash } from "react-icons/hi";

import {
  getAccessProfileQueryKey,
  getAccessProfilesByPropertyQueryKey,
  getAccessProfilesQueryKey,
  useGetAccessProfilesQuery,
} from "src/routes/Property/queries";
import { Loading } from "src/common/Loading";
import { noop } from "src/common/util";
import { handleHookFormHTTPError, handleHTTPError } from "src/common/form";
import { WizardStepFormView } from "src/routes/Property/AccessControl/Wizard/components/WizardStepFormView";
import { useDeleteProfile } from "src/routes/Property/hooks";
import { CreateAccessProfileForm } from "src/routes/Property/AccessControl/Profiles/components/CreateAccessProfileForm";
import { EditAccessProfileForm } from "src/routes/Property/AccessControl/Profiles/components/EditAccessProfileForm";
import { HTTPError } from "src/common/ky";

export type CreateProfilesStepProps = {
  propertyOrganizationId: string;
  navbarPortalRef: React.RefObject<HTMLElement | null>;
  Footer: (props: { addAccessProfile: () => void }) => React.ReactElement;
};

export const CreateAccessProfilesStep = (props: CreateProfilesStepProps) => {
  const { propertyOrganizationId, navbarPortalRef, Footer } = props;
  const accessProfilesQuery = useGetAccessProfilesQuery(
    propertyOrganizationId as string
  );
  const toast = useToast();
  const queryClient = useQueryClient();

  const [editingAccessProfileId, setEditingAccessProfileId] =
    useState<string>();
  const [creatingAccessProfile, setCreatingAccessProfile] = useState(false);

  const accessProfiles = useMemo(
    () =>
      sortBy(accessProfilesQuery.data ?? [], (v) =>
        v.displayName.toLowerCase()
      ),
    [accessProfilesQuery.data]
  );

  const { isDeletingProfile, deleteProfileMutation, setDeletingProfileId } =
    useDeleteProfile({
      onSuccess: () => {
        queryClient.invalidateQueries(getAccessProfilesByPropertyQueryKey());
        setDeletingProfileId(undefined);
        toast({
          description: "Profile deleted",
          status: "success",
        });
      },
      onError: async (error: HTTPError) => {
        await handleHTTPError(toast)(error);
        setDeletingProfileId(undefined);
      },
    });

  if (accessProfilesQuery.isLoading || !accessProfilesQuery.isSuccess) {
    return <Loading />;
  }

  return (
    <>
      <Stack>
        <Text fontSize="xl" fontWeight={600}>
          Profile Creation
        </Text>
        <Text fontSize="md" color={"gray.500"}>
          Assign Entry Groups and Entry Schedules to a profile that will be
          attached to property users.
        </Text>
      </Stack>

      {creatingAccessProfile ? (
        <WizardStepFormView>
          <CreateAccessProfileForm
            propertyOrganizationId={propertyOrganizationId as string}
            mutationOptionsBuilder={({ setError, getValues }) => ({
              onSuccess: () => {
                toast({
                  description: "Profile added",
                  status: "success",
                });
                queryClient.invalidateQueries(
                  getAccessProfilesQueryKey(propertyOrganizationId)
                );
                setCreatingAccessProfile(false);
              },
              onError: handleHookFormHTTPError(setError, getValues, toast),
            })}
            hideFooterDivider={true}
            Footer={({ isSubmitting, onSubmit }) => (
              <Portal containerRef={navbarPortalRef}>
                <ButtonGroup display="flex" justifyContent="flex-end">
                  <Button onClick={() => setCreatingAccessProfile(false)}>
                    Cancel
                  </Button>
                  <Button
                    onClick={() => onSubmit()}
                    colorScheme="brand.blue"
                    isLoading={isSubmitting}
                  >
                    Save
                  </Button>
                </ButtonGroup>
              </Portal>
            )}
          />
        </WizardStepFormView>
      ) : null}

      {editingAccessProfileId ? (
        <WizardStepFormView>
          <EditAccessProfileForm
            accessProfileId={editingAccessProfileId}
            propertyOrganizationId={propertyOrganizationId}
            mutationOptionsBuilder={({ setError, getValues }) => ({
              onSuccess: () => {
                toast({
                  description: "Entry Schedule updated",
                  status: "success",
                });
                queryClient.invalidateQueries(
                  getAccessProfileQueryKey(editingAccessProfileId as string)
                );
                queryClient.invalidateQueries(
                  getAccessProfilesQueryKey(propertyOrganizationId as string)
                );
                setEditingAccessProfileId(undefined);
              },
              onError: handleHookFormHTTPError(setError, getValues, toast),
            })}
            hideFooterDivider={true}
            Footer={({ isSubmitting, onSubmit }) => (
              <Portal containerRef={navbarPortalRef}>
                <ButtonGroup display="flex" justifyContent="flex-end">
                  <Button onClick={() => setEditingAccessProfileId(undefined)}>
                    Cancel
                  </Button>
                  <Button
                    onClick={() => onSubmit()}
                    colorScheme="brand.blue"
                    isLoading={isSubmitting}
                  >
                    Save Changes
                  </Button>
                </ButtonGroup>
              </Portal>
            )}
          />
        </WizardStepFormView>
      ) : null}

      {!creatingAccessProfile && !editingAccessProfileId ? (
        <>
          <Box overflowX={"auto"}>
            <Table>
              <Thead>
                <Tr>
                  <Th>Display Name</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {accessProfiles.length ? (
                  accessProfiles.map((accessProfile) => {
                    return (
                      <Tr key={accessProfile.accessProfileId}>
                        <Td>{accessProfile.displayName}</Td>

                        <Td textAlign={"right"}>
                          <ButtonGroup>
                            <IconButton
                              onClick={() =>
                                setEditingAccessProfileId(
                                  accessProfile.accessProfileId
                                )
                              }
                              aria-label="Actions"
                              icon={<HiOutlinePencil />}
                              variant="clear"
                            />
                            <IconButton
                              onClick={() =>
                                setDeletingProfileId(
                                  accessProfile.accessProfileId
                                )
                              }
                              aria-label="Actions"
                              icon={<HiOutlineTrash />}
                              variant="clear"
                            />
                          </ButtonGroup>
                        </Td>
                      </Tr>
                    );
                  })
                ) : (
                  <Tr>
                    <Td colSpan={2} textAlign={"center"}>
                      <Text fontStyle={"italic"}>There are no profiles</Text>
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </Box>
          <Portal containerRef={navbarPortalRef}>
            {Footer({
              addAccessProfile: () => setCreatingAccessProfile(true),
            })}
          </Portal>
        </>
      ) : null}

      <Modal
        isOpen={isDeletingProfile}
        onClose={() => {
          setDeletingProfileId(undefined);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Remove Profile</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure? You can&apos;t undo this action.</ModalBody>

          <ModalFooter>
            <Button
              colorScheme="brand.lightGray"
              color="gray.700"
              mr={3}
              onClick={() => {
                setDeletingProfileId(undefined);
              }}
            >
              Cancel
            </Button>
            <Button
              colorScheme="brand.red"
              onClick={() => deleteProfileMutation.mutateAsync().catch(noop)}
              isDisabled={deleteProfileMutation.isLoading}
            >
              Remove Profile
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

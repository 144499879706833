import {
  Box,
  BoxProps,
  Container,
  Heading,
  Text,
  Flex,
  Spacer,
  Stack,
} from "@chakra-ui/react";
import * as React from "react";
import { ReactNode } from "react";

export const PageHeaderTitle = ({ children }: { children: ReactNode }) => (
  <Heading size="lg" color="brand.navy" py="2">
    {children}
  </Heading>
);

export const PageHeaderBackLink = ({ children }: { children: ReactNode }) => (
  <>{children}</>
);

export const PageHeaderSubtitle = ({ children }: { children: ReactNode }) => (
  <Text color="gray.500" fontWeight="medium" fontSize="lg">
    {children}
  </Text>
);

export const PageHeaderTabs = ({ children }: { children?: ReactNode }) => (
  <Stack direction="row" spacing="4" mt="4">
    {children}
  </Stack>
);

export const PageHeader = ({
  children,
  buttons,
  ...props
}: BoxProps & {
  buttons?: React.ReactNode;
  children: React.ReactNode;
}) => {
  const hasTabs = React.Children.toArray(children).some(
    (child) =>
      React.isValidElement(child) && child.type === (<PageHeaderTabs />).type
  );

  return (
    <Box
      bg="white"
      pt="12"
      mb="4"
      pb={hasTabs ? "0" : "12"}
      shadow="sm"
      {...props}
    >
      <Container maxW="7xl">
        <Flex>
          <Box>{children}</Box>
          <Spacer />
          {buttons && (
            <Box display="flex" alignItems="center">
              {buttons}
            </Box>
          )}
        </Flex>
      </Container>
    </Box>
  );
};

export const formatDate = (
  dateToFormat?: string | Date | null,
  type: "short" | "medium" | "long" = "long"
) => {
  if (!dateToFormat) return null;

  let options: Intl.DateTimeFormatOptions;
  switch (type) {
    case "short":
      options = {
        month: "2-digit",
        day: "2-digit",
        year: "2-digit",
      };
      break;

    case "medium":
      options = {
        month: "long",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
      break;

    case "long":
    default:
      options = {
        weekday: "long",
        hour: "numeric",
        minute: "numeric",
        month: "long",
        day: "numeric",
        year: "numeric",
        hour12: true,
      };
      break;
  }

  const date = new Date(dateToFormat);
  return new Intl.DateTimeFormat("en-US", options).format(date);
};

export const queryStringify = (
  queryParams: Record<string, string | number>
) => {
  const params = Object.keys(queryParams);

  if (params.length === 0) return "";

  const queryString = params
    .map(
      (key) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`
    )
    .join("&");

  return `?${queryString}`;
};

export const truncate = (value: string | number, maxLength = 100) => {
  let output = value?.toString() ?? "";

  if (output.length > maxLength) {
    output = output.substring(0, maxLength) + "...";
  }

  return output;
};

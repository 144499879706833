import React, { ReactNode, useState, useEffect, useRef } from "react";
import {
  Spacer,
  Button,
  Container,
  Flex,
  FormLabel,
  Switch,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";

import { Loading } from "src/common/Loading";
import {
  PropertyOrganization,
  IntercomActivation,
  IntercomGeneration,
} from "src/common/types";
import { HTTPError, useKy } from "src/common/ky";
import { noop } from "src/common/util";
import { IntercomActivationUpdateSchema } from "src/routes/Property/schema";
import { useFlags } from "src/common/hooks/useFlags";
import useIntercomDetails from "src/routes/IntercomUnits/IntercomActivation/useIntercomDetails";
import { IntercomActivationLayout } from "src/routes/IntercomUnits/IntercomActivation/IntercomActivationLayout";
import { useIntercomActivationComponentsQuery } from "src/routes/IntercomUnits/IntercomActivation/useIntercomActivationComponentsQuery";
import { IntercomComponents } from "src/routes/IntercomUnits/IntercomActivation/IntercomDetails/IntercomComponents";

type UpdateIntercomActivationValues = Pick<
  IntercomActivation,
  keyof typeof IntercomActivationUpdateSchema
>;

interface ValidationError {
  property: keyof UpdateIntercomActivationValues;
  messages: string[];
}

export const IntercomDetails = () => {
  const { dealerOrganizationId, propertyOrganizationId, intercomActivationId } =
    useParams();
  const toast = useToast();
  const ky = useKy();
  const { gen4DealerPortal } = useFlags();
  const { currentActivation, generation } = useIntercomDetails();
  const componentsQuery = useIntercomActivationComponentsQuery();

  const isGen4Plus = generation !== IntercomGeneration.GEN_3;
  const [formValues, setFormValues] =
    useState<IntercomActivation>(currentActivation);
  const isFirstRender = useRef(true);
  const propertyOrganizationQuery = useQuery<PropertyOrganization, HTTPError>(
    ["PROPERTY_ORGANIZATION", propertyOrganizationId, dealerOrganizationId],
    () =>
      ky
        .get(
          `property-organizations/${propertyOrganizationId}/dealer-organization/${dealerOrganizationId}`
        )
        .json<PropertyOrganization>()
  );
  const updateIntercom = useMutation<
    void,
    HTTPError,
    UpdateIntercomActivationValues
  >(
    async (values: UpdateIntercomActivationValues) => {
      await ky.patch(`product-unit-activations/${intercomActivationId}`, {
        json: values,
      });
    },
    {
      onError: async (error: HTTPError) => {
        if (error.response.status < 500) {
          const { validationErrors }: { validationErrors: ValidationError[] } =
            await error.response.json();
          validationErrors.map((error: ValidationError) =>
            error.messages.map((message) => console.log("ERROR: ", message))
          );
        }
      },
      onSuccess: () => {
        toast({
          description: "Intercom successfully updated",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      },
    }
  );
  const restartIntercom = useMutation<void, HTTPError>(
    async () => {
      await ky.post(
        `product-unit-activations/${intercomActivationId}/reboot`,
        {}
      );
    },
    {
      onSuccess: () => {
        toast({
          description: "Intercom successfully rebooted",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      },
    }
  );

  const powerCycle = useMutation<void, HTTPError>(
    async () => {
      await ky.post(
        `product-unit-activations/${intercomActivationId}/power-cycle`,
        {}
      );
    },
    {
      onSuccess: () => {
        toast({
          description: "Intercom successfully power cycled",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      },
    }
  );
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    const update = async (values: UpdateIntercomActivationValues) => {
      await updateIntercom.mutateAsync(values).catch(noop);
    };
    const updatedValues: UpdateIntercomActivationValues = {
      name: formValues.name,
      enableResidentDirectory: formValues.enableResidentDirectory,
      enableNumericCodes: formValues.enableNumericCodes,
      enableQrCodes: formValues.enableQrCodes,
      enableCallManager: formValues.enableCallManager,
      enableVideoCalls: formValues.enableVideoCalls,
      enableDMI: formValues.enableDMI,
      enableDMIUA: formValues.enableDMIUA,
      lat: formValues.lat,
      long: formValues.long,
      locationThresholdFeet: formValues.locationThresholdFeet,
      requireNfcTap: formValues.requireNfcTap,
    };

    update(updatedValues);
  }, [formValues]);

  if (
    propertyOrganizationQuery.isLoading ||
    !propertyOrganizationQuery.isSuccess ||
    !componentsQuery.data
  ) {
    return <Loading />;
  }

  const WrapperComponent = gen4DealerPortal
    ? ({ children }: { children: ReactNode }) => (
        <IntercomActivationLayout
          title={`${propertyOrganizationQuery.data.name}`}
          subtitle="View intercom details, cell kit information, and virtual intercom settings"
        >
          {children}
        </IntercomActivationLayout>
      )
    : () => null;

  const handleChange = async (field: keyof IntercomActivation) => {
    setFormValues({ ...formValues, [field]: !formValues[field] });
  };

  return (
    <WrapperComponent>
      <Flex
        flexDirection="row"
        flexWrap="wrap"
        alignItems="start"
        justifyContent="space-between"
      >
        <Flex flexDirection="column" gap={4} flexBasis="40%">
          <Container>
            <Text fontWeight={500} fontSize={"18px"}>
              Product Model
            </Text>
            <Spacer height={2} />
            <Text>{currentActivation.intercomModel}</Text>
            <Spacer height={2} />

            <Text>SN : {currentActivation.serialNumber}</Text>
            <Spacer height={6} />

            {isGen4Plus && (
              <>
                <IntercomComponents components={componentsQuery.data} />

                <Spacer height={6} />

                <Text fontWeight={500} fontSize={"18px"}>
                  Coordinates
                </Text>

                <Text>
                  {currentActivation.lat && currentActivation.long
                    ? `${currentActivation.lat}, ${currentActivation.long}`
                    : "Unknown"}
                </Text>
                <Spacer height={8} />
              </>
            )}
          </Container>

          <Flex>
            <Container>
              <FormLabel>Enable Tenant Directory</FormLabel>
            </Container>
            <Container>
              <Switch
                id="enableResidentDirectory"
                isChecked={formValues.enableResidentDirectory}
                onChange={() => handleChange("enableResidentDirectory")}
              />
            </Container>
          </Flex>

          <Flex>
            <Container>
              <FormLabel>Enable Numeric Codes</FormLabel>
            </Container>
            <Container>
              <Switch
                id="enableNumericCodes"
                isChecked={formValues.enableNumericCodes}
                onChange={() => handleChange("enableNumericCodes")}
              />
            </Container>
          </Flex>
          <Flex>
            <Container>
              <FormLabel>Enable QR Codes</FormLabel>
            </Container>
            <Container>
              <Switch
                id="enableQrCodes"
                isChecked={formValues.enableQrCodes}
                onChange={() => handleChange("enableQrCodes")}
              />
            </Container>
          </Flex>
          <Flex>
            <Container>
              <FormLabel>Enable Call a Manager</FormLabel>
            </Container>
            <Container>
              <Switch
                id="enableCallManager"
                isChecked={formValues.enableCallManager}
                onChange={() => handleChange("enableCallManager")}
              />
            </Container>
          </Flex>
          <Flex>
            <Container>
              <FormLabel>Enable Video Calls</FormLabel>
            </Container>
            <Container>
              <Switch
                id="enableVideoCalls"
                isChecked={formValues.enableVideoCalls}
                onChange={() => handleChange("enableVideoCalls")}
              />
            </Container>
          </Flex>
          <Flex>
            <Container>
              <FormLabel>Require NFC Tap</FormLabel>
            </Container>
            <Container>
              <Switch
                id="requireNfcTap"
                isChecked={formValues.requireNfcTap}
                onChange={() => handleChange("requireNfcTap")}
              />
            </Container>
          </Flex>
        </Flex>
        {isGen4Plus && (
          <>
            <Flex>
              <Button
                type="submit"
                colorScheme={"brand.blue"}
                onClick={() => restartIntercom.mutateAsync()}
                // style={{marginRight: '30px'}}
              >
                Software Restart
              </Button>
              <Spacer width={6} />
              <Button
                type="submit"
                colorScheme={"brand.blueGreen"}
                onClick={() => powerCycle.mutateAsync()}
              >
                Power Cycle
              </Button>
            </Flex>
          </>
        )}
      </Flex>
    </WrapperComponent>
  );
};

import { ComponentWithAs, IconProps } from "@chakra-ui/react";

import { Cable } from "src/common/icons/Cable";
import { Camera } from "src/common/icons/Camera";
import { Cellular } from "src/common/icons/Cellular";
import { Dashboard } from "src/common/icons/Dashboard";
import { Keypad } from "src/common/icons/Keypad";
import { MainBoard } from "src/common/icons/MainBoard";
import { Microphone } from "src/common/icons/Microphone";
import { Monitor } from "src/common/icons/Monitor";
import { ReaderBoard } from "src/common/icons/ReaderBoard";
import { Thermostat } from "src/common/icons/Thermostat";
import { VolumeUp } from "src/common/icons/VolumeUp";
import {
  IntercomComponentStatus,
  IntercomComponentType,
} from "src/common/types";

export const COMPONENT_TYPE_DISPLAY_NAMES: Record<
  IntercomComponentType,
  string
> = {
  [IntercomComponentType.ACCELEROMETER]: "Accelerometer",
  [IntercomComponentType.CAMERA]: "Camera",
  [IntercomComponentType.EEPROM]: "EEPROM",
  [IntercomComponentType.ETHERNET]: "Ethernet",
  [IntercomComponentType.IO_BOARD]: "IO Board",
  [IntercomComponentType.KEYPAD]: "Keypad",
  [IntercomComponentType.MAIN_BOARD]: "Main Board",
  [IntercomComponentType.MICROPHONE]: "Microphone",
  [IntercomComponentType.MODEM]: "Modem",
  [IntercomComponentType.READER]: "Reader Board",
  [IntercomComponentType.SCREEN]: "Screen",
  [IntercomComponentType.SPEAKER]: "Speaker",
  [IntercomComponentType.TEMP_SENSOR]: "Temperature Sensor",
  [IntercomComponentType.TOUCH]: "Touch",
};

export const COMPONENT_TYPE_ICONS: Record<
  IntercomComponentType,
  ComponentWithAs<"svg", IconProps>
> = {
  [IntercomComponentType.ACCELEROMETER]: Cable,
  [IntercomComponentType.CAMERA]: Camera,
  [IntercomComponentType.EEPROM]: Cable,
  [IntercomComponentType.ETHERNET]: Cable,
  [IntercomComponentType.IO_BOARD]: Dashboard,
  [IntercomComponentType.KEYPAD]: Keypad,
  [IntercomComponentType.MAIN_BOARD]: MainBoard,
  [IntercomComponentType.MICROPHONE]: Microphone,
  [IntercomComponentType.MODEM]: Cellular,
  [IntercomComponentType.READER]: ReaderBoard,
  [IntercomComponentType.SCREEN]: Monitor,
  [IntercomComponentType.SPEAKER]: VolumeUp,
  [IntercomComponentType.TEMP_SENSOR]: Thermostat,
  [IntercomComponentType.TOUCH]: Monitor,
};

export const COMPONENT_STATUS_DISPLAY_NAMES: Record<
  IntercomComponentStatus,
  string
> = {
  [IntercomComponentStatus.ONLINE]: "Online",
  [IntercomComponentStatus.OFFLINE]: "Offline",
  [IntercomComponentStatus.UNKNOWN]: "Unknown",
};

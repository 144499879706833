import React, { useState } from "react";
import {
  Button,
  ButtonGroup,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { useQueryClient } from "react-query";

import {
  PageHeader,
  PageHeaderBackLink,
  PageHeaderSubtitle,
  PageHeaderTitle,
} from "src/layout/PageHeader";
import { PageContent } from "src/layout/PageContent";
import { getLockUnlockScheduleQueryKey } from "src/routes/Property/queries";
import { EditLockUnlockScheduleForm } from "src/routes/Property/AccessControl/EntryPoints/components/EditLockUnlockScheduleForm";
import { handleHookFormHTTPError } from "src/common/form";

export const EditLockUnlockSchedule = () => {
  const { propertyOrganizationId, dealerOrganizationId, entryPointId } =
    useParams();

  const toast = useToast();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <PageHeader>
        <PageHeaderBackLink>
          <Link
            as={RouterLink}
            to={`/dealer-organizations/${dealerOrganizationId}/properties/${propertyOrganizationId}/access-control?panelTab=lock-unlock-schedules`}
          >
            &#8249; Back to list
          </Link>
        </PageHeaderBackLink>
        <PageHeaderTitle>Edit Lock/Unlock Schedule</PageHeaderTitle>
        <PageHeaderSubtitle>
          Enter lock/unlock schedule details below
        </PageHeaderSubtitle>
      </PageHeader>
      <PageContent>
        <EditLockUnlockScheduleForm
          entryPointId={entryPointId as string}
          mutationOptionsBuilder={({ setError, getValues }) => ({
            onSuccess: () => {
              toast({
                description: "Lock/Unlock Schedule updated",
                status: "success",
              });
              queryClient.invalidateQueries(
                getLockUnlockScheduleQueryKey(entryPointId as string)
              );
              navigate(
                `/dealer-organizations/${dealerOrganizationId}/properties/${propertyOrganizationId}/access-control?panelTab=entry-points`
              );
            },
            onError: handleHookFormHTTPError(setError, getValues, toast),
          })}
          Footer={({ isSubmitting }) => (
            <ButtonGroup display="flex" justifyContent="flex-end">
              <Link
                as={RouterLink}
                to={`/dealer-organizations/${dealerOrganizationId}/properties/${propertyOrganizationId}/access-control?panelTab=entry-points`}
              >
                <Button>Cancel</Button>
              </Link>
              <Button
                colorScheme="brand.blue"
                isLoading={isSubmitting}
                onClick={() => setIsModalOpen(true)}
              >
                Save Changes
              </Button>
            </ButtonGroup>
          )}
        >
          {({ onSubmit }) => (
            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Edit Lock/Unlock Schedule</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  Are you sure you want to edit this lock/unlock schedule?
                </ModalBody>
                <ModalFooter>
                  <Flex justifyContent={"flex-end"}>
                    <Button
                      colorScheme="brand.lightGray"
                      color="gray.700"
                      mr={3}
                      onClick={() => setIsModalOpen(false)}
                    >
                      Close
                    </Button>
                    <Button
                      colorScheme="brand.blue"
                      onClick={async () => {
                        setIsModalOpen(false);
                        await onSubmit();
                      }}
                      type={"submit"}
                      ml={4}
                    >
                      Save Changes
                    </Button>
                  </Flex>
                </ModalFooter>
              </ModalContent>
            </Modal>
          )}
        </EditLockUnlockScheduleForm>
      </PageContent>
    </>
  );
};

import React from "react";
import { Box, Button, ButtonGroup } from "@chakra-ui/react";

import { pluralize } from "src/common/util";

type PaginationProps = {
  unitName?: string;
  unitPlural?: string;
  total: number;
  pageNumber: number;
  pageSize: number;
  onPageChange: (pageNumber: number) => void;
};

export const Pagination = ({
  unitName = "item",
  unitPlural,
  total,
  pageNumber,
  pageSize,
  onPageChange,
}: PaginationProps) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      mt="32px"
      mb="32px"
    >
      <Box color="gray.500" fontSize="14px" fontWeight={500} fontStyle="normal">
        {total} {pluralize(total, unitName, unitPlural || `${unitName}s`)}
      </Box>
      {(pageNumber > 1 || total > pageNumber * pageSize) && (
        <ButtonGroup display="flex" justifyContent="flex-end">
          <Button
            isDisabled={pageNumber === 1}
            colorScheme="brand.blue"
            onClick={() => onPageChange(pageNumber - 1)}
          >
            Previous
          </Button>
          <Button
            isDisabled={pageNumber * pageSize >= total}
            colorScheme="brand.blue"
            onClick={() => onPageChange(pageNumber + 1)}
          >
            Next
          </Button>
        </ButtonGroup>
      )}
    </Box>
  );
};

import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import { IntercomComponent } from "src/common/types";
import { HTTPError, useKy } from "src/common/ky";

export const getIntercomActivationComponentsKey = (
  intercomActivationId?: string
) => ["INTERCOM_ACTIVATION", intercomActivationId, "COMPONENTS"];

export const useIntercomActivationComponentsQuery = () => {
  const { intercomActivationId } = useParams();
  const ky = useKy();
  return useQuery<IntercomComponent[], HTTPError>(
    getIntercomActivationComponentsKey(intercomActivationId),
    () =>
      ky
        .get(`product-unit-activations/${intercomActivationId}/components`)
        .json<IntercomComponent[]>()
  );
};

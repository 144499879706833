import { Box, Container } from "@chakra-ui/react";
import React, { Component } from "react";

import { SomethingWentWrong } from "src/common/ErrorBoundary/SomethingWentWrong";

type Props = {
  children: React.ReactNode;
};

export class GlobalErrorBoundary extends Component<
  Props,
  { error: Error | null }
> {
  constructor(props: Props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error) {
    console.error(error);
  }

  render() {
    if (this.state.error) {
      return (
        <Box as="main" py="4" flex="1">
          <Container maxW="7xl" h="calc(100vh)" centerContent={true}>
            <SomethingWentWrong error={this.state.error} />
          </Container>
        </Box>
      );
    }

    return this.props.children;
  }
}

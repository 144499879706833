import React from "react";
import { createIcon } from "@chakra-ui/react";

export const ReaderBoard = createIcon({
  displayName: "ReaderBoard",
  viewBox: "0 0 30 30",
  path: (
    <path
      fill="currentColor"
      d="M5.542 24.458H24.458V5.542H16.375C15.597 5.542 14.9443 5.81267 14.417 6.354C13.889 6.896 13.625 7.55567 13.625 8.333V12.625C13.153 12.875 12.7987 13.2013 12.562 13.604C12.326 14.0067 12.208 14.472 12.208 15C12.208 15.778 12.479 16.4377 13.021 16.979C13.5623 17.521 14.222 17.792 15 17.792C15.778 17.792 16.4377 17.521 16.979 16.979C17.521 16.4377 17.792 15.778 17.792 15C17.792 14.472 17.667 14.0067 17.417 13.604C17.167 13.2013 16.8197 12.875 16.375 12.625V8.333H21.667V21.667H8.333V8.333H12.042V5.542H5.542V24.458ZM2.792 30C2.042 30 1.389 29.7223 0.833 29.167C0.277667 28.611 0 27.958 0 27.208V2.792C0 2.042 0.277667 1.389 0.833 0.833C1.389 0.277667 2.042 0 2.792 0H27.208C27.958 0 28.611 0.277667 29.167 0.833C29.7223 1.389 30 2.042 30 2.792V27.208C30 27.958 29.7223 28.611 29.167 29.167C28.611 29.7223 27.958 30 27.208 30H2.792ZM2.792 27.208H27.208V2.792H2.792V27.208Z"
    />
  ),
});

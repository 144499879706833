import React from "react";
import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  Link,
  Radio,
  RadioGroup,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { Loading } from "src/common/Loading";
import {
  PageHeader,
  PageHeaderBackLink,
  PageHeaderSubtitle,
  PageHeaderTitle,
} from "src/layout/PageHeader";
import { PageContent } from "src/layout/PageContent";
import { DealerUser } from "src/common/types";
import { HTTPError, useKy } from "src/common/ky";
import { useCurrentDealerUser } from "src/routes/DealerOrganizationSelector";
import { noop } from "src/common/util";
import { handleHookFormHTTPError } from "src/common/form";
import { PhoneNumberInput } from "src/common/PhoneNumbers";
import { YupPossiblePhoneNumber } from "src/common/phone-numbers";

interface EditDealerUserValues {
  dealerOrganizationId: string;
  firstName: string;
  lastName: string;
  phone: string;
  role: string;
}

const EditDealerUserValidationSchema = Yup.object().shape({
  firstName: Yup.string().required().label("First Name"),
  lastName: Yup.string().required().label("Last Name"),
  phone: YupPossiblePhoneNumber({ required: true }),
  role: Yup.string().required().label("Role"),
});

// if the DealerUser.tsx component is implemented, this should be exported from there
export const GET_USER_QUERY_KEY = "GET_USER";

export const EditDealerUser = () => {
  const { dealerUserId, dealerOrganizationId } = useParams();
  const currentDealerUser = useCurrentDealerUser();
  const ky = useKy();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty },
    reset,
    setError,
    getValues,
  } = useForm<EditDealerUserValues>({
    defaultValues: {
      dealerOrganizationId,
      firstName: "",
      lastName: "",
      phone: "",
      role: "",
    },
    resolver: yupResolver(EditDealerUserValidationSchema),
  });

  const navigate = useNavigate();
  const toast = useToast();
  const queryClient = useQueryClient();

  const updateUser = useMutation<void, HTTPError, EditDealerUserValues>(
    async (values: EditDealerUserValues) => {
      await ky.patch(`dealer-users/${dealerUserId}`, {
        json: values,
      });
    },
    {
      onSuccess: () => {
        navigate(`/dealer-organizations/${dealerOrganizationId}/staff`);
        toast({
          description: "User Successfully Updated",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        queryClient.invalidateQueries(GET_USER_QUERY_KEY);
      },
      onError: handleHookFormHTTPError(setError, getValues, toast),
    }
  );

  const getUserQuery = useQuery<DealerUser, HTTPError>(
    [GET_USER_QUERY_KEY, dealerUserId],
    () => ky.get(`dealer-users/${dealerUserId}`).json<DealerUser>(),
    {
      onSuccess: (data) => {
        if (!isDirty) {
          reset({
            dealerOrganizationId,
            firstName: data.user.firstName || "",
            lastName: data.user.lastName || "",
            phone: data.user.phone || "",
            role: data.role || "",
          });
        }
      },
    }
  );

  if (getUserQuery.isLoading || !getUserQuery.isSuccess) {
    return <Loading />;
  }

  return (
    <>
      <PageHeader>
        <PageHeaderBackLink>
          <Link
            as={RouterLink}
            to={`/dealer-organizations/${dealerOrganizationId}/staff`}
          >
            &#8249; Back to list
          </Link>
        </PageHeaderBackLink>
        <PageHeaderTitle>Edit Staff User</PageHeaderTitle>
        <PageHeaderSubtitle>
          Enter the user&apos;s details here
        </PageHeaderSubtitle>
      </PageHeader>
      <PageContent>
        <form
          onSubmit={handleSubmit((values) =>
            updateUser.mutateAsync(values).catch(noop)
          )}
        >
          <Grid gap={4}>
            <GridItem>
              <Heading as={"h3"} fontSize="24px" mt={4} mb={4}>
                Staff User Details
              </Heading>
            </GridItem>

            <GridItem>
              <Divider />
            </GridItem>

            <GridItem>
              <Controller
                name="firstName"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl
                    isRequired
                    isInvalid={fieldState.invalid && fieldState.isTouched}
                  >
                    <FormLabel htmlFor="firstName">First Name</FormLabel>
                    <Input
                      width={["100%", "100%", "50%", "50%"]}
                      {...field}
                      id="firstName"
                    />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>

            <GridItem>
              <Controller
                name="lastName"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl
                    isRequired
                    isInvalid={fieldState.invalid && fieldState.isTouched}
                  >
                    <FormLabel htmlFor="lastName">Last Name</FormLabel>
                    <Input
                      width={["100%", "100%", "50%", "50%"]}
                      {...field}
                      id="lastName"
                    />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>

            <GridItem>
              <Controller
                name="phone"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl
                    isRequired
                    isInvalid={fieldState.invalid && fieldState.isTouched}
                  >
                    <FormLabel htmlFor="phone">Phone</FormLabel>
                    <PhoneNumberInput
                      width={["100%", "100%", "50%", "50%"]}
                      {...field}
                      id="phone"
                    />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>

            <GridItem>
              <FormLabel htmlFor="email">Email</FormLabel>
              <Input
                width={["100%", "100%", "50%", "50%"]}
                disabled={true}
                value={getUserQuery.data.user.email}
                id="email"
              />
            </GridItem>

            <GridItem>
              <Controller
                name="role"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl
                    isRequired
                    isInvalid={fieldState.invalid && fieldState.isTouched}
                  >
                    <FormLabel htmlFor="role">Role</FormLabel>
                    <RadioGroup id="role" {...field} isDisabled={true}>
                      <Stack direction="column">
                        <Radio
                          value="admin"
                          isDisabled={
                            currentDealerUser?.dealerUserId === dealerUserId
                          }
                        >
                          Admin
                        </Radio>
                        <Radio
                          value="staff"
                          isDisabled={
                            currentDealerUser?.dealerUserId === dealerUserId
                          }
                        >
                          Staff
                        </Radio>
                      </Stack>
                    </RadioGroup>
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>

            {updateUser.error && (
              <GridItem>
                <Alert status="error">
                  <AlertIcon />
                  {updateUser.error.message}
                </Alert>
              </GridItem>
            )}

            <GridItem>
              <Divider />
            </GridItem>

            <GridItem>
              <ButtonGroup display="flex" justifyContent="flex-end">
                <Link
                  as={RouterLink}
                  to={`/dealer-organizations/${dealerOrganizationId}/staff`}
                >
                  <Button>Cancel</Button>
                </Link>
                <Button
                  type="submit"
                  colorScheme="brand.blue"
                  isLoading={isSubmitting}
                >
                  Save User
                </Button>
              </ButtonGroup>
            </GridItem>
          </Grid>
        </form>
      </PageContent>
    </>
  );
};

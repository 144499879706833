import React, { SyntheticEvent, useCallback, useMemo, useState } from "react";
import {
  Button,
  ButtonGroup,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  Spacer,
} from "@chakra-ui/react";
import { HiOutlineSearch } from "react-icons/hi";
import {
  Link as RouterLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { GoX } from "react-icons/go";

import { useCurrentDealerOrganization } from "src/routes/DealerOrganizationSelector";
import { PropertiesFilterPopover } from "src/routes/PropertiesFilterPopover";
import { useIsAdmin } from "src/common/hooks/useIsAdmin";

export const PropertiesFilterBar = () => {
  const { dealerOrganizationId } = useParams();
  const isAdmin = useIsAdmin();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const [name, setName] = useState<string>(searchParams.get("name") || "");

  const dealerOrganization = useCurrentDealerOrganization();
  const financialsIsSet = dealerOrganization
    ? dealerOrganization.dealerFinancialsIsSet
    : false;

  const onSubmit = useCallback(
    (newSp: URLSearchParams) => {
      newSp.delete("pageNumber");
      searchParams.sort();
      newSp.sort();

      if (searchParams.toString() !== newSp.toString()) {
        navigate(`?${newSp.toString()}`);
      }
    },
    [searchParams]
  );

  const handleFormSubmit = useCallback(
    (event: SyntheticEvent) => {
      event.preventDefault();
      const newSp = new URLSearchParams(location.search);

      if (name) {
        newSp.set("name", name);
      } else {
        newSp.delete("name");
      }

      onSubmit(newSp);
    },
    [location.search, name, searchParams]
  );

  const clearSearch = () => {
    const newSp = new URLSearchParams(location.search);
    newSp.delete("name");
    setName("");

    onSubmit(newSp);
  };

  return (
    <Flex flexDirection="row">
      <form onSubmit={handleFormSubmit}>
        <InputGroup width={503}>
          <InputLeftElement pointerEvents="none" color="gray.300">
            <HiOutlineSearch />
          </InputLeftElement>

          <Input
            value={name}
            onChange={({ currentTarget: { value } }) => setName(value)}
            onBlur={handleFormSubmit}
            placeholder="Search for property name"
          />

          <InputRightElement>
            <Button variant="ghost" onClick={clearSearch}>
              <GoX />
            </Button>
          </InputRightElement>
        </InputGroup>
      </form>
      <PropertiesFilterPopover onSubmit={onSubmit} />
      {isAdmin && (
        <>
          <Spacer width={8} />
          <ButtonGroup>
            {financialsIsSet && (
              <Link
                as={RouterLink}
                to={`/dealer-organizations/${dealerOrganizationId}/properties/add`}
              >
                <Button colorScheme="brand.blue">
                  Add Property Organization
                </Button>
              </Link>
            )}
          </ButtonGroup>
        </>
      )}
    </Flex>
  );
};

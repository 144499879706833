import React, { useState } from "react";
import {
  Button,
  ButtonGroup,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";

import {
  PageHeader,
  PageHeaderBackLink,
  PageHeaderSubtitle,
  PageHeaderTitle,
} from "src/layout/PageHeader";
import { PageContent } from "src/layout/PageContent";
import { usePropertyOrganizationQuery } from "src/routes/Property/queries";
import { Loading } from "src/common/Loading";
import { EditAccessPanelForm } from "src/routes/Property/AccessControl/Panels/components/EditAccessPanelForm";
import { handleHookFormHTTPError } from "src/common/form";

export const EditAccessPanel = () => {
  const { propertyOrganizationId, dealerOrganizationId, accessPanelId } =
    useParams();
  const propertyOrganizationQuery = usePropertyOrganizationQuery({
    propertyOrganizationId: propertyOrganizationId as string,
    dealerOrganizationId: dealerOrganizationId as string,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toast = useToast();
  const navigate = useNavigate();

  if (
    propertyOrganizationQuery.isLoading ||
    !propertyOrganizationQuery.isSuccess
  ) {
    return <Loading />;
  }

  return (
    <>
      <PageHeader>
        <PageHeaderBackLink>
          <Link
            as={RouterLink}
            to={`/dealer-organizations/${dealerOrganizationId}/properties/${propertyOrganizationId}/access-control`}
          >
            &#8249; Back to list
          </Link>
        </PageHeaderBackLink>
        <PageHeaderTitle>
          {propertyOrganizationQuery.data.name} - Edit Access Control Panel
        </PageHeaderTitle>
        <PageHeaderSubtitle>Edit panel detail below</PageHeaderSubtitle>
      </PageHeader>
      <PageContent>
        <EditAccessPanelForm
          accessPanelId={accessPanelId as string}
          mutationOptionsBuilder={({ setError, getValues }) => ({
            onSuccess: () => {
              toast({
                description: "Access Panel updated",
                status: "success",
              });
              navigate(
                `/dealer-organizations/${dealerOrganizationId}/properties/${propertyOrganizationId}/access-control`
              );
            },
            onError: handleHookFormHTTPError(setError, getValues, toast),
          })}
          Footer={({ isSubmitting }) => (
            <ButtonGroup display="flex" justifyContent="flex-end">
              <Link
                as={RouterLink}
                to={`/dealer-organizations/${dealerOrganizationId}/properties/${propertyOrganizationId}/access-control`}
              >
                <Button>Cancel</Button>
              </Link>
              <Button
                colorScheme="brand.blue"
                isLoading={isSubmitting}
                onClick={() => setIsModalOpen(true)}
              >
                Save Changes
              </Button>
            </ButtonGroup>
          )}
        >
          {({ onSubmit }) => (
            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Edit Access Panel</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  Are you sure you want to edit this access panel?
                </ModalBody>
                <ModalFooter>
                  <Button
                    colorScheme="brand.lightGray"
                    color="gray.700"
                    mr={3}
                    onClick={() => setIsModalOpen(false)}
                  >
                    Close
                  </Button>
                  <Button colorScheme="brand.blue" onClick={onSubmit}>
                    Save Changes
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          )}
        </EditAccessPanelForm>
      </PageContent>
    </>
  );
};

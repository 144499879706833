import React from "react";
import PhoneInput, {
  Props as ReactPhoneNumberInputProps,
} from "react-phone-number-input/input";
import { DefaultInputComponentProps } from "react-phone-number-input/index";
import { forwardRef, Input, InputProps } from "@chakra-ui/react";

import { COUNTRY_DEF, formatPhoneNumberE164 } from "src/common/phone-numbers";

export const PLACEHOLDER_DEF = "(555) 555-5555";

const PhoneInputComponent = forwardRef((props, ref) => {
  return <Input type="tel" ref={ref} {...props} />;
});

export const PhoneNumberInput = (
  props: InputProps & ReactPhoneNumberInputProps<DefaultInputComponentProps>
) => {
  // this corrects phone numbers in the DB that don't already have '+1' E.164 format
  const country = props.defaultCountry || COUNTRY_DEF;
  const fixed = formatPhoneNumberE164(props.value as string, {
    country,
    onInvalid: (value: string) => value,
  });
  return (
    <PhoneInput
      {...props}
      placeholder={props.placeholder || PLACEHOLDER_DEF}
      value={fixed}
      defaultCountry={country}
      onChange={(value?: string) => {
        // last backspace while deleting values causes undefined to be sent instead of empty string
        // if we don't block this, it will reset the value to the last known number set
        props.onChange(value || "");
      }}
      international={false}
      smartCaret={true}
      inputComponent={PhoneInputComponent}
    />
  );
};

import {
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Textarea,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import React, { useCallback, useMemo, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "react-query";
import { HTTPError } from "ky";
import { v4 as uuidv4 } from "uuid";
import { FiEye, FiEyeOff } from "react-icons/fi";

import { useKy } from "src/common/ky";
import { AddAccessPanelValidationSchema } from "src/routes/Property/schema";
import { noop } from "src/common/util";
import { AccessControlFormProps } from "src/routes/Property/AccessControl/types";
import {
  getNormalizedUSTimezone,
  USTimezoneSelect,
} from "src/common/USTimezoneSelect";

export interface ActivateAccessPanelValues {
  panelSerialNumber: string;
  propertyOrganizationId: string;
  ipAddress: string;
  port: string;
  timezone: string;
  notes: string;
  listenPort734n: string;
  passphrase734n: string;
}

export type ActivateAccessPanelFormProps =
  AccessControlFormProps<ActivateAccessPanelValues> & {
    propertyOrganizationId: string;
  };

export const ActivateAccessPanelForm = (
  props: ActivateAccessPanelFormProps
) => {
  const {
    propertyOrganizationId,
    hideFooterDivider,
    Footer,
    mutationOptionsBuilder,
  } = props;

  const ky = useKy();

  const hookForm = useForm<ActivateAccessPanelValues>({
    defaultValues: {
      panelSerialNumber: "",
      propertyOrganizationId,
      ipAddress: "",
      port: "2001",
      timezone: getNormalizedUSTimezone() ?? "",
      listenPort734n: "2002",
      passphrase734n: uuidv4().toUpperCase().slice(0, 8),
      notes: "",
    },
    resolver: yupResolver(AddAccessPanelValidationSchema),
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = hookForm;

  const mutationOptions = useMemo(
    () => mutationOptionsBuilder && mutationOptionsBuilder(hookForm),
    [mutationOptionsBuilder, hookForm]
  );

  const [passphrase734nVisible, setPassphrase734nVisible] = useState(true);

  const addAccessPanel = useMutation<
    void,
    HTTPError,
    ActivateAccessPanelValues
  >(async (values: ActivateAccessPanelValues) => {
    await ky.post(`access-control/panel/activate`, {
      json: { ...values },
    });
  }, mutationOptions);

  const onSubmit = useCallback(
    handleSubmit((data) => addAccessPanel.mutateAsync(data).catch(noop)),
    [propertyOrganizationId, handleSubmit]
  );

  return (
    <form onSubmit={onSubmit}>
      <Grid gap={4} width={{ base: "100%", xl: "50%" }}>
        <GridItem>
          <Controller
            name="panelSerialNumber"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl isRequired isInvalid={!!fieldState.error}>
                <FormLabel htmlFor="panelSerialNumber">Serial Number</FormLabel>
                <Input {...field} id="panelSerialNumber" />
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </GridItem>
        <GridItem>
          <Controller
            name="ipAddress"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl isRequired isInvalid={!!fieldState.error}>
                <FormLabel htmlFor="ipAddress">IP Address</FormLabel>
                <Input {...field} id="ipAddress" />
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </GridItem>

        <GridItem>
          <Controller
            name="port"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl isRequired isInvalid={!!fieldState.error}>
                <FormLabel htmlFor="port">Port</FormLabel>
                <Input {...field} id="port" />
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </GridItem>

        <GridItem>
          <Controller
            name="timezone"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl isRequired isInvalid={!!fieldState.error}>
                <FormLabel htmlFor="timezone">Timezone</FormLabel>
                <USTimezoneSelect
                  placeholder={"Select Timezone"}
                  {...field}
                  id="timezone"
                ></USTimezoneSelect>
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </GridItem>

        <GridItem>
          <Controller
            name="listenPort734n"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl isRequired isInvalid={!!fieldState.error}>
                <FormLabel htmlFor="listenPort734n">
                  Network Module Listen Port
                </FormLabel>
                <Input {...field} id="listenPort734n" />
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </GridItem>

        <GridItem>
          <Controller
            name="passphrase734n"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl isRequired isInvalid={!!fieldState.error}>
                <FormLabel htmlFor="passphrase734n">
                  Network Module Passphrase
                </FormLabel>
                <InputGroup>
                  <Input
                    type={passphrase734nVisible ? "text" : "password"}
                    {...field}
                    autoComplete={"off"}
                    id="passphrase734n"
                  />
                  <InputRightElement>
                    <IconButton
                      size="sm"
                      variant={"ghost"}
                      icon={passphrase734nVisible ? <FiEyeOff /> : <FiEye />}
                      onClick={() =>
                        setPassphrase734nVisible(!passphrase734nVisible)
                      }
                      aria-label={"toggle-visible"}
                    />
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </GridItem>

        <GridItem>
          <Controller
            name="notes"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl isInvalid={!!fieldState.error}>
                <FormLabel htmlFor="">Notes</FormLabel>
                <Textarea {...field} id="notes" />
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </GridItem>
      </Grid>
      {Footer ? (
        <Grid gap={4} mt={4}>
          {!hideFooterDivider ? (
            <GridItem>
              <Divider />
            </GridItem>
          ) : null}
          <GridItem>{Footer({ isSubmitting, onSubmit })}</GridItem>
        </Grid>
      ) : null}
    </form>
  );
};

import React from "react";
import { Button, ButtonGroup, Link, useToast } from "@chakra-ui/react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";

import { handleHookFormHTTPError } from "src/common/form";
import {
  PageHeader,
  PageHeaderBackLink,
  PageHeaderSubtitle,
  PageHeaderTitle,
} from "src/layout/PageHeader";
import { PageContent } from "src/layout/PageContent";
import { CreateAccessProfileForm } from "src/routes/Property/AccessControl/Profiles/components/CreateAccessProfileForm";

export const AddAccessProfile = () => {
  const { dealerOrganizationId, propertyOrganizationId } = useParams();
  const toast = useToast();
  const navigate = useNavigate();

  return (
    <>
      <PageHeader>
        <PageHeaderBackLink>
          <Link
            as={RouterLink}
            to={`/dealer-organizations/${dealerOrganizationId}/properties/${propertyOrganizationId}/access-control?panelTab=profiles`}
          >
            &#8249;Back to list
          </Link>
        </PageHeaderBackLink>
        <PageHeaderTitle>Add Profile</PageHeaderTitle>
        <PageHeaderSubtitle>Enter profile details below</PageHeaderSubtitle>
      </PageHeader>
      <PageContent>
        <CreateAccessProfileForm
          propertyOrganizationId={propertyOrganizationId as string}
          mutationOptionsBuilder={({ setError, getValues }) => ({
            onSuccess: () => {
              toast({
                description: "Profile added",
                status: "success",
              });
              navigate(
                `/dealer-organizations/${dealerOrganizationId}/properties/${propertyOrganizationId}/access-control?panelTab=profiles`
              );
            },
            onError: handleHookFormHTTPError(setError, getValues, toast),
          })}
          Footer={({ isSubmitting }) => (
            <ButtonGroup display="flex" justifyContent="flex-end">
              <Link
                as={RouterLink}
                to={`/dealer-organizations/${dealerOrganizationId}/properties/${propertyOrganizationId}/access-control?panelTab=profiles`}
              >
                <Button>Cancel</Button>
              </Link>
              <Button
                type="submit"
                isLoading={isSubmitting}
                colorScheme="brand.blue"
              >
                Save
              </Button>
            </ButtonGroup>
          )}
        />
      </PageContent>
    </>
  );
};

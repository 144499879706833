import React, { useState } from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { useQueryClient } from "react-query";

import { PageContent } from "src/layout/PageContent";
import {
  PageHeader,
  PageHeaderBackLink,
  PageHeaderSubtitle,
  PageHeaderTitle,
} from "src/layout/PageHeader";
import { EditEntryPointForm } from "src/routes/Property/AccessControl/EntryPoints/components/EditEntryPointForm";
import { getEntryPointQueryKey } from "src/routes/Property/queries";
import { handleHookFormHTTPError } from "src/common/form";

export const EditEntryPoint = () => {
  const { dealerOrganizationId, propertyOrganizationId, entryPointId } =
    useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toast = useToast();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return (
    <>
      <PageHeader>
        <PageHeaderBackLink>
          <Link
            as={RouterLink}
            to={`/dealer-organizations/${dealerOrganizationId}/properties/${propertyOrganizationId}/access-control?panelTab=entry-points`}
          >
            &#8249;Back to list
          </Link>
        </PageHeaderBackLink>
        <PageHeaderTitle>Edit Entry Point</PageHeaderTitle>
        <PageHeaderSubtitle>Enter entry point details below</PageHeaderSubtitle>
      </PageHeader>
      <PageContent>
        <EditEntryPointForm
          entryPointId={entryPointId as string}
          propertyOrganizationId={propertyOrganizationId as string}
          mutationOptionsBuilder={({ setError, getValues }) => ({
            onSuccess: () => {
              toast({
                description: "Entry Point updated",
                status: "success",
              });
              navigate(
                `/dealer-organizations/${dealerOrganizationId}/properties/${propertyOrganizationId}/access-control?panelTab=entry-points`
              );
              queryClient.invalidateQueries(
                getEntryPointQueryKey(entryPointId as string)
              );
            },
            onError: handleHookFormHTTPError(setError, getValues, toast),
          })}
          Footer={({ isSubmitting }) => (
            <ButtonGroup display="flex" justifyContent="flex-end">
              <Link
                as={RouterLink}
                to={`/dealer-organizations/${dealerOrganizationId}/properties/${propertyOrganizationId}/access-control?panelTab=entry-points`}
              >
                <Button>Cancel</Button>
              </Link>
              <Button
                colorScheme="brand.blue"
                isLoading={isSubmitting}
                onClick={() => setIsModalOpen(true)}
              >
                Save Changes
              </Button>
            </ButtonGroup>
          )}
        >
          {({ onSubmit }) => (
            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Edit Entry Point</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  Are you sure you want to edit this entry point?
                </ModalBody>
                <ModalFooter>
                  <Flex justifyContent={"flex-end"}>
                    <Button
                      colorScheme="brand.lightGray"
                      color="gray.700"
                      mr={3}
                      onClick={() => setIsModalOpen(false)}
                    >
                      Close
                    </Button>
                    <Button
                      colorScheme="brand.blue"
                      onClick={async () => {
                        setIsModalOpen(false);
                        await onSubmit();
                      }}
                      type={"submit"}
                      ml={4}
                    >
                      Save Changes
                    </Button>
                  </Flex>
                </ModalFooter>
              </ModalContent>
            </Modal>
          )}
        </EditEntryPointForm>
      </PageContent>
    </>
  );
};

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { useState } from "react";
import { useToast } from "@chakra-ui/react";
import { UseFormGetValues, UseFormSetError } from "react-hook-form";
import { KyResponse } from "ky";

import {
  getPropertyOrganizationQueryKey,
  getPropertyOrganizationStaffListQueryKey,
  getPropertyUserQueryKey,
} from "src/routes/Property/queries";
import { HTTPError, useKy } from "src/common/ky";
import { EditPropertyUserValues } from "src/routes/Property/interfaces";
import { PropertyUser } from "src/common/types";
import { handleHookFormHTTPError } from "src/common/form";

export const useDeletePropertyUser = () => {
  const queryClient = useQueryClient();
  const { propertyOrganizationId } = useParams();
  const location = useLocation();
  const ky = useKy();
  const searchParams = new URLSearchParams(location.search);

  if (propertyOrganizationId) {
    searchParams.set("propertyOrganizationId", propertyOrganizationId);
  }

  searchParams.sort();

  const [deletingPropertyUserId, setDeletingPropertyUserId] = useState<
    string | undefined
  >();
  const deletePropertyUserMutation = useMutation(
    () =>
      ky.delete(`property-users/${deletingPropertyUserId}`, {
        json: { propertyOrganizationId },
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          getPropertyOrganizationStaffListQueryKey(
            propertyOrganizationId as string
          ),
          searchParams.toString(),
        ]);
        setDeletingPropertyUserId(undefined);
      },
    }
  );

  return {
    isDeletingPropertyUser: !!deletingPropertyUserId,
    deletePropertyUserMutation: deletePropertyUserMutation,
    setDeletingPropertyUserId: setDeletingPropertyUserId,
  };
};

export const useUpdatePropertyUser = (
  setError: UseFormSetError<EditPropertyUserValues>,
  getValues: UseFormGetValues<EditPropertyUserValues>
) => {
  const { propertyUserId, propertyOrganizationId, dealerOrganizationId } =
    useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const ky = useKy();
  const queryClient = useQueryClient();

  const [physicalAccessCredentialsError, setPhysicalAccessCredentialsError] =
    useState<string | null>(null);

  const [accessProfilesError, setAccessProfilesError] = useState<string | null>(
    null
  );

  const mutation = useMutation<void, HTTPError, EditPropertyUserValues>(
    async (values: EditPropertyUserValues) => {
      await ky.patch(`property-users/${propertyUserId}`, {
        json: values,
      });
    },
    {
      onSuccess: () => {
        navigate(
          `/dealer-organizations/${dealerOrganizationId}/properties/${propertyOrganizationId}/staff`
        );
        toast({
          description: "User Successfully Updated",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        queryClient.invalidateQueries(
          getPropertyUserQueryKey(propertyUserId as string)
        );
      },
      onError: handleHookFormHTTPError(setError, getValues, toast),
    }
  );

  return {
    updatePropertyUserMutation: mutation,
    physicalAccessCredentialsError: physicalAccessCredentialsError,
    setPhysicalAccessCredentialsError: setPhysicalAccessCredentialsError,
    accessProfilesError,
    setAccessProfilesError,
  };
};

export const useUpdatePropertyContact = () => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const ky = useKy();
  const { dealerOrganizationId, propertyOrganizationId } = useParams();

  const [updatingPropertyContact, setUpdatingPropertyContact] = useState<
    PropertyUser | undefined
  >();
  const updatePropertyContactMutation = useMutation(
    () =>
      ky.patch(
        `property-organizations/${propertyOrganizationId}/update-admin-contact`,
        {
          json: { propertyUserId: updatingPropertyContact?.propertyUserId },
        }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          getPropertyOrganizationQueryKey(
            propertyOrganizationId as string,
            dealerOrganizationId as string
          )
        );
        toast({
          description: "Property Contact Updated",
          status: "success",
          duration: 9000,
          isClosable: true,
        });

        setUpdatingPropertyContact(undefined);
      },
    }
  );

  return {
    isUpdatingPropertyContact: !!updatingPropertyContact,
    updatePropertyContactMutation: updatePropertyContactMutation,
    updatingPropertyContact: updatingPropertyContact,
    setUpdatingPropertyContact: setUpdatingPropertyContact,
  };
};

export const useResetPropertyUserPassword = () => {
  const { propertyOrganizationId } = useParams();
  const toast = useToast();
  const ky = useKy();

  const [resettingPropertyUserId, setResettingPropertyUserId] = useState<
    string | undefined
  >();
  const resetPropertyUserPasswordMutation = useMutation(
    () =>
      ky.post(`property-users/${resettingPropertyUserId}/reset-password`, {
        json: { propertyOrganizationId },
      }),
    {
      onSuccess: () => {
        setResettingPropertyUserId(undefined);
        toast({
          description: "Password reset was successful",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      },
      onError: () => {
        toast({
          description: "Password reset was unsuccessful",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      },
    }
  );

  return {
    isResettingPropertyUserPassword: !!resettingPropertyUserId,
    resetPropertyUserPasswordMutation: resetPropertyUserPasswordMutation,
    setResettingPropertyUserId: setResettingPropertyUserId,
  };
};

export const useDeleteAccessPanel = (
  options: UseMutationOptions<KyResponse, HTTPError>
) => {
  const ky = useKy();

  const [deletingAccessPanelId, setDeletingAccessPanelId] = useState<
    string | undefined
  >();
  const deleteAccessPanelMutation = useMutation(
    () => ky.delete(`access-control/panel/${deletingAccessPanelId}`),
    options
  );

  return {
    isDeletingAccessPanel: !!deletingAccessPanelId,
    deleteAccessPanelMutation: deleteAccessPanelMutation,
    setDeletingAccessPanelId: setDeletingAccessPanelId,
  };
};

export const useDeleteEntryPoint = (
  options: UseMutationOptions<KyResponse, HTTPError>
) => {
  const ky = useKy();

  const [deletingEntryPointId, setDeletingEntryPointId] = useState<
    string | undefined
  >();
  const deleteEntryPointMutation = useMutation(
    () => ky.delete(`access-control/entry-point/${deletingEntryPointId}`),
    options
  );

  return {
    isDeletingEntryPoint: !!deletingEntryPointId,
    deleteEntryPointMutation: deleteEntryPointMutation,
    setDeletingEntryPointId: setDeletingEntryPointId,
  };
};
export const useDeleteEntryGroup = (
  options: UseMutationOptions<KyResponse, HTTPError>
) => {
  const ky = useKy();

  const [deletingEntryGroupId, setDeletingEntryGroupId] = useState<
    string | undefined
  >();

  const deleteEntryGroupMutation = useMutation(
    () => ky.delete(`access-control/entry-group/${deletingEntryGroupId}`),
    options
  );

  return {
    isDeletingEntryGroup: !!deletingEntryGroupId,
    deleteEntryGroupMutation,
    setDeletingEntryGroupId,
  };
};

export const useDeleteEntrySchedule = (
  options: UseMutationOptions<KyResponse, HTTPError>
) => {
  const ky = useKy();

  const [deletingEntryScheduleId, setDeletingEntryScheduleId] = useState<
    string | undefined
  >();

  const deleteEntryScheduleMutation = useMutation(
    () => ky.delete(`access-control/entry-schedule/${deletingEntryScheduleId}`),
    options
  );

  return {
    isDeletingEntrySchedule: !!deletingEntryScheduleId,
    deleteEntryScheduleMutation,
    setDeletingEntryScheduleId,
  };
};

export const useDeleteProfile = (
  options: UseMutationOptions<KyResponse, HTTPError>
) => {
  const ky = useKy();

  const [deletingProfileId, setDeletingProfileId] = useState<
    string | undefined
  >();

  const deleteProfileMutation = useMutation(
    () => ky.delete(`access-control/profile/${deletingProfileId}`),
    options
  );

  return {
    isDeletingProfile: !!deletingProfileId,
    deleteProfileMutation,
    setDeletingProfileId,
  };
};

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Flex,
  Stack,
  StackDivider,
  Text,
} from "@chakra-ui/react";
import React, { FC } from "react";

import { IntercomComponent, IntercomComponentType } from "src/common/types";

type IntercomComponentsProps = {
  components: IntercomComponent[];
};

const INTERCOM_COMPONENT_TYPES_FOR_DISPLAY: Record<
  IntercomComponentType,
  string
> = {
  [IntercomComponentType.ACCELEROMETER]: "Accelerometer",
  [IntercomComponentType.CAMERA]: "Camera",
  [IntercomComponentType.EEPROM]: "EEPROM",
  [IntercomComponentType.ETHERNET]: "Ethernet",
  [IntercomComponentType.IO_BOARD]: "IO Board",
  [IntercomComponentType.KEYPAD]: "Keypad",
  [IntercomComponentType.MAIN_BOARD]: "Motherboard",
  [IntercomComponentType.MICROPHONE]: "Microphone",
  [IntercomComponentType.MODEM]: "Modem",
  [IntercomComponentType.READER]: "Reader Board",
  [IntercomComponentType.SCREEN]: "Screen",
  [IntercomComponentType.SPEAKER]: "Speaker",
  [IntercomComponentType.TEMP_SENSOR]: "Temperature Sensor",
  [IntercomComponentType.TOUCH]: "Touchscreen",
};

export const IntercomComponents: FC<IntercomComponentsProps> = ({
  components,
}) => (
  <Accordion
    border="1px solid"
    borderColor="brand.lightGray.500"
    borderRadius="6px"
    allowToggle
  >
    <AccordionItem border="none">
      <AccordionButton>
        <Text
          as="span"
          fontSize="md"
          fontWeight="extrabold"
          flex={1}
          textAlign="left"
        >
          Intercom Components
        </Text>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel>
        {components?.length ? (
          <Stack divider={<StackDivider borderColor="brand.lightGray.500" />}>
            {components.map((component) => (
              <Flex key={component.componentId} justifyContent="space-between">
                <Text fontWeight="bold">
                  {
                    INTERCOM_COMPONENT_TYPES_FOR_DISPLAY[
                      component.componentType
                    ]
                  }
                </Text>
                <Text>{component.serialNumber}</Text>
              </Flex>
            ))}
          </Stack>
        ) : (
          <Flex justifyContent="center">
            <Text>No components registered</Text>
          </Flex>
        )}
      </AccordionPanel>
    </AccordionItem>
  </Accordion>
);

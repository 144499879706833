import * as React from "react";
import { Outlet } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { Loading } from "src/common/Loading";

export const AuthGate: React.FC = () => {
  const { isAuthenticated, loginWithRedirect, isLoading, user } = useAuth0();

  if (isLoading) {
    return <Loading />;
  }

  if (!isAuthenticated || !user) {
    loginWithRedirect();
    return null;
  }

  return <Outlet />;
};

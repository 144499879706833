import React from "react";
import { createIcon } from "@chakra-ui/react";

export const Cable = createIcon({
  displayName: "Cable",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      d="M3.27327 23.5383C2.96701 23.5383 2.71238 23.4369 2.50939 23.2339C2.3064 23.0309 2.2049 22.7762 2.2049 22.47V20.9742H0.922852V16.0597C0.922852 15.7535 1.02435 15.4988 1.22734 15.2958C1.43033 15.0929 1.68496 14.9914 1.99122 14.9914H3.48695V5.37595C3.48695 3.99419 3.98909 2.82966 4.99336 1.88236C5.99764 0.935069 7.2049 0.461426 8.61516 0.461426C9.98268 0.461426 11.1437 0.938637 12.0981 1.89306C13.0525 2.84746 13.5297 4.00842 13.5297 5.37595V18.6238C13.5297 19.4002 13.7986 20.0572 14.3363 20.595C14.8741 21.1327 15.5311 21.4016 16.3075 21.4016C17.1265 21.4016 17.8299 21.1363 18.4175 20.6057C19.0051 20.075 19.2989 19.4144 19.2989 18.6238V9.00841H17.8032C17.4969 9.00841 17.2423 8.90692 17.0393 8.70393C16.8363 8.50093 16.7348 8.24631 16.7348 7.94005V3.02553H18.0169V1.52979C18.0169 1.22353 18.1184 0.968904 18.3214 0.765913C18.5244 0.562922 18.779 0.461426 19.0853 0.461426H21.6494C21.9556 0.461426 22.2102 0.562922 22.4132 0.765913C22.6162 0.968904 22.7177 1.22353 22.7177 1.52979V3.02553H23.9998V7.94005C23.9998 8.24631 23.8983 8.50093 23.6953 8.70393C23.4923 8.90692 23.2377 9.00841 22.9314 9.00841H21.4357V18.6238C21.4357 20.0056 20.9335 21.1701 19.9293 22.1174C18.925 23.0647 17.7177 23.5383 16.3075 23.5383C14.9399 23.5383 13.779 23.0611 12.8246 22.1067C11.8701 21.1523 11.3929 19.9914 11.3929 18.6238V5.37595C11.3929 4.5996 11.1241 3.94255 10.5863 3.40479C10.0486 2.86704 9.39151 2.59816 8.61516 2.59816C7.79608 2.59816 7.09272 2.86348 6.50509 3.39412C5.91749 3.92474 5.62368 4.58535 5.62368 5.37595V14.9914H7.11942C7.42568 14.9914 7.68031 15.0929 7.8833 15.2958C8.08629 15.4988 8.18779 15.7535 8.18779 16.0597V20.9742H6.90574V22.47C6.90574 22.7762 6.80424 23.0309 6.60125 23.2339C6.39826 23.4369 6.14363 23.5383 5.83737 23.5383H3.27327Z"
    />
  ),
});
